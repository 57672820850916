<div id="dialog-editar-recurso" class="standard">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ 'dialogs.editar-recurso.titulo' | translate }}</h1>
    <div mat-dialog-content class="overflow-hidden">
        <mat-tab-group>
            <mat-tab *ngIf="data.comunes?.tipoOrigen?.valor === 'Plantilla' || editionModeService.moduloSeleccionado.propiedades?.tipoActividad?.valor?.editor" [label]="'dialogs.editar-recurso.tab6' | translate">
                <div [ngClass]="data.color" class="d-flex flex-column">
                    <p class="mt-3">{{'dialogs.edit-recurso.plantilla.editar' | translate}}</p>
                    <div class="justify-content-center mt-3">
                        <button role="button" (click)="editarPlantilla(data.comunes?.tipoOrigen?.valor === 'Plantilla')" class="default-button default-button__primary me-3">{{ 'dialogs.editar-recurso.editar-plantilla' | translate }}</button>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="data.comunes?.tipoOrigen?.valor !== 'Plantilla' && !editionModeService.moduloSeleccionado.propiedades?.tipoActividad?.valor?.editor" [label]="'dialogs.editar-recurso.tab1' | translate">
                <form role="form" [formGroup]="recursoForm" class="overflow-auto overflow-height-no-ck">
                    <fieldset class="w-100 d-flex flex-column mt-4">
                        <div class="d-flex flex-row">
                            <div class="form-group default-input w-100 mb-4 me-3">
                                <label for="name">{{ 'dialogs.editar-recurso.nombre-recurso' | translate }}</label>
                                <input id="name" aria-labelledby="error_name" type="text" class="form-control" [placeholder]="('dialogs.editar-recurso.nombre-recurso' | translate)" formControlName="name">
                                <mat-error id="error_name" *ngIf="recursoForm.get('name').hasError('required') && recursoForm.controls['name'].dirty">
                                    {{'generico.required' | translate}}
                                </mat-error>
                            </div>

                            <div class="form-group default-input w-100 mb-4">
                                <label for="author">{{ 'dialogs.editar-recurso.autor' | translate }}</label>
                                <input id="author" aria-labelledby="error_author" type="text" class="form-control" [placeholder]="('dialogs.editar-recurso.autor' | translate)" formControlName="author">
                                <mat-error id="error_author" *ngIf="recursoForm.get('author').hasError('required') && recursoForm.controls['author'].dirty">
                                    {{'generico.required' | translate}}
                                </mat-error>
                            </div>
                        </div>

                        <div class="form-group default-textarea w-100 mb-4">
                            <label for="description">{{'dialogs.editar-recurso.descripcion' | translate}}</label>
                            <textarea id="description" aria-labelledby="error_description" type="text" class="form-control" [placeholder]="('dialogs.editar-recurso.descripcion' | translate)" formControlName="description"></textarea>
                            <mat-error id="error_description" *ngIf="recursoForm.get('description').hasError('required') && recursoForm.controls['description'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>
                    </fieldset>
                </form>
            </mat-tab>

            <mat-tab [label]="'dialogs.editar-recurso.tab3' | translate">
                <div class="overflow-auto overflow-height-no-ck">
                    <div *ngIf="showAttachedFilesModal" class="p-3 my-3 attached_files-container">
                        <form role="form" [formGroup]="fileForm">
                            <fieldset class="w-100 d-flex flex-column">
                                <div class="d-flex flex-column form-group default-dropzone mb-4">
                                    <label for="fileField">{{ 'dialogs.adjuntar-fichero.upload-label' | translate }}</label>
                                    <div class="d-flex flex-column flex-md-row align-items-center">
                                        <div (dropZone)="onDrop($event.target.files[0])" (click)="fileField.click()" class="w-40 default-dropzone__container default-dropzone__container--white d-flex align-items-center justify-content-center">
                                            <mat-icon *ngIf="!fileForm.get('file').value" class="custom-icon icon">cloud_upload</mat-icon>
                                            <mat-icon *ngIf="fileForm.get('file').value" class="custom-icon icon">cloud_done</mat-icon>
                                        </div>

                                        <div class="ms-4 p-0 d-flex flex-column default-dropzone__text">
                                            <p class="mb-0">{{'dialogs.adjuntar-fichero.upload-info' | translate}}<a [matMenuTriggerFor]="menu">{{ (fileForm.get('file').value ? 'dialogs.editar-recurso.change' : 'dialogs.editar-recurso.add') | translate }}</a>{{'dialogs.adjuntar-fichero.upload-info2'
                                                | translate}}</p>
                                            <input id="fileField" type="file" name="file" #fileField (change)="onDrop($event.target.files[0])" hidden>
                                            <mat-menu #menu="matMenu" class="default-dropdown">
                                                <button mat-menu-item (click)="fileField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>{{ 'dialogs.editar-recurso.button.seleccionar-fichero' | translate }}</button>
                                                <button [disabled]="!fileForm.get('file').value" mat-menu-item (click)="deleteFile()"> <mat-icon class="material-icons-filled">delete</mat-icon>{{ 'dialogs.editar-recurso.button.borrar-fichero' | translate }}</button>
                                            </mat-menu>
                                            <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group default-input default-input--white mb-4">
                                    <label for="title">{{ 'dialogs.adjuntar-fichero.upload-file-name' | translate }}</label>
                                    <input id="title" type="text" class="form-control" [placeholder]="('dialogs.adjuntar-fichero.upload-file-placeholder' | translate)" formControlName="title" readonly>
                                </div>

                                <div class="justify-content-start mt-0 mb-0">
                                    <button role="button" class="default-button default-button__primary default-button__primary--transparent me-3" (click)="showAttachedFilesModal = !showAttachedFilesModal">{{ 'generico.cancelar' | translate }}</button>
                                    <button role="button" class="default-button default-button__primary default-button__primary--transparent" type="button" (click)="addFile()" [disabled]="(!fileForm.valid || fileForm.pristine)">{{ 'generico.adjuntar' | translate }}</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>

                    <div *ngIf="!showAttachedFilesModal" class="table-container">
                        <table #table mat-table cdkDropList multiTemplateDataRows class="w-100 mb-5 default-table standard" (cdkDropListDropped)="onListDrop($event)" [dataSource]="data.propiedades.adjuntos">

                            <ng-container matColumnDef="dragDrop">
                                <th class="w-25p" mat-header-cell *matHeaderCellDef></th>
                                <td class="ps-0 withC" mat-cell *matCellDef="let element">
                                    <div class="d-flex">
                                        <mat-icon class="drag-drop grey-icon">drag_indicator</mat-icon>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="titulo">
                                <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold" readonly>{{ 'dialogs.editar-recurso.adjuntos.titulo' | translate}}</th>
                                <td mat-cell *matCellDef="let element" class="titulo">
                                    <div class="d-flex align-items-center">
                                        <mat-icon class="grey-icon me-3">insert_drive_file</mat-icon>
                                        {{element.titulo_fichero}}
                                    </div>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="fichero">
                                <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold w-30">{{ 'dialogs.editar-recurso.adjuntos.tipo' | translate}}</th>
                                <td mat-cell *matCellDef="let element">{{element.tipo}} {{element.size}}</td>
                            </ng-container>

                            <ng-container matColumnDef="visibilidad">
                                <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold w-20">{{ 'dialogs.editar-recurso.adjuntos.visibilidad' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="d-flex align-items-center selector">
                                        <div class="pt-0 pe-2 selector-text">
                                            <mat-icon class="grey-icon" *ngIf="element.visible_alumno">person</mat-icon>
                                            <mat-icon class="grey-icon" *ngIf="!element.visible_alumno">person_off</mat-icon>
                                        </div>

                                        <div class="ps-0 pt-2">
                                            <mat-select class="default-dropdown custom-arrow" #selectorFiltro [(value)]="element.visible_alumno">
                                                <mat-option class="default-option" [value]="true" selected>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <!-- <mat-icon class="custom-icon">person</mat-icon> -->
                                                        <span>{{'dialogs.editar-recurso.adjuntos.option1' | translate}}</span>
                                                    </div>
                                                </mat-option>
                                                <mat-option class="default-option" [value]="false">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <!-- <mat-icon class="custom-icon">person_off</mat-icon> -->
                                                        <span>{{'dialogs.editar-recurso.adjuntos.option2' | translate}}</span>
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="acciones">
                                <th class="text-end pe-0 acciones w-10" mat-header-cell *matHeaderCellDef>
                                    <button class="custom-icon" *ngIf="!data.propiedades.adjuntos || data.propiedades.adjuntos.length < 10" mat-icon-button aria-label="Añadir" (click)="showAttachedFilesModal = !showAttachedFilesModal">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </th>
                                <td class="pe-0 text-end" mat-cell *matCellDef="let element; let i = index">
                                    <button class="custom-icon" mat-icon-button aria-label="Eliminar" (click)="removeFileRow(element)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row class="element" cdkDrag *matRowDef="let element; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
            </mat-tab>

            <mat-tab [label]="'dialogs.editar-recurso.tab4' | translate">
                <div mat-dialog-content [ngClass]="data.color" class="d-flex flex-column overflow-auto overflow-height">
                    <div class="ckeditor-container">
                        <div ckeditor [editor]="editor" [config]="config" [formControl]="recursoForm.controls['fichaAlumno']"></div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab [label]="'dialogs.editar-recurso.tab5' | translate">
                <div mat-dialog-content [ngClass]="data.color" class="d-flex flex-column overflow-auto overflow-height">
                    <div class="ckeditor-container">
                        <div ckeditor [editor]="editor" [config]="config" [formControl]="recursoForm.controls['fichaProfesor']"></div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__primary" (click)="onClose()" type="submit">{{ 'generico.guardar' | translate }}</button>
    </div>
</div>
