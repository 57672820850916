import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'aula-planeta-modificar-tema',
  templateUrl: 'modificar-tema.component.html',
  styleUrls: ['./modificar-tema.component.scss'],
})
export class ModificarTemaComponent implements OnInit {
  form: UntypedFormGroup;
  file = null;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ModificarTemaComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      author: ['', Validators.required],
      shared: [false],
      img: ['']
    });
  }

  /**
   * Submit create new theme
   */
  onSubmit() {

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.form.value);
  }

  onDrop(e) {
    this.file = (e as HTMLInputElement);
    const url = URL.createObjectURL(this.file);

    // Set files form control
    this.form.patchValue({
      img: url
    });
    this.form.get('img').updateValueAndValidity();
  }

  deleteImg() {
    this.form.patchValue({
      img: null
    });

    this.form.get('img').updateValueAndValidity();

  }
}