import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'aula-planeta-editar-recurso-confirm',
  templateUrl: './editar-recurso-confirm.component.html',
  styleUrls: ['./editar-recurso-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditarRecursoConfirmComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditarRecursoConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }


  ngOnInit() {}
}
