import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MainPlataformaComponent } from './main-plataforma/main-plataforma.component';
import { MainMoodleComponent } from './main-moodle/main-moodle.component';
import { routeAnimations } from '../../core/core.module';
import { AuthManagementService } from '../../core/auth/auth-management.service';
import { MainVoidComponent } from './main-void/main-void.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'aula-planeta-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routeAnimations]
})
export class MainComponent implements OnInit {
  isMoodle = false;

  mainDinamico: any;

  constructor(
    private authManagementService: AuthManagementService,
    private router: Router
  ) {
    this.isMoodle = this.authManagementService.userCanPermission(['AccesoLtiTareas']);
  }

  ngOnInit() {
    // Comprobar si el parámetro `fs` es igual a '1'
    const urlSegments = this.router.url.split('/');

    // Verificar si alguno de los segmentos contiene "void"
    if (urlSegments.includes('void')) {
      // Cargar `MainVoidComponent` si la URL contiene "void"
      this.mainDinamico = MainVoidComponent;
    } else if (this.isMoodle) {
      // Cargar `MainMoodleComponent` si tiene permiso de Moodle
      this.mainDinamico = MainMoodleComponent;
    } else {
      // Cargar `MainPlataformaComponent` en otros casos
      this.mainDinamico = MainPlataformaComponent;
    }
  }
}
