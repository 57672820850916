import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Tarea } from '../../../shared/models/tarea.model';
import { CuadernoEvaluacionData, TareaDataService } from './tarea-data.service';
import { take, takeUntil } from 'rxjs/operators';
import { AuthManagementService } from '../../../core/auth/auth-management.service';
import { Alumno } from '../../../shared/models/alumno.model';
import { GtagService } from '../../../services/gtag/gtag.service';
import { TemasManagementService } from '../temas/temas-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';

@Injectable({
  providedIn: 'root'
})
export class TareaManagementService implements OnDestroy {

  tareas$: Observable<Tarea[]>;
  tarea$: Observable<Tarea>;
  alumnosTareas$: Observable<Alumno[]>;
  listadoTareasAlumno$: Observable<Alumno[]>;
  alumnosActividades$: Observable<Alumno[]>;
  notificaciones$: Observable<Tarea[]>;
  moduloAsignadosComoTareaEnPlataforma$: Observable<any[]>;
  moduloAsignadosComoTareaAlumnoEnPlataforma$: Observable<any[]>;
  moduloAsignadosComoTareaEnMoodle$: Observable<any[]>;
  moduloAsignadosComoTareaAlumnoEnMoodle$: Observable<any[]>;
  moduloAsignadosComoTareaMoodle$: Observable<any[]>;
  cuadernoEvaluacion$: Observable<any[]>
  cursosCuadernoEvaluacion$: Observable<any[]>
  asignaturasCuadernoEvaluacion$: Observable<any[]>

  openCaducada = true;
  saveOnChange = false;

  private _tareas: BehaviorSubject<Tarea[]> = new BehaviorSubject<Tarea[]>(null);
  private _tarea: BehaviorSubject<Tarea> = new BehaviorSubject<Tarea>(null);
  private _alumnosTareas: BehaviorSubject<Alumno[]> = new BehaviorSubject<Alumno[]>(null);
  private _listadoTareasAlumno: BehaviorSubject<Alumno[]> = new BehaviorSubject<Alumno[]>(null);
  private _alumnosActividades: BehaviorSubject<Alumno[]> = new BehaviorSubject<Alumno[]>(null);
  private _notificaciones: BehaviorSubject<Tarea[]> = new BehaviorSubject<Tarea[]>(null);
  private _moduloAsignadosComoTareaEnPlataforma: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  private _moduloAsignadosComoTareaAlumnoEnPlataforma: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  private _moduloAsignadosComoTareaEnMoodle: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  private _moduloAsignadosComoTareaAlumnoEnMoodle: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  private _moduloAsignadosComoTareaAlumno: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  private _moduloAsignadosComoTareaMoodle: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  private _cuadernoEvaluacion: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null)
  private _cursosCuadernoEvaluacion: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null)
  private _asignaturasCuadernoEvaluacion: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null)


  private destroy$: Subject<void> = new Subject<void>();

  noResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private tareaDataService: TareaDataService,
    private authManagementService: AuthManagementService,
    private gtagService: GtagService,
    private temasManagementService: TemasManagementService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private localCacheService: LocalCacheService

  ) {
    this.tareas$ = this._tareas.asObservable();
    this.tarea$ = this._tarea.asObservable();
    this.listadoTareasAlumno$ = this._listadoTareasAlumno.asObservable();
    this.alumnosTareas$ = this._alumnosTareas.asObservable();
    this.alumnosActividades$ = this._alumnosActividades.asObservable();
    this.moduloAsignadosComoTareaEnPlataforma$ = this._moduloAsignadosComoTareaEnPlataforma.asObservable();
    this.moduloAsignadosComoTareaAlumnoEnPlataforma$ = this._moduloAsignadosComoTareaAlumnoEnPlataforma.asObservable();
    this.moduloAsignadosComoTareaEnMoodle$ = this._moduloAsignadosComoTareaEnMoodle.asObservable();
    this.moduloAsignadosComoTareaAlumnoEnMoodle$ = this._moduloAsignadosComoTareaAlumnoEnMoodle.asObservable();
    this.moduloAsignadosComoTareaMoodle$ = this._moduloAsignadosComoTareaMoodle.asObservable();
    this.notificaciones$ = this._notificaciones.asObservable();
    this.cuadernoEvaluacion$ = this._cuadernoEvaluacion.asObservable();
    this.cursosCuadernoEvaluacion$ = this._cursosCuadernoEvaluacion.asObservable();
    this.asignaturasCuadernoEvaluacion$ = this._asignaturasCuadernoEvaluacion.asObservable();

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get tarea() {
    return this._tarea.getValue();
  }

  get tareas() {
    return this._tareas.getValue();
  }

  set tareas(tareas: Tarea[]) {
    this._tareas.next(tareas);
  }

  set listadoTareasAlumno(listadoTareasAlumno: Alumno[]) {
    this.listadoTareasAlumnoNext(listadoTareasAlumno);
  }

  get listadoTareasAlumno() {
    return this._listadoTareasAlumno.getValue();
  }

  set alumnosTareas(alumnosTareas: Alumno[]) {
    this.alumnosTareasNext(alumnosTareas);
  }

  get alumnosTareas() {
    return this._alumnosTareas.getValue();
  }

  set alumnosActividades(alumnosActividades: Alumno[]) {
    this.alumnosActividadesNext(alumnosActividades);
  }

  get alumnosActividades() {
    return this._alumnosActividades.getValue();
  }





  get moduloAsignadosComoTareaEnPlataforma() {
    return this._moduloAsignadosComoTareaEnPlataforma.getValue();
  }

  get moduloAsignadosComoTareaAlumnoEnPlataforma() {
    return this._moduloAsignadosComoTareaAlumnoEnPlataforma.getValue();
  }

  get moduloAsignadosComoTareaEnMoodle() {
    return this._moduloAsignadosComoTareaEnMoodle.getValue();
  }

  get moduloAsignadosComoTareaAlumnoEnMoodle() {
    return this._moduloAsignadosComoTareaAlumnoEnMoodle.getValue();
  }

  get moduloAsignadosComoTareaMoodle() {
    return this._moduloAsignadosComoTareaMoodle.getValue();
  }

  set moduloAsignadosComoTareaEnPlataforma(moduloAsignadosComoTarea: any[]) {
    this._moduloAsignadosComoTareaEnPlataforma.next(moduloAsignadosComoTarea);
  }

  set moduloAsignadosComoTareaAlumnoEnPlataforma(moduloAsignadosComoTareaAlumno: any[]) {
    this._moduloAsignadosComoTareaAlumnoEnPlataforma.next(moduloAsignadosComoTareaAlumno);
  }

  set moduloAsignadosComoTareaEnMoodle(moduloAsignadosComoTarea: any[]) {
    this._moduloAsignadosComoTareaEnMoodle.next(moduloAsignadosComoTarea);
  }

  set moduloAsignadosComoTareaAlumnoEnMoodle(moduloAsignadosComoTareaAlumno: any[]) {
    this._moduloAsignadosComoTareaAlumnoEnMoodle.next(moduloAsignadosComoTareaAlumno);
  }

  set moduloAsignadosComoTareaMoodle(moduloAsignadosComoTareaMoodle: any[]) {
    this._moduloAsignadosComoTareaMoodle.next(moduloAsignadosComoTareaMoodle);
  }

  get cuadernoEvaluacion() {
    return this._cuadernoEvaluacion.getValue();
  }

  set cuadernoNext(listadoCuaderno: any) {
    this._cuadernoEvaluacion.next(listadoCuaderno);
  }

  isModuloAsignadosComoTarea(moduloId, moduloAsignadosComoTarea): boolean {
    return moduloAsignadosComoTarea?.find(moduloAsignado => moduloAsignado.moduloId === moduloId);
  }

  isModuloAsignadosComoTareaAlumno(moduloId: string, estados: string[], modulosAsignadosComoTareaAlumno): boolean {
    return this.getModuloAsignadoComoTareaAlumno(moduloId, estados, modulosAsignadosComoTareaAlumno) !== undefined;
  }

  getModuloAsignadoComoTareaAlumno(moduloId: string, estados: string[], modulosAsignadosComoTareaAlumno: any[]): any | undefined {
    return modulosAsignadosComoTareaAlumno?.find(
      moduloAsignado =>
        moduloAsignado.moduloId === moduloId &&
        estados.includes(moduloAsignado.estado)
    );
  }

  isModuloAsignadosComoTareaMoodle(moduloId): boolean {
    return this.moduloAsignadosComoTareaMoodle?.find(moduloAsignadoMoodle => moduloAsignadoMoodle.moduloId === moduloId);
  }

  getTareas() {
    const isProfesor = this.authManagementService.currentUser.usuarioRoles.includes('Profesor');
    this.tareasNext(null);
    this.tareaDataService.getTareas(isProfesor).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.tareasNext(res);
        }
      );
  }

  getTareasMoodle() {
    const isProfesor = this.authManagementService.currentUser.usuarioRoles.includes('Profesor');
    this.tareasNext(null);
    this.tareaDataService.getTareasMoodle(isProfesor).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.tareasNext(res);
        }
      );
  }

  getTarea(isAutonoma: boolean, id: string, reload = true) {
    const isProfesor = this.authManagementService.currentUser.usuarioRoles.includes('Profesor');
    if (reload) {
      this.tareaNext(null);
      this.alumnosActividadesNext(null);
    }
    this.tareaDataService.getTarea(isAutonoma, isProfesor, id).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.tareaNext(res);
          this.alumnosActividadesNext(res.alumnosTareas);
        }
      );
  }

  getTareaMoodle(isAutonoma: boolean, id: string, reload = true) {
    const isProfesor = this.authManagementService.currentUser.usuarioRoles.includes('Profesor');
    if (reload) {
      this.tareaNext(null);
      this.alumnosActividadesNext(null);
    }
    this.tareaDataService.getTareaMoodle(isAutonoma, isProfesor, id).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.tareaNext(res);
          this.alumnosActividadesNext(res.alumnosTareas);
        }
      );
  }


  getTareasDelAlumnoTarea(id: string) {
    const isProfesor = this.authManagementService.currentUser.usuarioRoles.includes('Profesor');
    this.alumnosTareasNext(null);
    this.tareaDataService.getTareasDelAlumno(id, isProfesor).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.alumnosTareasNext(res);
          // this.tareasNext(res[0].tareas);
        }
      );
  }

  getTareasDelAlumnoMoodle(id: string) {
    const isProfesor = this.authManagementService.currentUser.usuarioRoles.includes('Profesor');
    this.alumnosTareasNext(null);
    this.tareaDataService.getTareasDelAlumnoMoodle(id, isProfesor).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.alumnosTareasNext(res);
          // this.tareasNext(res[0].tareas);
        }
      );
  }

  getTrabajoDelAlumno() {
    this.listadoTareasAlumnoNext(null);
    this.tareaDataService.getTrabajoDelAlumno().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.listadoTareasAlumnoNext(res);
        }
      );
  }

  getTrabajoDelAlumnoMoodle() {
    this.listadoTareasAlumnoNext(null);
    this.tareaDataService.getTrabajoDelAlumnoMoodle().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.listadoTareasAlumnoNext(res);
        }
      );
  }

  getNotificacionesTareasDelAlumno() {
    this.notificacionesTareas([]);
    this.tareaDataService.getNotificacionesTareasDelAlumno().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.notificacionesTareas(res);
        }
      );
  }

  getModuloAsignadosComoTareaEnPlataforma(temaId) {
    this.tareaDataService.getModuloAsignadosComoTareaEnPlataforma(temaId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(response => {
      this.moduloAsignadosComoTareaEnPlataforma = response;
    });
  }

  getModuloAsignadosComoTareaAlumnoEnPlataforma(temaId) {
    this.tareaDataService.getModuloAsignadosComoTareaAlumnoEnPlataforma(temaId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(response => {
      this.moduloAsignadosComoTareaAlumnoEnPlataforma = response;
    });
  }

  getModuloAsignadosComoTareaEnMoodle(temaId, contextId) {
    this.tareaDataService.getModuloAsignadosComoTareaEnMoodle(temaId, contextId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(response => {
      this.moduloAsignadosComoTareaEnMoodle = response;
    });
  }

  getModuloAsignadosComoTareaAlumnoEnMoodle(temaId, contextId) {
    this.tareaDataService.getModuloAsignadosComoTareaAlumnoEnMoodle(temaId, contextId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(response => {
      this.moduloAsignadosComoTareaAlumnoEnMoodle = response;
    });
  }

  getModuloAsignadosComoTareaMoodle(temaId, contextid) {
    this.tareaDataService.getModuloAsignadosComoTareaMoodle(temaId, contextid).pipe(
      takeUntil(this.destroy$)
    ).subscribe(response => {
      this.moduloAsignadosComoTareaMoodle = response;
    });
  }

  createTarea(tarea: any, event?: PointerEvent) {
    this.tareaDataService.createTarea(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.temasManagementService.getTema(this.temasManagementService.tema.id);
        this.getModuloAsignadosComoTareaEnPlataforma(this.temasManagementService.tema.id);
      },
        err => {
          const info = {
            event: 'popup_steps_error',
            popupStepsError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-asignar-tarea',
              popupName: 'popup_asignar_tarea',
              popupStep: '2'
            }
          };
          this.gtagService.event(info);
        });
  }

  createTareaMoodle(tarea: any, contextId) {
    this.localCacheService
      .expire(`TEMASDATASERVICE-GETTEMA-${this.temasManagementService.tema.id}`).pipe(take(1), takeUntil(this.destroy$)).subscribe();
    this.tareaDataService.createTareaMoodle(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        const mns = this.translateService.instant('dialogs.asignar-tarea.moodle.creada');
        this.snackBar.open(mns, 'X', {
          duration: 4000,
          panelClass: 'success-notification-overlay-new'
        });
        this.temasManagementService.getTema(this.temasManagementService.tema.id);
        this.getModuloAsignadosComoTareaEnMoodle(this.temasManagementService.tema.id, contextId);
      },
        err => {
          const info = {
            event: 'popup_steps_error',
            popupStepsError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-asignar-tarea',
              popupName: 'popup_asignar_tarea',
              popupStep: '2'
            }
          };
          this.gtagService.event(info);
        });
  }

  createTareaClassroom(tarea: any) {
    this.tareaDataService.createTareaClassroom(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        // this.getTareas();
        this.temasManagementService.getTema(this.temasManagementService.tema.id);

      },
        error => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: error?.status,
              errorMessage: error?.statusText,
              popupId: 'dialog-asignar-tarea-classroom',
              popupName: 'popup_asignar_tarea_classroom'
            }
          };
          this.gtagService.event(info);
        });
  }

  createTareaTeams(tarea: any) {
    this.tareaDataService.createTareaTeams(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        // this.getTareas();
        this.temasManagementService.getTema(this.temasManagementService.tema.id);

      },
        error => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: error?.status,
              errorMessage: error?.statusText,
              popupId: 'dialog-asignar-tarea-teams',
              popupName: 'popup_asignar_tarea_teams'
            }
          };
          this.gtagService.event(info);
        });
  }

  enviarTablonClassroom(tablon: any) {
    this.tareaDataService.enviarTablonClassroom(tablon).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        // this.getTareas();
      },
        error => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: error?.status,
              errorMessage: error?.statusText,
              popupId: 'dialog-start-compartir',
              popupName: 'popup_compartir'
            }
          };
          this.gtagService.event(info);
        });
  }

  editarTarea(tarea: any) {
    return this.tareaDataService.editarTarea(tarea);
  }

  editarTareaMoodle(tarea: any) {
    this.tareaDataService.editarTareaMoodle(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTareas();
      },
        err => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-editar',
              pageId: 'popup_editar'
            }
          };
          this.gtagService.event(info);
        });
  }

  reasignarTarea(tarea: any) {
    return this.tareaDataService.reasignarTarea(tarea);
  }

  validarReasignarTarea(body: any) {
    return this.tareaDataService.validarReasignarTarea(body);
  }

  validarActualizarTarea(body: any) {
    return this.tareaDataService.validarActualizarTarea(body);
  }

  reasignarAlumnos(body: any) {
    return this.tareaDataService.reasignarAlumnos(body);
  }

  repetirTarea(tarea: any) {
    this.tareaDataService.repetirTarea(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTareas();
        this.getTarea(false, tarea.id, true);

      },
        err => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-repetir_tarea',
              pageId: 'popup_repetir_tarea'
            }
          };
          this.gtagService.event(info);
        });
  }

  repetirTareaAlumnosSeleccionados(tarea: any) {
    this.tareaDataService.repetirTareaAlumnosSeleccionados(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTareas();
      },
        err => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-repetir_tarea',
              pageId: 'popup_repetir_tarea'
            }
          };
          this.gtagService.event(info);
        });
  }

  evaluarActividad(tarea: any, idEvidencia: string, data: any) {
    this.tareaDataService.evaluarActividad(tarea.autonoma, idEvidencia, data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTarea(tarea.autonoma, tarea.id, false);
      });
  }

  repetirActividad(data: any) {
    this.tareaDataService.repetirActividad(data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {

        this.getTarea(data.isAutonoma, data.id, false);
      });
  }

  realizarActividad(data: any) {
    this.tareaDataService.realizarActividad(data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTarea(false, data.idTarea, false);
      });
  }

  ocultarTareaVencida(data: any) {
    this.tareaDataService.ocultarTareaVencida(data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe();
  }

  duplicarTarea(tarea: any) {
    this.tareaDataService.duplicarTarea(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTareas();
      },
        err => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-duplicate',
              pageId: 'popup_duplicate'
            }
          };
          this.gtagService.event(info);
        });
  }

  desasignarContenido(tarea: any) {
    this.tareaDataService.desasignarContenido(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTareas();
      },
        err => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-unassign',
              pageId: 'popup_unassign'
            }
          };
          this.gtagService.event(info);
        });
  }

  adjuntarfichero(adjunto: any) {
    this.tareaDataService.adjuntarFichero(adjunto).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTareas();
      },
        err => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-adjuntar-fichero',
              pageId: 'popup_attach_file'
            }
          };
          this.gtagService.event(info);
        });
  }

  deleteTarea(tarea: Tarea) {
    this.tareaDataService.deleteTarea(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTareas();
      },
        err => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-delete',
              pageId: 'popup_delete'
            }
          };
          this.gtagService.event(info);
        });
  }

  deleteTareaByIds(ids: number[]) {
    this.tareaDataService.deleteTareaByIds(ids).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTareas();
      },
        err => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-delete',
              pageId: 'popup_delete'
            }
          };
          this.gtagService.event(info);
        });
  }

  deleteTareaMoodle(tarea: Tarea) {
    this.tareaDataService.deleteTareaMoodle(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getTareas();
      },
        err => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-delete',
              pageId: 'popup_delete'
            }
          };
          this.gtagService.event(info);
        });
  }

  descargarCsv(tarea: Tarea) {
    this.tareaDataService.descargarCsv(tarea).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        () => { },
        err => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: err?.status,
              errorMessage: err?.error?.title || err?.statusText,
              popupId: 'dialog-start-compartir',
              pageId: 'popup_share'
            }
          };
          this.gtagService.event(info);
        });
  }

  descargarListadoAlumnosCSV(alumnoId, ids) {
    return this.tareaDataService.descargarListadoAlumnosCSV(alumnoId, ids).pipe(
      take(1),
      takeUntil(this.destroy$));
  }

  descargarListadoActividadesCSV(tareaId, ids) {
    return this.tareaDataService.descargarListadoActividadesCSV(tareaId, ids).pipe(
      take(1),
      takeUntil(this.destroy$));
  }

  descargarListadoNotasCSV(ids) {
    return this.tareaDataService.descargarListadoNotasCSV(ids).pipe(
      take(1),
      takeUntil(this.destroy$));
  }

  descargarAlumnosSeleccionadosCSV(alumnosId) {
    return this.tareaDataService.descargarAlumnosSeleccionadosCSV(alumnosId).pipe(
      take(1),
      takeUntil(this.destroy$));
  }


  // CUADERNO DE EVALUACIÓN

  getCuadernoEvaluacion(data: CuadernoEvaluacionData) {
    // eslint-disable-next-line max-len
    this.tareaDataService.getCuadernoEvaluacion(data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this._cuadernoEvaluacion.next(res);
        }
      );

  };

  getCursosCuadernoEvaluacion() {
    this.tareaDataService.getCursosCuadernoEvaluacion()
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(res => this.cursosCuadernoEvaluacion = res);
  }


  getAsignaturasCuadernoEvaluacion() {
    this.tareaDataService.getAsignaturasCuadernoEvaluacion()
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(res => this._asignaturasCuadernoEvaluacion.next(res));
  }



  get cursosCuadernoEvaluacion() {
    return this._cursosCuadernoEvaluacion.getValue();
  }

  set cursosCuadernoEvaluacion(value: any) {
    this._cursosCuadernoEvaluacion.next(value);
  }

  get asignaturasCuadernoEvaluacion() {
    return this._asignaturasCuadernoEvaluacion.getValue();
  }

  set asignaturasCuadernoEvaluacion(value: any) {
    this._asignaturasCuadernoEvaluacion.next(value);
  }





  private tareaNext(tarea: Tarea) {
    this._tarea.next(tarea);
  }

  private tareasNext(tareas: Tarea[]) {
    this._tareas.next(tareas);
  }

  private listadoTareasAlumnoNext(alumnos: Alumno[]) {
    this._listadoTareasAlumno.next(alumnos);
  }

  private alumnosTareasNext(alumnos: Alumno[]) {
    this._alumnosTareas.next(alumnos);
  }

  private alumnosActividadesNext(alumnos: Alumno[]) {
    this._alumnosActividades.next(alumnos);
  }

  private notificacionesTareas(notificaciones: Tarea[]) {
    this._notificaciones.next(notificaciones);
  }



  getAssignmentText(moduloId: string, assignments?: any[]): string {
    let _assignments: any[] = [];
    if (assignments) {
      _assignments = assignments;
    } else {
      _assignments = this.moduloAsignadosComoTareaEnPlataforma
        ? this.moduloAsignadosComoTareaEnPlataforma.filter(item => item.moduloId === moduloId)
        : [];

    }

    if (_assignments.length === 0) {
      return 'No hay asignaciones para este módulo.';
    }

    const texts = _assignments.map(assignment => {
      const fechaInicio = this.formatDate(assignment.fechaInicioVigencia);
      const fechaFin = this.formatDate(assignment.fechaFinVigencia);

      let asignadoA = '';

      switch (assignment.tipoAsignacion) {
        case 'Clase':
          asignadoA = `al grupo ${assignment.grupoId}`;
          break;
        case 'GrupoPersonalizado':
          asignadoA = `al grupo personalizado "${assignment.grupoPersonalizadoNombre}"`;
          break;
        case 'SeleccionAlumnos':
          asignadoA = 'a una selección de alumnos';
          break;
        default:
          asignadoA = 'a un grupo desconocido';
      }

      return `·Tarea activa del ${fechaInicio} al ${fechaFin}, asignado ${asignadoA}\n\n`;
    });

    return texts.join('');
  }


  formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    const day = ('0' + date.getDate()).slice(-2); // Asegura dos dígitos
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Meses de 0 a 11
    const year = date.getFullYear().toString().slice(-2); // Últimos dos dígitos del año
    return `${day}.${month}.${year}`;
  }






}
