import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColorHelper } from '../../../helper/color.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { MiCentroManagementService } from '../../../../features/mi-centro/services/mi-centro-management.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'aula-planeta-configurar-clase',
  templateUrl: './configurar-clase.component.html',
  styleUrls: ['./configurar-clase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurarClaseComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  selectedCurso: any = null;
  selectedGrupo: any = null;

  constructor(
    public colorHelper: ColorHelper,
    public dialogRef: MatDialogRef<ConfigurarClaseComponent>,
    public miCentroManagementService: MiCentroManagementService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    this.miCentroManagementService.getCursosGrupos();
  }

  ngOnInit() {
    this.miCentroManagementService.cursos$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(curso => console.log('CURSO', curso));

    this.miCentroManagementService.grupos$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(gr => console.log('GRUPOS', gr));

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-configurar-aulaplaneta',
        popupName: 'popup_configurar_aulaplaneta'
      }
    };
    this.gtagService.event(info);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  close() {
    this.dialogRef.close({ cursoId: this.selectedCurso, grupoId: this.selectedGrupo });
  }
}
