import { Router } from '@angular/router';
import { Breadcrumb } from './../shared/models/breadcrumb.model';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService implements OnDestroy {

  public offSetBreadcrumb = 0;

  visible$: Observable<boolean>;
  private _visible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  breadcrumbs$: Observable<Breadcrumb[]>;
  private _breadcrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router
  ) {
    this.breadcrumbs$ = this._breadcrumbs.asObservable();
    this.visible$ = this._visible.asObservable();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get breadcrumbs() {
    return this._breadcrumbs.getValue();
  }

  set breadcrumbs(breadcrumbs: Breadcrumb[]) {
    this._breadcrumbs.next(breadcrumbs);
  }

  get visible() {
    return this._visible.getValue();
  }

  set visible(visible: boolean) {
    this._visible.next(visible);
  }

  navigateLink?(link) {
    if (link) {
      this.router.navigate([link]);
    }
  }

  breadCrumbClickAction(isLink) {
    if (isLink){
      document.getElementById('breadcrumbs').classList.add('fade-out-in');
      // document.querySelector('.default-breadcrumb').classList.add('fade-out-in');
    }
  }

}
