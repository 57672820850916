import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ColorHelper {
  colors: any = [
    'verdeCN', 
    'rojoM', 
    'verdeFQ', 
    'naranjaT', 
    'lilaL', 
    'marronI', 
    'marronMS', 
    'grisOM', 
    'azulG', 
    'religion', 
    'educacionFisica', 
    'musica', 
    'plastica', 
    'valoresCivicos', 
    'frances'
  ];

  getColor(color) {
    return (color && this.colors.includes(color)) ? color : 'standard';
  }
}
