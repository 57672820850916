import { Component, OnInit, ChangeDetectionStrategy, Inject, ApplicationRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { ColorHelper } from '../../../helper/color.helper';

@Component({
  selector: 'aula-planeta-generic',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericComponent implements OnInit {

  checkboxValue = false;

  constructor(
    public dialogRef: MatDialogRef<GenericComponent>,
    public colorHelper: ColorHelper,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService,

  ) { }

  ngOnInit() {
    console.log('GenericComponent data:', this.data);
    const info = {
      event: 'popup',
      popup: {
        pageId: this.data?.event?.view?.location.hash,
        popupId: 'dialog-generico',
        popupName: 'popup_generic_dialog'
      }
    };
    this.gtagService.event(info);

  }

  close(result) {
    this.dialogRef.close(result);
  }

  getTypeButton(type) {
    return (type === 'primary') ? 'default-button__primary' : 'default-button__secondary';
  }

  toggleCheckbox() {
    this.checkboxValue = !this.checkboxValue;
  }

}
