import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { AuthManagementService } from '../../../core/auth/auth-management.service';
import { RecursoPlataformaComponent } from './recurso-plataforma/recurso-plataforma.component';
import { RecursoMoodleComponent } from './recurso-moodle/recurso-moodle.component';

@Component({
  selector: 'aula-planeta-recurso-view',
  templateUrl: './recurso-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecursoViewComponent implements OnInit {
  isMoodle = false;
  recursoDinamico: any;

  constructor(private authManagementService: AuthManagementService) {
    this.isMoodle = this.authManagementService.userCanPermission(['AccesoLtiTareas']);
  }

  ngOnInit() {
    if (this.isMoodle) {
      this.recursoDinamico = RecursoMoodleComponent;
    } else {
      this.recursoDinamico = RecursoPlataformaComponent;
    }

  }
}