import { Injectable, OnDestroy } from '@angular/core';
import { AuthManagementService } from '../../../core/auth/auth-management.service';
import * as microsoftTeams from '@microsoft/teams-js';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export const CURRENT_USER = 'CURRENT_USER';

@Injectable({
  providedIn: 'root'
})

export class MicrosoftTeamsService implements OnDestroy {

  context$: Observable<any>;
  protected _context: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private authManagementService: AuthManagementService) {
    this.context$ = this._context.asObservable();
    if (this.authManagementService.currentUser?.integraciones?.microsoft) {
      try {
        const promiseInitialize = microsoftTeams.app.initialize();
        promiseInitialize.catch(error => console.log(error));
        microsoftTeams.app.getContext().then((context) => {
          this.context = context;
        });
      } catch (error) {
        console.log(error);
        return;
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get context() {
    return this._context.getValue();
  }

  set context(context: any) {
    this._context.next(context);
  }
}
