<div [class]="'theme-wrapper ' + (settingsService.settings$  | async | settings: 'theme') + ' ' + changeBg(router.url)">

    <div overflow class="wrapper" id="wrapper">

        <div class="content" (scroll)="eventoScroll($event)" [ngClass]="{
        'mt-0 content-moodle-legacy': ((ltiManagementService.moodle$ | async) && !(editionModeService.enable$ | async)),
        'm-0': smartlinkManagementService.smartlink && temasManagementService.fullscreen,
        'super-fullscreen': (temasManagementService.fullscreen || (editionModeService.enable$ | async))}" [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.data && o.activatedRoute.routeConfig.data.title">

            <mat-sidenav-container role="main" [hasBackdrop]="true" [ngClass]="{
              'open-tasklist': (taskListService.open$ | async),
              'mat-sidenav-width': !smartlinkManagementService.smartlink && !temasManagementService.fullscreen || smartlinkManagementService.smartlink,
              'mat-sidenav-full': temasManagementService.fullscreen || ((microsoftTeamsService.context$ | async) && (microsoftTeamsService.context$ | async).page?.id === 'Tab'),
              'mat-sidenav-lti': (ltiManagementService.moodle$ | async)
            }">
                <router-outlet #o="outlet"></router-outlet>
            </mat-sidenav-container>

            <ng-template [hasRole]="['Alumno']">
                <ng-container *ngIf="((!(ltiManagementService.moodle$ | async) && !(microsoftTeamsService.context$ | async) )
                  || ((microsoftTeamsService.context$ | async) && (microsoftTeamsService.context$ | async).page.id !== 'Tab')) &&
                  !temasManagementService.fullscreen">

                    <div *ngIf="tareaManagementService.notificaciones$ | async as notificaciones" class="task-list menu-column position-fixed d-flex flex-column align-items-center pt-4" [ngClass]="(taskListService.open$ | async) ? 'open' : ''">

                        <!-- NOTIFICACIONES -->
                        <ng-template [hasRole]="['Alumno']">
                            <button mat-icon-button (click)="openNotifications($event);" [matBadge]="countTareas(notificacionesTareas)" [matBadgeHidden]="(taskListService.open$ | async) || !countTareas(notificacionesTareas)" matTooltipPosition="below" [matTooltip]="(taskListService.open$ | async) ? ('notificaciones.icon' | translate) : ('notificaciones.titulo' | translate)"
                                [attr.aria-label]="(taskListService.open$ | async) ? ('notificaciones.icon' | translate) : ('notificaciones.titulo' | translate)" class="default-button default-button--xs default-button--circle default-button__primary--transparent task-menu-btn default-badge mb-2">

                                <mat-icon *ngIf="!(taskListService.open$ | async)"
                                    class="default-text">content_paste</mat-icon>
                                <mat-icon *ngIf="(taskListService.open$ | async)"
                                    class="grey-text__light">chevron_right</mat-icon>
                            </button>
                        </ng-template>

                        <mat-icon *ngIf="(taskListService.open$ | async)" class="title-icon position-absolute grey-text__lightest" matTooltipPosition="below" [matTooltip]="'materia.mis-temas.tabla.acciones.recargar' | translate" [attr.aria-label]="'materia.mis-temas.tabla.acciones.recargar' | translate">

                            pending_actions
                        </mat-icon>

                        <div *ngIf="(taskListService.open$ | async)" class="h-100 d-flex flex-column justify-content-between">

                            <div class="overflow-auto">
                                <div *ngIf="!countTareas(notificacionesTareas)" class="w-100 text-center text__small mb-3 px-3">
                                    <span>{{'notificaciones.tareas.no-tareas' | translate}}</span>
                                </div>

                                <div *ngFor="let item of notificacionesTareas" class="w-100 text-center mb-3 container-text">
                                    <ng-container *ngIf="item.tareas.length > 0">

                                        <header class="grey-text" [id]="dateHelper.getOffsetLocal(item.fecha) | date: 'dd MMM y'">
                                            {{ dateHelper.getOffsetLocal(item.fecha) | date: 'dd MMM y' }}
                                        </header>

                                        <ul class="text-start mx-3 mt-2 list-unstyled">
                                            <li *ngFor="let tarea of item.tareas" [ngClass]="colorHelper.getColor(tarea.materia.color)">
                                                <div class="task-item d-flex mb-3" tabindex="0" (click)="routeTarea(tarea.id)" (keyup.enter)="routeTarea(tarea.id)">
                                                    <mat-icon class="custom-icon">assignment</mat-icon>
                                                    <div class="d-flex flex-column ms-2">
                                                        <span class="cursor-pointer link grey-text font-weight-bold">{{tarea.titulo}}</span>
                                                        <span class="text__light mt-1">{{tarea.materia.curso.ordinal}}
                                                            {{tarea.materia.curso.etapa.acronimo}},
                                                            {{tarea.materia.nombre}}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>

                                    </ng-container>
                                </div>

                                <div class="w-100 text-center mb-3 px-3">
                                    <a routerLink="/inicio/tareas" class="text__small grey-text" [ngClass]="router.url == '/inicio/tareas' ? 'disable-link' : ''">{{'notificaciones.tareas.ver-todas'
                                        | translate}}</a>
                                </div>
                            </div>

                            <ng-template [hasRole]="['Alumno']">
                                <div *ngIf="countTareas(notificacionesTareas)" class="tasklist-calendar__container">
                                    <mat-calendar [minDate]="minDate" [maxDate]="maxDate" (monthSelected)="getCalendarBadges()" (selectedChange)="navigateToTaskList($event)" class="tasklist-calendar" [(selected)]="selectedDate" role="application">
                                    </mat-calendar>
                                </div>
                            </ng-template>

                        </div>

                    </div>
                </ng-container>
            </ng-template>
        </div>

    </div>

</div>
