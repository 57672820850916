/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { AddModulesBaseComponent } from '../../add-modules-base.component';

@Component({
  selector: 'aula-planeta-add-module-enlace',
  templateUrl: './enlace.component.html',
  styleUrls: ['./enlace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnlaceComponent extends AddModulesBaseComponent {

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EnlaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    super(fb, dialogRef, data);
  }

  ngOnInit() {
    const isLink = '((http|https|ftp|file)://)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';

    this.formModule = this.fb.group({
      titulo_html: [this.data && this.data.propiedades.titulo_html ? this.data.propiedades.titulo_html : '', Validators.required],
      descripcion_html: [this.data && this.data.propiedades.descripcion_html ? this.data.propiedades.descripcion_html : ''],
      visible: [this.data && this.data.propiedades.visible === 'Profesores' ? true : false],
      enlace: [this.data && this.data.propiedades.enlace ? this.data.propiedades.enlace : '', [Validators.required, Validators.pattern(isLink)]],
      abrir_en_ventana_nueva: [this.data && this.data.propiedades.abrir_en_ventana_nueva ? this.data.propiedades.abrir_en_ventana_nueva : false, Validators.required]
    });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-add-module-enlace',
        popupName: 'popup_añadir_enlace'
      }
    };
    this.gtagService.event(info);
  }


  onClose() {
    if (this.formModule.status === 'VALID') {
      const formData = this.formModule.value;
      const result = {
       
       
        
          titulo_html: this.formModule.get('titulo_html') ? this.formModule.get('titulo_html').value : '',
          descripcion_html: formData.descripcion_html,
          visible: this.formModule.get('visible').value ? 'Profesores' : 'Alumnos',
          enlace: formData.enlace,
          abrir_en_ventana_nueva: formData.abrir_en_ventana_nueva
        
      };

      console.log(result)
      this.dialogRef.close(result);
    }
  }
}





