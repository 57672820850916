import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskListService implements OnDestroy {

  open$: Observable<boolean>;
  private _open: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$: Subject<void> = new Subject<void>();

  constructor() {
    this.open$ = this._open.asObservable();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get open() {
    return this._open.getValue();
  }

  set open(open: boolean) {
    this._open.next(open);
  }
}
