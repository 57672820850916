<div id="dialog-cambiar-contrasena" class="standard">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.cambiar-contrasena.title' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <div class="overflow-auto">
            <form role="form" [formGroup]="form">
                <fieldset class="w-100 d-flex flex-column">
                    <div class="form-group default-input position-relative w-100">
                        <label for="oldPassword">{{'perfil.contrasena.old' | translate}}</label>
                        <input id="oldPassword" aria-labelledby="error_oldPassword_1 error_oldPassword_2" [type]="revealOld ? 'text' : 'password'" class="form-control" [placeholder]="('perfil.contrasena.old' | translate)" formControlName="oldPassword">
                        <button (click)="revealOld = !revealOld" class="m-0 p-0 position-absolute default-text btn-icon d-flex justify-content-center align-items-center" mat-icon-button type="button">
                            <mat-icon>{{revealOld ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>

                        <mat-error id="error_oldPassword_1" *ngIf="form.get('oldPassword').hasError('minlength') && form.controls['oldPassword'].dirty">
                            {{'generico.minlength' | translate:{minlength: form.get('oldPassword').errors.minlength.requiredLength} }}
                        </mat-error>
                        <mat-error id="error_oldPassword_2" *ngIf="form.get('oldPassword').hasError('required') && form.controls['oldPassword'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                    <div class="form-group default-input position-relative w-100">
                        <label for="newPassword">{{'perfil.contrasena.new' | translate}}</label>
                        <input id="newPassword" aria-labelledby="error_newPassword_1 error_newPassword_2 error_newPassword_3" [type]="revealNew ? 'text' : 'password'" class="form-control" [placeholder]="('perfil.contrasena.new' | translate)" formControlName="newPassword">
                        <button (click)="revealNew = !revealNew" class="m-0 p-0 position-absolute default-text btn-icon d-flex justify-content-center align-items-center" mat-icon-button type="button">
                            <mat-icon>{{revealNew ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <div class="d-flex flex-column">
                            <mat-error id="error_newPassword_1" *ngIf="form.get('newPassword').hasError('pattern') && form.controls['newPassword'].dirty">
                                {{'generico.formulario.segura' |translate}}
                            </mat-error>
                            <mat-error id="error_newPassword_2" *ngIf="form.get('newPassword').hasError('minlength') && form.controls['newPassword'].dirty">
                                {{'mi-centro.gestion-contrasenas.validator.minlength' | translate:{minlength: form.get('newPassword').errors.minlength.requiredLength} }}
                            </mat-error>
                            <mat-error id="error_newPassword_3" *ngIf="form.get('newPassword').hasError('required') && form.controls['newPassword'].dirty">
                                {{'mi-centro.gestion-contrasenas.validator.required' | translate}}
                            </mat-error>
                            <span class="w-100 default-hint pt-2">Mínimo de 8 caracteres y letras</span>
                        </div>

                    </div>
                    <div class="form-group default-input position-relative w-100">
                        <label for="repeatNewPassword">{{'perfil.contrasena.confirm' | translate}}</label>
                        <input id="repeatNewPassword" aria-labelledby="error_repeatNewPassword" [type]="revealRepeat ? 'text' : 'password'" class="form-control" [placeholder]="('perfil.contrasena.confirm' | translate)" formControlName="repeatNewPassword">
                        <button (click)="revealRepeat = !revealRepeat" class="m-0 p-0 position-absolute default-text btn-icon d-flex justify-content-center align-items-center" mat-icon-button type="button">
                            <mat-icon>{{revealRepeat ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error id="error_repeatNewPassword" *ngIf="form.errors?.mismatch && (form.controls['repeatNewPassword'].dirty || form.controls['repeatNewPassword'].touched)">
                            {{'perfil.contrasena.error_confirm' | translate}}
                        </mat-error>
                    </div>
                </fieldset>
            </form>
        </div>

    </div>

    <div mat-dialog-actions class="justify-content-start mt-2 mb-0 pb-0">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__primary" (click)="onSubmit()" [disabled]="form.invalid" type="submit">{{ 'generico.guardar' | translate }}</button>
    </div>
</div>
