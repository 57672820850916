import { Directive, Input, OnInit, OnDestroy, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as DecoupledEditor from '../../../../assets/js/ck-editor-math-type/ckeditor';

@Directive({
  selector: '[ckeditor]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CkeditorDirective),
      multi: true
    }
  ]
})
export class CkeditorDirective implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() config: any;
  @Input() editor: any;
  @Input() data: any;
  @Input() disabled: boolean;

  private instance: any;
  private onChange: (value: any) => void;
  private onTouched: () => void;
  private initialValue: any;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.initializeEditor();
  }

  ngOnDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  }

  private initializeEditor() {
    this.editor.create(this.el.nativeElement, this.config)
      .then(editor => {
        this.instance = editor;
        this.instance.isReadOnly = this.disabled;

        // Attach the toolbar to the DOM
        const toolbarContainer = document.createElement('div');
        toolbarContainer.classList.add('ck-toolbar-container');
        this.el.nativeElement.parentElement.insertBefore(toolbarContainer, this.el.nativeElement);
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);

        // Set initial value
        if (this.data) {
          this.instance.setData(this.data);
        } else if (this.initialValue) {
          this.instance.setData(this.initialValue);
        }

        // Listen to editor changes
        this.instance.model.document.on('change:data', () => {
          const data = this.instance.getData();
          if (this.onChange) {
            this.onChange(data);
          }
        });

        // Setup touch handler
        this.instance.editing.view.document.on('blur', () => {
          if (this.onTouched) {
            this.onTouched();
          }
        });

      })
      .catch(err => {
        console.error(err.stack);
      });
  }

  writeValue(value: any): void {
    if (this.instance) {
      this.instance.setData(value);
    } else {
      this.initialValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.instance) {
      this.instance.isReadOnly = isDisabled;
    }
  }
}
