<div id="dialog-add-module-texto">
    <h1 class="default-text" mat-dialog-title>{{ data ? ('dialogs.edit-module-base.texto.title'| translate) : ('dialogs.add-module-base.texto.title' | translate) }}</h1>

    <div mat-dialog-content class="overflow-auto standard mat-dialog-content">
        <div class="row">
            <form role="form" [formGroup]="formModule" class="w-100">
                <div class="col-md-12">
                    <div class="w-100 d-flex flex-row-reverse justify-content-between align-items-center actions-row py-2">
                        <div class="d-flex flex-row">
                            <button mat-icon-button (click)="toggleDisabled()" class="default-text">
                  <!-- <mat-icon *ngIf="!isDisabled">save</mat-icon>
                  <mat-icon *ngIf="isDisabled">mode_edit</mat-icon> -->
                </button>
                        </div>
                    </div>
                    <div ckeditor #editorTexto [config]="config" [editor]="editor" formControlName="contenido_html"></div>
                </div>
                <div class="form-group my-3 ms-3">
                    <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="visible">{{ 'dialogs.add-module-base.form.visible-profesor' | translate }}</mat-slide-toggle>
                </div>
            </form>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" (click)="onClose()"  >{{ 'generico.guardar' | translate }}</button>
    </div>
</div>
