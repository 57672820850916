import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { GestionAlumnoManagementService } from '../../../../services/data/gestion-alumno/gestion-alumno-management.service';
import { ColorHelper } from '../../../helper/color.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'aula-planeta-ocultar-unidad-alumnado',
  templateUrl: 'ocultar-unidad-alumnado.component.html',
  styleUrls: ['./ocultar-unidad-alumnado.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OcultarUnidadAlumnadoComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  alumnosForm: UntypedFormGroup;
  selection: SelectionModel<null>;
  selectionDisponibles: SelectionModel<null>;
  selectionAsignados: SelectionModel<null>;

  alumnos: any[] = [];
  alumnosTemp: any[] = [];
  alumnosAsignados: any[] = [];
  grupos: any[] = [];
  changed = false;
  selectedOption: any = 'oculto';
  options: any[] = [
    {
      value: 'oculto',
      viewValue: 'dialogs.ocultar-unidad-alumnado.select.option.ocultar'
    },
    {
      value: 'profesores',
      viewValue: 'dialogs.ocultar-unidad-alumnado.select.option.profesores'
    },
    {
      value: 'alumnos',
      viewValue: 'dialogs.ocultar-unidad-alumnado.select.option.alumnos'
    },
    {
      value: 'manual',
      viewValue: 'dialogs.ocultar-unidad-alumnado.select.option.manual'
    },
  ]

  constructor(
    private fb: UntypedFormBuilder,
    public colorHelper: ColorHelper,
    public dialogRef: MatDialogRef<OcultarUnidadAlumnadoComponent>,
    public gestionAlumnoManagementService: GestionAlumnoManagementService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    this.selection = new SelectionModel<null>(true, []);
    this.selectionDisponibles = new SelectionModel<null>(true, []);
    this.selectionAsignados = new SelectionModel<null>(true, []);
  }

  ngOnInit() {
    this.alumnosForm = this.fb.group({
      alumnosAsignados: new UntypedFormControl([], Validators.required),
      curso: new UntypedFormControl(''),
      nombreGrupo: new UntypedFormControl('')
    });

    this.gestionAlumnoManagementService.getMisClases();
    this.gestionAlumnoManagementService.getMisAlumnos();
    this.gestionAlumnoManagementService.misClases$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(clases => {
        this.grupos[0] = clases.map(el => ({
          nombre: `${el.curso.ordinal} ${el.curso?.etapa?.acronimo} ${el.grupo.id}`,
          clase: { curso: el.curso, grupo: el.grupo },
          alumnos: el.alumnos,
          tipoAsignacion: 'Clase'
        }));
      });

    this.gestionAlumnoManagementService.getMisGruposPersonalizados();
    this.gestionAlumnoManagementService.misGruposPersonalizados$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(gruposPersonalizados => {
        this.grupos[1] = gruposPersonalizados.map(el => ({
          nombre: el.nombre,
          alumnos: el.alumnos,
          tipoAsignacion: 'GrupoPersonalizado'
        }));
      });

    if (this.data.visible) {
      if (this.data.visible === 'Alumnos' && this.data.alumnosIds !== null) {
        this.selectedOption = 'manual';

        const alumnosIds = this.data.alumnosIds.map(alumno => Math.abs(alumno));
        this.gestionAlumnoManagementService.misAlumnos$
          .pipe(
            takeUntil(this.destroy$))
          .subscribe(alumnos => {
            const alumnosFiltered: any = alumnos.filter(alumno => alumnosIds.includes(alumno.id))
            console.log(alumnosFiltered);

            if (alumnosFiltered && alumnosFiltered.length > 0) {
              const curso = {
                // eslint-disable-next-line max-len
                nombre: `${alumnosFiltered[0].clase?.curso.ordinal} ${alumnosFiltered[0].clase.curso?.etapa?.acronimo} ${alumnosFiltered[0].clase.grupo.id}`,
                clase: { curso: alumnosFiltered[0].clase.curso, grupo: alumnosFiltered[0].clase.grupo },
                tipoAsignacion: 'Clase'
              };
              this.alumnosForm.controls['curso'].setValue(curso.nombre);
              this.alumnosAsignados = alumnosFiltered;
              this.setAlumnosList(curso);
            }
          })
      } else {
        this.selectedOption = this.data.visible.toLowerCase();
      }
    }

    const info = {
      event: 'popup_steps',
      popupSteps: {
        popupId: 'dialog-asignar-tarea',
        popupName: 'popup_asignar_tarea',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  onClose(hideUnidad: boolean = true) {
    let result;

    if (this.selectedOption === 'manual') {
      result = { visible: 'Alumnos', alumnosIds: this.alumnosAsignados.map(alumno => hideUnidad ? -Math.abs(alumno.id) : alumno.id) }
    } else if (this.selectedOption === 'profesores') {
      result = { visible: 'Profesores' }
    } else if (this.selectedOption === 'alumnos') {
      result = { visible: 'Alumnos' }
    } else {
      result = { visible: 'Oculto' }
    }

    this.dialogRef.close(result);
  }

  setAlumnosList(grupo) {
    let alumnos;
    // si no hay clase es que es un grupo personalizado y trae el array de alumnos
    if (!grupo.clase) {
      alumnos = grupo.alumnos;
    } else {
      alumnos = this.gestionAlumnoManagementService.getListaAlumnosEnUnaClase(grupo.clase, this.gestionAlumnoManagementService.misAlumnos);
    }
    this.alumnosTemp = (alumnos && alumnos.length > 0) ? alumnos : [];
    this.alumnos = this.alumnosTemp.filter(alumno => !this.alumnosAsignados.some(aluAsi => aluAsi.id === alumno.id));
  }

  asignarAlumnos() {
    this.alumnosAsignados = this.alumnosAsignados.concat(this.selectionDisponibles.selected);

    this.alumnosForm.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.alumnos = this.alumnos.filter(alumno => !this.selectionDisponibles.selected.includes(alumno));
    this.selectionDisponibles.clear();
  }

  asignarTodos() {
    this.alumnos.forEach((row) => this.selectionDisponibles.select(row));
    this.asignarAlumnos();
  }

  desasignarAlumnos() {
    this.alumnos = this.alumnos.concat(this.alumnosTemp.filter(alumno => this.selectionAsignados.selected.includes(alumno)));

    this.alumnosAsignados = this.alumnosAsignados.filter(alumno => !this.selectionAsignados.selected.includes(alumno));
    this.alumnosForm.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.selectionAsignados.clear();
  }

  desasignarTodos() {
    this.alumnosAsignados.forEach((row) => this.selectionAsignados.select(row));
    this.desasignarAlumnos();
  }
}
