import { GestionAlumnoManagementService } from '../../../../services/data/gestion-alumno/gestion-alumno-management.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateHelper } from '../../../helper/date.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-asignar-tarea-teams',
  templateUrl: './asignar-tarea-teams.component.html',
  styleUrls: ['./asignar-tarea-teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DateHelper]
})
export class AsignarTareaTeamsComponent implements OnInit {
  form: UntypedFormGroup;

  minDate = new Date(Date.now());
  maxDate = new Date(2045, 0, 1);

  grupos: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AsignarTareaTeamsComponent>,
    public gestionAlumnoManagementService: GestionAlumnoManagementService,
    public dateHelper: DateHelper,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
  }

  ngOnInit() {

    this.form = this.fb.group({
      curso: ['', Validators.required],
      name: ['', Validators.required],
      inicio: [this.minDate, Validators.required],
      fin: ['', Validators.required],
      nota: [10, [Validators.required, Validators.pattern('^[0-9]*$')]],
      comentario: ['', Validators.maxLength(255)]
    });

    this.gestionAlumnoManagementService.getMisClasesTeams();

    const info = {
      event: 'popup',
      popup: {
        action: 'asignar en teams',
        popupId: 'dialog-asignar-tarea-teams',
        popupName: 'popup_asignar_tarea_teams'
      }
    };
    this.gtagService.event(info);

  }

  onClose() {
    console.log('STEP 2 ::', this.form.value);

    // console.log('RESULT', {...this.firstStep.value, ...this.form.value, result});
    this.dialogRef.close({
      curso: this.form.value.curso,
      name: this.form.value.name,
      inicio: this.dateHelper.setToUTC(this.dateHelper.setFechaInicio(this.form.value.inicio)),
      fin: this.dateHelper.setToUTC(this.dateHelper.setFechaFin(this.form.value.fin)),
      nota: this.form.value.nota,
      comentario: this.form.value.comentario,
    });
  }

}
