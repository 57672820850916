<div *ngIf="!(ltiManagementService.preview$ | async)" id="highlights" class="d-flex align-items-center" [ngClass]="show ? 'show' : ''">
    <div>
        <div class="highlights-list mat-elevation-z3 open">
            <mat-list id="highlights-list" class="highlights-list__list p-0">
                <mat-list-item role="list" class="h-auto">
                    <ng-container *ngIf="!highlightsModeService.removeMode">
                        <mat-list-item role="listitem">
                            <mat-icon class="orange p-0 m-l2 m-1" (touchstart)="$event.preventDefault(); $event.stopPropagation();colorSelector('orange', 'touch');" (click)="$event.preventDefault(); $event.stopPropagation();colorSelector('orange', 'click');" [ngClass]="{ 'active-button' : lastSelectedColor == 'orange' }">lens</mat-icon>
                        </mat-list-item>
                        <mat-list-item role="listitem">
                            <mat-icon class="green p-0 m-1" (touchstart)="$event.preventDefault(); $event.stopPropagation();colorSelector('green', 'touch');" (click)="$event.preventDefault(); $event.stopPropagation();colorSelector('green', 'click');" [ngClass]="{ 'active-button' : lastSelectedColor == 'green' }">lens</mat-icon>
                        </mat-list-item>
                        <mat-list-item role="listitem">
                            <mat-icon class="red p-0 m-1" (touchstart)="$event.preventDefault(); $event.stopPropagation();colorSelector('red', 'touch');" (click)="$event.preventDefault(); $event.stopPropagation();colorSelector('red', 'click');" [ngClass]="{ 'active-button' : lastSelectedColor == 'red' }">lens</mat-icon>
                        </mat-list-item>
                        <mat-list-item role="listitem">
                            <mat-icon class="purple p-0 m-1" (touchstart)="$event.preventDefault(); $event.stopPropagation();colorSelector('purple', 'touch');" (click)="$event.preventDefault(); $event.stopPropagation();colorSelector('purple', 'click');" [ngClass]="{ 'active-button' : lastSelectedColor == 'purple' }">lens</mat-icon>
                        </mat-list-item>
                        <mat-list-item role="listitem">
                            <mat-icon class="blue p-0 m-1 me-2" (touchstart)="$event.preventDefault(); $event.stopPropagation();colorSelector('blue', 'touch');" (click)="$event.preventDefault(); $event.stopPropagation();colorSelector('blue', 'click');" [ngClass]="{ 'active-button' : lastSelectedColor == 'blue' }">lens</mat-icon>
                        </mat-list-item>
                    </ng-container>
                    <ng-container *ngIf="highlightsModeService.removeMode">
                        <mat-list-item role="listitem">
                            <mat-icon class="custom-icon p-0 m-2" (touchstart)="$event.preventDefault(); $event.stopPropagation(); remove();" (click)="$event.preventDefault(); $event.stopPropagation(); remove();">delete</mat-icon>
                        </mat-list-item>
                    </ng-container>
                </mat-list-item>
            </mat-list>
        </div>
    </div>

</div>