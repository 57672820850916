<mat-drawer-container>
    <mat-drawer class="sidenav"  mode="side" opened [position]="sidenavPosition">
        <div class="buttons-position">
            <button mat-button [mat-dialog-close]="true"><mat-icon class="gray">close</mat-icon></button>
            <div class="actions">
                <button (click)="pencil();" [ngClass]="{ 'active-button' : isValue == 1 }" mat-button><mat-icon>create</mat-icon></button>
                <button (click)="marker();" [ngClass]="{ 'active-button' : isValue == 2 }" mat-button><mat-icon>brush</mat-icon></button>
                <button (click)="eraseDrawing();" mat-button><mat-icon>delete</mat-icon></button>
                <button (click)="rubber();" [ngClass]="{ 'active-button' : isValue == 4 }" mat-button><img class="rubber-icon" src="assets/icons/rubber.png"/></button>
            </div>
            
            <div class="colors" *ngIf="showColors">
                <button (click)="colorSelector('blue');" [ngClass]="{ 'active-button' : lastSelectedColor == 'blue' }" mat-button><mat-icon class="blue">lens</mat-icon></button>
                <button (click)="colorSelector('green');" [ngClass]="{ 'active-button' : lastSelectedColor == 'green' }" mat-button><mat-icon class="green">lens</mat-icon></button>
                <button (click)="colorSelector('red');" [ngClass]="{ 'active-button' : lastSelectedColor == 'red' }" mat-button><mat-icon class="red">lens</mat-icon></button>
                <button (click)="colorSelector('orange');" [ngClass]="{ 'active-button' : lastSelectedColor == 'orange' }" mat-button><mat-icon class="orange">lens</mat-icon></button>
                <button (click)="colorSelector('black');" [ngClass]="{ 'active-button' : lastSelectedColor == 'black' }" mat-button><mat-icon class="black">lens</mat-icon></button>
                <button class="button-white" (click)="colorSelector('white');" [ngClass]="{ 'active-button' : lastSelectedColor == 'white' }" mat-button><mat-icon class="white material-icons-outlined">lens</mat-icon></button>
            </div>
        </div>
    </mat-drawer>

    <mat-drawer-content [ngClass]="{'cursor-rubber': cursorStyle, 'cursor-pencil': !cursorStyle}">
        <canvas #canvas [width]="windowWidth" [height]="windowHeight"></canvas>
    </mat-drawer-content>
</mat-drawer-container>
    