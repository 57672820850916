import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthManagementService } from '../../core/core.module';
import { SmartlinkManagementService } from '../data/smartlink/smartlink-management-service.service';
import { TemasManagementService } from '../data/temas/temas-management.service';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { Subject } from 'rxjs';
import { take, takeUntil, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RetroSmartlinkBancoService{

  public smartlink = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private smartlinkManagementService: SmartlinkManagementService,
    private authManagementService: AuthManagementService,
    private temasManagementService: TemasManagementService,
    private localStorageService: LocalStorageService
  ) { }

 
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('retrobanco');

    this.authManagementService.redirectUrl = state.url as any;
    const tmp$: Subject<void> = new Subject<void>();
    const recurso = route.queryParams['recurso'];
    const token = await this.getToken();
    this.smartlinkManagementService.getRetroSmartlinkBanco(recurso).pipe(
      take(1),
      takeUntil(tmp$))
        .subscribe(
        res => {
          tmp$.next();
          tmp$.complete();
          window.open(`${res}&plano=true`, '_self');
        },
        _err => {
          localStorage.removeItem('redirectUrl');
          window.open('', '_self');
        }
      );
    return false;

  }

  private formarUrl(idMateria, idTema, idRecurso) {
    if (idRecurso) {
      return `/inicio/mis-materias/materia/${idMateria}/tema/${idTema}/recurso/${idRecurso}`;
    } else {
      return `/inicio/mis-materias/materia/${idMateria}/tema/${idTema}`;
    }
  }

  private async getToken() {
    return await this.localStorageService.getItem('JWT').pipe(first()).toPromise();
  }
}
