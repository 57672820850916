<div id="dialog-editar-metados">
    <h1 mat-dialog-title class="d-flex align-items-center">{{ 'dialogs.editar-metadatos.titulo' | translate }}</h1>

    <form role="form" [formGroup]="form">
        <fieldset>
            <div class="row mt-3">
                <div class="col-md-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{ 'dialogs.editar-metadatos.label.nivel-dificultad' | translate }}</mat-label>
                        <mat-select formControlName="dificultades">
                            <mat-option *ngFor="let dificultad of dificultades" [value]="dificultad.value">
                                {{dificultad.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{ 'dialogs.editar-metadatos.label.accion-didactica' | translate }}</mat-label>
                        <mat-select formControlName="acciones">
                            <mat-option *ngFor="let accion of acciones" [value]="accion.value">
                                {{accion.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{ 'dialogs.editar-metadatos.label.tiempo' | translate }}</mat-label>
                        <mat-select formControlName="tiempos">
                            <mat-option *ngFor="let tiempo of tiempos" [value]="tiempo.value">
                                {{tiempo.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>



            <h1 mat-dialog-title class="d-flex align-items-center">{{ 'dialogs.editar-metadatos.competencias' | translate }}</h1>

            <div class="row mt-3">
                <div class="col-md-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{ 'dialogs.editar-metadatos.label.nivel-1' | translate }}</mat-label>
                        <mat-select formControlName="niveles1">
                            <mat-option *ngFor="let nivel1 of niveles1" [value]="nivel1.value">
                                {{nivel1.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{ 'dialogs.editar-metadatos.label.nivel-2' | translate }}</mat-label>
                        <mat-select formControlName="niveles2">
                            <mat-option *ngFor="let nivel2 of niveles2" [value]="nivel2.value">
                                {{nivel2.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </form>

    <div mat-dialog-actions class="flex-row-reverse justify-content-start">
        <button role="button" mat-raised-button color="accent" [mat-dialog-close]="form.value" class="ms-2">{{ 'dialogs.editar-metadatos.aceptar' | translate }}</button>
        <button role="button" mat-button color="warning" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
    </div>
</div>
