import { Injectable } from '@angular/core';
import { FormularioIncidenciasService } from './formulario-incidencias.service';
import { Incidencia } from '../shared/models/incidencia.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormularioIncidenciasManagementService {

  constructor(private incidenciasService:FormularioIncidenciasService) {

  }

  postIncidencia(data: Incidencia): Observable<any> {
    return this.incidenciasService.postIncidencia(data);
  }

}