<div id="dialog-descargar-csv" class="standard">
    <div mat-dialog-content class="d-flex w-100 position-relative m-0 p-0">
        <button class="close-button default-button default-button__primary position-absolute me-1 mt-1" mat-dialog-close aria-label="{{'dialogs.close' | translate}}">
            <mat-icon>close</mat-icon>
        </button>
        <div class="col-2 p-0 custom-icon">
            <mat-progress-spinner *ngIf="showSpinner" color="warn" diameter="80" aria-label="Cargando"></mat-progress-spinner>
            <mat-icon *ngIf="!showSpinner" class="mt-2">download</mat-icon>
        </div>
        <div class="col-9 d-flex flex-column justify-content-between">
            <h1 mat-dialog-title class="d-flex align-items-center custom-text mb-3">{{ 'dialogs.download-csv.title' | translate }}</h1>
            <!-- <p class="mb-0">{{ 'dialogs.unassign-students.message' | translate }}</p> -->
            <div mat-dialog-actions class="justify-content-start mt-3">
                <button [disabled]="showSpinner" role="button" class="default-button default-button__primary" (click)="onDownload()" cdkFocusInitial>{{ 'dialogs.download-csv.button' | translate }}</button>
            </div>
        </div>
    </div>
</div>