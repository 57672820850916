// WIREFRAME - D02

import { Component, OnInit, ChangeDetectionStrategy, Inject, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { EnvService } from '../../../../services/env.service';
import { TemasManagementService } from '../../../../services/data/temas/temas-management.service';

@Component({
  selector: 'aula-planeta-programacion-aula',
  templateUrl: './programacion-aula.component.html',
  styleUrls: ['./programacion-aula.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramacionAulaComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  displayedColumns: string[] = [
    'select',
    'tema'
  ];

  @ViewChild('iframe', { read: ElementRef }) iframe: ElementRef;

  urlSafe: SafeResourceUrl;
  showSpinner = false;
  openedScorm = false;

  // selection: SelectionModel<any>;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ProgramacionAulaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private sanitizer: DomSanitizer,
    private envService: EnvService,
    private temasManagementService: TemasManagementService,
    private gtagService: GtagService
  ) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      temas: new UntypedFormArray([]),
      materia: [this.data.materia.id, Validators.required],
      curso: [this.data.materia.curso.id, Validators.required]
    });
    this.patch();

    this.urlSafe = this.formarUrl(this.data.url);

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-programacion-aula',
        popupName: 'popup_programacion_aula',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
    // this.selection = new SelectionModel(true, []);
    window.addEventListener('message', (e) => {
      console.log(e);
      this.close(null);
    });
    // this.selection = new SelectionModel(true, []);
    window.addEventListener('cerrar', (e) => {
      console.log(e);
      this.close(null);
    });
    window.addEventListener('*', (e) => {
      console.log(e);
    });

  }

  ngOnDestroy() {
    this.openedScorm = false;
    this.temasManagementService.fullscreen = this.openedScorm;
    // const scormElement = (document.querySelector('.wrapper .content') as HTMLElement);
    // scormElement.removeAttribute('style');
  }


  patch() {
    const control = <UntypedFormArray>this.form.get('temas');
    this.data.temas.forEach((tema: { id: any; tituloHtml: any; checked: any; }) => {
      control.push(this.patchValues(tema.id, tema.tituloHtml, tema.checked));
    });
  }

  patchValues(id: any, name: any, checked: any) {
    return this.fb.group({
      id: [id],
      name: [name],
      // checked: [checked],
    });
  }

  openScorm(event: PointerEvent) {
    this.openedScorm = !this.openedScorm;
    this.temasManagementService.fullscreen = this.openedScorm;

    // const scormElement = (document.querySelector('.wrapper .content') as HTMLElement);
    // scormElement.style.marginTop = this.openedScorm ? '0' : '5em';

    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: this.openedScorm ? 'expand_scorm' : 'shrink_scorm',
        pageId: event.view.location.hash,
      }
    };
    this.gtagService.event(info);
  }

  formarUrl(url) {
    if (!url) {
      url = '/assets/images/not-found-ap.png';
    } else {
      url = this.envService.rootContentNoCDN + url;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  close(result: any) {
    if (result) {
      const formData = {
        materia: this.form.get('materia').value,
        curso: this.form.get('curso').value
        // temas: this.selection.selected.map(tema => tema.id)
      };
      this.dialogRef.close(formData);
    } else {
      this.dialogRef.close();
    }
  }

  loadIframe() {
    this.showSpinner = true;
    if (this.iframe) {
      this.showSpinner = false;
    }
  }


}
