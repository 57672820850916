<div id="dialog-descargar-notas" class="standard">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.descargar-notas.aceptar' | translate }}</h1>

    <div>
        <p>{{ 'dialogs.descargar-notas.cuerpo1' | translate }}<b> {{data.materia}}</b></p>
    </div>

    <div mat-dialog-content class="mat-dialog-content">
        <form role="form" [formGroup]="form">
            <fieldset>
                <div class="d-flex flex-column flex-md-row mt-2  mb-3">
                    <div class="form-group w-50 me-2">
                        <label for="tituloMateria" class="default-text">{{ 'seguimiento-alumno.tarea.titulo.materia' | translate }}</label>
                        <mat-select id="tituloMateria" cdkFocusInitial class="default-select default-select__blue" [placeholder]="'seguimiento-alumno.tarea.titulo.materia' | translate" formControlName="materias" panelClass="cdkSelect">
                            <ng-container *ngFor="let materia of materias">
                                <mat-option class="default-option" [value]="materia">
                                    {{ materia.nombre }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>
                    <ng-template [hasRole]="['Profesor']">
                        <div class="form-group w-50 me-3">
                            <label for="clase" class="default-text">{{ 'tareas.tabla.clase' | translate }}</label>
                            <mat-select id="clase" cdkFocusInitial class="default-select default-select__blue" [placeholder]="'tareas.tabla.clase' | translate" formControlName="clases" panelClass="cdkSelect">
                                <ng-container *ngFor="let clase of clases">
                                    <mat-option class="default-option" [value]="clase">
                                        {{ clase.curso.ordinal }} {{ clase.curso.etapa.acronimo }} {{ clase.grupo.id }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </div>
                    </ng-template>
                </div>


                <div class="d-flex flex-column flex-md-row mt-2 mb-3">
                    <div class="w-50 form-group default-input mb-4 position-relative me-2">
                        <label for="fechaInicio">{{ 'dialogs.asignar-tarea.step2.fecha-inicio' | translate }}</label>
                        <input id="fechaInicio" type="text" class="form-control" [min]="minDate" [max]="maxDate" [matDatepicker]="pickerInicio" (click)="pickerInicio.open()" [placeholder]="('dialogs.asignar-tarea.step2.fecha-inicio' | translate)" formControlName="inicio" tabindex="2">
                        <mat-datepicker-toggle matSuffix [for]="pickerInicio" class="datepicker-icon default-text position-absolute">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerInicio startView="year" [startAt]="now"></mat-datepicker>
                    </div>

                    <div class="w-50 form-group default-input mb-4 position-relative me-3">
                        <label for="fechaFin">{{ 'dialogs.asignar-tarea.step2.fecha-fin' | translate }}</label>
                        <input id="fechaFin" [min]="form.get('inicio').value" [max]="maxDate" [matDatepicker]="pickerFin" (click)="pickerFin.open()" type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.fecha-fin' | translate)" formControlName="fin" tabindex="3">
                        <mat-datepicker-toggle matSuffix [for]="pickerFin" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFin startView="year" [startAt]="now"></mat-datepicker>
                    </div>
                </div>

                <div mat-dialog-actions class="justify-content-start mt-2 mb-0 pb-0">
                    <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
                    <button role="button" class="default-button default-button__secondary" type="submit" (click)="close(true, $event)" [disabled]="!form.valid || form.pristine">{{ 'generico.guardar' | translate }}</button>
                </div>

            </fieldset>
        </form>
    </div>
</div>