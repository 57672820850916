import {
  transition,
  trigger,
} from '@angular/animations';
import { slideTo } from './animations.service';

export const tabAnimations = trigger('tabAnimations', [
  transition(':increment', slideTo('right')),
  transition(':decrement', slideTo('left')),
]);
