import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ColorHelper } from '../../../helper/color.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { LtiManagementService } from '../../../../services/data/lti/lti-management.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'aula-planeta-duplicar',
  templateUrl: 'duplicar.component.html',
  styleUrls: ['./duplicar.component.scss'],
})
export class DuplicarComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  panelOpenState = false;
  isCurrentLocation = true;
  dpForm: UntypedFormGroup;
  locationsType = [
    { value: true, label: 'Ubicación actual' },
    { value: false, label: 'Otra ubicación' }
  ];
  temaSeleccionado: null;
  translateCopy = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public colorHelper: ColorHelper,
    public ltiManagementService: LtiManagementService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<DuplicarComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngOnInit() {
    this.translateCopy = this.translate.instant('dialogs.copy.name');

    if (this.data.cursos.length <= 0) {
      this.locationsType.pop();
    }

    const tempDivElement = document.createElement('div');
    if (this.data && this.data.nombre !== undefined) {
      tempDivElement.innerHTML = this.data.nombre;
      this.data.nombre = tempDivElement.textContent || tempDivElement.innerText || '';
    } else {
      this.data.nombre = '';
    }

    this.dpForm = this.formBuilder.group({
      name: [this.data.nombre ? this.translateCopy + ' - ' + this.data.nombre : '', Validators.required],
      type: [true, Validators.required],
      location: [this.data.currentLocationId, Validators.required],
      shared: [this.data.shared]
    });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-duplicar',
        popupName: 'popup_duplicar',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }

  changeLocation($event) {
    this.isCurrentLocation = $event.value;
    const newValue = (this.isCurrentLocation) ? this.data.currentLocationId : null;
    this.dpForm.controls['location'].setValue(newValue);
  }


}
