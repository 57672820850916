<div id="dialog-mensaje-notificacion">
    <h1 mat-dialog-title class="d-flex align-items-center">
        <mat-icon>access_alarm</mat-icon>
        {{ 'dialogs.mensaje-notificacion.title' | translate }}
    </h1>
     <div mat-dialog-content class="mat-dialog-content">{{ 'dialogs.mensaje-notificacion.content' | translate }}</div>
    <div mat-dialog-actions class="justify-content-end">
        <button role="button" mat-raised-button color="accent" mat-dialog-close cdkFocusInitial>{{ 'dialogs.close' | translate }}</button>
    </div>
</div>
