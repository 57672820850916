<div id="dialog-modificar-tarea" class="standard">
    <h1 mat-dialog-title class="custom-text mb-0 font-weight-bold">{{ 'dialogs.modificar-tarea.titulo' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">

        <h2 class="custom-text mt-4">{{ 'dialogs.modificar-tarea.step2.intro' | translate }}</h2>

        <div class="overflow-auto fluid-content">
            <form [formGroup]="secondStep">
                <fieldset class="w-100 d-flex flex-column">
                    <div class="d-flex">
                        <div class="w-50 form-group default-input mb-4 me-3">
                            <label for="tareaName">{{ 'dialogs.asignar-tarea.step2.nombre-tarea' | translate }}</label>
                            <input id="tareaName" aria-labelledby="errorTareaName" type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.nombre-tarea' | translate)" formControlName="name" cdkFocusInitial>
                            <mat-error id="errorTareaName" *ngIf="secondStep.get('name').hasError('required') && secondStep.controls['name'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="w-25 form-group default-input mb-4 position-relative me-3">
                            <label for="tareaInicio">{{ 'dialogs.asignar-tarea.step2.fecha-inicio' | translate }}</label>
                            <input id="tareaInicio" matInput type="text" class="form-control" [min]="minDate" [max]="maxDate" [matDatepicker]="pickerInicio" (click)="pickerInicio.open()" [placeholder]="('dialogs.asignar-tarea.step2.fecha-inicio' | translate)" formControlName="inicio">
                            <mat-datepicker-toggle matSuffix [for]="pickerInicio" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                            <mat-datepicker #pickerInicio startView="year" [startAt]="secondStep.get('inicio').value"></mat-datepicker>
                        </div>

                        <div class="w-25 form-group default-input mb-4 position-relative">
                            <label for="tareaFin">{{ 'dialogs.asignar-tarea.step2.fecha-fin' | translate }}</label>
                            <input id="tareaFin" aria-labelledby="errorTareaFin" matInput [min]="secondStep.get('inicio').value" [max]="maxDate" [matDatepicker]="pickerFin" (click)="pickerFin.open()" type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.fecha-fin' | translate)"
                                formControlName="fin">
                            <mat-datepicker-toggle matSuffix [for]="pickerFin" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFin startView="year" [startAt]="secondStep.get('fin').value"></mat-datepicker>
                            <mat-error id="errorTareaFin" *ngIf="compareDates()">
                                {{'generico.fecha.mayor' | translate}}
                            </mat-error>
                        </div>

                    </div>

                    <div class="d-flex">
                        <div class="w-50 form-group default-textarea mb-4 pe-3">
                            <label for="tareaComentario">{{'dialogs.asignar-tarea.step2.comentario' | translate}}</label>
                            <textarea id="tareaComentario" aria-labelledby="errorTareaComentario" type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.comentario' | translate)" formControlName="comentario" maxlength="255"></textarea>
                            <span class="text-secondary"><small>{{'('+ secondStep.controls['comentario'].value.length +'/255)'}}</small></span>
                            <mat-error id="errorTareaComentario" *ngIf="secondStep.get('comentario').hasError('required') && secondStep.controls['comentario'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>
                    </div>

                    <!-- <div class="w-100 d-flex">
                                <div class="form-group">
                                    <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="shared" tabindex="5">{{ 'dialogs.asignar-tarea.step2.shared' | translate }}</mat-slide-toggle>
                                </div>
                            </div> -->

                </fieldset>
            </form>
        </div>







    </div>
    <div mat-dialog-actions class="d-flex justify-content-between">
        <button role="button" class="default-button default-button__secondary" mat-dialog-close>{{ 'dialogs.create-theme.cancel' | translate }}</button>
        <!-- <button role="button" class="default-button default-button__secondary" (click)="log()">log</button> -->

        <button role="button" type="submit" class="default-button default-button__primary" (click)="onClose()" [disabled]="secondStep.invalid">{{ 'generico.guardar' | translate }}</button>
    </div>

</div>
