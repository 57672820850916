<div id="dialog-add-module-tabla" class="standard mw-500px">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ data ? ('dialogs.edit-module-base.tabla.title'| translate) : ('dialogs.add-module-base.tabla.title' | translate) }}</h1>
    <div mat-dialog-content class="mat-dialog-content">
        <form role="form" [formGroup]="formModule" class="empty-section d-flex flex-row">
            <div class="w-100 d-flex flex-column">
                <div class="form-group default-input mb-0">
                    <label>{{'dialogs.add-module-base.form.titulo' | translate}}</label>
                    <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.titulo' | translate)" formControlName="titulo_html">
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('titulo_html').hasError('required') && formModule.controls['titulo_html'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>

                <div class="form-group default-textarea mb-0">
                    <label>{{'dialogs.add-module-base.form.contenido' | translate}}</label>
                    <textarea type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.contenido' | translate)" formControlName="contenido_html"></textarea>
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('contenido_html').hasError('required') && formModule.controls['contenido_html'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>

                <div class="form-group">
                    <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="visible">{{ 'dialogs.add-module-base.form.visible-profesor' | translate }}</mat-slide-toggle>
                </div>

            </div>
        </form>
    </div>
    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__primary" (click)="onClose()" [disabled]="!formModule.valid">{{ 'generico.guardar' | translate }}</button>
    </div>

</div>
