<div id="dialog-ocultar" class="standard">
    <div mat-dialog-content class="d-flex w-100">
        <div class="col-3 custom-icon">
            <mat-icon class="mt-2">group_off</mat-icon>
        </div>
        <div class="col-9 d-flex flex-column justify-content-between">
            <h1 mat-dialog-title class="d-flex align-items-center custom-text">{{ 'dialogs.hidden.title' | translate }}</h1>
            <p class="mb-0">{{ 'dialogs.hidden.message' | translate }}</p>
            <div mat-dialog-actions class="justify-content-start mt-3">
                <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
                <button role="button" class="default-button default-button__primary" [mat-dialog-close]="true" cdkFocusInitial>{{ 'dialogs.ok' | translate }}</button>
            </div>
        </div>
    </div>
</div>
