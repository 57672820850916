import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SmartlinkDataService } from './smartlink-data-service.service';
@Injectable({
  providedIn: 'root'
})
export class SmartlinkManagementService {

  public smartlink = false;

  constructor(
    private smartlinkDataService: SmartlinkDataService
  ) { }


  getPermalink(id): Observable<any> {
    return this.smartlinkDataService.getPermalink(id);
  }

  getSmartlinkData(page, id): Observable<any> {
    return this.smartlinkDataService.getSmartlinkData(id, page);
  }

  getRetroSmartlinkData(id): Observable<any> {
    return this.smartlinkDataService.getRetroSmartlinkData(id);
  }

  getRetroSmartlinkBanco(texto): Observable<any> {
    return this.smartlinkDataService.getRetroSmartlinkBanco(texto);
  }

}
