import { Directive, ElementRef, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { ScrollHorizontalService } from '../../../services/scroll-horizontal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[scrollHorizontal]'
})
export class ScrollHorizontalDirective implements OnInit, OnDestroy {
  private wheelListener: () => void;
  private scrollListener: () => void;
  private destroy$ = new Subject<void>();
  private isDispatchingScrollEvent = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private scrollHorizontalService: ScrollHorizontalService
  ) { }

  ngOnInit() {
    this.scrollHorizontalService.scrollHorizontal$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isEnabled: boolean) => {
        if (isEnabled) {
          this.addWheelListener();
          this.addScrollListener();
        } else {
          this.removeWheelListener();
          this.removeScrollListener();
        }
      });

    // Inicialmente agregar los listeners
    this.addWheelListener();
    this.addScrollListener();
  }

  // Método para agregar el listener del evento 'wheel'
  addWheelListener() {
    if (!this.wheelListener) {
      this.wheelListener = this.renderer.listen(this.el.nativeElement, 'wheel', (event: WheelEvent) => this.onWheelEvent(event));
    }
  }

  // Método para agregar el listener del evento 'scroll'
  addScrollListener() {
    if (!this.scrollListener) {
      this.scrollListener = this.renderer.listen(this.el.nativeElement, 'scroll', () => this.onScrollEvent());
    }
  }

  // Método para quitar el listener del evento 'wheel'
  removeWheelListener() {
    if (this.wheelListener) {
      this.wheelListener();
      this.wheelListener = null;
    }
  }

  // Método para quitar el listener del evento 'scroll'
  removeScrollListener() {
    if (this.scrollListener) {
      this.scrollListener();
      this.scrollListener = null;
    }
  }

  // Método para manejar el evento 'wheel'
  onWheelEvent(event: WheelEvent) {
    // Prevenir el comportamiento predeterminado
    event.preventDefault();

    // Realizar el scroll horizontal
    this.el.nativeElement.scrollLeft += event.deltaY;

    // Crear y despachar el evento de scroll
    this.dispatchScrollEvent();
  }

  // Método para manejar el evento 'scroll'
  onScrollEvent() {
    if (!this.isDispatchingScrollEvent) {
      // Manejar el evento de scroll si no se está despachando ya
      this.dispatchScrollEvent();
    }
  }

  // Método para despachar el evento de scroll
  dispatchScrollEvent() {
    this.isDispatchingScrollEvent = true;

    const scrollEvent = new Event('scroll', {
      bubbles: true,
      cancelable: true
    });
    this.el.nativeElement.dispatchEvent(scrollEvent);

    this.isDispatchingScrollEvent = false;
  }

  ngOnDestroy() {
    // Desuscribirnos del Observable al destruir la directiva
    this.destroy$.next();
    this.destroy$.complete();
    this.removeWheelListener();
    this.removeScrollListener();
  }
}
