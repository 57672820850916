<div id="dialog-enviar-comentario">
    <h1 mat-dialog-title>{{ 'dialogs.enviar-comentario.titulo' | translate }}</h1>
    <h2 mat-dialog-title>{{ 'dialogs.enviar-comentario.subtitulo' | translate }}</h2>
     <div mat-dialog-content class="mat-dialog-content">
        <mat-form-field class="w-100">
            <mat-label>{{ 'dialogs.enviar-comentario.comentario' | translate }}</mat-label>
            <textarea matInput aria-label="Comentario" [formControl]="comentarioControl" placeholder="{{ 'dialogs.enviar-comentario.comentario' | translate }}"></textarea>
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="flex-row-reverse justify-content-start m-1">
        <button role="button" mat-raised-button color="accent" [mat-dialog-close]="comentarioControl.value" class="ms-2">{{ 'dialogs.enviar-comentario.enviar' | translate }}</button>
        <button role="button" mat-button mat-dialog-close>{{ 'dialogs.close' | translate }}</button>
    </div>
</div>
