import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'aula-planeta-desasignar-alumnado',
  templateUrl: 'desasignar-alumnado.component.html',
  styleUrls: ['./desasignar-alumnado.component.scss'],
})
export class DesasignarAlumnadoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DesasignarAlumnadoComponent>, 
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit () {}
}