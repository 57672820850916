import { Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { SmartlinkManagementService } from '../data/smartlink/smartlink-management-service.service';
import { AuthManagementService, LocalStorageService } from '../../core/core.module';
import { TemasManagementService } from '../data/temas/temas-management.service';

@Injectable({
  providedIn: 'root'
})
export class RetroSmartlink{
  private destroy$: Subject<void> = new Subject<void>();
  public smartlink = false;

  constructor(
    private router: Router,
    private smartlinkManagementService: SmartlinkManagementService,
    private authManagementService: AuthManagementService,
    private temasManagementService: TemasManagementService,
    private localStorageService: LocalStorageService

  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.authManagementService.redirectUrl = state.url as any;
    const tmp$: Subject<void> = new Subject<void>();
    const idRecurso = route.paramMap.get('idRecurso');
    let idTema = route.paramMap.get('idTema');
    const token = await this.getToken();
    let idMateria = null;
    this.smartlinkManagementService.getRetroSmartlinkData(idTema).pipe(
      take(1),
      takeUntil(tmp$))
      .subscribe(
        res => {
          tmp$.next();
          tmp$.complete();
          idMateria = res.materiaId;
          idTema = res.temaId;
          let url = this.formarUrl(idMateria, idTema, idRecurso);
          localStorage.removeItem('redirectUrl');
          if (this.authManagementService.currentUser.moodle) {
            url = `${url}?moodle=${token}`;
          }
          this.router.navigateByUrl(url, { replaceUrl: true });
          this.smartlinkManagementService.smartlink = true;
        },
        _err => {
          console.log('retro ko ');
          localStorage.removeItem('redirectUrl');
          this.router.navigate(['/']);
        }
      );
    return false;

  }

  private formarUrl(idMateria, idTema, idRecurso) {
    if (idRecurso) {
      return `/inicio/mis-materias/materia/${idMateria}/tema/${idTema}/recurso/${idRecurso}`;
    } else {
      return `/inicio/mis-materias/materia/${idMateria}/tema/${idTema}`;
    }
  }

  private async getToken() {
    return await this.localStorageService.getItem('JWT').pipe(first()).toPromise();
  }
}