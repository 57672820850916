<div id="dialog-asignar-unidad" class="standard">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.asignar-unidad.title' | translate }}</h1>

     <div mat-dialog-content class="mat-dialog-content">
        <form role="form" [formGroup]="form">
            <fieldset>
                <div class="d-flex flex-column flex-md-row mt-2 mb-0 alumnos-container__row">
                    <div class="form-group w-100">
                        <label class="default-text">{{ 'dialogs.asignar-unidad.clase-ref' | translate }}</label>
                        <mat-select cdkFocusInitial class="default-select default-select__blue" [placeholder]="'nuevo-grupo-alumnos.clase-placeholder' | translate" formControlName="claseRef" panelClass="cdkSelect">
                            <ng-container *ngFor="let clase of this.gestionAlumnoManagementService.misClases$ | async">
                                <mat-option class="default-option" [value]="clase.curso.id+'#'+clase.grupo.id" (click)="getMateriasByCurso(clase.curso.id);setAlumnosList({clase: clase})">
                                    {{ clase.curso.ordinal }} {{ clase.curso.etapa.acronimo }} {{ clase.grupo.id }}
                                </mat-option>
                            </ng-container>
                        </mat-select>

                        <label class="default-text mt-3">{{ 'dialogs.asignar-unidad.materia-destino' | translate }}</label>
                        <mat-select cdkFocusInitial class="default-select default-select__blue" [placeholder]="'dialogs.asignar-unidad.materia-destino-placeholder' | translate" formControlName="materia" panelClass="cdkSelect">
                            <ng-container *ngFor="let materia of materias">
                                <mat-option class="default-option" [class]="colorHelper.getColor(materia.color)" [value]="materia.id">
                                    {{ materia.nombre }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>

                    <div class="form-group w-100 default-input">
                        <label class="default-text">{{ 'dialogs.asignar-unidad.nombre' | translate }}</label>
                        <input type="text" class="form-control" formControlName="nombreGrupo" [placeholder]="('dialogs.asignar-unidad.nombre-placeholder' | translate)">
                        <div class="form-group d-flex flex-row-reverse mb-4">
                            <mat-slide-toggle formControlName="nuevoGrupo" class="grey-text w-100 mt-3" disableRipple="true">{{ 'dialogs.asignar-tarea.step3.create-grupo' | translate }}</mat-slide-toggle>
                        </div>
                    </div>
                </div>

                <div class="alumnos-container d-flex flex-column flex-md-row mb-4">
                    <div class="w-100 alumnos-container__list">
                        <label class="w-100 pb-2 mb-0 default-text">{{ 'mi-centro.gestion-contrasenas.alumnos-disponibles' | translate }}</label>
                        <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="alumnos" [cdkDropListConnectedTo]="[doneList]" (cdkDropListDropped)="drop($event)">
                            <ng-container *ngIf="form.get('claseRef').value">
                                <div *ngFor="let item of alumnos" class="my-3" cdkDrag>
                                    <mat-checkbox class="ms-3" (click)="$event.stopPropagation()" (change)="$event ? selectionDisponibles.toggle(item) : null " [checked]="selectionDisponibles.isSelected(item)" [aria-label]="item.name">
                                        {{item.nombre}} {{item.apellidoUno}} {{item.apellidoDos}}
                                    </mat-checkbox>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="alumnos-container__actions d-flex flex-row flex-md-column justify-content-center align-content-center px-4">
                        <button mat-icon-button class="custom-text" (click)="asignarAlumnos()" aria-label="Asignar alumnos">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                        <button mat-icon-button class="custom-text" (click)="asignarTodos()" aria-label="Asignar todos">
                            <mat-icon>last_page</mat-icon>
                        </button>
                        <button mat-icon-button class="custom-text" (click)="desasignarTodos()" aria-label="Desasignar todos">
                            <mat-icon>first_page</mat-icon>
                        </button>
                        <button mat-icon-button class="custom-text" (click)="desasignarAlumnos()" aria-label="Desasignar alumnos">
                            <mat-icon>chevron_left</mat-icon>
                        </button>
                    </div>
                    <div class="w-100 alumnos-container__list">
                        <label class="w-100 pb-2 mb-0 default-text">{{ 'mi-centro.gestion-contrasenas.alumnos-seleccionados' | translate }}</label>
                        <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="alumnosAsignados" [cdkDropListConnectedTo]="[todoList]" (cdkDropListDropped)="drop($event)">
                            <div *ngFor="let item of alumnosAsignados" class="my-3" cdkDrag>
                                <mat-checkbox class="ms-3" (click)="$event.stopPropagation()" (change)="$event ? selectionAsignados.toggle(item) : null " [checked]="selectionAsignados.isSelected(item)" [aria-label]="item.name">
                                    {{item.nombre}} {{item.apellidoUno}} {{item.apellidoDos}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div mat-dialog-actions class="justify-content-start mt-2 mb-0 pb-0">
                    <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
                    <button role="button" class="default-button default-button__primary" type="submit" (click)="close(true)" [disabled]="!form.valid || form.pristine">{{ 'generico.guardar' | translate }}</button>
                </div>

            </fieldset>
        </form>
    </div>
</div>
