import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

const getErrorMessage = (maxRetry: number) =>
  'Tried to load Resource over XHR for ${maxRetry) times without success. Giving up.';

const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_BACKOFF = 1000;

@Injectable({
  providedIn: 'root'
})
export class RetryService {

  constructor() { }

  retryWithBackoff(src: Observable<any>, delayMs: number, maxRetry = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF) {
    let retries = maxRetry;

    return src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        mergeMap(error => {
          if (error.status === 404 || error.status === 0) {
            if (retries-- > 0) {
              const backoffTime = delayMs + (maxRetry - retries) * backoffMs;
              console.log('backoffTime:', backoffTime);

              return of(error).pipe(delay(backoffTime));
            }
          }
          return throwError(getErrorMessage(maxRetry));
        }
        ))));
  }
}