<div id="dialog-add-module-imagen" class="standard mw-550px">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ data ? ('dialogs.edit-module-base.imagen.title'| translate) : ('dialogs.add-module-base.imagen.title' | translate) }}</h1>
    <div mat-dialog-content class="mat-dialog-content">
        <form role="form" [formGroup]="formModule" class="empty-section d-flex flex-row">
            <div class="w-100 d-flex flex-column">
                <div class="form-group default-input mb-0">
                    <label>{{'dialogs.add-module-base.form.titulo' | translate}}</label>
                    <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.titulo' | translate)" formControlName="titulo_html">
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('titulo_html').hasError('required') && formModule.controls['titulo_html'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>

                <div class="form-group">
                    <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="visible">{{ 'dialogs.add-module-base.form.visible-profesor' | translate }}</mat-slide-toggle>
                </div>

                <div *ngIf="!data" class="d-flex flex-column form-group default-dropzone mb-3">
                    <label>{{ 'dialogs.add-module-base.form.file-image' | translate }}</label>
                    <div class="d-flex flex-column flex-md-row align-items-center">
                        <div (dropZone)="onDropImg($event.target.files[0], 'imagen')" (click)="imageField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center" [ngClass]="(imagen$ | async) ? 'default-dropzone__container--white' : '' ">
                            <div class="avatar d-flex align-items-center justify-content-center">
                                <div *ngIf="(imagen$ | async) as foto; else nube" class="avatar text-center">
                                    <img [src]="foto" alt="">
                                </div>
                                <ng-template #nube>
                                    <div class="avatar text-center">
                                        <mat-icon>cloud_upload</mat-icon>
                                    </div>
                                </ng-template>

                            </div>
                        </div>

                        <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                            <p class="mb-0"><a [matMenuTriggerFor]="menu">{{formModule.get('imagen').value ? ('generico.cambiar' | translate) : ('generico.anadir' | translate) }}</a>{{'dialogs.adjuntar-fichero.upload-info2' | translate}}</p>
                            <input type="file" name="file" #imageField (change)="onDropImg($event.target.files[0], 'imagen')" hidden>
                            <mat-menu #menu="matMenu" class="default-dropdown">
                                <button mat-menu-item (click)="imageField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                                <button [disabled]="!formModule.get('imagen').value" mat-menu-item (click)="deleteImg('imagen')"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                            </mat-menu>
                            <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                        </div>
                    </div>
                    <mat-error class="danger-text text__small mt-2" *ngIf="error.imagen">
                        {{'generico.formulario.fichero-novalid' | translate}}
                    </mat-error>
                </div>
                <!--
                <div class="form-group default-input mb-2">
                    <label>{{'dialogs.add-module-base.form.texto_alternativo' | translate}}</label>
                    <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.texto_alternativo' | translate)" formControlName="texto_alternativo">
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('texto_alternativo').hasError('required') && formModule.controls['texto_alternativo'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>

                <div class="form-group default-input">
                    <label>{{'dialogs.add-module-base.form.pie_de_imagen' | translate}}</label>
                    <ckeditor #editorTexto [disabled]="false" [config]="config" [editor]="editor" [data]="formModule.get('pie_de_imagen').value" (ready)="onReady($event)" (change)="onChange($event)"></ckeditor>
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('pie_de_imagen').hasError('required') && formModule.controls['pie_de_imagen'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>

                <div class="form-group default-input mb-3 mt-2">
                    <label>{{'dialogs.add-module-base.form.creditos' | translate}}</label>
                    <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.creditos' | translate)" formControlName="creditos_html">
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('creditos_html').hasError('required') && formModule.controls['creditos_html'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>
            -->
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" (click)="onClose()" [disabled]="!formModule.valid">{{ 'generico.guardar' | translate }}</button>
    </div>

</div>
