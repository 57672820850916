import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateHelper } from '../../../helper/date.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-filtrar-resultados',
  templateUrl: 'filtrar-resultados.component.html',
  styleUrls: ['./filtrar-resultados.component.scss'],
})
export class FiltrarResultadosComponent implements OnInit {

  form: UntypedFormGroup;
  now = new Date(Date.now());
  minDate = new Date(2000, 0, 1);
  maxDate = new Date(2045, 0, 1);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dateHelper: DateHelper,
    public dialogRef: MatDialogRef<FiltrarResultadosComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngOnInit() {
    const formGroup = {};

    this.data.filters.forEach(filter => {
      if (filter.type !== 'date') {
        formGroup[filter.code] = new UntypedFormControl(filter.value);
      } else {
        formGroup[filter.code + 'From'] = new UntypedFormControl(filter.valueFrom);
        formGroup[filter.code + 'Until'] = new UntypedFormControl(filter.valueUntil);
      }
    });
    this.form = new UntypedFormGroup(formGroup);

    const info = {
      event: 'popup',
      popup: {
        action: 'filter_results',
        popupId: 'dialog-filtrar-resultados',
        popupName: 'popup_filter_results'
      }
    };
    this.gtagService.event(info);
  }
}
