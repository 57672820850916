<div id="edition-add"
  class="edition-add position-relative my-1 d-flex justify-content-center align-items-center"
  [ngClass]="this.editionModeService.orientation === 'vertical' ? 'h-100 edition-add-vertical' : 'w-100'"
  [@fadeIn]="(editionModeService.selected$ | async) ? 'in' : 'out'"
  *ngIf="(editionModeService.selected$ | async)">
    <button (click)="gtagInfo($event); $event.stopPropagation()" [matMenuTriggerFor]="elementsMenu" matTooltip="Añadir elemento" matTooltipPosition="above" [attr.aria-label]="'Añadir elemento'">
        <span class="add">+</span>
    </button>
    <mat-menu #elementsMenu="matMenu" panelClass="mat-select-panel__edit" yPosition="below" xPosition="after" class="default-dropdown default-dropdown__edit">
        <ng-container *ngFor="let option of options">
            <button *ngIf="option.id !== 'divider'" role="button" mat-menu-item (click)="openDialog(option.id, $event)">
                <mat-icon>{{ option.icon }}</mat-icon>
                <span>{{ option.label }}</span>
            </button>
            <mat-divider *ngIf="option.id === 'divider'"></mat-divider>
        </ng-container>
        <ng-container *ngIf="false">{{voidConst}}</ng-container>


    </mat-menu>
</div>
