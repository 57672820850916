/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { Materia } from '../../../shared/models/materia.model';
import { EnvService } from '../../env.service';


@Injectable({
  providedIn: 'root'
})
export class MateriaDataService {

  constructor(private http: HttpClient, public cache: LocalCacheService, private envService: EnvService) { }

  public getMisMaterias(): Observable<any> {
    const requestObservable =
      this.http.get<any>(`${this.envService.apiUrl}/aula/materias`);
    return this.cache.observable('MATERIADATASERVICE-GETMISMATERIAS', requestObservable);
  }

  public getMisLibros(incluirIndiceTemasLibros?): Observable<any> {
    const requestObservable =
      this.http.get<any>(`${this.envService.apiUrl}/aula/materias?incluirIndiceTemasLibros=true`,
        {
          headers: new HttpHeaders().set('noLoader', 'noLoader'),
          params: new HttpParams().set('incluirIndiceTemasLibros', incluirIndiceTemasLibros),

        });
    return this.cache.observable('MATERIADATASERVICE-GETMISLIBROS', requestObservable);
  }

  public getMateriasByCurso(id): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/curso-materias?cursoId=${id}`);
  }

  public getAsignaturas(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/colegio/materias/selector`);
    return this.cache.observable('MATERIADATASERVICE-GETASIGNATURAS', requestObservable);
  }

  public getOtrasMaterias(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/contenido/MateriasCentro`);
    return this.cache.observable('MATERIADATASERVICE-GETOTRASMATERIAS', requestObservable);
  }

  public getAutoreMateriaCentro(id: string): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/MateriaCentro/${id}/AutoresLibro`);

    // const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/contenido/MateriasCentro/${id}/AutoresLibro`);
    // return this.cache.observable('MATERIADATASERVICE-GETOTRASMATERIAS', requestObservable);
  }

  public getLibrosAsignaciones(id: string): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/aula/LibrosAsignaciones/Materia/${id}`);
  }

  public getMateria(id: string, temasOriginales: boolean): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/contenido/materias/${id}/indice${temasOriginales ? '/temas-originales' : ''}`);
    return this.cache.observable(`MATERIADATASERVICE-GETMATERIA-${id}`, requestObservable);
  }

  public getMateriasMaestras(isEdit: boolean): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/${isEdit ? 'MateriasMaestras' : 'MateriasMaestrasParaMateriasCentro'}`);

  }

  public reordenarTemasMateria(materia: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/materia-temas/reordenar`, materia);
  }

  public createMateria(materia: Materia): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/materias/register`, materia);
  }

  public createMateriaCentro(materia: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/MateriaCentro`, materia);
  }

  public updateMateria(materia: Materia): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/materias/${materia.id}`, materia);
  }

  public updateMateriaCentro(id, materia: Materia): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/MateriaCentro/${id}`, materia);
  }

  public updateMisMaterias(materias: any[]): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/aula/materias`, materias);
  }

  public deleteMateria(materia: Materia): Observable<any> {
    return this.http.delete<any>(`${this.envService.apiUrl}/Materias/${materia.id}`);
  }

  public deleteMateriaCentro(materia: Materia): Observable<any> {
    return this.http.delete<any>(`${this.envService.apiUrl}/contenido/MateriaCentro/${materia.id}`)
  }

  public copiarMateria(materia: Materia): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/materias/copiar`, materia);
  }

  public crearEnlace(materia: Materia): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/materias/register`, materia);
  }

  public crearLibro(data: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/materia/duplicar`, data);
  }

  public updateTituloLibro(data: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/libro/ModificarTitulo`, data);
  }

  public asignarClasesLibro(data: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/libro/asignar`, data);
  }

  public duplicarLibro(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/libro/duplicar`, data);
  }

  public deleteLibro(libroId: any, libroValidacion: boolean = false): Observable<any> {
    return this.http.delete<any>(`${this.envService.apiUrl}/contenido/libro/${libroId}/validarTareas/${libroValidacion}`);
  }


}
