<mat-drawer-container [class]="colorHelper.getColor(data.color)">
    <mat-drawer class="sidenav custom-text" [ngClass]="{ 'hidden': fadeOut, 'visible': !fadeOut }" mode="side" opened [position]="sidenavPosition">
        <button [ngClass]="{ 'icon-right': swapRight, 'icon-left': !swapRight}" mat-button [mat-dialog-close]="true"><mat-icon>close</mat-icon></button>
        <div class="middle d-flex flex-column">
            <button [ngClass]="{ 'icon-right': swapRight, 'icon-left': !swapRight}" (click)="showExpositor($event)" mat-button [disabled]="expositor"><mat-icon>home</mat-icon></button>
            <button [ngClass]="{ 'icon-right': swapRight, 'icon-left': !swapRight}" (click)="showPreviousModulo($event)" mat-button [disabled]="lastSelection < 2 || expositor"><mat-icon>keyboard_arrow_left</mat-icon></button>
            <button [ngClass]="{ 'icon-right': swapRight, 'icon-left': !swapRight}" (click)="showNextModulo($event)" mat-button [disabled]="lastSelection === data.recursos.length || expositor"><mat-icon>keyboard_arrow_right</mat-icon></button>
            <button [ngClass]="{ 'icon-right': swapRight, 'icon-left': !swapRight}" (click)="routeToDrawer($event)" mat-button [disabled]="expositor || badUrl"><mat-icon>edit</mat-icon></button>
        </div>
        <button class="bottom" mat-button (click)="togglePosition(sidenavPosition, $event)"><mat-icon>video_label</mat-icon></button>
    </mat-drawer>

    <mat-drawer-content #content [ngClass]="{'drawer-right': swapRight, 'drawer-left': !swapRight, 'recurso-left': !expositor && !swapRight, 'recurso-right': !expositor && swapRight}" class="center custom-bg__light main_container">
        <div class="no-results-base no-results-base__xl d-flex flex-column align-items-center bad-url" *ngIf="data.recursos.length < 1">
            <mat-icon class="material-icons-round color">sentiment_dissatisfied</mat-icon>
            <div class="w-25">
                <span>{{ 'dialogs.expositor.sin-recursos' | translate }}</span>
            </div>
        </div>
        <div class="no-results-base no-results-base__xl d-flex flex-column align-items-center bad-url" *ngIf="badUrl">
            <mat-icon class="material-icons-round color">sentiment_dissatisfied</mat-icon>
            <div class="w-25">
                <span>{{ 'dialogs.expositor.actividad-no-encontrada' | translate }}</span>
            </div>
        </div>
        <div *ngIf="expositor && data.recursos.length > 0">
            <div class="title custom-text"><span><img class="materia-color" src="assets/logo01.svg" alt="{{'login.logo' | translate}}" role="img" /></span></div>
            <h2 class="subtitle custom-text">{{data.titulo}}</h2>
            <div class="cards_container">
                <ng-container *ngFor="let modulo of recursos; let i = index">
                    <div class="card" *ngIf="modulo.propiedades.visible !== 'Oculto'">
                        <p class="index custom-text"> {{i + 1}} </p>
                        <mat-card (click)="showModulo(modulo, i+1, $event)">
                            <img *ngIf="modulo.propiedades.imagen_previa" sanitize-img [src]="modulo.propiedades.imagen_previa | safe:'resourceUrl'" alt="{{'dialogs.expositor.imagen' | translate}}">
                            <div *ngIf="isRecursoInlineWithIcon(modulo)" class=" img-container custom-bg__regular">
                                <mat-icon class="white-icon">touch_app</mat-icon>
                            </div>
                            <mat-card-content>
                                <span class="custom-text title-card-expositor">{{modulo.propiedades.titulo_plano}}</span>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </ng-container>
            </div>    
        </div>
        <div *ngIf="!expositor  && data.recursos.length > 0 && !badUrl" class="modulo">
            <iframe class="iframe" id="material" #iframe  [src]="urlSafe"></iframe>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
    