import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  bloqueateScroll(): void {
    // Bloquear el scroll vertical en body y html
    document.body.style.overflowY = 'hidden';
    document.documentElement.style.overflowY = 'hidden'; // También aplica en <html>
  }

  desbloqueateScroll(): void {
    // Desbloquear el scroll vertical en body y html
    document.body.style.overflowY = '';
    document.documentElement.style.overflowY = ''; // También aplica en <html>
  }

}
