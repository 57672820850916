import {
  Component, OnInit, ChangeDetectionStrategy,
  Input, Optional, Inject, SimpleChanges, OnChanges, ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'aula-planeta-mis-clases-modal',
  templateUrl: './mis-clases.component.html',
  styleUrls: ['./mis-clases.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MisClasesModalComponent implements OnInit {

  @Input() isModal = true;
  @Input()
  set changeStep(value: boolean) {
    if (value) {
      this.returnValue.emit(this._clases);
    }
  }
  @Output() returnValue = new EventEmitter<any[]>();

  @Input() cursos: any[] = [];
  @Input() grupos: any[] = [];
  @Input() clases: any[] = [];

  _cursos: any[] = [];
  _grupos: any[] = [];
  _clases: any[] = [];

  displayedColumns: string[] = [];
  enableSaveButton = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<MisClasesModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // Inicialización cuando el componente se usa como modal o componente independiente.
    this._cursos = this.cursos.length ? this.cursos : this.data?.cursos || [];
    this._grupos = this.grupos.length ? this.grupos : this.data?.grupos || [];
    this._clases = this.clases.length ? this.clases : this.data?.clases || [];

    this.updateDisplayedColumns();
  }

  updateDisplayedColumns() {
    // Actualiza las columnas que se muestran en la tabla y fuerza la detección de cambios.
    this.displayedColumns = ['curso'];
    this._grupos.forEach(grupo => this.displayedColumns.push(grupo.id));

    // Forzar la detección de cambios
    this.cdr.detectChanges();
  }

  selectClase(cursoId: number, grupoId: number) {
    this.enableSaveButton = true;
    const existe = this._clases.some(clase => clase.cursoId === cursoId && clase.grupoId === grupoId);
    if (existe) {
      this._clases = this._clases.filter(clase => !(clase.cursoId === cursoId && clase.grupoId === grupoId));
    } else {
      this._clases.push({ cursoId, grupoId });
    }

    // Volver a forzar la detección de cambios después de actualizar las clases
    // this.cdr.detectChanges();
  }

  isEstaUnaDeMisClases(cursoId: number, grupoId: number) {
    return this._clases.some(clase => clase.cursoId === cursoId && clase.grupoId === grupoId);
  }

  close(result: boolean) {
    if (this.dialogRef) {
      if (result) {
        this.dialogRef.close(this._clases);
      } else {
        this.dialogRef.close();
      }
    }
  }
}
