/* eslint-disable max-len */
/* eslint-disable guard-for-in */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { AuthManagementService } from '../auth/auth-management.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';

declare global {
  interface Window { API_1484_11: any; }
}

@Injectable({
  providedIn: 'root'
})
export class ScormService implements OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private window: Window,
    private authManagementService: AuthManagementService,
    private localStorageService: LocalStorageService
  ) {
    this.authManagementService.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          this.window = window;
          this.window.API_1484_11 = this.API_1484_11;
          window.API_1484_11 = this.API_1484_11;
        }
      });
  }

  API_1484_11 = {
    version: '1.0', // mandatory version attribute
    STATE: {
      NOT_INITIALIZED: 'Not Initialized',
      RUNNING: 'Running',
      TERMINATED: 'Terminated'
    },
    idActividad: '',
    idTarea: '',
    estado: '',
    evaluable: '',
    tipoEvaluable: '',
    idAutonoma: false,
    running: false,
    debug: typeof (console) == 'undefined' ? null : console, // console, false
    error: 0,
    cmiDefault: {
      'cmi.score.max': '0',
      'cmi.mode': 'normal',
      'cmi.session_time': 'PT0H0M0S',
      'cmi.suspend_data': '',
      'cmi.success_status': 'unknown',
      'cmi.exit': '',
      'cmi.score.min': '0',
      'cmi.score.raw': '0',
      'cmi.completion_status': 'unknown',
      'cmi.score.scaled': '0'
    },

    cmi: null,
    _valuesChanged: {},
    _valueNameSecurityCheckRe: /^cmi\.(\w|\.)+$/,

    // help functions
    _stringEndsWith: function (str, suffix) {
      return str.length >= suffix.length && str.substr(str.length - suffix.length) == suffix;
    },
    _valueNameSecurityCheck: function (name) {
      this.error = name.search(this._valueNameSecurityCheckRe) === 0 ? 0 : 401;
      return this.error === 0;
    },
    _valueNameCheckReadOnly: function (name) {
      this.error = 0;
      if (this._stringEndsWith(name, '._children')) {
        this.error = 404;
      }

      return this.error === 0;
    },
    _checkRunning: function (errBefore, errAfter) {
      if (this.state === this.STATE.NOT_INITIALIZED) {
        this.error = errBefore;
      } else if (this.state === this.STATE.TERMINATED) {
        this.error = errAfter;
      } else {
        this.error = 0;
      }
      return this.error === 0;
    },

    _preInitialize: function (window, scormManagementService, params, data) {
      console.log('AP --> _preInitialize() window: ', window);
      if (window.API_1484_11) {
        window.API_1484_11.typeScorm = params.typeScorm;
        window.API_1484_11.rol = params.rol;
        window.API_1484_11.scormManagementService = scormManagementService;
        window.API_1484_11.idActividad = params.idActividad;
        window.API_1484_11.idTarea = params.idTarea;
        window.API_1484_11.estado = params.estado;
        window.API_1484_11.evaluable = params.evaluable;
        window.API_1484_11.tipoEvaluable = params.tipoEvaluable;
        window.API_1484_11.autonoma = params.autonoma;
        window.API_1484_11.idMateria = params.idMateria;
        window.API_1484_11.idTema = params.idTema;
        window.API_1484_11.idRecurso = params.idRecurso;
        window.API_1484_11.state = window.API_1484_11.STATE.NOT_INITIALIZED;
        window.API_1484_11.inline = params.inline;
        window.API_1484_11.caducada = params.caducada;
        // console.log('AP --> window.API_1484_11', window.API_1484_11);
        data = window.API_1484_11.InitDataModelValues(data);
        window.API_1484_11.cmi = { ...window.API_1484_11.cmiDefault, ...data };
      }
    },

    // SCO RTE functions
    Initialize: function () {
      console.log('LMS Initialize');
      console.log('AP --> API', this);
      if (this.state === this.STATE.RUNNING) {
        // this.error = 103;
        return 'true';
      }
      if (this.state === this.STATE.TERMINATED) {
        this.error = 103;
        return 'false';
      }
      this.state = this.STATE.RUNNING;
      this.error = 0;

      return 'true';
    },

    Terminate: function () {

      console.log('LMS Terminate');
      if (!this._checkRunning(112, 113)) return 'false';

      if (this.typeScorm === 'mauthor') {
        this.state = this.STATE.RUNNING;
      }
      this.state = this.STATE.TERMINATED;
      //  return this.Commit();
      return true;
    },

    GetValue: function (name) {
      console.log('LMS GetValue', name);
      if (!this._checkRunning(122, 123)) {
        return '';
      }
      if (!this._valueNameSecurityCheck(name)) return '';
      console.log('this.cmi', this.cmi);

      let retval = this.cmi ? this.cmi[name] : this.cmiDefault;
      if (typeof (retval) == 'undefined') {
        retval = '';
      }

      // console.log('LMS GetValue return: ', retval);
      return retval;
    },

    SetValue: function (name, value) {
      console.log('LMS SetValue', name, value);
      if (!this._checkRunning(132, 133)) return 'false';
      if (!this._valueNameSecurityCheck(name)) return 'false';

      if (!this._valueNameCheckReadOnly(name)) return 'false';

      console.log('_valuesChanged', this._valuesChanged);
      this._valuesChanged[name] = value;
      return 'true';

    },

    Commit: function () {

      try {

        console.log('LMS Commit', this._valuesChanged);
        if (!this._valuesChanged) return 'false';
        if (!this._checkRunning(142, 143)) return 'false';

        // merge values
        this.cmi = { ...this.cmi, ...this._valuesChanged };
        console.log('LMS Commit va a guardar', this.cmi);      

        if (this.estado !== 1) {

          this.scormManagementService.scormData = {
            status: this.cmi['cmi.completion_status'],
            typeScorm: this.typeScorm,
            idActividad: this.idActividad,
            idTarea: this.idTarea,
            autonoma: this.autonoma,
            idTema: this.idTema,
            idMateria: this.idMateria,
            idRecurso: this.idRecurso,
            datosScorm: this.Site_GetDataModelValueToCommit(this.cmi),
            inline: this.inline,
            caducada: this.caducada,
            estado: this.estado,
            evaluable: this.evaluable,
            tipoEvaluable: this.tipoEvaluable
          };
        }

        this._valuesChanged = {}; // clean changed values
        this.state = this.STATE.NOT_INITIALIZED;
        return 'true';

      } catch (ex) {

        try {

          const mensaje = '\nError al guardar la actividad';
          // const mensaje = '\nError al guardar la actividad : ' + (ex.message || ex) +
          //     '\nTareaId: ' + urlParams.MiTarea +
          //     '\nTareaRecursoId: ' + urlParams.mtarrec;

          // const body = logError(mensaje, NivelMensajeError);

          // const r = confirm(t('error_no_controlado'));
          const r = true;

          if (r == true) {

            const email = 'noresponder@aulaplaneta.com';

            // const mailto_link = 'mailto:' + email +
            //     '?Subject=Error al guardar (notificaci%C3%B3n de usuario)' +
            //     '&Body=' + encodeURI(body);

            // window = window.open(mailto_link, 'emailWindow');
          }

        } catch (e) {

          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          // console.log('Error al guardar la Actividad. Error: ' + (e.message || e)) + ' Error2: ' + (ex.message || ex);

        }

        return 'false';
      }

    },

    GetDiagnostic: function (errCode) {
      // console.log('LMS GetDiagnostic', errCode);
      if (!errCode) return this.GetLastError();
      return this.error_strings[errCode] ? this.error_strings[errCode] : 'Uknown errCode.';
    },

    GetErrorString: function (errCode) {
      // console.log('LMS GetErrorString', errCode);
      return this.error_strings[errCode] ? this.error_strings[errCode] : '';
    },

    GetLastError: function () {
      // console.log('LMS GetLastError return', this.error);
      return this.error;
    },

    // predefined constants
    error_strings: {
      0: 'No error',
      // General Errors 100-199
      101: 'General Exception',
      102: 'General Initialization Failure',
      103: 'Already Initialized',
      104: 'Content Instance Terminated',
      111: 'General Termination Failure',
      112: 'Termination Before Initialization',
      113: 'Termination After Termination',
      122: 'Retrieve Data Before Initialization',
      123: 'Retrieve Data After Termination',
      132: 'Store Data Before Initialization',
      133: 'Store Data After Termination',
      142: 'Commit Before Initialization',
      143: 'Commit After Termination',
      // Syntax Errors 200-299
      201: 'General Argument Error',
      // RTS (LMS) Errors 300-399
      301: 'General Get Failure',
      351: 'General Set Failure',
      391: 'General Commit Failure',
      // Data Model Errors 400-499
      401: 'Undefined Data Model Element',
      402: 'Unimplemented Data Model Element',
      403: 'Data Model Element Value Not Initialized',
      404: 'Data Model Element Is Read Only',
      405: 'Data Model Element Is Write Only',
      406: 'Data Model Element Type Mismatch',
      407: 'Data Model Element Value Out Of Range',
      408: 'Data Model Dependency Not Established',
      // Implementation-defined Errors 1000-65535
      1000: 'General communication failure (Ajax)'
    },

    InitDataModelValues(datosScorm) {
      // console.log('AP --> datosScorm evidencia: ', datosScorm);
      let aDmPairs = ''; // array of name@Evalue strings
      let aStrNameValue; // string array with 2 elements: 0 is name, 1 is value

      const m_aValues = {};

      if (!datosScorm) {
        datosScorm = 'cmi.score.max@E0@Ncmi.mode@Enormal@Ncmi.success_status@Eunknown@Ncmi.score.min@E0@Ncmi.score.raw@E0@Ncmi.completion_status@Eunknown@Ncmi.score.scaled@E0@N';
      }

      if (datosScorm) {

        // console.log('AP -->  datosScorm a tratar: ', datosScorm);
        datosScorm = datosScorm.replace(/@G/g, '>').replace(/@L/g, '<').replace(/@A/g, '@');

        // split long string into name@Evalue pairs
        aDmPairs = datosScorm.split('@N');
        // console.log('AP -->  aDmPairs: ', aDmPairs);

        for (let i = 0; i < aDmPairs.length; i++) {
          aStrNameValue = aDmPairs[i].split('@E');
          if (aStrNameValue.length == 2) {
            m_aValues[aStrNameValue[0]] = aStrNameValue[1];
          }
        }
      }
      // console.log('AP --> m_aValues: ', m_aValues);

      return m_aValues;

    },

    // Return the value of the datamodel string that will be posted. This puts all the values changed 
    // since the last commit into the returned string.
    Site_GetDataModelValueToCommit(dataR) {

      let strDmValue = ''; // return value

      for (const i in dataR) {
        // don't ever commit names that end in _count.
        const findCount = /.*_count$/g;
        if (i.match(findCount) == null) {
          const name = this.PrepToPost(i);
          const value = this.PrepToPost((dataR[i]).toString());

          if (name !== 'cmi.mode') {
            strDmValue += name + '@E' + value + '@N';
          };
        };
      };
      return strDmValue;
    },

    // Prepare the string to be a name or value to be posted to the server.
    // Remove any < or > tags from name, in order to make information postable within a form field
    PrepToPost(name) {
      return name.replace(/@/g, '@A').replace(/</g, '@L').replace(/>/g, '@G');
    }
  };


  actionFromPostMessage(action: string, values: any) {

    // console.log('PARENT: action', action);
    // console.log('PARENT: value', values);
    /* Hago algo con el event.data que contiene las variables cmi que me envía el usuario */



    switch (action) {
      case 'Initialize':
        this.window.API_1484_11.Initialize();
        break;

      case 'Commit':
        this.window.API_1484_11.Commit();
        break;

      case 'Terminate':
        this.window.API_1484_11.Terminate();
        break;

      case 'GetValue':
        Object.keys(values).forEach(name => this.window.API_1484_11.GetValue(name));
        break;

      case 'SetValue':
        if (values) {
          Object.keys(values).forEach(name => this.window.API_1484_11.SetValue(name, values[name]));
        }
        break;

      case 'GetLastError':
        this.window.API_1484_11.GetLastError();
        break;

      case 'enlace':
        if (values) {
          Object.keys(values).forEach(name => this.window.API_1484_11.SetValue(name, values[name]));
        }
        break;

      default:
        break;
    }
  }

  openLinkBanco(event) {
     if (event.target.nodeName === 'A' && event.target.href.includes('banco.aulaplaneta')) {
      const url = event.target.href.replace('http://', 'https://');
      event.stopPropagation();
      event.preventDefault();
     
      this.localStorageService.getItem('JWT')
        .pipe(takeUntil(this.destroy$))
        .subscribe(jwt => {
          if(this.authManagementService.currentUser.funcionalidades.some(funcionalidad => funcionalidad.id === 3)){
            window.open(`${url}?DATA=Bearer ${jwt}`, '_blank');

          } else{
            window.open(`${url}?DATA=Bearer ${jwt}&plano=true&simple=true`, '_blank');
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
