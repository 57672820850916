import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-descargar-pdf',
  templateUrl: 'descargar-pdf.component.html',
  styleUrls: ['./descargar-pdf.component.scss'],
})
export class DescargarPdfComponent implements OnInit {
  label = '';
  seleccion = '';
  opcionSeleccionada = false;
  generado = false;
  showSpinner = false;


  locationsType = [
  //  { value: 'indice', label: 'Indice de contenidos de la materia' },
    { value: 'contenido', label: 'Contenido' }
  ];

  constructor(
    public dialogRef: MatDialogRef<DescargarPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngOnInit(): void {
    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-descargar-pdf',
        popupName: 'popup_descargar_pdf',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }

  selected(location){
    this.seleccion = location.value;
    this.label = location.label;
  }

  generar() {
    this.generado = true;
    this.showSpinner = true;
    setTimeout(() => {
      this.showSpinner = false;
    }, 1800);

    const info = {
      event: 'popup',
      popup: {
        action: 'generar',
        popupId: 'dialog-descargar-pdf',
        popupName: 'popup_descargar_pdf',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }
}
