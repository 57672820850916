import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthManagementService } from './auth-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TitleService } from '../title/title.service';
import { TranslateService } from '@ngx-translate/core';
import { LtiManagementService } from '../../services/data/lti/lti-management.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { first } from 'rxjs/operators';
import { SmartlinkManagementService } from '../../services/data/smartlink/smartlink-management-service.service';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private authManagementService: AuthManagementService;
  private titleService: TitleService;
  private translateService: TranslateService;

  constructor(
    private injector: Injector,
    private router: Router,
    private snackBar: MatSnackBar,
    private ltiManagementService: LtiManagementService,
    private smartlinkManagementService: SmartlinkManagementService,
    private localStorageService: LocalStorageService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.authManagementService = this.injector.get(AuthManagementService);
    this.titleService = this.injector.get(TitleService);
    this.translateService = this.injector.get(TranslateService);

    this.ltiManagementService.moodle = route.queryParams.moodle;
    this.ltiManagementService.preview = route.queryParams.preview;
    this.ltiManagementService.contextId = route.queryParams.context_id;
    this.ltiManagementService.moodleTeacherId = route.queryParams.moodleTeacherId;

    this.authManagementService.redirectUrl = state.url;

    const token = await this.getToken();
    const currentUser = await this.getCurrentUser();

    const isSmartlinkActive = this.smartlinkManagementService.smartlink || sessionStorage.getItem('smartlink') === 'true';
    const redirectUrl = sessionStorage.getItem('redirectUrl');

    if (isSmartlinkActive && redirectUrl && state.url !== redirectUrl) {
      this.smartlinkManagementService.smartlink = true;
      console.log('Redirecting to:', redirectUrl);
      this.router.navigateByUrl(redirectUrl);
      return false;
    } else {
      console.log('this.smartlinkManagementService.smartlink:', this.smartlinkManagementService.smartlink);
      console.log('sessionStorage.getItem("smartlink"):', sessionStorage.getItem('smartlink'));
      console.log('isSmartlinkActive:', isSmartlinkActive);
      console.log('redirectUrl:', redirectUrl);
      console.log('state.url:', state.url);
    }
    if (this.authManagementService.userCanPermission(['AccesoLtiTareas'])) {
      const referrer = route.queryParams.main;
      if (referrer) {
        localStorage.setItem('moodleMainUrl', state.url);
        const params = { ...route.queryParams };
        delete params.main;
        this.router.navigate([state.url.split('?')[0]], { queryParams: params });
      }

      if (!this.ltiManagementService.moodle) {
        this.ltiManagementService.moodle = token;
      } else if (this.ltiManagementService.moodle !== token) {
        this.ltiManagementService.token = route.queryParams.moodle;
        this.authManagementService.authLogoutNotClean(state.url);
        this.authManagementService.authLoginMoodle();
        return false;
      }
    }

    if (this.ltiManagementService.contextId  ) {
      localStorage.setItem('contextId', route.queryParams.context_id);
    
    } else {
      this.ltiManagementService.contextId = localStorage.getItem('contextId') as any;
    
    }


    if ( this.ltiManagementService.moodleTeacherId ) {
      localStorage.setItem('moodleTeacherId', route.queryParams.moodleTeacherId);
    } else {
      this.ltiManagementService.moodleTeacherId = localStorage.getItem('moodleTeacherId') as any;
    }


    if (state.url.includes('google') && !currentUser) {
      localStorage.setItem('redirectUrl', this.router.url);
      this.router.navigateByUrl('/login/google');
      return true;
    }

    if (state.url.includes('microsoft') && !currentUser) {
      localStorage.setItem('redirectUrl', this.router.url);
      this.router.navigateByUrl('/login/microsoft');
      return true;
    }

    if (currentUser && !this.ltiManagementService.moodle) {
      if (currentUser.moodle) {
        this.localStorageService.clearDB();
        localStorage.clear();
        this.router.navigate(['/login']);
        return false;
      }

      this.ltiManagementService.moodle = null;
      if (route.data.role && !this.authManagementService.userCanRoles(route.data.role)) {
        this.snackBar.open('No tienes privilegios', 'OK', {
          duration: 2000
        });
        this.router.navigate(['/login']);
        return false;
      }

      return true;
    } else if (currentUser && this.ltiManagementService.moodle) {
      const token = await this.getToken();
      if (this.authManagementService.userCanPermission(['AccesoLtiTareas'])) {
        this.ltiManagementService.moodle = token;
        this.ltiManagementService.moodleTeacherId = localStorage.getItem('moodleTeacherId') as any;
      }
      if (this.ltiManagementService.token.length < 1) {
        this.ltiManagementService.token = this.ltiManagementService.moodle as any;
      }

      if (!currentUser.moodle) {
        this.ltiManagementService.token = route.queryParams.moodle;
        this.authManagementService.authLogoutNotClean(state.url);
        this.authManagementService.authLoginMoodle();
      } else if (this.ltiManagementService.token !== token) {
        this.ltiManagementService.token = route.queryParams.moodle;
        this.authManagementService.authLogoutNotClean(state.url);
        this.authManagementService.authLoginMoodle();
      } else {
        return true;
      }
    } else if (!currentUser && this.ltiManagementService.moodle) {
      if (route.queryParams.moodle) {
        this.ltiManagementService.token = route.queryParams.moodle;
        this.authManagementService.authLoginMoodle();
      }

      // No está logueado, redirigir a la página de login
      this.router.navigate(['/login']);
      return false;
    } else {
      // No está logueado, redirigir a la página de login
      this.router.navigate(['/login']);
      return false;
    }
  }

  private async getToken() {
    return await this.localStorageService.getItem('JWT').pipe(first()).toPromise();
  }

  private async getCurrentUser() {
    return await this.localStorageService.getItem('CURRENT_USER').pipe(first()).toPromise();
  }
}
