<div id="dialog-configurar-clase" role="form" class="standard">

    <h1 class="custom-text heading-6 font-weight-bold" mat-dialog-title>{{'dialogs.configurar-clase.title' | translate}}</h1>
     <div mat-dialog-content class="mat-dialog-content">

        <p class="description">{{'dialogs.configurar-clase.description' | translate}}</p>

        <form role="form" *ngIf="(miCentroManagementService.cursos$ | async)">
            <fieldset class="w-100 d-flex flex-column flex-md-row">
                <div class="form-group default-input w-100 me-4">
                    <label for="curso" class="default-text">{{ 'dialogs.configurar-clase.curso' | translate }}</label>
                    <mat-select id="curso" [(value)]="selectedCurso" class="default-select default-select__blue" [placeholder]="'dialogs.configurar-clase.curso.placeholder' | translate" panelClass="cdkSelect" [disabled]="(miCentroManagementService.cursos$ | async).length < 1">
                        <mat-option  class="default-option" *ngFor="let curso of (miCentroManagementService.cursos$ | async)" [value]="curso.id">
                            {{ curso.ordinal }} {{ curso.etapa.nombre }}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="form-group default-input w-100 me-4">
                    <label for="grupo" class="default-text">{{ 'dialogs.configurar-clase.grupo' | translate }}</label>
                    <mat-select id="grupo" [(value)]="selectedGrupo" cdkFocusInitial class="default-select default-select__blue" [placeholder]="'dialogs.configurar-clase.grupo.placeholder' | translate" panelClass="cdkSelect" [disabled]="(miCentroManagementService.grupos$ | async).length < 1">
                        <mat-option  class="default-option" *ngFor="let grupo of (miCentroManagementService.grupos$ | async)" [value]="grupo.id">
                            {{ grupo.id }}
                        </mat-option>
                    </mat-select>
                </div>
            </fieldset>

            <div mat-dialog-actions class="justify-content-start mt-2">
                <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close tabindex="7">{{ 'generico.cerrar' | translate }}</button>
                <button role="button" class="default-button default-button__primary" (click)="close()" type="submit" tabindex="6" [disabled]="!selectedCurso || !selectedGrupo">{{ 'generico.guardar' | translate }}</button>
            </div>
        </form>
    </div>
</div>
