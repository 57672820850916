import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { ProyectoDataService } from './proyecto-data.service';
import { take, takeUntil } from 'rxjs/operators';
import { Materia } from '../../../shared/models/materia.model';
@Injectable({
  providedIn: 'root'
})
export class ProyectoManagementService implements OnDestroy {

  misProyectos$: Observable<Materia[]>;
  proyecto$: Observable<Materia>;

  private _misProyectos: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>([]);
  private _proyecto: BehaviorSubject<Materia> = new BehaviorSubject<Materia>(null);
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private proyectoDataService: ProyectoDataService
  ) {
    this.misProyectos$ = this._misProyectos.asObservable();
    this.proyecto$ = this._proyecto.asObservable();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get proyecto() {
    return this._proyecto.getValue();
  }

  get misProyectos() {
    return this._misProyectos.getValue();
  }

  set misProyectos(proyectos: Materia[]) {
    this.misProyectosNext(proyectos);
  }

  getMisProyectos() {
    this.proyectoDataService.getMisProyectos().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.misProyectosNext(res.proyectos);
        }
      );
  }

  getProyecto(id: string) {
    this.proyectoDataService.getProyecto(id).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.proyectoNext(res.proyecto);
        }
      );
  }

  createProyecto(proyecto: Materia) {
    this.proyectoDataService.createProyecto(proyecto).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getMisProyectos();
      });
  }

  updateProyecto(proyecto: Materia) {
    this.proyectoDataService.updateProyecto(proyecto).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getMisProyectos();
      });
  }

  deleteProyecto(proyecto: Materia) {
    this.proyectoDataService.deleteProyecto(proyecto).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getMisProyectos();
      });
  }

  private misProyectosNext(proyectos: Materia[]) {
    this._misProyectos.next(proyectos);
  }

  private proyectoNext(proyecto: Materia) {
    this._proyecto.next(proyecto);
  }
}
