import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import { GtagModule } from 'angular-gtag';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';

import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SettingsPipe } from './services/pipes/settings.pipe';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MaterialModule } from './material/material.module';
import { MainComponent } from './features/main/main.component';
import es from '@angular/common/locales/es';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { EnvService } from './services/env.service';
import { SharedModule } from './shared/shared.module';
import { ScriptService } from './services/scripts.service';
import { MatIconRegistry } from '@angular/material/icon';
import { SvgService } from './services/svg.service';
import { VoidComponent } from './features/void/void.component';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { AliveComponent } from './features/alive/alive.component';

export const customTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 300,
  touchendHideDelay: 300
};

registerLocaleData(es);

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,
    OverlayModule,
    PortalModule,
    ClipboardModule,
    DragDropModule,
    // TODO: DEUDA VERSIONADO
    // GtagModule.forRoot({ trackingId: environment.googleAnalytics, trackPageviews: false }),
    SharedModule,


    // core
    CoreModule,
    MaterialModule,
    TableVirtualScrollModule,

    // app
    AppRoutingModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production ? false : environment.production,
      registrationStrategy: 'registerImmediately'
    })
  ],
  declarations: [AppComponent, MainComponent, VoidComponent, AliveComponent],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-ES' },
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => envService.init(),
      deps: [EnvService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (scriptService: ScriptService) => () => scriptService.init(),
      deps: [ScriptService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (svgService: SvgService) => () => svgService.init(),
      deps: [SvgService],
      multi: true
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults },
    { provide: Window, useValue: window },
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-icons-round');
  }
}
