<div id="dialog-validar-licencia" class="standard">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.validar-licencia.validar-licencia' | translate }}</h1>
    <p>{{ 'dialogs.validar-licencia.texto' | translate }}</p>
    <br>
     <div mat-dialog-content class="mat-dialog-content">
        <div class="accordeon-list">
            <form role="form" [formGroup]="dpForm">
                <fieldset class="w-100 d-flex flex-column flex-md-row">
                    <div class="form-group default-input w-100 me-4">
                        <label for="licencia" class="default-text">{{ 'dialogs.validar-licencia.label' | translate }}</label>
                        <input id="licencia" aria-labelledby="error_licencia" type="text" class="form-control" [placeholder]="('dialogs.validar-licencia.placeholder' | translate)" formControlName="licencia" cdkFocusInitial tabindex="1">
                        <mat-error id="error_licencia" *ngIf="dpForm.get('licencia').hasError('required') && dpForm.controls['licencia'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </fieldset>
            </form>
            <p>{{'dialogs.validar-licencia.aclaracion' | translate}}</p>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-start mt-3">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__primary" [mat-dialog-close]="dpForm.value" type="submit" [disabled]="dpForm.invalid" cdkFocusRegionEnd>{{ 'dialogs.validar-licencia.validar' | translate }}</button>
    </div>
</div>
