import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateText'
})

export class TruncateTextPipe implements PipeTransform {

    transform(value: string, args: any[]): string {
        if (value) {
            const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
            const trail = args.length > 1 ? args[1] : '...';

            if (args.length > 2) {
                return value.length > limit ? this.truncateToLastSpace(value, limit) + trail : value;
            } else {
                return value.length > limit ? value.substring(0, limit) + trail : value;
            }
        }
        return '';
    }

    truncateToLastSpace(value, limit) {
        value = value.substring(0, limit);
        const lastIndex = value.lastIndexOf(' ');
        return value.substring(0, lastIndex);
    }
}
