import { ElementRef, Input, Renderer2 } from "@angular/core";
import { Directive } from "@angular/core";

@Directive({
  selector: '[setIndex]'
})
export class setSlideToDirective {
  @Input() indexSlide;
  constructor (private _elRef: ElementRef, private _renderer: Renderer2) {  }

  ngOnInit() {
    console.log('!', this.indexSlide);
    this._renderer.setAttribute(this._elRef.nativeElement, 'data-slide-to', this.indexSlide);
  }
}