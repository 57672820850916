/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { AddModulesBaseComponent } from '../../add-modules-base.component';

@Component({
  selector: 'aula-planeta-add-module-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent extends AddModulesBaseComponent {

  private _imagen_previa: BehaviorSubject<string> = new BehaviorSubject<string>('');
  imagen_previa$ = this._imagen_previa.asObservable();


  error = {
    video: {
      size: false,
      type: false
    },
    image: false,
    subtitulos: false
  };

  LIMIT_MB = 28600000;
  validTypes = {
    video: ['video/mp4', 'video/x-msvideo', 'video/quicktime', 'video/x-flv', 'video/mpeg'],
    image: ['image/gif', 'image/jpeg', 'image/jpg', 'image/pipeg', 'image/png'],
    subtitulos: ['application/x-subrip',
      'application/octet-stream',
      'application/x-subtitle',
      'application/x-subtitle-sami',
      'application/x-subtitle-tmplayer',
      'application/x-subtitle-mpl2',
      'application/x-subtitle-dks',
      'application/x-subtitle-qttext',
      'application/x-subtitle-lrc'
    ]
  };

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<VideoComponent>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    super(fb, dialogRef, data);
  }

  ngOnInit() {
    this.formModule = this.fb.group({
      titulo_html: [this.data && this.data.propiedades.titulo_html ? this.data.propiedades.titulo_html : '', Validators.required],
      descripcion_html: [this.data && this.data.propiedades.descripcion_html ? this.data.propiedades.descripcion_html : ''],
      visible: [this.data && this.data.propiedades.visible === 'Profesores' ? true : false],
      video: [this.data && this.data.propiedades.video ? this.data.propiedades.video : null, Validators.required],
      videoName: [''],
      transcripcion: [this.data && this.data.propiedades.transcripcion ? this.data.propiedades.transcripcion : ''],
      subtitulos: [this.data && this.data.propiedades.subtitulos ? this.data.propiedades.subtitulos : ''],
      subtitulosName: [null],
      imagen_previa: [this.data && this.data.propiedades.imagen_previa ? this.data.propiedades.imagen_previa : ''],
      imagen_previaName: [''],
      creditos_html: [this.data && this.data.propiedades.creditos_html ? this.data.propiedades.creditos_html : ''],
      abrir_en_ventana_nueva: [this.data && this.data.propiedades.abrir_en_ventana_nueva ? this.data.propiedades.abrir_en_ventana_nueva : false, Validators.required]
    });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-add-module-video',
        popupName: 'popup_añadir_video'
      }
    };
    this.gtagService.event(info);
  }

  onVideoFile(file, field) {
    this.error.video.type = false;
    this.error.video.size = false;
    if (file && file.size <= this.LIMIT_MB && this.isValidFile(file, field)) {
      this.onDropFile(file, field);
    } else {
      if (file.size > this.LIMIT_MB) {
        this.error.video.size = true;
      }
      if (!this.isValidFile(file, field)) {
        this.error.video.type = true;
      }
    }
  }

  onDropFile(file, field) {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (field === 'imagen_previa') {
          this._imagen_previa.next(reader.result as string);
        }
        this.formModule.get(field).setValue(reader.result as string);
        this.formModule.get(field + 'Name').setValue(file.name);
        this.formModule.markAsDirty();
        this.formModule.updateValueAndValidity();
        this.cd.markForCheck();
      };
    }
  }

  deleteFile(field) {
    this.formModule.get(field).setValue(null);
    this.formModule.get(field).updateValueAndValidity();
    this._imagen_previa.next(null);
    if (field === 'imagen_previa') {
      this._imagen_previa.next(null);
    }
  }

  isValidFile(file, field) {
    console.log('field:', field);
    console.log('filetype: ', file.type);
    return this.validTypes[field].includes(file.type);
  }
}

