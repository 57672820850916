<div id="dialog-configuracion-cookies" class="standard" cdkTrapFocus>
  <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ 'dialogs.configuracion-cookies.titulo' | translate }}</h1>
   <div mat-dialog-content class="mat-dialog-content">
      <p>Utilizamos cookies propias y de terceros para fines analíticos y para mostrarte publicidad personalizada en base a un perfil elaborado a partir de tus hábitos de navegación (por ejemplo, páginas visitadas). Puedes aceptar o rechazar todas las
          cookies pulsando el botón correspondiente o configurarlas mediante el enlace “Configuración de cookies”.</p>
      <a href="textos-legales/politica-cookies">{{ 'dialogs.configuracion-cookies.link' | translate }}</a>
      <br/><br/>
      <form [formGroup]="form">
          <fieldset>

              <mat-tab-group>
                  <!-- <mat-tab [label]="'dialogs.configuracion-cookies.tab1' | translate">
                      <div class="w-100 d-flex flex-column flex-md-row my-4">
                          <div class="col-md-3 ps-0 ms-0">
                              <h2 class="default-text">{{'dialogs.configuracion-cookies.tab1.titulo' | translate}}</h2>
                              <mat-slide-toggle [checked]="form.get('privacidad').value" formControlName="privacidad" class="mt-4 grey-text">{{'dialogs.configuracion-cookies.tab1.toggle' | translate}}</mat-slide-toggle>
                          </div>
                          <div class="col-md-9 pe-0 me-0">
                              <p>
                                  Son aquellas que permiten al usuario la navegación a través de una plataforma y la utilización de las diferentes opciones o servicios que en ella existan, incluyendo aquellas que el editor utiliza para permitir la gestión y operativa de la página web
                                  y habilitar sus funciones y servicios, como identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, gestionar el pago,
                                  controlar el fraude vinculado a la seguridad del servicio, utilizar elementos de seguridad durante la navegación, habilitar contenidos dinámicos o compartir contenidos a través de redes sociales.
                                  <br/><br/> También pertenecen a esta categoría aquellas cookies que permiten la gestióno identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso
                                  de compra de un pedido, gestionar el pago, controlar el fraude vinculado.
                              </p>

                              <table class="w-100 default-regular-table mb-3">
                                <thead>
                                    <tr>
                                        <th>Cookie</th>
                                        <th>Proveedor</th>
                                        <th>Categoria</th>
                                        <th>Entidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let cookie of data.cookiesData">
                                      <tr *ngIf="cookie.catValue == 'privacidad'">
                                        <td>{{ cookie.cookie }}</td>
                                        <td>{{ cookie.proveedor }}</td>
                                        <td>{{ cookie.categoria }}</td>
                                        <td>{{ cookie.entidad }}</td>
                                      </tr>
                                    </ng-container>
                                </tbody>
                            </table>

                          </div>
                      </div>
                  </mat-tab> -->

                  <mat-tab [label]="'dialogs.configuracion-cookies.tab2' | translate">
                      <div class="w-100 d-flex flex-column flex-md-row my-4">
                          <div class="col-md-3 ps-0 ms-0">
                              <h2 class="default-text">{{'dialogs.configuracion-cookies.tab2.titulo' | translate}}</h2>
                              <mat-slide-toggle [checked]="true" [disabled]="true" class="mt-4 grey-text">{{'dialogs.configuracion-cookies.tab2.toggle' | translate}}</mat-slide-toggle>
                          </div>
                          <div class="col-md-9 pe-0 me-0">
                            <p>Son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan, incluyendo aquellas que el editor utiliza para permitir la
                              gestión y operativa de la página web y habilitar sus funciones y servicios, como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran
                              un pedido, realizar el proceso de compra de un pedido, gestionar el pago, controlar el fraude vinculado a la seguridad del servicio, realizar la solicitud de inscripción o participación en un evento, contar visitas a efectos de la facturación
                              de licencias del software con el que funciona el servicio (sitio web, plataforma o aplicación), utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o sonido, habilitar contenidos dinámicos
                              (por ejemplo, animación de carga de un texto o imagen) o compartir contenidos a través de redes sociales.</p>
                              <p>También pertenecen a esta categoría, por su naturaleza técnica, aquellas cookies que permiten la gestión, de la forma más eficaz
                              posible, de los espacios publicitarios que, como un elemento más de diseño o “maquetación” del servicio ofrecido al usuario, el editor haya incluido en una página web, aplicación o plataforma en base a criterios como el contenido editado,
                              sin que se recopile información de los usuarios con fines distintos, como puede ser personalizar ese contenido publicitario u otros contenidos.</p>


                              <table class="w-100 default-regular-table mb-3">
                                <thead>
                                    <tr>
                                        <th>Cookie</th>
                                        <th>Proveedor</th>
                                        <th>Categoria</th>
                                        <th>Entidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let cookie of data.cookiesData">
                                      <tr *ngIf="cookie.catValue == 'tecnicas'">
                                        <td>{{ cookie.cookie }}</td>
                                        <td>{{ cookie.proveedor }}</td>
                                        <td>{{ cookie.categoria }}</td>
                                        <td>{{ cookie.entidad }}</td>
                                      </tr>
                                    </ng-container>
                                </tbody>
                            </table>

                          </div>
                      </div>
                  </mat-tab>

                  <mat-tab [label]="'dialogs.configuracion-cookies.tab3' | translate">
                      <div class="w-100 d-flex flex-column flex-md-row my-4">
                          <div class="col-md-3 ps-0 ms-0">
                              <h2 class="default-text">{{'dialogs.configuracion-cookies.tab3.titulo' | translate}}</h2>
                              <mat-slide-toggle [checked]="form.get('analisis').value" formControlName="analisis" class="mt-4 grey-text">{{'dialogs.configuracion-cookies.tab3.toggle' | translate}}</mat-slide-toggle>
                          </div>
                          <div class="col-md-9 pe-0 me-0">
                            <p>Son aquellas que permiten al responsable de las mismas el seguimiento y análisis del comportamiento de los usuarios del sitio web a los que están vinculadas, incluida la cuantificación de los impactos de los anuncios. La información recogida
                              mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</p>
                          <p>El sitio web puede utilizar la información de su visita para realizar evaluaciones y cálculos estadísticos sobre datos anónimos, así como para garantizar la continuidad del servicio o para realizar mejoras en sus sitios web. Dicha información
                              no será utilizada para ninguna otra finalidad.</p>

                              <table class="w-100 default-regular-table mb-3">
                                <thead>
                                    <tr>
                                        <th>Cookie</th>
                                        <th>Proveedor</th>
                                        <th>Categoria</th>
                                        <th>Entidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let cookie of data.cookiesData">
                                      <tr *ngIf="cookie.catValue == 'analisis'">
                                        <td>{{ cookie.cookie }}</td>
                                        <td>{{ cookie.proveedor }}</td>
                                        <td>{{ cookie.categoria }}</td>
                                        <td>{{ cookie.entidad }}</td>
                                      </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                          </div>
                      </div>
                  </mat-tab>

                  <mat-tab [label]="'dialogs.configuracion-cookies.tab4' | translate">
                      <div class="w-100 d-flex flex-column flex-md-row my-4">
                          <div class="col-md-3 ps-0 ms-0">
                              <h2 class="default-text">{{'dialogs.configuracion-cookies.tab4.titulo' | translate}}</h2>
                              <mat-slide-toggle [checked]="form.get('publicitarias').value" formControlName="publicitarias" class="mt-4 grey-text">{{'dialogs.configuracion-cookies.tab4.toggle' | translate}}</mat-slide-toggle>
                          </div>
                          <div class="col-md-9 pe-0 me-0">
                            <p>Las cookies publicitarias permiten gestionar, de la forma más eficaz posible, los espacios publicitarios incluidos en nuestra página web, en base a criterios como el contenido editado o la frecuencia con la que se muestran los anuncios.</p>


                              <table class="w-100 default-regular-table mb-3">
                                <thead>
                                    <tr>
                                        <th>Cookie</th>
                                        <th>Proveedor</th>
                                        <th>Categoria</th>
                                        <th>Entidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let cookie of data.cookiesData">
                                      <tr *ngIf="cookie.catValue == 'publicitarias'">
                                        <td>{{ cookie.cookie }}</td>
                                        <td>{{ cookie.proveedor }}</td>
                                        <td>{{ cookie.categoria }}</td>
                                        <td>{{ cookie.entidad }}</td>
                                      </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                          </div>
                      </div>
                  </mat-tab>
                  <!--
                  <mat-tab [label]="'dialogs.configuracion-cookies.tab5' | translate">
                      <div class="w-100 d-flex flex-column flex-md-row my-4">
                          <div class="col-md-3 ps-0 ms-0">
                              <h2 class="default-text">{{'dialogs.configuracion-cookies.tab5.titulo' | translate}}</h2>
                              <mat-slide-toggle [checked]="form.get('preferencias').value" formControlName="preferencias" class="mt-4 grey-text">{{'dialogs.configuracion-cookies.tab5.toggle' | translate}}</mat-slide-toggle>
                          </div>
                          <div class="col-md-9 pe-0 me-0">
                              <p>
                                  Son aquellas que permiten al responsable de las mismas el seguimiento y análisis del comportamiento de los usuarios del sitio web a los que están vinculadas, incluida la cuantificación de los impactos de los anuncios.
                                  <br/><br/> La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma, con el fin de introducir mejoras en función del análisis de los
                                  datos de uso que hacen los usuarios del servicio.
                                  <br/><br/> El sitio web puede utilizar la información de su visita para realizar evaluaciones y cálculos estadísticos sobre datos anónimos, así como para garantizar la continuidad del servicio o para realizar mejoras
                                  en sus sitios web.
                                  <br/><br/> Dicha información no será utilizada para ninguna otra finalidad.
                              </p>

                              <table class="w-100 default-regular-table mb-3">
                                <thead>
                                    <tr>
                                        <th>Cookie</th>
                                        <th>Proveedor</th>
                                        <th>Categoria</th>
                                        <th>Entidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let cookie of data.cookiesData">
                                      <tr *ngIf="cookie.catValue == 'preferencias'">
                                        <td>{{ cookie.cookie }}</td>
                                        <td>{{ cookie.proveedor }}</td>
                                        <td>{{ cookie.categoria }}</td>
                                        <td>{{ cookie.entidad }}</td>
                                      </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                          </div>
                      </div>
                  </mat-tab> -->
              </mat-tab-group>
          </fieldset>
      </form>
  </div>
  <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
    <button role="button" class="default-button default-button__primary mx-auto mb-0 my-md-1" (click)="close(false, true)">{{ 'dialogs.configuracion-cookies.denegar' | translate }}</button>
    <button role="button" class="default-button default-button__primary mx-auto mb-0 my-md-1" (click)="close(true, false)">{{ 'dialogs.configuracion-cookies.permitir' | translate }}</button>
    <button role="button" class="default-button default-button__primary mx-auto mb-0 my-md-1" (click)="close(false, false)">{{ 'dialogs.configuracion-cookies.guardar' | translate }}</button>
    <button role="button" class="default-button default-button__secondary mx-auto mb-0 my-md-1" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
  </div>
</div>
