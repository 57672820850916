import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-duplicar-materia-personalizada',
  templateUrl: 'duplicar-materia-personalizada.component.html',
  styleUrls: ['./duplicar-materia-personalizada.component.scss'],
})
export class DuplicarMateriaPersonalizadaComponent implements OnInit {

  form: UntypedFormGroup;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DuplicarMateriaPersonalizadaComponent>, 
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {}

  ngOnInit () {
    this.form = this.formBuilder.group({
      titulo: [this.data && this.data.materia ? this.data.materia : '', [Validators.required]],
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'duplicate-custom-materia',
        popupId: 'dialog-duplicar-materia-personalizada',
        popupName: 'popup_duplicate_custom_materia'
      }
    };
    this.gtagService.event(info);
  }

  onSubmit() {
    if (this.form.valid) {
      const info = {
        event: 'popup',
        popup: {
          action: 'guardar',
          popupId: 'dialog-duplicar-materia-personalizada',
          popupName: 'popup_duplicate_custom_materia'
        }
      };
      this.gtagService.event(info);
      const formValue = this.form.getRawValue();
      this.dialogRef.close(formValue);
    }
  }
}
