import { Pipe, PipeTransform } from '@angular/core';
import { TemasDataService } from '../data/temas/temas-data.service';


@Pipe({
  name: 'wirisFormula'
})
export class WirisFormulaPipe implements PipeTransform {

  constructor(
    public temaDataService: TemasDataService
  ){}

  transform(htmlString: string): string {

    const domParser = new DOMParser();
    const docElement = domParser.parseFromString(htmlString, 'text/html').documentElement;

    const emCollection = docElement.getElementsByClassName('Wirisformula');

    Array.from(emCollection).forEach((em) => {
      const mySpan = document.createElement('span');
      let valor = em.attributes['data-mathml'].nodeValue;

      valor = valor.replaceAll('«', '<');
      valor = valor.replaceAll('»', '>');
      valor = valor.replaceAll('§', '&');
      valor = valor.replaceAll('¨', '"');
      valor = valor.replaceAll('`', '\'');

      mySpan.innerHTML = valor;
      em.parentNode?.replaceChild(mySpan, em);
    });

    return docElement.innerHTML;
  }

  transformBack(htmlString: string) {
    let htmlMath = htmlString;
    htmlMath = this.replaceAllOcurrences(htmlMath, '<', '«');
    htmlMath = this.replaceAllOcurrences(htmlMath, '>', '»');
    htmlMath = this.replaceAllOcurrences(htmlMath, '&', '§');
    htmlMath = this.replaceAllOcurrences(htmlMath, '"', '¨');
    htmlMath = this.replaceAllOcurrences(htmlMath, '\'', '`');

    const encodedMML = encodeURIComponent(htmlString);
    // console.log('Contenido MML codificado:', encodedMML);

    // const imageUrl = `https://www.wiris.net/demo/editor/render?mml=${encodedMML}&format=svg`;
    // const accesibleText = getAccessibleText(encodedMML)
    let imageUrl = '';
    let imageAlt = '';
    const xhr = new XMLHttpRequest();

    // Configurar la solicitud como síncrona (false en el tercer parámetro)
    xhr.open('GET', `https://www.wiris.net/demo/editor/render?mml=${encodedMML}&format=svg`, false);

    // Enviar la solicitud
    xhr.send();

    // Verificar si la solicitud fue exitosa
    if (xhr.status === 200) {
        // Obtener el texto de la respuesta (contenido SVG)
        const svgText = xhr.responseText;

        // Codificar el SVG en base64 para embutirlo en el src de la imagen
        const base64SVG = btoa(svgText);

        // Crear una URL embebida para el SVG
        const svgDataUrl = `data:image/svg+xml;base64,${base64SVG}`;

        // Configurar el src de la imagen
        const imgElement = document.getElementById('miImagen');
        imageUrl = svgDataUrl;
        // console.error('Éxito al obtener el SVG de la API de Wiris', xhr.responseText);
    } else {
        // console.error('Error al obtener el SVG de la API de Wiris', xhr.responseText);
    }

    // Configurar la solicitud como síncrona (false en el tercer parámetro)
    xhr.open('GET', `https://www.wiris.net/demo/editor/mathml2accessible?mml=${encodedMML}&lang=es`, false);

    // Enviar la solicitud
    xhr.send();

    // Verificar si la solicitud fue exitosa
    if (xhr.status === 200) {
        // Obtener el texto de la respuesta (contenido SVG)
        imageAlt = xhr.responseText;
        // console.error('Éxito al obtener el Alt de la API de Wiris', xhr.responseText);
    } else {
        // console.error('Error al obtener el Alt de la API de Wiris', xhr.responseText);
    }


    const imgElement = document.createElement('img');
    imgElement.src = imageUrl;
    imgElement.classList.add('Wirisformula');
    // this.temaDataService.getAltWiriData(encodedMML).pipe(tap(console.log)).subscribe(res=>imgElement.alt = res);
    imgElement.alt = imageAlt;
    imgElement.setAttribute('data-mathml', htmlMath);
    imgElement.setAttribute('role', 'math');

    return imgElement;
  }

  replaceAllOcurrences(str, search, replacement){
    // return str.split(search).join(replacement);
    while(str.includes(search)){
      str = str.replace(search, replacement);
   }
   return str;
  }




}


// async function loadWirisSVG(encodedMML) {
//   try {
//       // Realiza la solicitud a la API de Wiris
//       const response = await fetch(`https://www.wiris.net/demo/editor/render?mml=${encodedMML}&format=svg`);

//       // Asegúrate de que la solicitud fue exitosa
//       if (!response.ok) {
//           throw new Error('Error al obtener el SVG de la API de Wiris');
//       }

//       // Lee la respuesta como texto (el contenido SVG)
//       const svgText = await response.text();

//       // Codifica el SVG en base64 para embutirlo en el src de la imagen
//       const base64SVG = btoa(svgText);

//       // Crea una URL embebida para el SVG
//       const svgDataUrl = `data:image/svg+xml;base64,${base64SVG}`;

//       // Configura el src de la imagen
//       const imgElement = document.getElementById('miImagen');
//       imgElement.src = svgDataUrl;

//   } catch (error) {
//       console.error('Error:', error);
//   }
// }




// async function getAccessibleText(mathML, lang = 'es') {
//   const baseUrl = 'https://www.wiris.net/demo/editor/mathml2accessible';
//   const url = `https://www.wiris.net/demo/editor/mathml2accessible?mml=${mathML}&lang=${lang}`;


//   try {
//       const response = await fetch(url);
//       if (!response.ok) {
//           throw new Error('Network response was not ok');
//       }
//       const text = await response.text();
//       console.log(text)
//       return text;
//   } catch (error) {
//       console.error('There was a problem with the fetch operation:', error);
//       return 'Error al obtener el texto accesible';
//   }
