<div id="dialog-guia-didactica" class="d-flex h-100 flex-column">
    <h1 class="default-text" mat-dialog-title>{{'dialogs.guia-didactica.titulo' | translate}}</h1>

    <div mat-dialog-content [ngClass]="data.color" class="d-flex flex-column h-100">
        <div class="w-100 d-flex justify-content-between align-items-center actions-row py-2">
            <span class="custom-text font-weight-bold">{{data.titulo}}</span>
            <span class="grey-text__light">{{data.curso}}, {{data.materia}}</span>
            <div class="d-flex flex-row">
                <button mat-icon-button (click)="toggleDisabled()" [ngClass]="!isDisabled ? 'default-text' : 'grey-icon'" [disabled]="true">
                    <mat-icon>mode_edit</mat-icon>
                </button>
                <button mat-icon-button
                  class="default-text"
                  attr.aria-label="{{'generico.print' | translate}}"
                  printSectionId="print-section"
                  printTitle="{{'dialogs.guia-didactica.titulo' | translate}}"
                  [useExistingCss]="true"
                  styleSheetFile="./guia-didactica.component.scss" ngxPrint>
                    <mat-icon>print</mat-icon>
                </button>
            </div>
        </div>
        <div class="ckeditor-container overflow-auto h-100" id="print-section" tabindex="0">
            <ckeditor #editorQ [disabled]="isDisabled" [config]="config" [editor]="editor" [data]="data.content" (ready)="onReady($event)" (change)="onChange($event)"></ckeditor>
        </div>

    </div>

    <div mat-dialog-actions class="justify-content-start mb-0 align-items-end">
        <button role="button" class="default-button default-button__primary me-4 font-weight-bold mt-2" [mat-dialog-close]="valueData">{{ 'generico.cerrar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary mt-2" *ngIf="data.editable && !isDisabled" (click)="toggleDisabled()">{{ 'generico.guardar' | translate }}</button>
    </div>
</div>
