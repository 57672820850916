export class Expandable {
    expanded: boolean;

    constructor() {
        this.expanded = false;
    }

    open() {
        this.expanded = true;
    }
    close() {
        this.expanded = false;
    }
    toogle() {
        this.expanded = !this.expanded;
    }
}
