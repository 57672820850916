import {
  Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild,
  ElementRef, OnDestroy, ApplicationRef, NgZone
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { take, takeUntil } from 'rxjs/operators';
import { TemasManagementService } from '../../../../services/data/temas/temas-management.service';
import { Subject } from 'rxjs';
import { ColorHelper } from '../../../helper/color.helper';

@Component({
  selector: 'aula-planeta-edit-recurso-plantilla',
  templateUrl: './edit-recurso-plantilla.component.html',
  styleUrls: ['./edit-recurso-plantilla.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRecursoPlantillaComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  voidConst = ' ';
  @ViewChild('iframe', { read: ElementRef }) iframe: ElementRef;
  iframeURL;
  showSpinner = true;
  urlSafe: SafeResourceUrl;

  constructor(
    public applicationRef: ApplicationRef,
    public zone: NgZone,
    public dialogRef: MatDialogRef<EditRecursoPlantillaComponent>,
    public temasManagementService: TemasManagementService,
    @Inject(MAT_DIALOG_DATA) public data,
    public sanitizer: DomSanitizer,
    public colorHelper: ColorHelper
  ) { }

  ngOnInit() {

    this.dialogRef.keydownEvents()
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(event => {
        if (event.key === 'Escape') {
          this.onCancel(null);
        }
      });

    this.dialogRef.backdropClick()
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(event => {
        this.onCancel(null);
      });

    const win = window as any;
    win.temasManagementService = this.temasManagementService;
    win.idModulo = this.data.idModulo;
    win.editRecursoPlantillaComponent = this;
    win.open(this.data.url);

    win.onEditorUpdated = (action) => {
      console.log('action', action);

      if (action === 'save') {
        console.log('save');
        const tmp$: Subject<void> = new Subject<void>();
        win.temasManagementService.getModuloDesdePlantilla(win.idModulo).pipe(
          take(1),
          takeUntil(tmp$))
          .subscribe(nuevoModulo => {
            console.log('_nuevoModulo:', nuevoModulo);
            this.onCancel(nuevoModulo);
            tmp$.next();
            tmp$.complete();
          });
      }
      if (action === 'cancel') {
        this.onCancel(null);
      }
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCancel(event): void {
    this.dialogRef.close(event);
  }

  getTypeButton(type) {
    return (type === 'primary') ? 'default-button__primary' : 'default-button__secondary';
  }
}
