import { animate, group, query, style } from '@angular/animations';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnimationsService {
  private static routeAnimationType: RouteAnimationType = 'NONE';

  constructor() {
    AnimationsService.routeAnimationType = 'NONE';
  }

  static isRouteAnimationsType(type: RouteAnimationType) {
    return AnimationsService.routeAnimationType === type;
  }

  updateRouteAnimationType(
    pageAnimations: boolean,
    elementsAnimations: boolean
  ) {
    AnimationsService.routeAnimationType =
      pageAnimations && elementsAnimations
        ? 'ALL'
        : pageAnimations
          ? 'PAGE'
          : elementsAnimations
            ? 'ELEMENTS'
            : 'NONE';
  }
}

export type RouteAnimationType = 'ALL' | 'PAGE' | 'ELEMENTS' | 'NONE';

export function slideTo(direction) {
  const optional = { optional: true };

  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: '0',
        [direction]: 0,
        width: 'calc(100% - 20px)',
        'padding-left': '20px'
      })
    ],
      optional
    ),
    query(':enter', [
      style({ [direction]: '-100%', }),
    ],
      optional
    ),
    group([
      query(':leave', [
        animate('1200ms ease', style({ [direction]: '100%', height: '0' }))
      ],
        optional
      ),
      query(':enter', [
        animate('1200ms ease', style({ [direction]: '0%', width: '100%', height: '*', 'padding-left': '0' }))
      ],
        optional
      )
    ]),
  ];
}
