import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GtagService } from '../../services/gtag/gtag.service';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector,
    private gtagService: GtagService) { }

  readonly errorLogin = 'Login o password incorrectos';
  readonly licenciaCaducada = 'El usuario no tiene licencia activa';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err?.error?.title !== this.licenciaCaducada){
              const appErrorHandler = this.injector.get(ErrorHandler);
              appErrorHandler.handleError(err);
  
              if (err && err.error && err.error.title === this.errorLogin) {
                const infoError = {
                  event: 'error',
                  error: {
                    method: 'directo',
                    errorId: err.status,
                    errorMessage: err.error.title,
                    pageId: 'Login'
                  }
                };
                this.gtagService.error(infoError);
              }
            }
          }
        }
      })
    );
  }
}
