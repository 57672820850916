<div id="dialog-start-compartir" class="standard">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.start-compartir.titulo' | translate }}</h1>

     <div mat-dialog-content class="mat-dialog-content">
        <div class="w-100 d-flex flex-column flex-md-row">
            <div class="form-group default-input w-100 me-4">
                <label for="compartirCompartir" class="default-text">{{ 'dialogs.start-compartir.compartir' | translate }}</label>
                <mat-select id="compartirCompartir" [(value)]="selectedFilters" cdkFocusInitial class="default-select default-select__blue" [placeholder]="'dialogs.start-compartir.compartir' | translate" panelClass="cdkSelect">
                    <mat-optgroup *ngFor="let filtersTypes of filtersTypesArr" class="default-group-options">
                        <mat-option class="default-option" *ngFor="let filter of filtersTypes" [value]="filter.value">
                            {{filter.label}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </div>
            <div *ngIf="!(ltiManagementService.moodle$ | async)" class="form-group default-input w-100 position-relative">
                <label for="compartirLink" class="default-text">{{ 'dialogs.start-compartir.link' | translate }}</label>
                <input id="compartirLink" type="text" class="form-control link-shared" [placeholder]="('dialogs.duplicate.name' | translate)" [value]="data.linkAulaplaneta" readonly>
                <button mat-icon-button class="clipboard-btn custom-text position-absolute" (click)="copyClipboard()" attr.aria-label="{{'dialogs.copiar-enlace.copiar' | translate}}">
                    <mat-icon matSuffix>content_copy</mat-icon>
                </button>
            </div>
        </div>

        <div class="my-4" *ngIf="selectedFilters === 'Profesores'">
            <div>
                <div class="form-group default-input autocomplete-input w-50 d-flex align-items-center position-relative">
                    <mat-icon class="ms-2 position-absolute custom-icon" matPrefix>search</mat-icon>
                    <label for="compartirBuscar" class="visually-hidden">{{ 'dialogs.start-compartir.buscar' | translate }}</label>
                    <input id="compartirBuscar" class="form-control ps-5" type="text" [placeholder]="('dialogs.start-compartir.buscar' | translate)" [formControl]="profesoresControl" [matAutocomplete]="auto" aria-label="Buscar profesores" />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" class="font-weight-bold" panelClass="cdkSelect">
                        <mat-option class="default-option avatar-option" *ngFor="let profesor of profesoresFiltro | async" [value]="profesor" (click)="anadirProfesorCompatir(profesor)">
                            <img *ngIf="profesor.img" class="photo me-2" [src]="profesor.img" [alt]="profesor.firstName">
                            <mat-icon *ngIf="!profesor.img" class="icon me-2">person</mat-icon>
                            {{profesor.nombre}} {{profesor.apellidoUno}} {{profesor.apellidoDos}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

            <div class="overflow-auto" *ngIf="profesoresCompartir.length > 0">
                <table #table mat-table multiTemplateDataRows class="w-100 mb-5 default-table standard" [dataSource]="profesoresCompartir">
                    <ng-container matColumnDef="profesor">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <img *ngIf="element.img" [src]="element.img | safe:'url'" alt="" height="40" width="40" class="cover">
                            <mat-icon *ngIf="!element.img" class="icon">person</mat-icon>
                            <span class="font-weight-bold">{{element.nombre}} {{element.apellidoUno}} {{element.apellidoDos}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="centro">
                        <th mat-header-cell *matHeaderCellDef class="default-text font-weight-bold w-30">{{'seguimiento-alumno.trabajo-alumno.tabla.centro' | translate}}</th>
                        <td mat-cell *matCellDef="let element">{{element.colegio.nombre}}</td>
                    </ng-container>

                    <ng-container matColumnDef="estatus">
                        <th mat-header-cell *matHeaderCellDef class="default-text font-weight-bold w-20">{{'seguimiento-alumno.trabajo-alumno.tabla.estatus' | translate}}</th>
                        <td class="pe-0" mat-cell *matCellDef="let element">
                            <div class="d-flex align-items-center selector">

                                <div class="pt-0 pe-2 selector-text">
                                    <span>{{element.tipo | titlecase }}</span>
                                </div>
                                <div class="ps-0 pt-2">
                                    <mat-select cdkFocusInitial class="custom-arrow" #selectorProgreso [(value)]="element.tipo" panelClass="cdkSelect">
                                        <mat-optgroup *ngFor="let rol of roles" class="default-group-options">
                                            <mat-option class="default-option" *ngFor="let item of rol.items" [value]="item.value">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <span>{{item.viewValue}}</span>
                                                    <mat-icon class="right-icon" [hidden]="item.value !== element.tipo">check</mat-icon>
                                                </div>
                                            </mat-option>
                                        </mat-optgroup>
                                    </mat-select>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="acciones">
                        <th class="text-end pe-0 acciones w-10" mat-header-cell *matHeaderCellDef></th>
                        <td class="pe-0 text-end" mat-cell *matCellDef="let element; let i = index">
                            <button class="custom-icon" mat-icon-button aria-label="Eliminar" (click)="quitarProfesorCompartir(element)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row class="base" *matRowDef="let element; columns: displayedColumns;"></tr>
                </table>
            </div>

        </div>

        <div class="my-4" *ngIf="selectedFilters === 'Classroom'">
            <div class="form-group w-100 row" style="align-items: self-end; padding-left: 15px;">
                <div class="w-50">
                    <label class="default-text">{{ 'dialogs.asignar-tarea.classroom.clases' | translate }}</label>
                    <mat-select class="default-select" [placeholder]="'dialogs.asignar-tarea.classroom.clases.selector' | translate" [formControl]="cursoControl" name="curso" panelClass="cdkSelect">
                        <ng-container *ngFor="let curso of gestionAlumnoManagementService.misClasesClassroom$ | async">
                            <mat-option class="default-option" [value]="curso">
                                {{ curso.name }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </div>

                <button [disabled]="cursoControl.pristine" role="button" class="default-button default-button__primary ms-3" style="height: 50%;" (click)="verClase(cursoControl)">{{ 'materia.mis-temas.tabla.acciones.asignar-tarea-classroom.ver-clase' | translate }}</button>

            </div>

        </div>
    </div>

    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button *ngIf="(selectedFilters === 'Profesores' && (profesoresCompartir.length > 0 ||  canSave) ) || selectedFilters === 'Centro' || selectedFilters === 'NoCompartido'" role="button" class="default-button default-button__secondary" (click)="onClose()"
            type="submit">{{ 'generico.guardar' | translate }}</button>
        <button *ngIf="selectedFilters === 'Teams'" role="button" class="default-button default-button__primary" (click)="onClose()" type="submit">{{ 'materia.mis-temas.materia.tema.acciones.compartir.boton-teams' | translate }}</button>
        <button *ngIf="selectedFilters === 'Classroom'" role="button" class="default-button default-button__primary" (click)="onClose()" type="submit">{{ 'materia.mis-temas.materia.tema.acciones.compartir.boton-classroom' | translate }}</button>
    </div>
</div>
