import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TemasManagementService } from '../../services/data/temas/temas-management.service';
import { EnvService } from '../../services/env.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core'

@Component({
  selector: 'aula-planeta-alive',
  templateUrl: './alive.component.html',
  styleUrls: ['./alive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AliveComponent implements OnInit, OnDestroy {

  file = '{NAS:"KO"}';

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public cd: ChangeDetectorRef,
    private http: HttpClient,
    private envService: EnvService,
    private temasManagementService: TemasManagementService
  ) { }


  ngOnInit(): void {
    this.temasManagementService.fullscreen = true;
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    this.http.get<any>(`${this.envService.rootContent}/Contenido/Alive/nas_alive.png`,
      httpOptions)
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.file = '{NAS:"OK"}';
        this.cd.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}




