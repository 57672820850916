/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { MiCentroManagementService } from '../../../../../../features/mi-centro/services/mi-centro-management.service';
import { MateriaManagementService } from '../../../../../../services/data/materia/materia-management.service';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { Profesor } from '../../../../../models/profesor.model';
import { BuscadorManagementService } from '../../../../../../services/data/buscador/buscador-management.service';

@Component({
  selector: 'aula-planeta-crear-materia',
  templateUrl: 'crear-materia.component.html',
  styleUrls: ['./crear-materia.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrearMateriaComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  form: UntypedFormGroup;
  materias: any = [
    {
      id: 1,
      name: 'Matematicas'
    },
    {
      id: 2,
      name: 'Ciencias Naturales'
    },
    {
      id: 3,
      name: 'Inglés'
    },
    {
      id: 4,
      name: 'Tecnología'
    },
    {
      id: 5,
      name: 'Filosofía'
    }
  ];
  etapas: any = [];

  cursos: any = null;

  selectedEtapa: any = 'Todas';
  selectedtCurso: any = null;
  materiasCentroFiltro: any[];

  displayedColumns: string[] = ['profesor', 'acciones'];
  profesores: Profesor[] = [];
  profesoresFiltro: Observable<Profesor[]>;
  profesoresCompartir: any[] = [];
  profesoresControl = new UntypedFormControl();
  
 
  constructor(
    private _change: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CrearMateriaComponent>,
    private fb: UntypedFormBuilder,
    private gtagService: GtagService,
    public miCentroManagementService: MiCentroManagementService,
    public materiaManagementService: MateriaManagementService,
    public buscadorManagementService: BuscadorManagementService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

    const tmp$: Subject<void> = new Subject<void>();

    this.miCentroManagementService.getProfesores().pipe(
     takeUntil(tmp$))
      .subscribe(
        profesores => {
          tmp$.next();
          tmp$.complete();
          this.profesores = profesores;
          if (this.data.profesores) {
            this.profesoresCompartir = this.profesores.filter(profesor => this.data.profesores.some(id => id === profesor.id));
            this._change.markForCheck();
          }
        }
      );

    this.buscadorManagementService.getCursos();
    this.buscadorManagementService.cursos$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(val => {
        if (val?.length > 0) {
          this.etapas = [...new Set(val.map(mat => mat.etapa.nombre))];
          this.etapas.unshift('Todas');

          // eslint-disable-next-line arrow-body-style
          this.cursos = [...new Set(val.map(mat => {
            return { ...mat.etapa, ordinal: mat.ordinal };
          }))];
          this.cursos = this.groupBy(this.cursos, curso => curso.nombre);

        }

      });

    this.materiaManagementService.getMateriasMaestras(false);
    this.materiaManagementService.materiasMaestras$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(val => {
        if (val?.length > 0) {

          if (this.data.isEdit) {
            this.selectedEtapa = this.data.etapa;
            this.selectedtCurso = this.data.curso;
          } else {
            const materia = val.find(materia => materia.id === this.data.materiaMaestraId);
            this.selectedEtapa = materia?.curso?.etapa?.nombre;
            this.selectedtCurso = materia?.curso?.ordinal;
          }
          return this.materiasCentroFiltro = val;
        }
      });

    this.profesoresFiltro = this.profesoresControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }



  ngOnInit() {
    this.form = this.fb.group({
      nombre: [this.data.nombre ? this.data.nombre : '', [Validators.required]],
      descripcion: [this.data.descripcion ? this.data.descripcion : '', [Validators.required]],
      materiaMaestraId: [this.data.materiaMaestraId ? this.data.materiaMaestraId : '', [Validators.required]]
    });

    if (this.data.isEdit) {
      this.selectedEtapa = this.data.etapa;
      this.selectedtCurso = this.data.curso;
    }


    const info = {
      event: 'popup',
      popup: {
        action: 'create-other-materia',
        popupId: 'dialog-crear-otra-materia',
        popupName: 'popup_create_other_materia'
      }
    };
    this.gtagService.event(info);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  isEditTitle() {
    return this.data.isEdit ? 'dialogs.crear-materia.titulo-editar' : 'dialogs.crear-materia.titulo-crear';
  }

  onSubmit() {
    if (this.form.valid) {
      const info = {
        event: 'popup',
        popup: {
          action: 'guardar',
          popupId: 'dialog-crear-otra-materia',
          popupName: 'popup_create_other_materia'
        }
      };
      this.gtagService.event(info);
      const formValue = this.form.getRawValue();
      formValue.autores = this.profesoresCompartir.map(elem => elem.id);
      this.dialogRef.close(formValue);
    }
  }

  anadirProfesorCompatir(profesor: Profesor) {
    if (profesor) {
      this.profesoresCompartir = [...this.profesoresCompartir, profesor];
      this.profesores.splice(this.profesores.findIndex(function (i) {
        return i.id === profesor.id;
      }), 1);

      this.profesoresControl.setValue('');
    }
  }

  quitarProfesorCompartir(profesor) {
    const position = this.profesoresCompartir.indexOf(profesor);
    if (position > -1) {
      this.profesoresCompartir = this.profesoresCompartir.filter((item, index) => index !== position);
      this.profesores = [...this.profesores, profesor];
    }
  }

  displayFn(profe): string {
    return profe && profe.firstName ? `${profe.firstName} ${profe.lastName}` : '';
  }

  private _filter(value) {
    const filterValue = (typeof value === 'string') ? value.toLowerCase() : `${value.nombre}${value.apellidoUno}${value.apellidoDos}`;
    return this.profesores.filter(profe => {
      const nombreApellido = `${profe.nombre}${profe.apellidoUno}${profe.apellidoDos}`;
      return nombreApellido.toLowerCase().includes(filterValue);
    });
  }

  filterByEtapa(value) {
    this.selectedtCurso = null;
    this.materiasCentroFiltro = this._materiasByEtapaFilter(value);
  }

  private _materiasByEtapaFilter(value) {
    if (value !== 'Todas') {
      return this.materiaManagementService.materiasMaestras.filter(materia =>
        materia.curso.etapa.nombre === value
      );
    } else {
      return this.materiaManagementService.materiasMaestras;
    }
  }

  filterByCurso(value) {
    this.materiasCentroFiltro = this._materiasByCursoFilter(value);
    if (!this.data.isEdit && this.materiasCentroFiltro && this.materiasCentroFiltro.length > 0) {
      this.form.controls['materiaMaestraId'].setValue(this.materiasCentroFiltro[0].id);
    }
  }

  private _materiasByCursoFilter(value) {
    return this.materiaManagementService.materiasMaestras
      .filter(materia => materia.curso.ordinal === value && materia.curso.etapa.nombre === this.selectedEtapa);
  }
}