<div id="dialog-modificar-tarea" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ 'dialogs.asignar-tarea.teams.title' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">

        <div class="overflow-auto fluid-content">
            <form [formGroup]="form">
                <fieldset class="w-100 d-flex flex-column">
                    <div class="form-group default-input w-30">
                        <label class="default-text">{{ 'dialogs.asignar-tarea.teams.clases' | translate }}</label>
                        <mat-select class="default-select default-select__blue" [placeholder]="'dialogs.asignar-tarea.teams.clases.selector' | translate" formControlName="curso" name="curso" panelClass="cdkSelect">
                            <ng-container *ngFor="let curso of gestionAlumnoManagementService.misClasesTeams$ | async">
                                <mat-option class="default-option" [value]="curso.Id">
                                    {{ curso.DisplayName }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>
                    <div class="d-flex">
                        <div class="w-50 form-group default-input mb-4 me-3">
                            <label>{{ 'dialogs.asignar-tarea.step2.nombre-tarea' | translate }}</label>
                            <input type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.nombre-tarea' | translate)" formControlName="name" cdkFocusInitial tabindex="1">
                            <mat-error *ngIf="form.get('name').hasError('required') && form.controls['name'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="w-25 form-group default-input mb-4 position-relative me-3">
                            <label>{{ 'dialogs.asignar-tarea.step2.fecha-inicio' | translate }}</label>
                            <input type="text" class="form-control" [min]="minDate" [max]="maxDate" [matDatepicker]="pickerInicio" (click)="pickerInicio.open()" [placeholder]="('dialogs.asignar-tarea.step2.fecha-inicio' | translate)" formControlName="inicio" tabindex="2">
                            <mat-datepicker-toggle matSuffix [for]="pickerInicio" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                            <mat-datepicker #pickerInicio startView="year" [startAt]="minDate"></mat-datepicker>
                        </div>

                        <div class="w-25 form-group default-input mb-4 position-relative">
                            <label>{{ 'dialogs.asignar-tarea.step2.fecha-fin' | translate }}</label>
                            <input [min]="form.get('inicio').value" [max]="maxDate" [matDatepicker]="pickerFin" (click)="pickerFin.open()" type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.fecha-fin' | translate)" formControlName="fin" tabindex="3">
                            <mat-datepicker-toggle matSuffix [for]="pickerFin" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFin startView="year" [startAt]="form.get('inicio').value"></mat-datepicker>
                        </div>

                    </div>

                    <div class="d-flex mb-4">
                        <div class="w-50 form-group default-textarea mb-4 pe-3">
                            <label>{{'dialogs.asignar-tarea.step2.comentario' | translate}}</label>
                            <textarea type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.comentario' | translate)" formControlName="comentario" tabindex="4" maxlength="255"></textarea>
                            <span class="text-secondary"><small>{{'('+ form.controls['comentario'].value.length +'/255)'}}</small></span>
                            <mat-error *ngIf="form.get('comentario').hasError('required') && form.controls['comentario'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="w-50 form-group default-input mb-4">
                            <label>{{ 'dialogs.asignar-tarea.step2.nota-maxima' | translate }}</label>
                            <input type="number" class="form-control input-nota" formControlName="nota" [step]="1" [min]="0" [max]="100" matInput name="nota">
                            <div *ngIf="form.get('nota').invalid && form.get('nota').errors && (form.get('nota').dirty || form.get('nota').touched)">
                                <small class="text-danger" *ngIf="form.get('nota').hasError('required') && !form.pristine">
                                    {{'generico.required' | translate}}
                                </small>
                                <small class="text-danger" *ngIf="form.get('nota').hasError('min')">
                                    {{'generico.minvalue' | translate:{minvalue: 0} }}
                                </small>
                                <small class="text-danger" *ngIf="form.get('nota').hasError('max')">
                                    {{'generico.maxvalue' | translate:{maxvalue: form.get('nota').errors.max.max} }}
                                </small>
                                <small class="text-danger" *ngIf="form.get('nota').hasError('pattern')">
                                    {{'generico.enteros' | translate }}
                                 </small>
                            </div>
                        </div>
                    </div>

                </fieldset>
            </form>
        </div>




    </div>
    <div mat-dialog-actions>
        <button role="button" class="default-button default-button__primary" mat-dialog-close>{{ 'dialogs.create-theme.cancel' | translate }}</button>
        <button role="button" class="default-button default-button__secondary ms-2" (click)="onClose()" [disabled]="!form.valid">{{ 'dialogs.asignar-tarea.step3' | translate }}</button>
    </div>

</div>
