import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';


@Component({
  selector: 'aula-planeta-descargar-notas',
  templateUrl: './descargar-notas.component.html',
  styleUrls: ['./descargar-notas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescargarNotasComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  materias: any = [];
  clases: any = [];

  minDate = new Date(new Date().getFullYear() - 2, 1, 1);
  maxDate = new Date(new Date().getFullYear() + 2, 12, 31);
  now = Date.now();

  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DescargarNotasComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngOnInit() {

    this.materias = this.data.materias;
    this.clases = this.data.clases;

    this.form = this.fb.group({
      materias: ['', Validators.required],
      clases: ['', this.clasesRequiredIfProfesor()],
      inicio: ['', Validators.required],
      fin: ['', Validators.required]
    });



    this.form.get('clases').disable();

    this.form.get('materias').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(asignatura => {
      this.clases = [];
      this.data.clases.forEach(clase => {

        asignatura.materias.forEach(materia => {
          if (clase.curso.id === materia.curso.id) {
            this.clases.push(clase);
          }
        });

      });
      this.clases = this.clases.filter(
        (obj, index, self) => index === self.findIndex((o) => o.curso.id === obj.curso.id && o.grupo.id === obj.grupo.id)
      );
      setTimeout(() => {
        this.form.get('clases').enable();
      }, 1);
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'reassign-groups-materia',
        popupId: 'dialog-asignar-grupo',
        popupName: 'popup_assign_groups_materia'
      }
    };
    this.gtagService.event(info);
  }

  clasesRequiredIfProfesor(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.data.profesor && !control.value) {
        return { required: true };
      }
      return null;
    };
  }

  close(result, event: PointerEvent) {
    if (result) {
      const formData = this.form.value;
      this.dialogRef.close(formData);
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
