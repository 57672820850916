<div id="dialog-crear-tema-nuevo" class="standard">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.create-theme.title' | translate }}</h1>
    <div mat-dialog-content class="overflow-auto">
        <form role="form" [formGroup]="form">
            <div class="d-flex flex-wrap fielset-container">

                <fieldset class="w-100 d-flex flex-column flex-md-row">
                    <div class="w-100 me-3 d-flex flex-column">
                        <div class="form-group default-input mb-4">
                            <label for="themeName">{{ 'dialogs.create-theme.name' | translate }}</label>
                            <input id="themeName" aria-labelledby="errorThemeName" type="text" class="form-control" [placeholder]="('dialogs.create-theme.name' | translate)" formControlName="name" cdkFocusInitial>
                            <mat-error id="errorThemeName" *ngIf="form.get('name').hasError('required') && form.controls['name'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="form-group default-textarea mb-4">
                            <label for="themeDescription">{{'dialogs.create-theme.description' | translate}}</label>
                            <textarea id="themeDescription" aria-labelledby="errorThemeDescription" type="text" class="form-control" [placeholder]="('dialogs.create-theme.description' | translate)" formControlName="description"></textarea>
                            <mat-error id="errorThemeDescription"  *ngIf="form.get('description').hasError('required') && form.controls['description'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="form-group">
                            <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="shared">{{ 'dialogs.create-theme.share' | translate }}</mat-slide-toggle>
                        </div>
                    </div>
                    <div class="w-100 d-flex flex-column">
                        <div class="form-group default-input mb-4">
                            <label for="themeAuthor">{{ 'dialogs.create-theme.author' | translate }}</label>
                            <input id="themeAuthor" aria-labelledby="errorThemeAuthor" type="text" class="form-control" [placeholder]="('dialogs.create-theme.author' | translate)" formControlName="author">
                            <mat-error id="errorThemeAuthor" *ngIf="form.get('author').hasError('required') && form.controls['author'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="d-flex flex-column form-group default-dropzone" (dropZone)="onDrop($event)">
                            <label for="uploadImage">{{ 'dialogs.create-theme.upload-image' | translate }}</label>
                            <div class="mb-2">
                                <div class="default-dropzone__container d-flex align-items-center justify-content-center">
                                    <!-- <img *ngIf="form.get('img').value" [src]="form.get('img').value | safe:'url'" alt="Imagen del tema">
                                    <mat-icon *ngIf="!form.get('img').value">cloud_upload</mat-icon> -->
                                    <div *ngIf="(photo$ | async) as foto; else nube" class="avatar text-center">
                                        <img [src]="foto" alt="foto perfil">
                                    </div>
                                    <ng-template #nube>
                                        <div class="avatar text-center">
                                            <mat-icon>cloud_upload</mat-icon>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="p-0 d-flex flex-column default-dropzone__text">
                                <p class="mb-3">
                                  {{ 'dialogs.crear-tema-nuevo.arrastra-o' | translate }}
                                  <a tabindex="0" role="button" [matMenuTriggerFor]="menu">
                                    {{ (form.get('img').value ? 'dialogs.crear-tema-nuevo.cambia' : 'dialogs.crear-tema-nuevo.añade') | translate }}
                                  </a>
                                  {{ 'dialogs.crear-tema-nuevo.una-imagen' | translate }}</p>
                                <input  id="uploadImage" type="file" name="img" #fileField (change)="onDrop($event.target.files[0])" hidden multiple>
                                <mat-menu #menu="matMenu" class="default-dropdown">
                                    <button mat-menu-item (click)="fileField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>{{ 'dialogs.crear-tema-nuevo.seleccionar-imagen' | translate }}</button>
                                    <button [disabled]="!form.get('img').value" mat-menu-item (click)="deleteImg()"> <mat-icon class="material-icons-filled">delete</mat-icon>{{ 'dialogs.crear-tema-nuevo.borrar-imagen' | translate }}</button>
                                </mat-menu>
                                <p>{{ 'dialogs.crear-tema-nuevo.ayuda' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </fieldset>

            </div>

            <div mat-dialog-actions class="justify-content-start mt-2">
                <button role="button" class="default-button default-button__primary me-3" mat-dialog-close >{{ 'dialogs.create-theme.cancel' | translate }}</button>
                <button role="button" class="default-button default-button__secondary" (click)="onSubmit()" type="submit" [disabled]="!form.valid || form.pristine">{{ 'dialogs.create-theme.create' | translate }}</button>
            </div>
        </form>
    </div>
</div>
