import { Component, OnInit, Renderer2, OnDestroy, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-drawer-dialog',
  templateUrl: 'drawer-dialog.component.html',
  styleUrls: ['./drawer-dialog.component.scss'],
})
export class DrawerDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  private unlistenMouseMove: () => void;
  private unlistenMouseDown: () => void;
  private unlistenMouseUp: () => void;
  private unlistenMouseOut: () => void;
  private unlistenTouchMove: () => void;
  private unlistenTouchStart: () => void;
  private unlistenTouchEnd: () => void;
  private unlistenTouchCancel: () => void;

  private flag: boolean;
  private dotFlag: boolean;
  prevX = 0;
  currX = 0;
  prevY = 0;
  currY = 0;

  private ongoingTouches = [];

  private colorPincel = 'dodgerblue';
  private grosorPincel = 2;
  private ctx: any;
  lastSelectedColor = 'blue';
  windowWidth: number;
  windowHeight: number;
  cursorStyle: boolean;
  showColors: boolean;
  sidenavPosition: string;
  sidenavCompensation: number;
  isValue = 1;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService,
    private renderer2: Renderer2) {

    this.sidenavPosition = data.sidenavPosition;
    this.sidenavCompensation = this.sidenavPosition === 'start' ? 100 : 0;

    const info = {
      event: 'page_view',
      pageView: {
        imPage: 'drawer',
        pageId: 'drawer',
        imPagetitle: 'Drawer',
        pageTitle: 'Drawer'
      }
    };
    this.gtagService.pageview(info);
  }

  ngOnInit() {
    this.grosorPincel = 3;
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.showColors = true;
  }

  colorSelector(color) {
    this.lastSelectedColor = color;
    this.showColors = true;
    switch (color) {
      case 'green':
        this.colorPincel = 'forestgreen';
        break;
      case 'blue':
        this.colorPincel = 'dodgerblue';
        break;
      case 'red':
        this.colorPincel = 'red';
        break;
      case 'orange':
        this.colorPincel = 'orange';
        break;
      case 'black':
        this.colorPincel = 'black';
        break;
      case 'white':
        this.colorPincel = 'white';
        break;
    }
  }

  eraseDrawing() {
    this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.pencil();
  }

  rubber() {
    this.isValue = 4;
    this.grosorPincel = 30;
    this.cursorStyle = true;
    this.showColors = false;
    this.ctx.lineCap = 'round';
    // Erase drawings on canvas with mouse selection
    this.ctx.globalCompositeOperation = 'destination-out';
  }

  pencil() {
    this.isValue = 1;
    this.grosorPincel = 2;
    this.cursorStyle = false;
    this.ctx.lineCap = 'square';
    this.ctx.globalCompositeOperation = 'source-over';
    this.colorSelector(this.lastSelectedColor);
  }

  marker() {
    this.isValue = 2;
    this.grosorPincel = 20;
    this.cursorStyle = false;
    this.ctx.lineCap = 'square';
    this.ctx.globalCompositeOperation = 'source-over';
    this.colorSelector(this.lastSelectedColor);
  }

  draw() {
    this.ctx.beginPath();
    // compensation for sidenav width 100
    this.ctx.moveTo(this.prevX - this.sidenavCompensation, this.prevY);
    this.ctx.lineTo(this.currX - this.sidenavCompensation, this.currY);
    this.ctx.strokeStyle = this.colorPincel;
    this.ctx.lineWidth = this.grosorPincel;
    this.ctx.stroke();
    this.ctx.closePath();
  }

  calculatePosition(action, event) {
    if (action === 'down') {
      this.prevX = this.currX;
      this.prevY = this.currY;
      this.currX = event.clientX - this.canvas.nativeElement.offsetLeft;
      this.currY = event.clientY - this.canvas.nativeElement.offsetTop;

      this.flag = true;
      this.dotFlag = true;
      // Draw start point on mouse click
      if (this.dotFlag) {
        this.ctx.beginPath();
        this.ctx.fillStyle = this.colorPincel;
        // compensation for sidenav width 100
        this.ctx.fillRect(this.currX - this.sidenavCompensation - 1, this.currY - 1, 2, 2);
        this.ctx.closePath();
        this.dotFlag = false;
      }
    }
    if (action === 'up' || action === 'out') {
      this.flag = false;
    }
    if (action === 'move') {
      if (this.flag) {
        this.prevX = this.currX;
        this.prevY = this.currY;
        this.currX = event.clientX - this.canvas.nativeElement.offsetLeft;
        this.currY = event.clientY - this.canvas.nativeElement.offsetTop;
        this.draw();
      }
    }
  }

  ngAfterViewInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    // Draw on top of canvas content
    this.ctx.globalCompositeOperation = 'source-over';
    this.ctx.lineCap = 'square';

    this.unlistenMouseMove = this.renderer2.listen(this.canvas.nativeElement, 'mousemove', event => {
      this.calculatePosition('move', event);
    });

    this.unlistenMouseDown = this.renderer2.listen(this.canvas.nativeElement, 'mousedown', event => {
      this.calculatePosition('down', event);
    });

    this.unlistenMouseUp = this.renderer2.listen(this.canvas.nativeElement, 'mouseup', event => {
      this.calculatePosition('up', event);
    });

    this.unlistenMouseOut = this.renderer2.listen(this.canvas.nativeElement, 'mouseout', event => {
      this.calculatePosition('out', event);
    });

    this.unlistenTouchMove = this.renderer2.listen(this.canvas.nativeElement, 'touchmove', event => {
      this.handleMove(event);
    });
    this.unlistenTouchEnd = this.renderer2.listen(this.canvas.nativeElement, 'touchend', event => {
      this.handleEnd(event);
    });
    this.unlistenTouchStart = this.renderer2.listen(this.canvas.nativeElement, 'touchstart', event => {
      this.handleStart(event);
    });
    this.unlistenTouchCancel = this.renderer2.listen(this.canvas.nativeElement, 'touchcancel', event => {
      this.handleCancel(event);
    });

  }


  handleStart(evt) {
    // alert('handleStart');
    evt.preventDefault();
    const touches = evt.changedTouches;
    this.prevX = this.currX;
    this.prevY = this.currY;
    this.currX = this.canvas.nativeElement.getBoundingClientRect().left;
    this.currY = this.canvas.nativeElement.getBoundingClientRect().top;
    for (let i = 0; i < touches.length; i++) {
      this.ongoingTouches.push(this.copyTouch(touches[i]));
    }
  }

  handleMove(evt) {
    // alert('handleMove');
    evt.preventDefault();
    const touches = evt.changedTouches;
    for (let i = 0; i < touches.length; i++) {
      const idx = this.ongoingTouchIndexById(touches[i].identifier);
      if (idx >= 0) {
        this.ctx.beginPath();
        this.ctx.moveTo(this.ongoingTouches[idx].clientX - this.currX, this.ongoingTouches[idx].clientY - this.currY);
        this.ctx.lineTo(touches[i].clientX - this.currX, touches[i].clientY - this.currY);
        this.ctx.lineWidth = this.grosorPincel;
        this.ctx.strokeStyle = this.colorPincel;
        this.ctx.lineJoin = 'round';
        this.ctx.closePath();
        this.ctx.stroke();
        this.ongoingTouches.splice(idx, 1, this.copyTouch(touches[i]));  // swap in the new touch record
      }
    }
  }

  handleEnd(evt) {
    // alert('handleEnd');
    evt.preventDefault();
    const touches = evt.changedTouches;
    for (let i = 0; i < touches.length; i++) {
      const color = this.colorPincel;
      const idx = this.ongoingTouchIndexById(touches[i].identifier);
      // alert(touches);
      this.draw();
      if (idx >= 0) {
        this.ctx.lineWidth = this.grosorPincel;
        this.ctx.fillStyle = color;
        this.ongoingTouches.splice(idx, 1);  // remove it; we're done
      }
    }
  }

  handleCancel(evt) {
    // alert('handleCancel');
    evt.preventDefault();
    const touches = evt.changedTouches;
    for (let i = 0; i < touches.length; i++) {
      const idx = this.ongoingTouchIndexById(touches[i].identifier);
      this.ongoingTouches.splice(idx, 1);  // remove it; we're done
    }
  }

  copyTouch({ identifier, clientX, clientY }) {
    return { identifier, clientX, clientY };
  }

  ongoingTouchIndexById(idToFind) {
    for (let i = 0; i < this.ongoingTouches.length; i++) {
      const id = this.ongoingTouches[i].identifier;
      if (id === idToFind) {
        return i;
      }
    }
    return -1;    // not found
  }



  ngOnDestroy() {
    this.unlistenMouseMove();
    this.unlistenMouseUp();
    this.unlistenMouseDown();
    this.unlistenMouseOut();

    this.unlistenTouchMove();
    this.unlistenTouchEnd();
    this.unlistenTouchStart();
    this.unlistenTouchCancel();
  }

}
