<div id="dialog-configurar-otras-materias" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ 'dialogs.configurar-otras-materias.titulo' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <p>{{'dialogs.configurar-otras-materias.subtitulo' | translate}}</p>
        <mat-tab-group *ngIf="materiaManagementService.otrasMaterias$ | async" (selectedTabChange)="setData($event)">
            <mat-tab *ngFor="let curso of cursos; let indexOfelement = index;" [label]="curso.title">
                <div class="overflow-auto table-container">
                    <table *ngIf="dataSource.data" #table mat-table multiTemplateDataRows class="w-100 mb-5 default-table standard" [dataSource]="dataSource">

                        <ng-container matColumnDef="titulo">
                            <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold">{{ 'dialogs.configurar-otras-materias.materias-centro' | translate}}</th>
                            <td mat-cell *matCellDef="let element" class="titulo" [ngClass]="colorHelper.getColor(element.color)">
                                <div class="d-flex align-items-center font-weight-bold">
                                    <mat-icon class="custom-text me-3">insert_drive_file</mat-icon>
                                    <span class="custom-text">{{element.nombre}}</span>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="acciones">
                            <th class="text-end pe-0 acciones w-10" mat-header-cell *matHeaderCellDef>
                                <button class="custom-icon" mat-icon-button attr.aria-label="{{'generico.anadir' | translate}}" (click)="addMateria($event)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </th>
                            <td class="pe-0 text-end" mat-cell *matCellDef="let element;let i = index;">
                                <div class="d-flex align-items-center justify-content-between">
                                    <button class="custom-icon" mat-icon-button attr.aria-label="{{'generico.editar' | translate}}" (click)="editMateria(element, $event)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button class="custom-icon" mat-icon-button attr.aria-label="{{'generico.eliminar' | translate}}" (click)="removeMateria(element, $event)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row class="element" *matRowDef="let element; columns: displayedColumns;let i = index;"></tr>
                    </table>
                </div>

            </mat-tab>
        </mat-tab-group>

        <div class="no-results-base no-results-base__xl" *ngIf="(materiaManagementService.otrasMaterias$ | async).length == 0">
            <mat-icon class="material-icons-round">book</mat-icon>
            <div class="d-flex flex-column align-items-center">
                <span>{{'dialogs.configurar-otras-materias.no-results' | translate}}</span>
                <button class="mt-4 default-button default-button__primary" role="button" type="button" (click)="addMateria($event)">{{'dialogs.configurar-otras-materias.crear-materia' | translate}}</button>
            </div>
        </div>

    </div>
    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cerrar' | translate }}</button>
    </div>

</div>
