/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AuthManagementService } from '../auth/auth-management.service';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor, OnDestroy {


  private destroy$: Subject<void> = new Subject<void>();
  private authManagementService: AuthManagementService;
  private localStorageService: LocalStorageService;
  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.localStorageService = this.injector.get(LocalStorageService);
    this.authManagementService = this.injector.get(AuthManagementService);

    this.localStorageService.getItem('CURRENT_USER').pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe((currentUser: any) => {
        if (currentUser
          && currentUser.persona
          && currentUser.persona.id
          && this.authManagementService?.userId
          && currentUser?.persona?.id !== this.authManagementService?.userId) {
          this.authManagementService.authLogout();
          return '';
        }
      });

    const isApi = req.url.startsWith(environment.api);

    // add authorization header with jwt token if available
    return this.localStorageService.getItem('JWT').pipe(
      switchMap(jwt => {
        if (isApi) {
          const request = req.clone({
            setHeaders: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              // eslint-disable-next-line quote-props
              'Authorization': `Bearer ${jwt}`,
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'

            }
          });
          return next.handle(request);
        }
        return next.handle(req);
      })

    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
