/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-bitwise */
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ModulosOrganizacion } from '../shared/models/tema/modulo.model';
import { Tema } from '../shared/models/tema/tema.model';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../core/notifications/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthManagementService } from '../core/auth/auth-management.service';
import { EditionDirective } from '../core/directives/edition/edition.directive';

@Injectable({
  providedIn: 'root'
})
export class EditionModeService implements OnDestroy {

  private editionDirectives: Set<EditionDirective> = new Set();

  optionsDefault: any = [
    {
      id: 'edit',
      icon: 'edit',
      name: 'Editar',
      group: 'button',
      divider: false
    },
    {
      id: 'visibility',
      icon: 'visibility',
      name: 'Visibilidad',
      group: 'visibility',
      divider: false
    },
    {
      id: 'menu',
      icon: 'more_vert',
      name: 'Más opciones',
      group: 'menu',
      divider: false
    },
    {
      id: 'up',
      icon: 'arrow_upward',
      name: 'Mover arriba',
      group: 'button',
      divider: true
    },
    {
      id: 'down',
      icon: 'arrow_downward',
      group: 'button',
      name: 'Mover abajo',
      divider: false
    }
  ];

  enable$: Observable<boolean>;
  dialogOpen$: Observable<boolean>;
  selected$: Observable<boolean>;
  position$: Observable<string>;
  options$: Observable<[any]>;

  moduloSeleccionado: any = {};
  headerData: any = {};
  type = '';

  index = 0;
  moduloParentAdd = '';
  parent = null;
  parentInjector = null;
  action = '';
  orientation;

  fromDuplicarUnidad = false;

  ocultos = [];
  acumuladoNegativo = 0;

  private _enable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _dialogOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _selected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _position: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _options: BehaviorSubject<[any]> = new BehaviorSubject<[any]>(this.optionsDefault);

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private authManagementService: AuthManagementService,
    private translateService: TranslateService,
  ) {
    this.enable$ = this._enable.asObservable();
    this.dialogOpen$ = this._dialogOpen.asObservable();
    this.selected$ = this._selected.asObservable();
    this.position$ = this._position.asObservable();
    this.options$ = this._options.asObservable();

    this.router.events
      .pipe(
        takeUntil(this.destroy$))
      .subscribe((val) => {
        // this._enable.next(false);
        this._dialogOpen.next(false);
        this._selected.next(false);
        this._position.next('');
        this._options.next([this.optionsDefault]);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get enable() {
    return this._enable.getValue();
  }

  set enable(enable: boolean) {
    this.handleLinks();
    this._enable.next(enable);
  }

  get dialogOpen() {
    return this._dialogOpen.getValue();
  }

  set dialogOpen(dialogOpen: boolean) {
    this._dialogOpen.next(dialogOpen);
  }

  get selected() {
    return this._selected.getValue();
  }

  set selected(selected: boolean) {
    this._selected.next(selected);
  }

  get position() {
    return this._position.getValue();
  }

  set position(position: string) {
    this._position.next(position);
  }

  get options() {
    return this._options.getValue();
  }

  set options(options: [unknown]) {
    this._options.next(options);
  }

  public setMenu(listId) {
    this._options.next(listId ? this.optionsDefault.filter(option => listId.indexOf(option.id) > -1) : this.optionsDefault);
  }

  public getMenu() {
    let options = this._options.getValue() as any;
    if (this.type === 'recurso-col') {
      options = options.filter((option) =>
        option.id !== 'up' && option.id !== 'down'
      );
    }

    if (
      this.type === 'aprendizaje'
      // this.type === 'tabla' ||
      // this.type === 'audio' ||
      // this.type === 'video' ||
      // this.type === 'documento' ||
      // this.type === 'imagen'
    ) {
      options = options.filter((option) =>
        option.id !== 'edit'
      );
    }

    return options;
  }

  /**
   * Al iniciar el modo edición, se desactivan todos los enlaces de la pantalla.
   * Al desactivar el modo edición, se activan todos los enlaces de nuevo.
   */
  private handleLinks() {
    const links = document.querySelectorAll('#tema a');
    if (links.length) {
      links.forEach(link => {
        if (link) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.enable ? this.disableLink(link) : this.enableLink(link);
        }
      });
    }
    // esto lo pongo aquí proque no se donde meterlo. Previene que el menu de edicion lateral rompa el scroll de la vista de recursos.
    // document.getElementById('edition-bar').style.top = '0px';
  }

  closeWiris() {
    const _win = window as any;
    _win.WirisPlugin?.currentInstance?.core?.modalDialog?.close();
  }

  /**
   * Desactiva los links de la pantalla.
   * Asi se evita puntos de fuga.
   *
   * @param link: Element
   */
  disableLink(link) {
    link.parentElement.classList.add('disable-link');
  }

  /**
   * Activa todos los links de la pantalla.
   * @param link: Element
   */
  enableLink(link) {
    if (link.parentElement.classList.contains('disable-link')) {
      link.parentElement.classList.remove('disable-link');
    }
  }


  /** Modificación del contenido */
  modificarModulo(object, parent, propiedades, moduloId, newId?, updateState = false) {
    if (object !== null) {
      switch (typeof object) {
        case 'string':
          if (object === moduloId && !parent.identificadorModulo) {
            parent.propiedades = { ...parent.propiedades, ...propiedades };
            if (newId) {
              parent.id = newId + '';
              parent.autor = {
                id: this.authManagementService.currentUser.persona.id,
                nombre: `${this.authManagementService.currentUser.persona.nombre} ${this.authManagementService.currentUser.persona.apellidoUno} ${this.authManagementService.currentUser.persona.apellidoDos}`
              };
              object = newId + '';
              moduloId = newId + '';
            }

            if (updateState) {
              parent.state = 'Modified';
            }
          }
          break;
        case 'object':
          if (object instanceof Array) {
            const length = object.length;
            for (let i = 0; i < length; i++) {
              object[i] = this.modificarModulo(object[i], object, propiedades, moduloId, newId, updateState);
            }
          } else {
            // eslint-disable-next-line guard-for-in
            for (const j in object) {
              object[j] = this.modificarModulo(object[j], object, propiedades, moduloId, newId, updateState);
            }
          }
          break;
      }
    }

    return object;
  };

  addNewSeccion1(tema, modulo, position) {
    let index = tema.map(e => e.id).indexOf(this.moduloSeleccionado?.id);
    tema.splice(
      position === 'up' ? index : ++index, 0, modulo
    );
    return tema;
  }


  /** Modificación del contenido */
  addNewModulo(object, parent, modulo, moduloId, position, hija) {
    if (object !== null) {
      switch (typeof object) {
        case 'string':
          if (object === moduloId && !parent.identificadorModulo) {
            let index = parent.modulosOrganizacion.map(e => e.id).indexOf(this.moduloSeleccionado?.id);
            if (this.moduloSeleccionado.idParent) {
              index = parent.modulosOrganizacion.map(e => e.id).indexOf(this.moduloSeleccionado.idParent);
            }
            if (hija && position === ('before')) {
              parent.modulosOrganizacion[index].modulosOrganizacion.unshift(modulo);
            } else {
              if (index === 0 && position === ('before')) {
                parent.modulosOrganizacion.unshift(modulo);
              } else {
                parent.modulosOrganizacion.splice(
                  position === ('up' || 'before') ? index : ++index, 0, modulo
                );
              }
            }
          }
          break;
        case 'object':
          if (object instanceof Array) {
            const length = object.length;
            for (let i = 0; i < length; i++) {
              object[i] = this.addNewModulo(object[i], object, modulo, moduloId, position, hija);
            }
          } else {
            // eslint-disable-next-line guard-for-in
            for (const j in object) {
              object[j] = this.addNewModulo(object[j], object, modulo, moduloId, position, hija);
            }
          }
          break;
      }
    }

    return object;
  }

  /** Modificación del contenido */
  moverModuloBuscarElAnterior(object, parent, moduloId, position, listModulosRenderizados) {
    let moduloObjetivo = null;
    let push = false;
    let cambioDeSeccion = false;
    let moverSecciones = false;
    let plusSubirSeccion = 0;
    listModulosRenderizados.some((modulo, index) => {
      if (modulo.id === this.moduloSeleccionado?.id) {
        if (this.moduloSeleccionado?.comunes.tipoModulo.idValor === 1) {
          moverSecciones = true;

          const iseccion = this.moduloSeleccionado?.indiceSeccion[0];
          const isubseccion = this.moduloSeleccionado?.indiceSeccion[1];
          const isubsubseccion = this.moduloSeleccionado?.indiceSeccion[2];
          const isubsubsubseccion = this.moduloSeleccionado?.indiceSeccion[3];

          let miparent;
          let migrandpa;
          let miposicion;
          let posicionmiparent;
          let first;
          let last;

          if (isubsubsubseccion > 0) {
            miparent = object[iseccion - 1].modulosOrganizacion[isubseccion].modulosOrganizacion[isubsubseccion];
            migrandpa = object[iseccion - 1].modulosOrganizacion[isubseccion];

            miposicion = miparent.modulosOrganizacion.map(e => e.id).indexOf(modulo.id);
            posicionmiparent = migrandpa.modulosOrganizacion.map(e => e.id).indexOf(miparent.id);
            first = miposicion === 0;
            last = miposicion === miparent.modulosOrganizacion.length - 1;

            if (position === 'up') {
              if (first) {
                this.notificationService.info(this.translateService.instant('mode-edition.edition-bar.mover.seccion4.info'));
              } else {
                miparent.modulosOrganizacion.splice(miposicion, 1);
                miparent.modulosOrganizacion.splice(miposicion - 1, 0, modulo);
              }
            } else {
              if (last) {
                this.notificationService.info(this.translateService.instant('mode-edition.edition-bar.mover.seccion4.info'));
              } else {
                miparent.modulosOrganizacion.splice(miposicion, 1);
                miparent.modulosOrganizacion.splice(miposicion + 1, 0, modulo);
              }
            }
          } else if (isubsubseccion > 0) {
            miparent = object[iseccion - 1].modulosOrganizacion[object[iseccion - 1].modulosOrganizacion.map(e => e.id).indexOf(moduloId)];
            migrandpa = object[iseccion - 1];
            miposicion = miparent.modulosOrganizacion.map(e => e.id).indexOf(modulo.id);
            posicionmiparent = migrandpa.modulosOrganizacion.map(e => e.id).indexOf(miparent.id);
            first = miposicion === 0;
            last = miposicion === miparent.modulosOrganizacion.length - 1;

            if (position === 'up') {
              if (first) {
                // miparent.modulosOrganizacion.splice(miposicion, 1);
                // migrandpa.modulosOrganizacion.splice(posicionmiparent, 0, modulo);
                this.notificationService.info(this.translateService.instant('mode-edition.edition-bar.mover.seccion3.info'));
              } else {
                miparent.modulosOrganizacion.splice(miposicion, 1);
                miparent.modulosOrganizacion.splice(miposicion - 1, 0, modulo);
              }
            } else {
              if (last) {
                this.notificationService.info(this.translateService.instant('mode-edition.edition-bar.mover.seccion3.info'));
                // miparent.modulosOrganizacion.splice(miposicion, 1);
                // migrandpa.modulosOrganizacion.splice(posicionmiparent + 1, 0, modulo);
              } else {
                miparent.modulosOrganizacion.splice(miposicion, 1);
                miparent.modulosOrganizacion.splice(miposicion + 1, 0, modulo);
              }
            }

          } else if (isubseccion > 0) {
            miparent = object[iseccion - 1];
            migrandpa = object;
            miposicion = miparent.modulosOrganizacion.map(e => e.id).indexOf(modulo.id);
            posicionmiparent = migrandpa.map(e => e.id).indexOf(miparent.id);
            first = miposicion === 0;
            last = miposicion === miparent.modulosOrganizacion.length - 1;
            if (position === 'up') {
              if (first) {
                if (posicionmiparent > 0) {
                  // va bien
                  miparent.modulosOrganizacion.splice(miposicion, 1);
                  migrandpa[posicionmiparent - 1].modulosOrganizacion.push(modulo);
                }
              } else {
                // va bien
                miparent.modulosOrganizacion.splice(miposicion, 1);
                miparent.modulosOrganizacion.splice(miposicion - 1, 0, modulo);
              }
            } else {
              if (last) {
                if (posicionmiparent < migrandpa.length - 1) {
                  miparent.modulosOrganizacion.splice(miposicion, 1);
                  migrandpa[posicionmiparent + 1].modulosOrganizacion.unshift(modulo);
                }
              } else {
                // va bien
                miparent.modulosOrganizacion.splice(miposicion, 1);
                miparent.modulosOrganizacion.splice(miposicion + 1, 0, modulo);
              }
            }


          } else {
            console.error('no puedo ser una seccion de primer nivel aqui');
          }
          return true;
        } else {
          if (position === 'up') {
            moduloObjetivo = listModulosRenderizados[index - 1];
            if (!moduloObjetivo) {
              cambioDeSeccion = true;
              const pos = this.moduloSeleccionado?.indiceSeccion[0] - 1;
              if (pos === 0) {
                return;
              }
              this.deleteModulo(object, parent, moduloId);
              if (object[pos - 1].modulosOrganizacion.length === 0) {
                object[pos - 1].modulosOrganizacion.push(this.moduloSeleccionado);
              } else {
                const modulosSeccionAnterior = [];
                this.aplanarIdsSeccionNivelUno(object[pos - 1], modulosSeccionAnterior);
                position = 'down';
                this.searchTree(object[pos - 1], modulosSeccionAnterior[modulosSeccionAnterior.length - 1].id, object, position, false, plusSubirSeccion);
              }
            } else {
              this.deleteModulo(object, parent, moduloId);
              if (moduloObjetivo.comunes.tipoModulo.idValor === 1) {
                if (index > 1) {
                  moduloObjetivo = listModulosRenderizados[index - 2];
                  plusSubirSeccion = plusSubirSeccion + 1;
                  if (moduloObjetivo.comunes.tipoModulo.idValor === 1) {
                    position = 'down';
                    push = true;
                  }
                }
              }
            }
          } else {
            moduloObjetivo = listModulosRenderizados[index + 1];
            if (!moduloObjetivo) {
              cambioDeSeccion = true;
              const pos = this.moduloSeleccionado?.indiceSeccion[0] - 1;
              if (pos === object.length - 1) {
                return;
              }
              this.deleteModulo(object, parent, moduloId);
              object[pos + 1].modulosOrganizacion.unshift(this.moduloSeleccionado);
            } else {
              if (moduloObjetivo.comunes.tipoModulo.idValor === 1) {
                push = true;
              }
              this.deleteModulo(object, parent, moduloId);
            }
          }
        }
        return true;
      }
    });

    if (!cambioDeSeccion && !moverSecciones) {
      object.forEach(seccion => {
        this.searchTree(seccion, moduloObjetivo.id, object, position, push, plusSubirSeccion);
      });
    }

    return object;
  }

  aplanarIdsSeccionNivelUno(modulo, arrayPlano) {
    arrayPlano.push(modulo);
    if (modulo.modulosOrganizacion != null) {
      let result = null;
      for (let i = 0; result == null && i < modulo.modulosOrganizacion.length; i++) {
        result = this.aplanarIdsSeccionNivelUno(modulo.modulosOrganizacion[i], arrayPlano);
      }
      return result;
    }
    return null;
  }

  searchTree(element, moduloObjetivoId, parent, position, push, plusSubirSeccion) {
    if (element.id === moduloObjetivoId) {
      const from = parent.map(e => e.id).indexOf(moduloObjetivoId);
      const to = position === 'down' ? from + 1 : from;

      if (push) {
        if (position === 'up') {
          element.modulosOrganizacion.push(this.moduloSeleccionado);
        } else {
          element.modulosOrganizacion.unshift(this.moduloSeleccionado);
        }

      } else if (to > parent.length - 1) {
        parent.push(this.moduloSeleccionado);
      } else {
        parent.splice(to + plusSubirSeccion, 0, this.moduloSeleccionado);

      }
      return element;
    } else if (element.modulosOrganizacion != null) {
      let result = null;
      for (let i = 0; result == null && i < element.modulosOrganizacion.length; i++) {
        result = this.searchTree(element.modulosOrganizacion[i], moduloObjetivoId, element.modulosOrganizacion, position, push, plusSubirSeccion);
      }
      return result;
    }
    return null;
  }

  moved(array, from, to, on = 1): [] {
    if ((from === 0 && to < from) || (from === array.length - 1 && to > from)) {
      return array;
    }
    return array = array.slice(), array.splice(to, 0, ...array.splice(from, on)), array;
  }

  moverSeccion1(tema, position) {
    let index = tema.map(e => e.id).indexOf(this.moduloSeleccionado?.id);
    tema = this.moved(tema, index, position === 'up' ? --index : ++index);
    return tema;
  }

  /** Eliminar contenido */
  deleteModulo(object, parent, moduloId) {
    if (object !== null) {
      switch (typeof object) {
        case 'string':
          if (object === moduloId && !parent.identificadorModulo) {
            const index = parent.modulosOrganizacion.map(e => e.id).indexOf(this.moduloSeleccionado?.id);
            if (index >= 0) {
              parent.modulosOrganizacion.splice(index, 1);
            }
          }
          break;
        case 'object':
          if (object instanceof Array) {
            const length = object.length;
            for (let i = 0; i < length; i++) {
              object[i] = this.deleteModulo(object[i], object, moduloId);
            }
          } else {
            // eslint-disable-next-line guard-for-in
            for (const j in object) {
              object[j] = this.deleteModulo(object[j], object, moduloId);
            }
          }
          break;
      }
    }

    return object;
  };

  // deleteModulo(element, parent, moduloObjetivoId) {
  //   if (element.id === moduloObjetivoId) {
  //     const index = parent.map(e => e.id).indexOf(this.moduloSeleccionado?.id);
  //     parent.splice(index, 1);

  //   } else if (element.modulosOrganizacion != null) {
  //     for (let i = 0; i < element.modulosOrganizacion.length; i++) {
  //       element.modulosOrganizacion[i] = this.deleteModulo(element.modulosOrganizacion[i], element.modulosOrganizacion, moduloObjetivoId);
  //     }
  //   }
  //   return element;
  // }

  /** Eliminar contenido */
  replaceModulo(object, parent, moduloId) {
    if (object !== null) {
      switch (typeof object) {
        case 'string':
          if (object === moduloId && !parent.identificadorModulo) {
            const index = parent.modulosOrganizacion.map(e => e.id).indexOf(this.moduloSeleccionado?.id);
            parent.modulosOrganizacion[index] = this.moduloSeleccionado;
          }
          break;
        case 'object':
          if (object instanceof Array) {
            const length = object.length;
            for (let i = 0; i < length; i++) {
              object[i] = this.replaceModulo(object[i], object, moduloId);
            }
          } else {
            // eslint-disable-next-line guard-for-in
            for (const j in object) {
              object[j] = this.replaceModulo(object[j], object, moduloId);
            }
          }
          break;
      }
    }
    return object;
  };

  recalculoIndicesOrden(tema): Tema {
    this.iterateModulosorganizacionAndSet(tema.modulosOrganizacion, [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], 0, 0, 0);
    return tema;
  }


  private iterateModulosorganizacionAndSet(modulosOrganizacion, indiceSeccion, indiceSeccionVirtual, nivel, index, indexVirtual) {
    if (!modulosOrganizacion || modulosOrganizacion.length < 1) return;
    this.setValueToIndiceSeccion(modulosOrganizacion, indiceSeccion, indiceSeccionVirtual, nivel, index, indexVirtual);
    // Profundizamos en los modulos
    if (modulosOrganizacion[index] && modulosOrganizacion[index].modulosOrganizacion) {
      nivel++;
      this.iterateModulosorganizacionAndSet(modulosOrganizacion[index].modulosOrganizacion, indiceSeccion, indiceSeccionVirtual, nivel, 0, 0);
      indiceSeccion[nivel] = 0;
      indiceSeccionVirtual[nivel] = 0;
      nivel--;
    }
    // Recorremos los modulos
    if ((modulosOrganizacion.length - 1) > index) {
      index++;
      indexVirtual++;
      this.iterateModulosorganizacionAndSet(modulosOrganizacion, indiceSeccion, indiceSeccionVirtual, nivel, index, indexVirtual);
    }
  }
  private setValueToIndiceSeccion(modulosOrganizacion, indiceSeccion, indiceSeccionVirtual, nivel, index, indexVirtual) {
    if (modulosOrganizacion[index].comunes.tipoModulo.idValor === 1) {
      indiceSeccion[nivel] += 1;
      if (modulosOrganizacion[index].propiedades.visible === 'Oculto' || modulosOrganizacion[index].propiedades.visible === 'Profesores') {
        // indexVirtual--;
      } else {
        indiceSeccionVirtual[nivel] += 1;
      }
    }
    modulosOrganizacion[index].indiceSeccion = [...indiceSeccion];
    modulosOrganizacion[indexVirtual].indiceSeccionVirtual = [...indiceSeccionVirtual];
  }

  cambiarVisibilidadHijos(modulo, visible): any {
    this.iterateModulosorganizacionYCambiarVisibilidad(modulo.modulosOrganizacion, 0, 0, visible);
    return modulo;
  }


  private iterateModulosorganizacionYCambiarVisibilidad(modulosOrganizacion, nivel, index, visible) {
    if (!modulosOrganizacion || modulosOrganizacion.length < 1) return;
    // Profundizamos en los modulos
    modulosOrganizacion[index].propiedades.visible = visible;
    if (modulosOrganizacion[index] && modulosOrganizacion[index].modulosOrganizacion) {
      nivel++;
      this.iterateModulosorganizacionYCambiarVisibilidad(modulosOrganizacion[index].modulosOrganizacion, nivel, 0, visible);
      nivel--;
    }
    // Recorremos los modulos
    if ((modulosOrganizacion.length - 1) > index) {
      index++;
      this.iterateModulosorganizacionYCambiarVisibilidad(modulosOrganizacion, nivel, index, visible);
    }
  }


  deleteSeccion1(tema) {
    const index = tema.map(e => e.id).indexOf(this.moduloSeleccionado?.id);
    tema.splice(index, 1);
    return tema;
  }

  generateUUID() { // Public Domain/MIT
    let d = new Date().getTime(); // Timestamp
    let d2 = ((typeof performance !== 'undefined') &&
      performance.now && (performance.now() * 1000)) || 0; // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16; // random number between 0 and 16
      if (d > 0) { // Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else { // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  generateModuleSchema(): ModulosOrganizacion {
    return {
      id: this.generateUUID(),
      clave: {
        identificadorModulo: '',
        _IdProyecto: 0,
        _IdGuion: 0,
        _IdVersion: 0,
        _Idmodulo: 0
      },
      comunes: {
        tipoModulo: {
          idMaestroReferencia: 0,
          idValor: 0,
          valor: ''
        },
        tipoOrigen: {
          idMaestroReferencia: 0,
          idValor: 0,
          valor: ''
        },
        version: 0,
        versionEditorial: {
          idMaestroReferencia: 0,
          idValor: 0,
          valor: ''
        },
        visible_solo_profesor: false
      },
      propiedades: {
        esScorm: false,
        titulo_html: '',
        titulo_plano: '',
        descripcion_html: '',
        abrir_en_ventana_nueva: true,
        enlace: '',
        tipo_de_destacado: '',
        contenido_html: '',
        imagen_previa: '',
        imagen: '',
        pie_de_imagen: '',
        texto_alternativo: '',
        creditos_html: '',
        identificadorOrigen: {
          id: '',
          _idRecurso: '',
          _idRes: '',
          _loId: ''
        },
        ficha_profesor_html: '',
        ficha_alumno_html: '',
        carpeta: '',
        actividad: '',
        tipoActividad: {
          idMaestroReferencia: 0,
          idValor: '',
          valor: {
            idTipoActividad: '',
            player: '',
            editor: '',
            templateDatos: ''
          }
        },
        adjuntos: [
          {
            titulo_fichero: '',
            nombre_fichero: '',
            visible_alumno: true,
            visible_profesor: true
          }
        ],
        matriculaDelMedia: {
          valor: ''
        },
        mediaPesado: true,
        fichero_subtitulos: '',
        transcripcion: '',
        audio: '',
        documento: '',
        video: '',
        visible: 'Alumnos',
        visible_solo_profesor: false,
        bloqueadoOffline: false
      },
      metadatos: {
        duracionNum: {
          valor: 0
        },
        duracionTexto: {
          valor: ''
        },
        nivelDificultad: {
          idMaestroReferencia: 0,
          idValor: 0,
          valor: ''
        },
        tipoMedia: [
          {
            idMaestroReferencia: 0,
            idValor: 0,
            valor: ''
          }
        ],
        tipoInteraccion: [
          {
            idMaestroReferencia: 0,
            idValor: 0,
            valor: ''
          }
        ],
        idioma: {
          idMaestroReferencia: 0,
          idValor: 0,
          valor: ''
        },
        palabrasClave: [
          ''
        ],
        competenciasAulaPlaneta: [
          {
            idMaestroReferencia: 0,
            idValor: 0,
            valor: ''
          }
        ],
        activacion: [
          {
            idMaestroReferencia: 0,
            idValor: 0,
            valor: ''
          }
        ],
        taxonomiaBloom: {
          idMaestroReferencia: 0,
          idValor: 0,
          valor: ''
        },
        inteligenciasMultiples: [
          {
            idMaestroReferencia: 0,
            idValor: 0,
            valor: ''
          }
        ],
        acciondidactica: [
          {
            idMaestroReferencia: 0,
            idValor: 0,
            valor: ''
          }
        ],
        tipocontenido: [
          {
            idMaestroReferencia: 0,
            idValor: 0,
            valor: ''
          }
        ]
      },
      metadatosOrganizacion: {
        curriculum: [
          {
            idValor: 0,
            valor: ''
          }
        ]
      },
      modulosOrganizacion: []
    }


      ;
  }


  registerDirective(directive: EditionDirective) {
    this.editionDirectives.add(directive);
  }

  unregisterDirective(directive: EditionDirective) {
    this.editionDirectives.delete(directive);
  }

  triggerOutsideClick() {
    this.editionDirectives.forEach(directive => {
      directive.simulateOutsideClick();
    });
  }
}
