import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafePipe } from './safe.service';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SafePipe
  ],
  exports: [
    SafePipe
  ]
})
export class SafeModule { }
