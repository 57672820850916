import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { EnviarIncidenciasComponent } from '../dialogs/enviar-incidencias/enviar-incidencias.component';
import { ScrollService } from '../../../core/scroll/scroll.service';


@Component({
  selector: 'aula-planeta-incidencias',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncidenciasComponent {
  gtagService: any;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public translateService: TranslateService,
    public router: Router,
    private dialog: MatDialog,
    private scrollService: ScrollService
  ) { }

  validTypes = {
    video: ['video/mp4', 'video/x-msvideo', 'video/quicktime', 'video/x-flv', 'video/mpeg'],
    image: ['image/gif', 'image/jpeg', 'image/jpg', 'image/pipeg', 'image/png'],
  }

  incidenciasDialog() {
    this.scrollService.bloqueateScroll();
    const dialogRef = this.dialog.open(EnviarIncidenciasComponent, {
      data: {

        titulo: this.translateService.instant('dialogs.incidencias.titulo'),
        descripcionFormulario: this.translateService.instant('dialogs.incidencias.descripcion-modal'),
        asunto: this.translateService.instant('dialogs.incidencias.asunto'),
        incidencia: this.translateService.instant('dialogs.incidencias.descripcion'),
        mensajeEmail: this.translateService.instant('dialogs.incidencias.mensaje-email'),
        email: this.translateService.instant('dialogs.incidencias.email'),
        camposObligatorios: this.translateService.instant('dialogs.incidencias.campos-obligatorios'),


        errors: {
          asunto: {
            obligatorio: this.translateService.instant('dialogs.incidencias.errors.asunto.obligatorio'),
            length: this.translateService.instant('dialogs.incidencias.errors.asunto.length'),
          },
          descripcion: {
            obligatorio: this.translateService.instant('dialogs.incidencias.errors.descripcion.obligatorio'),
          },
          email: {
            obligatorio: this.translateService.instant('dialogs.incidencias.errors.email.obligatorio'),
            validez: this.translateService.instant('dialogs.incidencias.errors.email.validez'),
          },
        },

        validTypes: {
          video: ['.mp4', '.x-msvideo', '.quicktime', '.x-flv', '.mpeg'],
          image: ['.gif', '.jpeg', '.jpg', '.pipeg', '.png'],
        },


        close: true,

      },
      restoreFocus: true,
      disableClose: true
    });

    dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(result => {
      this.scrollService.desbloqueateScroll();

    });
  }

}
