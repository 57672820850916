<div
  id="edition-header"
  class="edition-header w-100 position-fixed d-flex justify-content-between align-items-center"
  [ngClass]="(editionModeService.enable$ | async) ? 'open d-flex' : 'd-none'"
  [attr.tabindex]="!(editionModeService.enable$ | async) ? -1 : 0">
    <div class="edition-header__logo">
        <mat-icon id="logo-cosmos" svgIcon="logo_cosmos"></mat-icon>
    </div>
    <ng-container>
        <div class="default-breadcrumb d-flex">
            <div class="down">
                <span class="white-text">{{ 'mode-edition.edition-header.editing' | translate }}</span>
            </div>
            <div class="down" [ngClass]="editionModeService.headerData.color">
                <span class="custom-text">{{editionModeService.headerData.titulo}}</span>
            </div>
        </div>
    </ng-container>

    <div class="d-flex align-items-center">
        <button (click)="close()" class="edition-header__button edition-header__button--secondary me-3">{{'generico.cancelar' | translate}}</button>
        <button (click)="save()" class="edition-header__button edition-header__button--primary">{{'generico.guardar.edition' | translate}}</button>
    </div>
</div>
