import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VistaAlumnoService {

  vistaAlumno$: Observable<boolean>;

  private _vistaAlumno: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.vistaAlumno$ = this._vistaAlumno.asObservable();
  }

  get vistaAlumno() {
    return this._vistaAlumno.getValue();
  }

  set vistaAlumno(vistaAlumno: boolean) {
    this._vistaAlumno.next(vistaAlumno);
  }
}
