import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { EnvService } from '../../env.service';

@Injectable({
  providedIn: 'root'
})
export class ScormDataService {

  constructor(private http: HttpClient, public cache: LocalCacheService, private envService: EnvService) { }

  public iniciarScorm(idActividad): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/actividad-asignada/IniciarScorm/${idActividad}`, {});
  }

  public realizarScorm(data, isMoodle): Observable<any> {
    // eslint-disable-next-line quote-props
    const headers = { 'noLoader': 'noLoader' };
    return this.http.post<any>(`${this.envService.apiUrl}/actividad-autonoma${isMoodle ? '/Moodle' : ''}/RealizarScorm`, data, { headers });
  }

  public actualizarScorm(idActividad, datosScorm, autonoma): Observable<any> {
    // eslint-disable-next-line quote-props
    const headers = { 'noLoader': 'noLoader' };
    return this.http.put<any>(`${this.envService.apiUrl}/actividad-${autonoma ? 'autonoma' : 'asignada'}/ActualizarScorm/${idActividad}`,
      { datosScorm },
      { headers }
    );
  }
}
