<div id="dialog-descargar-pdf" class="standard">
    <div mat-dialog-content class="d-flex w-100 h-100 position-relative m-0 p-0">
        <!-- <button class="close-button default-button default-button__primary position-absolute" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button> -->
        <div class="w-100 d-flex flex-column position-relative container-dialog-descargar-pdf">
            <h1 mat-dialog-title class="d-flex heading-6 font-weight-bold align-items-center custom-text mb-4">{{ 'materia.mis-temas.tabla.acciones.descargar' | translate }}</h1>
            <div id="dialog-descargar-body">
                <div *ngIf="!generado" class="form-group default-input w-100">
                    <label class="default-text body-3 mb-3">{{ 'dialogs.download-pdf.subtitle' | translate }}</label>
                    <mat-select class="default-select" disableOptionCentering (selectionChange)="opcionSeleccionada = true;" [placeholder]="'dialogs.download-pdf.subtitle' | translate" panelClass="descargar-select-pdf">
                        <mat-select-trigger>
                            <div [innerHtml]="label"></div>
                        </mat-select-trigger>
                        <ng-container *ngFor="let location of locationsType">
                            <mat-option class="default-option" (click)="selected(location)" [value]="location.value">
                                {{ location.label }}
                                <mat-icon class="right-icon" [hidden]="seleccion !== location.value">check</mat-icon>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </div>
                <div *ngIf="generado" class="result-container w-100 light-blue-bg p-5 d-flex align-items-center justify-content-center">
                    <div *ngIf="showSpinner" class="w-100 light-blue-bg d-flex flex-column justify-content-center align-items-center">
                        <mat-progress-spinner class="position-relative mb-3" color="warn" diameter="80" aria-label="Cargando"></mat-progress-spinner>
                        <span class="grey-text pt-3">{{ 'dialogs.download-pdf.generating-pdf' | translate }}</span>
                    </div>
                    <div *ngIf="!showSpinner" class="logoDescarga d-flex flex-column align-items-center justify-content-center">
                        <mat-icon class="custom-icon">file_download</mat-icon>
                        <span class="grey-text pt-3">{{ 'dialogs.download-pdf.download-ready' | translate }}</span>
                    </div>
                </div>
            </div>
            <div mat-dialog-actions class="justify-content-start mt-3">
                <button [disabled]="showSpinner" class="default-button default-button__primary me-3" mat-dialog-close cdkFocusInitial>{{ 'generico.cancelar' | translate }}</button>
                <button *ngIf="!generado" [disabled]="showSpinner || !opcionSeleccionada" role="button" class="default-button default-button__secondary" (click)="generar()" cdkFocusInitial>{{ 'dialogs.download-pdf.generar' | translate }}</button>
                <button *ngIf="opcionSeleccionada && generado" [disabled]="showSpinner" role="button" class="default-button default-button__secondary" [mat-dialog-close]="seleccion" cdkFocusInitial>{{ 'dialogs.download-pdf.button' | translate }}</button>
            </div>
        </div>
    </div>
</div>