<div id="dialog-cambiar-vista" class="standard">
    <div mat-dialog-content class="d-flex w-100 position-relative m-0 p-0">
        <div class="col-2 p-0 custom-icon">
            <mat-icon class="mt-2">visibility</mat-icon>
        </div>
        <div class="d-flex flex-column justify-content-between col-9">
            <h1 mat-dialog-title class="d-flex align-items-center custom-text mb-3">{{'edition.titulo' | translate}}</h1>
            <p class="mb-0">{{ 'dialogs.cambiar-vista.question' | translate }}</p>
            <div mat-dialog-actions class="justify-content-start mt-3">
                <button role="button" class="default-button me-3 default-button__secondary" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
                <button role="button" class="default-button default-button__primary" mat-dialog-close>{{ 'generico.guardar' | translate }}</button>
            </div>
        </div>
    </div>
</div>