import { RoleDirective } from './role/role.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumbersOnlyDirective } from './numbers-only/numbers-only.directive';
import { UppercaseDirective } from './uppercase/uppercase.directive';
import { DropZoneDirective } from './dropZone/drop-zone.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { setSlideToDirective } from './set-slide-to/set-slide-to.directive';
import { TruncateTextPipe } from './truncate-text/truncate-text.directive';
import { EditionDirective } from './edition/edition.directive';
import { SanitizeImgDirective } from './sanitize-img/sanitize-img.directive';
import { CantidadTareasAsignadasDirective } from './cantidad-tareas-asignadas/cantidad-tareas-asignadas.directive';
import { OverflowDirective } from './overflow/overflow.directive';
import { HighlightsDirective } from './highlights/highlights.directive';
import { ScrollHorizontalDirective } from './scroll-horizontal/scroll-horizontal.directive';
import { DecodeHtmlPipe } from './decode-html/decode-html.directive';
import { CkeditorDirective } from './ckeditor/ckeditor.directive';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NumbersOnlyDirective,
    UppercaseDirective,
    RoleDirective,
    DropZoneDirective,
    ClickOutsideDirective,
    setSlideToDirective,
    TruncateTextPipe,
    EditionDirective,
    SanitizeImgDirective,
    CantidadTareasAsignadasDirective,
    OverflowDirective,
    HighlightsDirective,
    ScrollHorizontalDirective,
    DecodeHtmlPipe,
    CkeditorDirective,

  ],
  exports: [
    NumbersOnlyDirective,
    UppercaseDirective,
    DropZoneDirective,
    RoleDirective,
    ClickOutsideDirective,
    setSlideToDirective,
    TruncateTextPipe,
    EditionDirective,
    SanitizeImgDirective,
    CantidadTareasAsignadasDirective,
    OverflowDirective,
    HighlightsDirective,
    ScrollHorizontalDirective,
    DecodeHtmlPipe,
    CkeditorDirective

  ]
})
export class DirectivesModule { }
