import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatStepper } from '@angular/material/stepper';
import { GestionAlumnoManagementService } from '../../../../services/data/gestion-alumno/gestion-alumno-management.service';
import { DateHelper } from '../../../helper/date.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { Subject, forkJoin, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GenericComponent } from '../generic/generic.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'aula-planeta-modificar-tarea',
  templateUrl: './modificar-tarea.component.html',
  styleUrls: ['./modificar-tarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModificarTareaComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('stepper') private stepperSection: MatStepper;

  firstStep: UntypedFormGroup;
  secondStep: UntypedFormGroup;
  thirdStep1: UntypedFormGroup;
  thirdStep2: UntypedFormGroup;
  currentStep = 1;

  todayDate = new Date().toISOString().slice(0, 10);

  minDate = new Date(Date.now());
  maxDate = new Date(2045, 0, 1);
  selection?: SelectionModel<any>;
  indexTab = 0;

  selectionDisponibles: SelectionModel<null>;
  selectionAsignados: SelectionModel<null>;

  alumnos: any[] = [];
  alumnosTemp: any[] = [];
  alumnosAsignados: any[] = [];
  enableCustomGroup = false;
  seleccionManualAlumnos = false;
  habilitarSeleccionManualAlumnos = true;

  grupos: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dateHelper: DateHelper,
    public dialogRef: MatDialogRef<ModificarTareaComponent>,
    private dialog: MatDialog,
    public translateService: TranslateService,
    private gestionAlumnoManagementService: GestionAlumnoManagementService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    this.selection = new SelectionModel(true, []);
    this.selectionDisponibles = new SelectionModel<null>(true, []);
    this.selectionAsignados = new SelectionModel<null>(true, []);
  }

  ngOnInit() {
    this.minDate = new Date(this.data.periodo.fechaInicio);
    this.secondStep = this.fb.group({
      name: this.fb.control(this.data.titulo, [Validators.required]),
      inicio: this.fb.control(this.dateHelper.getOffsetLocal(this.data.periodo.fechaInicio), [Validators.required]),
      fin: this.fb.control(this.dateHelper.getOffsetLocal(this.data.periodo.fechaFin), [Validators.required]),
      comentario: this.fb.control(this.data.description, [Validators.maxLength(255)]),
      shared: [this.data.shared]
    });

    this.thirdStep1 = this.fb.group({
      grupo: new UntypedFormControl('', Validators.required)
    });

    this.thirdStep2 = this.fb.group({
      alumnosAsignados: new UntypedFormControl([], Validators.required),
      curso: this.fb.control(this.data.cursos, [Validators.required]),
      nombreGrupo: new UntypedFormControl('')
    });


    this.gestionAlumnoManagementService.getMisClases();
    this.gestionAlumnoManagementService.getMisAlumnos();
    this.gestionAlumnoManagementService.misClases$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(clases => {
        this.grupos[0] = clases.map(el => ({
          nombre: `${el.curso.ordinal} ${el.curso.etapa.acronimo} ${el.grupo.id}`,
          clase: { curso: el.curso, grupo: el.grupo },
          alumnos: el.alumnos,
          tipoAsignacion: 'Clase'
        }));

      });
    this.gestionAlumnoManagementService.getMisGruposPersonalizados();
    this.gestionAlumnoManagementService.misGruposPersonalizados$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(gruposPersonalizados => {
        this.grupos[1] = gruposPersonalizados.map(el => ({
          id: el.id,
          nombre: el.nombre,
          alumnos: el.alumnos,
          tipoAsignacion: 'GrupoPersonalizado'
        }));
      });

    const info = {
      event: 'popup',
      popup: {
        action: 'modify_task',
        popupId: 'dialog-modificar-tarea',
        popupName: 'popup_modify_task',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  tabChange(event: MatTabChangeEvent) {
    this.indexTab = event.index;
    if (this.indexTab < 1) {
      this.thirdStep2.reset(this.thirdStep1.value);
    } else {
      this.thirdStep1.reset(this.thirdStep2.value);
    }
  }

  onStepperChange(ev) {
    this.currentStep = ev.selectedIndex;

    if (this.currentStep === 1) {
      this.secondStep.markAsPristine();
      this.secondStep.markAsUntouched();
      this.secondStep.reset(this.secondStep.value);
    }

    let info;
    if (this.currentStep === 1) {
      info = {
        event: 'popup_steps',
        popupSteps: {
          action: 'anterior/siguiente',
          popupId: 'dialog-modificar-tarea',
          popupName: 'popup_modificar_tarea',
          popupStep: this.currentStep.toString(),
          shareTeachers: this.secondStep.controls.shared.value.toString()
        }
      };
    } else {
      info = {
        event: 'popup_steps',
        popupSteps: {
          action: 'anterior/siguiente',
          popupId: 'dialog-modificar-tarea',
          popupName: 'popup_modificar_tarea',
          popupStep: this.currentStep.toString()
        }
      };
    }

    this.gtagService.event(info);
  }

  compareDates() {
    return new Date(this.secondStep.get('inicio').value) > new Date(this.secondStep.get('fin').value);
  }

  goBack() {
    this.stepperSection.previous();
  }

  goNext() {
    this.stepperSection.next();
  }

  // log() {
  //   console.log(this.secondStep)
  // }

  onClose() {
    const result = this.indexTab === 0 ? this.thirdStep1.value : this.thirdStep2.value;
    this.dialogRef.close({
      ...result,
      name: this.secondStep.value.name,
      inicio: this.dateHelper.setToUTC(this.dateHelper.setFechaInicio(this.secondStep.value.inicio)),
      fin: this.dateHelper.setToUTC(this.dateHelper.setFechaFin(this.secondStep.value.fin)),
      comentario: this.secondStep.value.comentario,
      shared: this.secondStep.value.shared,
      step: this.currentStep,
      nombreGrupo: this.thirdStep2.value.nombreGrupo,
      alumnosAsignados: this.alumnosAsignados.map(alumno => alumno.id),
      tipoAsignacion: (this.seleccionManualAlumnos) ? 'SeleccionAlumnos' : this.thirdStep2.value.curso.tipoAsignacion,
      grupoPersonalizadoId: this.thirdStep2.value?.curso?.id,
      cursoId: this.thirdStep2.value?.curso?.clase?.curso?.id,
      grupoId: this.thirdStep2.value?.curso?.clase?.grupo?.id
    });
  }

  setAlumnosList(grupo) {
    let alumnos = [];
    // si no hay clase es que es un grupo personalizado y trae el array de alumnos
    if (!grupo.clase) {
      alumnos = grupo.alumnos;
      this.habilitarSeleccionManualAlumnos = false;
    } else {
      this.habilitarSeleccionManualAlumnos = true;
      alumnos = this.gestionAlumnoManagementService.getListaAlumnosEnUnaClase(grupo.clase, this.gestionAlumnoManagementService.misAlumnos);
    }
    this.alumnosTemp = (alumnos && alumnos.length > 0) ? alumnos : [];
    this.alumnos = this.alumnosTemp.filter(alumno => !this.alumnosAsignados.some(aluAsi => aluAsi.id === alumno.id));
    if (!this.seleccionManualAlumnos) {
      this.asignarTodos();
      this.alumnosAsignados = alumnos;
    } else {
      this.desasignarAlumnos();
      this.alumnosAsignados = [];
    }
  }
  asignarAlumnos() {
    this.alumnosAsignados = this.alumnosAsignados.concat(this.selectionDisponibles.selected);

    this.thirdStep2.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.alumnos = this.alumnos.filter(alumno => !this.selectionDisponibles.selected.includes(alumno));
    this.selectionDisponibles.clear();
  }

  asignarTodos() {
    this.alumnos.forEach((row) => this.selectionDisponibles.select(row));
    this.asignarAlumnos();
  }

  desasignarAlumnos() {
    this.alumnos = this.alumnos.concat(this.alumnosTemp.filter(alumno => this.selectionAsignados.selected.includes(alumno)));

    this.alumnosAsignados = this.alumnosAsignados.filter(alumno => !this.selectionAsignados.selected.includes(alumno));
    this.thirdStep2.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.selectionAsignados.clear();
  }

  desasignarTodos() {
    this.alumnosAsignados.forEach((row) => this.selectionAsignados.select(row));
    this.desasignarAlumnos();
  }

  setAsignacionOnInit() {
    const cursosSeleccionados = this.grupos
      .map(grupo => grupo.filter(g => this.data.cursos.some(curso =>
        (curso.grupoId === g?.clase?.grupo?.id &&
          curso.cursoId === g?.clase?.curso?.id) || curso.grupoPersonalizadoId === g.id
      )))
      .reduce((acc, val) => acc.concat(val), []);
    this.thirdStep2.get('curso').setValue(cursosSeleccionados);
  }

}
