
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { EnvService } from '../../env.service';

@Injectable({
  providedIn: 'root'
})
export class GestionAlumnoDataService {

  constructor(private http: HttpClient, public cache: LocalCacheService, private envService: EnvService) { }

  public getMisAlumnos(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/aula/alumnos`);
    // const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/aula/alumnos`);
    // return this.cache.observable('GESTIONALUMNODATASERVICE-GETMISALUMNOS', requestObservable);
  }

  public getMisClases(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/aula/Clases`);
    // const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/aula/Clases`);
    // return this.cache.observable('GESTIONALUMNODATASERVICE-GETMISCLASES', requestObservable);
  }

  public updateMisClases(misClases: any[]): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/aula/Clases`, misClases);
  }

  public updateMiClase(clase): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/account/Alumno/Clase`, clase);
  }

  public getCursosDelCentro(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/colegio/CursosGrupos`);
    // const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/colegio/CursosGrupos`);
    // return this.cache.observable('GESTIONALUMNODATASERVICE-GETCURSOSDELCENTRO', requestObservable);
  }

  public getMisGrupos(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/aula/grupos`);
    // const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/aula/grupos`);
    // return this.cache.observable('GESTIONALUMNODATASERVICE-GETMISGRUPOS', requestObservable);
  }

  public createGrupo(grupo: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/aula/GruposPersonalizados`, { ...grupo });
  }

  public updateGrupo(grupo: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/aula/GruposPersonalizados`, { ...grupo });
  }

  public deleteGrupo(idGrupo: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.apiUrl}/aula/GruposPersonalizados/${idGrupo}`);
  }

  public getMisGruposPersonalizados(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/aula/GruposPersonalizados`);
    // const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/aula/GruposPersonalizados`);
    // return this.cache.observable('GESTIONALUMNODATASERVICE-GETMISGRUPOSPERSONALIZADOS', requestObservable);
  }

  public getMisClasesClassroom(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Google/obtenercursos`);
    // const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/Google/obtenercursos`);
    // return this.cache.observable('GESTIONALUMNODATASERVICE-GETMISCLASESCLASSROOM', requestObservable);
  }

  public getMisClasesTeams(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Teams/GetClasses`);
  }

}
