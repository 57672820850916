

/* eslint-disable @typescript-eslint/naming-convention */
// import { GuiaDidactica } from './guia-didactica.model';

export interface Modulo {
  id: string;
  unidad: Unidad;
  organizacion: Idioma;
  modulosOrganizacion: ModulosOrganizacion[];
  indiceSeccion?: any[];
  indiceSeccionVirtual?: any[];
  autor: any;
  compartir: boolean;
  clave?: Clave;
  comunes?: Comunes;
  propiedades?: Propiedades;
  metadatos?: Metadatos;
  orden?: number[];
}

export interface ModulosOrganizacion {
  id: string;
  idParent?: any;
  autor?: any;
  clave: Clave;
  comunes: Comunes;
  propiedades: Propiedades;
  metadatos: Metadatos;
  metadatosOrganizacion: MetadatosOrganizacion;
  modulosOrganizacion: ModulosOrganizacion[];
  orden?: number[];
  temporal?: boolean;
}

export interface MetadatosOrganizacion {
  curriculum: Curriculum[];
}

export interface Curriculum {
  idValor: number;
  valor: string;
}

export interface Metadatos {
  duracionNum: DuracionNum;
  duracionTexto: GuiaDidactica;
  nivelDificultad: Idioma;
  tipoMedia: Idioma[];
  tipoInteraccion: Idioma[];
  idioma: Idioma;
  palabrasClave: string[];
  competenciasAulaPlaneta: Idioma[];
  activacion: Idioma[];
  taxonomiaBloom: Idioma;
  inteligenciasMultiples: Idioma[];
  acciondidactica: Idioma[];
  tipocontenido: Idioma[];
}

export interface DuracionNum {
  valor: number;
}

export interface Propiedades {
  titulo_html: string;
  titulo_plano: string;
  descripcion_html: string;
  abrir_en_ventana_nueva: boolean;
  enlace: string;
  tipo_de_destacado: string;
  contenido_html: string;
  // SPM remove or check this property
  Contenido_html?: string;
  //
  imagen_previa: string;
  imagen: string;
  pie_de_imagen: string;
  texto_alternativo: string;
  creditos_html: string;
  identificadorOrigen: IdentificadorOrigen;
  ficha_profesor_html: string;
  ficha_alumno_html: string;
  carpeta: string;
  actividad: string;
  esScorm: boolean;
  tipoActividad: TipoActividad;
  adjuntos: Adjunto[];
  matriculaDelMedia: GuiaDidactica;
  mediaPesado: boolean;
  fichero_subtitulos: string;
  transcripcion: string;
  audio: string;
  documento: string;
  video: string;
  visible_solo_profesor: boolean;
  visible: string;
  compartir?: string;
  modoVisualizacion?: {
    modo: string;
    imagenPreviaInline: string;
  };
  tipoRecursoPropio?: {
    idValor: number;
    valor: string;
  };
  data_proporcion?: string;
  data_ampliacion?: boolean;
  data_visualizacion?: string;
  data_orientacionpie?: string;
  bloqueadoOffline?: boolean;
  objetivos_recurso?:string

}

export interface Adjunto {
  titulo_fichero: string;
  nombre_fichero: string;
  visible_alumno: any;
  visible_profesor: any;
}

export interface TipoActividad {
  idMaestroReferencia: number;
  idValor: string;
  valor: Valor;
}

export interface Valor {
  idTipoActividad: string;
  player: string;
  editor: string;
  templateDatos: string;
}

export interface IdentificadorOrigen {
  id: string;
  _idRecurso: string;
  _idRes: string;
  _loId: string;
}

export interface Comunes {
  tipoModulo: Idioma;
  tipoOrigen: Idioma;
  version: number;
  versionEditorial: Idioma;
  visible_solo_profesor: boolean;
}

export interface Clave {
  identificadorModulo: string;
  _IdProyecto: number;
  _IdGuion: number;
  _IdVersion: number;
  _Idmodulo: number;
  smartlinkId?: string;
}

export interface Unidad {
  identificadorUnidad: string;
  _IdProyecto: number;
  _IdGuion: number;
  _IdVersion: number;
  _IgIdentificador: string;
  tituloHtml: string;
  tituloPlano: string;
  descripcion_html: string;
  idioma: Idioma;
  tipoUnidad: Idioma;
  version: number;
  versionEditorial: Idioma;
  imagen: string;
  imagenPrevia: string;
  guiaDidactica: GuiaDidactica;
  tipoContenido: Idioma[];
}

export interface GuiaDidactica {
  valor: string;
}

export interface Idioma {
  idMaestroReferencia: number;
  idValor: number;
  valor: string;
}

export enum TipoOrientacion {
  vertical = 'Vertical',
  muyVertical = 'MuyVertical',
  standard = 'Standard',
  horizontal = 'Horizontal',
  muyHorizontal = 'MuyHorizontal',
  exceptionGrande = 'ExceptionGrande'
}

export enum SizeEnum {
  small = 'small',
  mediano = 'mediano',
  grande = 'grande',
}