import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DateHelper } from '../../../helper/date.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';


@Component({
  selector: 'aula-planeta-repetir-tarea-o-actividad',
  templateUrl: './repetir-tarea-o-actividad.component.html',
  styleUrls: ['./repetir-tarea-o-actividad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepetirTareaOActividadComponent implements OnInit {

  form: UntypedFormGroup;
  minDate = new Date(Date.now());
  maxDate = ''
  tipoSeleccionado: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dateHelper: DateHelper,
    public dialogRef: MatDialogRef<RepetirTareaOActividadComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService,

  ) { }

  ngOnInit() {
   
    this.tipoSeleccionado = !this.data.autonoma ? 'actividad' : 'tarea';
    // this.minDate = this.data.fechaInicio;
    /*
        this.form = this.formBuilder.group({
          fechaInicio: this.formBuilder.control(this.dateHelper.getOffsetLocal(this.data.periodo.fechaInicio), [Validators.required]),
          fechaFin: this.formBuilder.control(this.dateHelper.getOffsetLocal(this.data.periodo.fechaFin), [Validators.required]),
          comentario: this.formBuilder.control('', [Validators.maxLength(255)]),
        });
    */
    this.form = this.formBuilder.group({
      tipo: this.formBuilder.control(this.tipoSeleccionado, [Validators.required]),
      fechaInicio: this.formBuilder.control(this.minDate, [Validators.required]),
      fechaFin: this.formBuilder.control(this.maxDate, [Validators.required]),
      comentario: this.formBuilder.control('', [Validators.maxLength(255)]),
    });



    const info = {
      event: 'popup',
      popup: {
        action: 'repetir tarea',
        popupId: 'dialog-repetir-tarea',
        popupName: 'popup_repetir_tarea',
      }
    };
    this.gtagService.event(info);
  }

  onSubmit() {
    if (this.form.valid) {
      const fechas = this.form.getRawValue();
      
      this.dialogRef.close({ fechas });
    }


  }
}
