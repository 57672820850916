import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { EnvService } from '../../env.service';
import { Materia } from '../../../shared/models/materia.model';

@Injectable({
  providedIn: 'root'
})
export class ProyectoDataService {

  constructor(private http: HttpClient, public cache: LocalCacheService, private envService: EnvService) { }

  public getMisProyectos(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/mis-proyectos`);
    return this.cache.observable('PROYECTODATASERVICE-GETMISPROYECTOS', requestObservable);
  }

  public getProyecto(id: string): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/proyecto/${id}`);
    return this.cache.observable(`PROYECTODATASERVICE-GETPROYECTO-${id}`, requestObservable);
  }

  public createProyecto(proyecto: Materia): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/proyecto/register`, proyecto);
  }

  public updateProyecto(proyecto: Materia): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/proyecto/${proyecto.id}`, proyecto);
  }

  public deleteProyecto(proyecto: Materia): Observable<any> {
    return this.http.delete<any>(`${this.envService.apiUrl}/proyecto/${proyecto.id}`);
  }
}
