import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-descargar-offline',
  templateUrl: './descargar-offline.component.html',
  styleUrls: ['./descargar-offline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescargarOfflineComponent implements OnInit {

  dispositivos: any[] = [
    { value: 'android', viewValue: 'Android' },
    { value: 'iOS', viewValue: 'iOS' },
    { value: 'macos', viewValue: 'MacOS' },
    { value: 'linux', viewValue: 'Linux' },
    { value: 'windows', viewValue: 'Windows' }
  ];

  constructor(
    public dialogRef: MatDialogRef<DescargarOfflineComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
    ) { }


  ngOnInit(): void {
    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-descargar-offline',
        popupName: 'popup_descargar_offline',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }

  close(result) {
    this.dialogRef.close(result);
  }

}
