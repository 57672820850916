import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { Directive, TemplateRef, ViewContainerRef, Input, OnInit } from '@angular/core';
import { AuthManagementService } from '../../auth/auth-management.service';
import { User } from '../../../shared/models/user.model';
import { VistaAlumnoService } from '../../../services/vista-alumno.service';
import { EditionModeService } from '../../../services/edition-mode.service';


@Directive({
  selector: '[hasRole], [vistaAlumno], [oculto], [hasPermissions]'
})
export class RoleDirective implements OnInit, OnDestroy {
  @Input('hasRole') roles: string[] = null;
  @Input('hasPermissions') permissions: string[] = null;
  @Input() vistaAlumno = false;
  @Input() oculto = false;
  hasRole = false;
  hasPermission = false;

  /**
   * Current user to inspect his privileges
   */
  private currentUser: User;

  /**
   * Operation to add conditional. OR / AND
   */
  logicalOperation: 'OR' | 'AND' = 'OR';

  _vistaAlumno = false;
  _oculto = false;
  /**
   * Determinates if viewContainer is hidden or not
   */
  private isHidden = true;

  private destroy$: Subject<void> = new Subject<void>();
  /**
   *
   * @param templateRef
   * @param viewContainer
   * @param applicationUserService
   */
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authManagementService: AuthManagementService,
    private vistaAlumnoService: VistaAlumnoService,
    private editionModeService: EditionModeService,
  ) { }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.hasRole = this.checkRole();
    this.hasPermission = this.checkPermission();

    this.authManagementService.currentUser$.pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(user => {
        this.currentUser = user;
        this._vistaAlumno = false;
        this.hasRole = this.checkRole();
        this.hasPermission = this.checkPermission();
        this.updateView();
      });

    this.vistaAlumnoService.vistaAlumno$.pipe(
      takeUntil(this.destroy$))
      .subscribe(value => {
        if (this.vistaAlumno) {
          this._vistaAlumno = value;
          this.updateView();
        }
      });

    this.editionModeService.enable$.pipe(
      takeUntil(this.destroy$))
      .subscribe(editando => {
        if (this.oculto) {
          this._oculto = !editando;
          this.updateView();
        }
      });
  }


  /**
   * Update view if is needed based on user privileges
   */
  private updateView() {
    if (this.hasRole && this.hasPermission && !this._vistaAlumno && !this._oculto) {
      if (this.isHidden) {
        const embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        embeddedViewRef.detectChanges();
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  /**
   * Check the user privileges if the operational
   * @returns boolean if component must be renderized or not
   */
  private checkRole(): boolean {
    if (this.roles === null) {
      return true;
    }
    let hasRole = false;
    if (this.currentUser && this.currentUser.usuarioRoles && this.currentUser.usuarioRoles.length) {
      for (const checkRoles of this.roles) {
        const roleFound = this.authManagementService.userCanRoles([checkRoles]);
        if (roleFound) {
          hasRole = true;
          if (this.logicalOperation === 'OR') {
            break;
          }
        } else {
          hasRole = false;
          if (this.logicalOperation === 'AND') {
            break;
          }
        }
      }
    }
    return hasRole;
  }

  /**
 * Check the user privileges if the operational
 * @returns boolean if component must be renderized or not
   */
  private checkPermission(): boolean {
    if (this.permissions === null) {
      return true;
    }
    let hasPermission = false;
    if (this.currentUser && this.currentUser.funcionalidades && this.currentUser.funcionalidades.length) {
      for (const checkPermissions of this.permissions) {
        const permissionFound = this.authManagementService.userCanPermission([checkPermissions]);
        if (permissionFound) {
          hasPermission = true;
          if (this.logicalOperation === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOperation === 'AND') {
            break;
          }
        }
      }
    }
    return hasPermission;
  }



}
