import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-crear-otra-materia',
  templateUrl: 'crear-otra-materia.component.html',
  styleUrls: ['./crear-otra-materia.component.scss'],
})
export class CrearOtraMateriaComponent implements OnInit {

  form: UntypedFormGroup;
  materias: any = [
    {
      id: 1,
      name: "Matematicas"
    },
    {
      id: 2,
      name: "Ciencias Naturales"
    },
    {
      id: 3,
      name: "Inglés"
    },
    {
      id: 4,
      name: "Tecnología"
    },
    {
      id: 5,
      name: "Filosofía"
    }
  ]
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CrearOtraMateriaComponent>, 
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {}

  ngOnInit () {
    this.form = this.formBuilder.group({
      titulo: ['', [Validators.required]],
      descripcion: ['', [Validators.required]],
      materia_maestra: ['', [Validators.required]]
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'create-other-materia',
        popupId: 'dialog-crear-otra-materia',
        popupName: 'popup_create_other_materia'
      }
    };
    this.gtagService.event(info);
  }

  onSubmit() {
    if (this.form.valid) {
      const info = {
        event: 'popup',
        popup: {
          action: 'guardar',
          popupId: 'dialog-crear-otra-materia',
          popupName: 'popup_create_other_materia'
        }
      };
      this.gtagService.event(info);
      const formValue = this.form.getRawValue();
      this.dialogRef.close(formValue);
    }
  }
}
