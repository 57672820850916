import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ColorHelper } from '../../../helper/color.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { AuthManagementService } from '../../../../core/auth/auth-management.service';

@Component({
  selector: 'aula-planeta-mover',
  templateUrl: 'mover.component.html',
  styleUrls: ['./mover.component.scss'],
})
export class MoverComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  panelOpenState = false;
  isCurrentLocation = true;
  mvForm: UntypedFormGroup;
  temaSeleccionado: null;
  indiceSeleccionado: null;

  _tema;
  indiceSeleccionadoVirtual;

  constructor(
    public authManagementService: AuthManagementService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<MoverComponent>,
    public colorHelper: ColorHelper,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngOnInit() {
    this.mvForm = this.formBuilder.group({
      location: [this.data.currentLocationId],
      seccion: [this.data.seccion]
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'move_to',
        popupId: 'dialog-mover',
        popupName: 'popup_mover',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }

  close() {
    const response = { ...this.mvForm.value };
    if (this.temaSeleccionado) {
      response.location = this.temaSeleccionado;
    }
    if (this.indiceSeleccionadoVirtual) {
      this.indiceSeleccionado = this._tema.indiceSecciones.find(seccion => seccion.id === this.indiceSeleccionadoVirtual.id);
    }
    if (this.indiceSeleccionado) {
      response.seccion = this.indiceSeleccionado;
    }
    this.dialogRef.close(response);
  }

  meCabeUnaGorda(seccion) {
    if (this.data.isCopiarSeccionIntoSeccion) {
      const nivelesDeLaSeccion = this.calcularNivelMaximo(seccion.indice);
      if ((this.data.profundidad + nivelesDeLaSeccion) <= 4) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private calcularNivelMaximo(indice) {
    let nivelMaximo = 0;

    for (const key in indice) {
      if (indice[key] !== 0) {
        nivelMaximo = key === 'seccion' ? 1 : key === 'subseccion' ? 2 : key === 'subsubseccion' ? 3 : 4;
      }
    }

    return nivelMaximo;
  }



}