import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as DecoupledDocumentEditor from '../../../../../../../assets/js/ck-editor-math-type/ckeditor';

@Component({
  selector: 'aula-planeta-jumbo-tema',
  templateUrl: './jumbo-tema.component.html',
  styleUrls: ['./jumbo-tema.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JumboTemaComponent implements OnInit, AfterViewInit {

  @ViewChild('editorTexto') editorTexto: any;
  editor = DecoupledDocumentEditor;
  isDisabled = true;
  valueData = '';

  formModule: UntypedFormGroup;

  config = {
    language: 'es',
    fontSize: { options: [9, 11, 12, 13, 'default', 17, 19, 21] },
    toolbar: ['heading', '|', 'bold', 'italic', 'fontSize', 'fontFamily', 'fontColor', 'underline', 'alignment', 'highlight', 'MathType', 'numberedList', 'link', 'insertTable', 'bulletedList', '|', 'fontBackgroundColor', 'strikethrough', 'indent', 'outdent', 'undo', 'redo', 'exportPdf', 'exportWord'],
    link: {
      addTargetToExternalLinks: true
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
      ]
    }
  };

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<JumboTemaComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.formModule = this.fb.group({
      tituloHtml: [this.data.tituloHtml, Validators.required],
      descripcion_html: [this.data && this.data.descripcion_html ? this.data.descripcion_html : '', Validators.required]
    });
  }

  ngAfterViewInit(): void {
    // Ya no es necesario ocultar o mostrar manualmente la barra de herramientas
  }

  toggleDisabled() {
    this.isDisabled = !this.isDisabled;
    if (this.isDisabled) {
      this.editorTexto.instance.isReadOnly = true;
    } else {
      this.editorTexto.instance.isReadOnly = false;
    }
  }

  public onChange({ editor }) {
    const data = editor.getData();
    this.formModule.get('descripcion_html').setValue(data);
  }

  public onReady(editor) {
    // La barra de herramientas ya se maneja en la directiva
  }

  private htmlToTextPlain(html: string) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.textContent || div.innerText || '';
    div.remove();
    return text;
  }

  onClose() {
    this.dialogRef.close(
      {
        ...this.formModule.value, tituloPlano: this.htmlToTextPlain(this.formModule.value.tituloHtml), isJumbo: true
      }
    );
  }
}
