import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MateriaManagementService } from '../../../../services/data/materia/materia-management.service';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { GestionAlumnoManagementService } from '../../../../services/data/gestion-alumno/gestion-alumno-management.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'aula-planeta-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingComponent implements OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  changeStep2 = false;
  changeStep3 = false;

  clases: any[] = [];
  step2HasError = false;
  step3HasError = false;
  step2Completed = false;
  step3Completed = false;

  constructor(
    private dialogRef: MatDialogRef<OnboardingComponent>,
    private materiaManagementService: MateriaManagementService,
    private gtagService: GtagService,
    public gestionAlumnoManagementService: GestionAlumnoManagementService,
  ) {
    this.clases = this.mapeoClases(this.gestionAlumnoManagementService.misClases);
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onStepChange(event: StepperSelectionEvent) {
    this.changeStep2 = this.changeStep3 = false;

    if (event.previouslySelectedIndex === 1) {
      this.step2HasError = this.checkStep2Error();
      this.step2Completed = !this.step2HasError;
      this.changeStep2 = true;
    } else if (event.previouslySelectedIndex === 2) {
      this.step3HasError = this.checkStep3Error();
      this.step3Completed = !this.step3HasError;
      this.changeStep3 = true;
    }
  }

  saveMisMaterias(result: any) {
    const resultMap = result ? result.map(materia => materia.id) : [];
    const misMateriasMap = this.materiaManagementService.misMaterias.map(materia => materia.id);

    this.step2HasError = resultMap.length === 0;
    this.step2Completed = !this.step2HasError;

    if (!this.step2HasError && resultMap !== misMateriasMap) {
      this.materiaManagementService.updateMisMaterias(resultMap);
    }
  }

  saveMisClases(misClases: any) {
    this.step3HasError = !misClases || misClases.length === 0;
    this.step3Completed = !this.step3HasError;

    if (!this.step3HasError) {
      this.gestionAlumnoManagementService.updateMisClases(misClases);
    }
  }

  configureLater() {
    if (!this.step2HasError && !this.step3HasError) {
      this.dialogRef.close();
    }
  }

  mapeoClases(clases: any[]): any[] {
    return clases.map(clase => ({
      cursoId: clase.cursoId || clase?.curso?.id,
      grupoId: clase.grupoId || clase?.grupo?.id
    }));
  }

  private checkStep2Error(): boolean {
    return !this.materiaManagementService.misMaterias.length;
  }

  private checkStep3Error(): boolean {
    return !this.clases.length;
  }
}
