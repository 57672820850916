<div id="dialog-edita-recurso-plantilla" [ngClass]="colorHelper.getColor(data.color)">
    <div mat-dialog-content class="d-flex w-100 position-relative m-0 p-0">
        <button class="close-button default-button default-button__primary position-absolute" [mat-dialog-close]="false">
            <mat-icon>close</mat-icon>
        </button>
        <div class="d-flex flex-column justify-content-between w-100">
            <h1 mat-dialog-title class="d-flex align-items-center custom-text mb-3">{{'dialogs.edit-recurso.plantilla.title' | translate}}</h1>
            <p class="mb-0">{{'dialogs.edit-recurso.plantilla.subtitle' | translate}}</p>
            <div mat-dialog-actions class="justify-content-start mt-3">
                <button role="button" class="default-button default-button__secondary me-3" [mat-dialog-close]="false">{{ 'dialogs.close' | translate }}</button>
            </div>
        </div>
    </div>
</div>