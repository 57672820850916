import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../services/env.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookiesDataService {

  constructor(private http: HttpClient, private envService: EnvService) { }

  public getCookies(): Observable<any[]> {
    // return of([
    //   {
    //     site: 'privacidad',
    //     accept: true
    //   }, {
    //     site: 'tecnica',
    //     accept: true
    //   }, {
    //     site: 'analisis',
    //     accept: true
    //   }, {
    //     site: 'publicidad',
    //     accept: true
    //   }, {
    //     site: 'preferencias',
    //     accept: true
    //   }
    // ]);
    return this.http.get<any>(`${this.envService.apiUrl}/account/usercookies`);
  }

  public setCookies(cookies: any[]): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/account/usercookies`, cookies);
  }

}
