const packageJson = require('../../../../package.json');

export const environment = {
  appName: 'Cosmos',
  envName: 'UAT',
  production: false,
  api: 'https://uatapi.aulaplaneta.com/api',
  rootContent: 'https://cdnuat.aulaplaneta.com',
  rootContentNoCDN: 'https://uat.aulaplaneta.com',
  rootContentFind: 'https://find.aulaplaneta.com',
  rootContentRocket: 'https://rocketuat.aulaplaneta.com',
  googleId: '926325069712-jotjgejnsjlgb1rcjrfolmd7kohdvtv5.apps.googleusercontent.com',
  googleSecret: '_-1RPoeQfuLpZBo-F4I1yyhN',
  googleIdToken: '504901875830-5arh4mshk3lidsbav5p1gh4cnn735cm2.apps.googleusercontent.com',
  googleSecretToken: '_-1RPoeQfuLpZBo-F4I1yyhN',
  googleAnalytics: 'GTM-PTD9CB2',
  bancoUri: 'http://prebanco.aulaplaneta.com',
  worldbookUri: 'https://worldbook.aulaplaneta.com',
  maticUri: 'https://api-qa.adaptemy.com/sso/aula/auth',
  test: false,
  teamsClientId: '6a23b570-04d9-47ee-adb5-d17140f0e06d',
  teamsScope: 'api://uat.aulaplaneta.com/6a23b570-04d9-47ee-adb5-d17140f0e06d/access_as_user',
  i18nPrefix: './',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free-webfonts'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress'],
    eslint: packageJson.devDependencies['eslint']
  }
};
