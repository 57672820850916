import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ColorHelper } from '../../../helper/color.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-validar-licencia',
  templateUrl: 'validar-licencia.component.html',
  styleUrls: ['./validar-licencia.component.scss'],
})
export class ValidarLicenciaComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  dpForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public colorHelper: ColorHelper,
    public dialogRef: MatDialogRef<ValidarLicenciaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngOnInit() {
    this.dpForm = this.formBuilder.group({
      licencia: [this.data.licencia, Validators.required]
    });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-duplicar',
        popupName: 'popup_duplicar',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }

}
