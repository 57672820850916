<div id="dialog-repetir-actividad" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.repetir-actividad.titulo' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <div class="overflow-auto">
            <form role="form" [formGroup]="form">
                <fieldset class="w-100 d-flex flex-column">
                    <div>
                        <div class="form-group default-input position-relative w-100">
                            <label>{{'dialogs.repetir-actividad.fecha-inicio' | translate}}</label>
                            <input type="text" class="form-control" [min]="minDate" [max]="maxDate" [matDatepicker]="pickerInicio" (click)="pickerInicio.open()" [placeholder]="('dialogs.repetir-actividad.fecha-inicio' | translate)" formControlName="fechaInicio" readonly>
                            <mat-error *ngIf="form.get('fechaInicio').hasError('required') && form.controls['fechaInicio'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="pickerInicio" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                            <mat-datepicker #pickerInicio startView="year" [startAt]="minDate"></mat-datepicker>

                        </div>
                        <div class="form-group default-input position-relative w-100">
                            <label>{{'dialogs.repetir-actividad.fecha-fin' | translate}}</label>
                            <input type="text" class="form-control" [min]="form.get('fechaInicio').value" [max]="maxDate" (click)="pickerFin.open()" [matDatepicker]="pickerFin" [placeholder]="('dialogs.repetir-actividad.fecha-fin' | translate)" formControlName="fechaFin" readonly>
                            <mat-error *ngIf="form.get('fechaFin').hasError('required') && form.controls['fechaFin'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="pickerFin" class="datepicker-icon default-text position-absolute" readonly></mat-datepicker-toggle>
                            <mat-datepicker #pickerFin startView="year" [startAt]="form.get('fechaInicio').value"></mat-datepicker>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="w-100 form-group default-textarea mb-4">
                            <label>{{'dialogs.asignar-tarea.step2.comentario' | translate}}</label>
                            <textarea type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.comentario' | translate)" formControlName="comentario" tabindex="4" maxlength="255"></textarea>
                            <span class="text-secondary"><small>{{'('+ form.controls['comentario'].value.length +'/255)'}}</small></span>
                            <mat-error *ngIf="form.get('comentario').hasError('required') && form.controls['comentario'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>
                    </div>

                </fieldset>
            </form>
        </div>

    </div>

    <div mat-dialog-actions class="justify-content-start mt-2 mb-0 pb-0">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__primary" (click)="onSubmit()" [disabled]="form.invalid" type="submit">{{ 'generico.guardar' | translate }}</button>
    </div>
</div>
