/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddModulesBaseComponent } from '../../add-modules-base.component';
import { DomSanitizer } from '@angular/platform-browser';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { TranslateService } from '@ngx-translate/core';
import * as DecoupledDocumentEditor from '../../../../../../../assets/js/ck-editor-math-type/ckeditor';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { WirisFormulaPipe } from '../../../../../../services/pipes/wiris-formula.pipe';
@Component({
  selector: 'aula-planeta-add-module-destacado',
  templateUrl: './destacado.component.html',
  styleUrls: ['./destacado.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [WirisFormulaPipe] // Provide the pipe
})
export class DestacadoComponent extends AddModulesBaseComponent implements AfterViewInit, OnInit {

  @ViewChild('editorTexto', { static: false }) editorTexto: any;
  editor = DecoupledDocumentEditor;
  isDisabled = true;
  valueData = '';

  config = {
    language: 'es',
    fontSize: { options: [9, 11, 12, 13, 'default', 17, 19, 21] },
    toolbar: ['heading', '|', 'bold',
      'italic', 'fontSize', 'fontFamily', 'fontColor', 'underline', 'alignment',
      'highlight', 'MathType', 'numberedList', 'link', 'insertTable',
      'bulletedList', '|', 'fontBackgroundColor',
      'strikethrough', 'indent', 'outdent', 'undo', 'redo', 'exportPdf', 'exportWord'],
    link: {
      addTargetToExternalLinks: true
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
      ]
    }
  };

  tiposDestacados = [
    {
      value: 0,
      viewValue: 'Destacado'
    },
    {
      value: 1,
      viewValue: 'Recuerda'
    }
  ]

  beforeTitle = '';
  // contenidoHtmlWiris = '';
  // contenidoHtml = '';
  // htmlTexto: any;
  // htmlTextoWiris: any;

  constructor(
    private domSanitizer: DomSanitizer,
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DestacadoComponent>,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService,
    private wirisFormulaPipe: WirisFormulaPipe // Inject the pipe
  ) {
    super(fb, dialogRef, data);
  }

  ngOnInit() {
    this.formModule = this.fb.group({
      titulo_html: ['Recuerda'],
      contenido_html: [this.data && this.data.propiedades.contenido_html ? this.data.propiedades.contenido_html : '', Validators.required],
      visible: [this.data && this.data.propiedades.visible === 'Profesores' ? true : false],
      tipo_de_destacado: [this.data && this.data.propiedades.tipo_de_destacado ? this.data.propiedades.tipo_de_destacado : 0, Validators.required]
    });

    // eslint-disable-next-line eqeqeq
    const isDestacado = (this.formModule.get('tipo_de_destacado').value == 'Recuerda' || this.formModule.get('tipo_de_destacado').value == '1') ? 1 : 0;

    this.formModule.patchValue({
      tipo_de_destacado: isDestacado
    });

    this.beforeTitle = this.formModule.get('titulo_html').value;

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-add-module-destacado',
        popupName: 'popup_añadir_destacado'
      }
    };
    this.gtagService.event(info);


    // const parser = new DOMParser();
    // this.htmlTexto = parser.parseFromString(this.data.propiedades?.contenido_html, 'text/html');
    // this.htmlTextoWiris = parser.parseFromString(this.wirisFormulaPipe.transform(this.data.propiedades.contenido_html), 'text/html');

    // this.contenidoHtml = this.htmlTexto.getElementsByTagName('html')[0].innerHTML;
    // this.contenidoHtmlWiris = this.htmlTextoWiris.getElementsByTagName('html')[0].innerHTML;

    // console.log('this.htmlTextoWiris', this.htmlTextoWiris);
    // console.log('this.contenidoHtmlWiris', this.contenidoHtmlWiris);


  }

  changeType(event) {
    let newTitle = '';

    if (event.value === 1) {
      this.beforeTitle = this.formModule.get('titulo_html').value;
      newTitle = this.translateService.instant('dialogs.add-module-base.form.tipo-destacado.recuerda');
    } else {
      newTitle = this.beforeTitle;
    }
    this.formModule.patchValue({
      titulo_html: newTitle
    });
  }

  ngAfterViewInit(): void {

    // this.transformMathMLToImages();

    setTimeout(() => {
      if (this.editorTexto?.elementRef?.nativeElement && this.editorTexto.elementRef.nativeElement.getElementsByClassName('ck').length > 0) {
        this.editorTexto.elementRef.nativeElement.getElementsByClassName('ck')[0].hidden = false;
      }
    }, 100);


  }

  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    const formControl = this.formModule.get('contenido_html');
    if (formControl.value !== data) {
      formControl.setValue(data, { emitEvent: false });
      formControl.updateValueAndValidity();
    }
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    if (this.editorTexto?.elementRef?.nativeElement) {
      this.editorTexto.elementRef.nativeElement.getElementsByClassName('ck')[0].hidden = true;
    }
  }

  onClose() {
    this.dialogRef.close(
      {
        ...this.formModule.value,
        titulo_plano: this.formModule.get('titulo_html') ? this.formModule.get('titulo_html').value : '',
        visible: this.formModule.get('visible').value ? 'Profesores' : 'Alumnos',
        // contenido_html: this.domSanitizer.sanitize(SecurityContext.HTML, this.formModule.get('contenido_html').value)
      }
    );
  }

  // transformMathMLToImages(): void {
  //   const mathElements = this.htmlTexto.querySelectorAll('math');

  //   mathElements.forEach((mathElement) => {

  //     const transformed = this.wirisFormulaPipe.transformBack(mathElement.outerHTML);

  //     if ( mathElement.parentNode.nodeName === 'P' &&
  //       mathElement.parentNode.childNodes.length === 1 &&
  //       mathElement.parentNode.firstChild === mathElement) {
  //       // Obtener el elemento <p> padre
  //       const parentParagraph = mathElement.parentNode;

  //       // Crear un nuevo elemento <div>
  //       const newDiv = document.createElement('div');
  //       newDiv.setAttribute('class', 'text-center');

  //       // Mover el contenido del <p> al nuevo <div>
  //       while (parentParagraph.firstChild) {
  //           newDiv.appendChild(parentParagraph.firstChild);
  //       }

  //       // Reemplazar el elemento <p> con el nuevo <div>
  //       parentParagraph.parentNode.replaceChild(newDiv, parentParagraph);

  //       // Reemplazar el elemento matemático dentro del nuevo <div>
  //       newDiv.replaceChild(transformed, mathElement);
  //     }
  //     else{
  //         mathElement.parentNode?.replaceChild(transformed, mathElement);
  //     }

  //   });

  //   // Actualiza el contenido HTML del componente
  //   this.contenidoHtml = this.htmlTexto.documentElement.innerHTML;

  // }
}
