<!-- <h1 mat-dialog-title>{{'dialogs.configurar-aulaplaneta.title' | translate}}</h1> -->
<div mat-dialog-content id="dialogOnBoarding" class="standard">
    <mat-horizontal-stepper [linear]="false" #stepper (selectionChange)="onStepChange($event)">
        <!-- Paso 1 -->
        <mat-step label="Paso 1">
            <ng-template matStepLabel>Contenido del Paso 1</ng-template>
            <div class="d-flex flex-column w-50 h-100 position-relative z-1 p-5 m-3">
                <div class="custom-text">
                    <h1 class="heading-6 text-color-regular font-weight-normal">¡Hola!</h1>
                    <h2 class="mt-4 heading-3 text-color-regular font-weight-normal">Te damos la bienvenida a Cosmos.</h2>
                    <p class="mt-4 body-1">En tan solo dos pasos estarás listo para dar tus clases. ¿Empezamos?</p>
                </div>
                <div class="step-actions pull-down">
                    <button class="default-button default-button__secondary default-button--lg" matStepperNext>Empezar</button>
                </div>
            </div>
            <img src="assets/images/static/onboarding/Saludando@2x.png" alt="" class="position-absolute bottom-0 end-0 h-100 z-0" />
        </mat-step>

        <!-- Paso 2 -->
        <mat-step label="Paso 2" [hasError]="step2HasError" [completed]="step2Completed">
            <ng-template matStepLabel>Contenido del Paso 2</ng-template>
            <div class="d-flex h-100">
                <div class="d-flex flex-column h-100 flex-grow-1 onboarding-content">
                    <div class="custom-text">
                        <h3 class="heading-6 text-color-regular">Selecciona tus materias</h3>
                        <p class="mt-4">Puedes seleccionar más de una.</p>
                    </div>
                    <div class="container-configurar">
                        <aula-planeta-configurar-aulaplaneta [isModal]="false" [changeStep]="changeStep2" (returnValue)="saveMisMaterias($event)"></aula-planeta-configurar-aulaplaneta>
                    </div>
                    <div class="step-actions">
                        <button class="default-button default-button__primary" matStepperPrevious>Volver</button>
                        <button class="default-button default-button__secondary ms-3" matStepperNext>Continuar</button>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center" style="width:33.33%;">
                    <img src="assets/images/static/onboarding/Subiendo@2x.png" alt="" class="img-fluid" />
                </div>
            </div>
        </mat-step>

        <!-- Paso 3 -->
        <mat-step label="Paso 3" [hasError]="step3HasError" [completed]="step3Completed">
            <ng-template matStepLabel>Contenido del Paso 3</ng-template>
            <div class="d-flex h-100">
                <div class="d-flex flex-column h-100 flex-grow-1 onboarding-content">
                    <div class="custom-text">
                        <h3 class="heading-6 text-color-regular">Selecciona tus cursos y clases</h3>
                        <p class="mt-4">Siempre podrás modificar tu selección desde el apartado de gestión de clases.</p>
                    </div>
                    <div class="container-configurar">
                        <aula-planeta-mis-clases-modal [isModal]="false" [changeStep]="changeStep3" (returnValue)="saveMisClases($event)" [clases]="clases" [cursos]="(gestionAlumnoManagementService.cursosDelCentro)" [grupos]="(gestionAlumnoManagementService.gruposDelCentro)">
                        </aula-planeta-mis-clases-modal>
                    </div>
                    <div class="step-actions">
                        <button class="default-button default-button__primary" matStepperPrevious>Volver</button>
                        <button class="default-button default-button__secondary ms-3" matStepperNext>Continuar</button>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center" style="width:33.33%;">
                    <img src="assets/images/static/onboarding/Pintando@2x.png" alt="" class="img-fluid" />
                </div>
            </div>
        </mat-step>

        <!-- Paso 4 -->
        <mat-step label="Paso 4">
            <ng-template matStepLabel>Contenido del Paso 4</ng-template>
            <div class="d-flex flex-column w-50 h-100 position-relative z-1 p-5 m-3">
                <div class="py-3 custom-text">
                    <h1 class="heading-3 text-color-regular font-weight-normal">¡Listo!<br>Ha sido fácil, ¿verdad?</h1>
                    <h2 class="mt-4 heading-6  font-weight-normal">Ya lo tienes todo preparado para empezar a impartir tus clases.</h2>
                </div>
                <div class="step-actions pull-downer">
                    <button class="btn btn-lg default-button default-button__primary default-button--lg" matStepperPrevious>Volver</button>
                    <button class="default-button default-button__secondary default-button--lg ms-3" (click)="close()" [disabled]="step2HasError || step3HasError">Comenzar</button>
                </div>
            </div>
            <img src="assets/images/static/onboarding/Flotando@2x.png" alt="" class="position-absolute top-0 end-0 h-100 z-0" />
        </mat-step>

    </mat-horizontal-stepper>
</div>
