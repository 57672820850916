<div id="dialog-tarea-vencida" [ngClass]="colorHelper.getColor(data.color)">
    <div mat-dialog-content class="d-flex w-100 position-relative m-0 p-0">

        <div class="col-2 p-0 custom-icon">
            <mat-icon  *ngIf="data.icon" class="mt-2">event_busy</mat-icon>

            <button *ngIf="data.close" aria-label="{{'dialogs.close' | translate}}"
          class="d-flex align-items-center justify-content-center py-1 default-button default-button__primary position-absolute" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <div class="d-flex flex-column justify-content-between col-9">
            <h1 mat-dialog-title class="d-flex align-items-center custom-text mb-3">{{ 'generico.tarea.vencida' | translate }}</h1>
            <p class="mb-0 content">{{ 'generico.tarea.vencida.mensaje' | translate }}</p>
            <div class="d-flex align-items-center mt-2">
                <mat-checkbox [(ngModel)]="checkboxValue">{{ 'generico.tarea.vencida.checkbox' | translate }}</mat-checkbox>
            </div>

            <div mat-dialog-actions class="justify-content-start mt-3">
                <button role="button" class="default-button me-3" [mat-dialog-close]="false" [ngClass]="getTypeButton('secondary')">{{ 'generico.cerrar' | translate }}</button>
                <button role="button" class="default-button me-3" [mat-dialog-close]="{value: true, checkboxValue: checkboxValue}" [ngClass]="getTypeButton('primary')">{{ 'dialogs.tarea-vencida.acceder'| translate }}</button>
            </div>
        </div>
    </div>
</div>
