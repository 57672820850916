import { ColorHelper } from '../../../../shared/helper/color.helper';
import { Component, OnInit, Inject, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EnvService } from '../../../../services/env.service';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DrawerDialogComponent } from '../drawer/drawer-dialog.component';
import { take, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthManagementService } from '../../../../core/core.module';
import { ScormService } from '../../../../core/scorm/scorm.service';
import { ScormManagementService } from '../../../../services/data/scorm/scorm-management-service.service';

@Component({
  selector: 'aula-planeta-expositor-dialog',
  templateUrl: 'expositor-dialog.component.html',
  styleUrls: ['./expositor-dialog.component.scss'],
})
export class ExpositorDialogComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('content') content: any;

  sidenavPosition = 'start';
  expositor = true;
  urlSafe: SafeResourceUrl;
  swapRight: boolean;
  lastSelection: number;
  fadeOut: boolean;
  badUrl: boolean;
  recursos: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public colorHelper: ColorHelper,
    public dialogRef: MatDialogRef<ExpositorDialogComponent>,
    public envService: EnvService,
    public router: Router,
    private gtagService: GtagService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private renderer2: Renderer2,
    public authManagementService: AuthManagementService,
    public scormService: ScormService,
    private scormManagementService: ScormManagementService
  ) {

    if (!data.color) {
      data.color = 'azulG';
    }

    const info = {
      event: 'page_view',
      pageView: {
        imPage: 'expositor',
        pageId: 'expositor',
        imPagetitle: 'Expositor',
        pageTitle: 'Expositor'
      }
    };
    this.gtagService.pageview(info);
  }

  ngOnInit() {
    this.swapRight = true;
    this.lastSelection = 0;

    this.authManagementService.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.recursos = this.data.recursos.filter(recurso => {
          if (recurso.propiedades.modoVisualizacion?.modo === 'Inline') {
            return false;
          }
          if (user.usuarioRoles.includes('Alumno')) {
            return recurso.propiedades.visible !== 'Oculto' || recurso.propiedades.visible !== 'Profesores';
          } else {
            return recurso.propiedades.visible !== 'Oculto';
          }
        });
      }

    });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  togglePosition(position: string, event: PointerEvent) {
    this.fadeOut = true;
    setTimeout(() => {
      this.sidenavPosition = position === 'start' ? 'end' : 'start';
      this.swapRight = position === 'start' ? false : true;
      this.fadeOut = false;
    }, 500);

    // Move scrollbar to left when content swaps position
    const dialogContainer = (document.querySelector('mat-dialog-container') as HTMLElement);
    dialogContainer.style.direction = this.swapRight ? 'rtl' : 'ltr';

    const drawerContainer = (document.querySelector('mat-drawer-container') as HTMLElement);
    drawerContainer.style.direction = 'initial';

    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: 'change_sidenav_position',
        pageId: event.view?.location.hash
      }
    };

    this.gtagService.event(info);
  }

  showModulo(modulo: any, index: number, event: PointerEvent) {
    const info = {
      event: 'select_content',
      selectContent: {
        contentType: 'modulo',
        pageId: event?.view?.location.hash,
        contentName: modulo.propiedades.titulo_plano,
        contentId: modulo.id
      }
    };

    this.gtagService.event(info);

    modulo = this.setActividad(modulo);
    this.lastSelection = index;

    if (modulo.propiedades.tipoActividad && modulo.propiedades.tipoActividad.valor.player) {
      const autonoma = true;
      this.scormService.API_1484_11._preInitialize(
        window,
        this.scormManagementService,
        {
          idMateria: this.data.materiaId,
          idTema: this.data.temaId,
          idRecurso: modulo.id,
          autonoma,
          rol: this.authManagementService.getRolPrimario(),
          typeScorm: modulo.propiedades.tipoActividad.valor.player
        },
        null);
    }

    this.urlSafe = this.formarUrl(modulo);
    // Change overflow to avoid bottom margin
    this.renderer2['addClass'](this.content.elementRef.nativeElement, 'iframe-overflow');
  }

  setActividad(modulo): any {
    if (modulo.propiedades.tipoActividad &&
      modulo.propiedades.tipoActividad.valor.player &&
      modulo.propiedades.tipoActividad.valor.player.includes('fs1://') &&
      !modulo.propiedades.actividad) {
      const paso1 = modulo.propiedades.tipoActividad.valor.player.replace('fs1://', '');
      const paso2 = paso1.replace('.zip', '');
      const paso3 = `/Contenido/${paso2}/Principal.html?_preload_data=/Contenido/Modulos/${modulo.id}/`;
      console.error(`El modulo ${modulo.id} viene con la url del SCORM mal formada`);
      modulo.propiedades.actividad = paso3;
    }
    return modulo;
  }

  formarUrl(modulo) {
    this.badUrl = false;
    let urlActividad = modulo?.propiedades?.actividad;
    if (modulo?.propiedades?.actividad) {
      this.expositor = false;
      urlActividad =
        // (modulo?.propiedades?.tipoRecursoPropio?.idValor === 3) ? urlActividad : this.envService.rootContent + urlActividad;
        (modulo?.propiedades?.tipoRecursoPropio?.idValor === 3) ? urlActividad : this.envService.rootContentNoCDN + urlActividad;
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlActividad);
    } else {
      this.badUrl = true;
      this.expositor = false;
      console.error('urlActividad no informada');
    }
  }

  showExpositor(event: PointerEvent) {
    this.expositor = true;
    this.badUrl = false;
    // Allow scroll overflow on expositor returning from iframe
    this.renderer2['removeClass'](this.content.elementRef.nativeElement, 'iframe-overflow');

    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: 'expositor_home',
        pageId: event.view?.location.hash
      }
    };

    this.gtagService.event(info);
  }

  showNextModulo(event: PointerEvent) {
    this.badUrl = false;
    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: 'next_module',
        pageId: event.view?.location.hash
      }
    };

    this.gtagService.event(info);

    this.showModulo(this.data.recursos[this.lastSelection], this.lastSelection + 1, event);
  }

  showPreviousModulo(event: PointerEvent) {
    this.badUrl = false;
    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: 'previous_module',
        pageId: event.view?.location.hash
      }
    };

    this.gtagService.event(info);

    this.showModulo(this.data.recursos[this.lastSelection - 2], this.lastSelection - 1, event);
  }

  routeToDrawer(event: PointerEvent) {
    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: 'show_drawer',
        pageId: event.view?.location.hash
      }
    };

    this.gtagService.event(info);
    const dialogRef = this.dialog.open(DrawerDialogComponent, {
      data: {
        sidenavPosition: this.sidenavPosition
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'drawer-dialog',
      restoreFocus: true
    });
    dialogRef.afterClosed().pipe(
      take(1),
      takeUntil(this.destroy$)).subscribe(result => {
        dialogRef.close();
      });
  }

  isRecursoInlineWithIcon(module) {
    return module.comunes.tipoModulo.idValor === 7
      && (module.propiedades.modoVisualizacion?.modo === 'Inline' || module.propiedades.modoVisualizacion?.modo === 'recursoInline')
      && !module.propiedades.imagen_previa;
  }

}
