<div #videoWrapper class="video-wrapper" [ngClass]="isTranscripcionOpen ? 'video-wrapper-open': 'cerrado'"
    [class.is-fullscreen]="isInFullScreenMode">
    <ng-container *ngIf="selectedVideoSource?.youtube">
        <iframe [src]="selectedVideoSource.source | safe:'resourceUrl'"></iframe>
    </ng-container>
    <ng-container *ngIf="!selectedVideoSource?.youtube">
        <video #video (loadeddata)="onVideoLoaded()" (playing)="onVideoPlaying()" (timeupdate)="onVideoTimeUpdated()"
            (ended)="onVideoEnded()" crossorigin="anonymous" playsinline>
            Sorry, Your browser does not support HTML5 video feature
            <source type="video/mp4" [src]="selectedVideoSource.source | safe:'resourceUrl'" />
            <source type="audio/mp3" [src]="selectedVideoSource.source | safe:'resourceUrl'" />
            <track *ngIf="subtitles" label="Subtítulos en Español" kind="captions" srclang="es" [src]="subtitles"
                disabled />
        </video>
    </ng-container>

    <!-- <div class="video-poster" *ngIf="!selectedVideoSource?.youtube && selectedVideoSource.poster && currentTime === 0">
      <img [src]="selectedVideoSource.poster" alt="poster" />
  </div> -->
    <!-- <div id="video-icon-container" class="icon-poster d-flex align-items-center justify-content-center w-100 h-100">

  </div> -->

    <div class="icon-poster" *ngIf="showIcon && modulo.comunes.tipoModulo.valor !== 'audio'">

        <div class="w-100 h-100 d-flex align-items-center justify-content-center play-container"
            [ngClass]="!selectedVideoSource.poster ? 'custom-bg__regular' : ''">
            <button mat-icon-button class="play-button cursor-pointer" (click)="handleStartVideo()">
                <mat-icon *ngIf="!selectedVideoSource.poster" class="white-icon"
                    fontSet="material-icons-round">play_arrow</mat-icon>
                <mat-icon *ngIf="selectedVideoSource.poster"
                    class="white-icon play-button__poster-icon">play_arrow</mat-icon>
            </button>
        </div>
        <img *ngIf="selectedVideoSource.poster" class="w-100 h-100 position-absolute" [src]="selectedVideoSource.poster"
            alt="poster" />
        <div *ngIf="!selectedVideoSource.poster" class="w-100 h-100 white-bg position-absolute"></div>

    </div>

    <div *ngIf="!selectedVideoSource?.youtube" #videoOverlay class="video-overlay" [class.in]="currentTime === 0"
        (mousemove)="clearOverlayTimeout(true)">
        <div class="video-overlay-header" *ngIf="!config.onlyAudio">{{selectedVideoSource.title}}</div>
        <div class="video-overlay-buttons" (click)="togglePlayingState($event)" *ngIf="!config.onlyAudio">
            <div id="video-icon-transition" class="position-absolute play-icon d-none">
                <mat-icon *ngIf="!isPlaying" class="white-icon">play_arrow</mat-icon>
                <mat-icon *ngIf="isPlaying" class="white-icon">pause</mat-icon>
            </div>


            <!-- <div class="container-play-pause-button" >
        <button *ngIf="!isPlaying && !showIcon" mat-icon-button class="play-button">
          <mat-icon fontSet="material-icons-round">play_arrow</mat-icon>
        </button>
        <button *ngIf="isPlaying && !showIcon"mat-icon-button class="pause-button">
          <mat-icon fontSet="material-icons-round">pause</mat-icon>
        </button>
      </div>
      -->

        </div>

        <div class="video-overlay-footer d-flex" [ngClass]="modulo.comunes.tipoModulo.valor !== 'audio' ? 'video' : ''"
            *ngIf="currentTime > 0 || config.onlyAudio">
            <div class="col video-custom-controls">
                <div>
                    <div class="seek-bar" (click)="onSeek($event)" (mousemove)="onSeeking($event)">
                        <div class="seek-bar-progress" [style.width]="seekBarPercentage + '%'">
                            <div class="seek-bar-handle"></div>
                        </div>
                        <div class="buffer-progress" [style.width]="bufferPercentage + '%'"></div>
                    </div>
                </div>

                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center container-mini-button">


                        <div (click)="togglePlayingState($event)">
                            <button *ngIf="!isPlaying" mat-icon-button class="play-button">
                                <mat-icon fontSet="material-icons-round">play_arrow</mat-icon>
                            </button>
                            <button *ngIf="isPlaying" mat-icon-button class="pause-button">
                                <mat-icon fontSet="material-icons-round">pause</mat-icon>
                            </button>
                        </div>


                        <div>
                            <div class="vol-open">

                                <button mat-icon-button (click)="abreVolumen()" class="volumen"
                                    >
                                    <mat-icon fontSet="material-icons-round">volume_up</mat-icon>
                                </button>
                                <mat-slider *ngIf="sliderVol === 'abierto'" [min]="0" [max]="1" [step]="0.1"
                                    (input)="cambiaVol($event)">
                                    <input matSliderThumb [(ngModel)]="volume" #slider>
                                </mat-slider>
                            </div>
                        </div>

                        <div class="pe-2 font-weight-normal time-container">{{currentTime | secsToMins}} / {{duration |
                            secsToMins}}</div>

                    </div>

                    <div class="container-button-fullscreen">
                        <div *ngIf="modulo.propiedades.fichero_subtitulos">
                            <button mat-icon-button class="button-subtitles" [disabled]="isTranscripcionOpen"
                                [ngClass]="isTranscripcionOpen ? 'abierto': 'cerrado' "
                                (click)="ToggleTranscripcionAulaPlaneta()">
                                <mat-icon>subtitles</mat-icon>
                            </button>
                        </div>
                        <div class="me-2">
                            <!-- <button *ngIf="modulo?.comunes?.tipoModulo?.valor !== 'audio'" mat-icon-button class="fullscreen-button" (click)="toggleFullscreen()"> -->
                            <button *ngIf="modulo?.comunes?.tipoModulo?.valor !== 'audio'" mat-icon-button
                                (click)="toggleFullscreen()">
                                <mat-icon>fullscreen</mat-icon>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="w-100 d-flex flex-column justify-content-between transcription-container animation"
    *ngIf="isTranscripcionOpen && config.onlyAudio == true"
    [ngClass]="modulo.propiedades.visible === 'Profesores' ?  'grey-bg__light' : 'white-bg'">

    <div class="w-100 d-flex justify-content-between align-items-center"
        [ngClass]="[config.component === 1 && config.onlyAudio ?  'area-position' : '', config.component === 2 && config.onlyAudio ?  'cuaderno-position' : '']">

        <button mat-icon-button
            class=" close-button position-relative default-button default-button--xs default-button--circle default-button__primary--transparent"
            (click)="ToggleTranscripcionAulaPlaneta()">
            <mat-icon class="close-icon custom-text">close</mat-icon>
        </button>
    </div>
    <div class="transcripcion-texto mt-2">
        <div #audioSubtitles class="transcripcion body-3">
            {{formatSubtitles}}
        </div>
    </div>

</div>