import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NotificationService } from '../notifications/notification.service';
import { ProbemDetails } from '../../shared/models/problem-details.model';
import { Router } from '@angular/router';
import { AuthManagementService } from '../auth/auth-management.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Title } from '@angular/platform-browser';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(
    private notificationsService: NotificationService,
    private localStorageService: LocalStorageService,
    private authManagementService: AuthManagementService,
    private router: Router
  ) {
    super();
  }

  handleError(httpErrorResponse: HttpErrorResponse | ProbemDetails | any) {
    const err = (httpErrorResponse && httpErrorResponse.error && httpErrorResponse.error.title) || httpErrorResponse.message;
    if (httpErrorResponse.error) {

      if (httpErrorResponse?.error?.instance?.includes('ActualizarScorm') || httpErrorResponse?.url?.includes('ActualizarScorm')) {
        httpErrorResponse.error.instance = 'api/actividad-asignada/ActualizarScorm';
      }
      switch (httpErrorResponse.error.instance) {
        case '/api/contenido/reto-mensual':
          break;
        case 'api/actividad-asignada/ActualizarScorm':
          break;
        default:
          if (httpErrorResponse.status === 307 && httpErrorResponse.error[0]?.title) {
            this.notificationsService.error(httpErrorResponse.error[0]?.title);
            this.router.navigate(['/inicio']);
            break;
          }
          if (httpErrorResponse.status !== 406) {
            this.notificationsService.error(err);
          }
          break;
      }
      super.handleError(httpErrorResponse);
      return;
    } else {

      if (this.authManagementService.redirectUrl && this.authManagementService.redirectUrl.includes('alive')) {
        return;
      }
      switch (httpErrorResponse.status) {
        case 401:
          this.authManagementService.authLogoutNotClean(
            this.authManagementService.redirectUrl ? this.authManagementService.redirectUrl : this.router.url);
          localStorage.setItem('redirectUrl', this.authManagementService.redirectUrl);
          // setTimeout(() => {
          //   this.localStorageService.clearDB();
          //   localStorage.clear();
          //   location.reload();
          // }, 1500);
          break;
        case 403:
          // this.notificationsService.error('No estás autorizado a realizar esta petición');
          this.authManagementService.authLogout();
          break;
        case 404:
          this.notificationsService.error(err);
          break;
        case 400:
          this.notificationsService.error(err);
          break;
        case 406:
          break;
        default:
          // EVITAR MOSTRAR ERROR DE CORS
          if (httpErrorResponse.code === httpErrorResponse.SECURITY_ERR) {
            break;
          }
          this.notificationsService.error(err);
          break;
      }
    }

    super.handleError(httpErrorResponse);
  }
}
