import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DateHelper } from '../../../helper/date.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';


@Component({
  selector: 'aula-planeta-repetir-tarea',
  templateUrl: './repetir-tarea.component.html',
  styleUrls: ['./repetir-tarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepetirTareaComponent implements OnInit {

  form: UntypedFormGroup;
  minDate = new Date(Date.now());
  maxDate = new Date(2025, 0, 1);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dateHelper: DateHelper,
    public dialogRef: MatDialogRef<RepetirTareaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngOnInit() {
    // this.minDate = this.data.fechaInicio;
/*
    this.form = this.formBuilder.group({
      fechaInicio: this.formBuilder.control(this.dateHelper.getOffsetLocal(this.data.periodo.fechaInicio), [Validators.required]),
      fechaFin: this.formBuilder.control(this.dateHelper.getOffsetLocal(this.data.periodo.fechaFin), [Validators.required]),
      comentario: this.formBuilder.control('', [Validators.maxLength(255)]),
    });
*/
    this.form = this.formBuilder.group({
      fechaInicio: this.formBuilder.control(this.minDate, [Validators.required]),
      fechaFin: this.formBuilder.control(this.maxDate, [Validators.required]),
      comentario: this.formBuilder.control('', [Validators.maxLength(255)]),
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'repetir tarea',
        popupId: 'dialog-repetir-tarea',
        popupName: 'popup_repetir_tarea',
      }
    };
    this.gtagService.event(info);
  }

  onSubmit() {
    if (this.form.valid) {
      const fechas = this.form.getRawValue();
      this.dialogRef.close({ fechas });
      
    }
  }
}
