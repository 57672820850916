/* eslint-disable max-len */
import { MateriaManagementService } from '../../../../services/data/materia/materia-management.service';
import { tabAnimations } from '../../../../core/animations/tab.animations';
import { Component, ChangeDetectionStrategy, OnDestroy, ElementRef, Renderer2, HostListener, AfterViewInit, SecurityContext } from '@angular/core';
import { transition, trigger, animate, state, style } from '@angular/animations';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, filter, take } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { AuthManagementService } from '../../../../core/auth/auth-management.service';
import { TemasManagementService } from '../../../../services/data/temas/temas-management.service';
import { VistaAlumnoService } from '../../../../services/vista-alumno.service';
import { EditionModeService } from '../../../../services/edition-mode.service';
import { ColorHelper } from '../../../../shared/helper/color.helper';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ScormService } from '../../../../core/scorm/scorm.service';
import { ModulosOrganizacion, Adjunto } from '../../../../shared/models/tema/modulo.model';
import { ScormManagementService } from '../../../../services/data/scorm/scorm-management-service.service';
import { EditarRecursoComponent } from '../../../../shared/components/dialogs/editar-recurso/editar-recurso.component';
import { EnvService } from '../../../../services/env.service';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { LtiManagementService } from '../../../../services/data/lti/lti-management.service';
import { ViewEncapsulation } from '@angular/core';
import { SmartlinkManagementService } from '../../../../services/data/smartlink/smartlink-management-service.service';
import { LocalStorageService } from '../../../../core/core.module';

import { TitleService } from '../../../../core/title/title.service';
import { TareaManagementService } from '../../../../services/data/tarea/tarea-management.service';
import { DateHelper } from '../../../../shared/helper/date.helper';
import { Tema } from '../../../../shared/models/tema/tema.model';
import { ScrollService } from '../../../../core/scroll/scroll.service';

@Component({
  selector: 'aula-planeta-recurso-moodle',
  templateUrl: './recurso-moodle.component.html',
  styleUrls: ['./recurso-moodle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [tabAnimations,
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('800ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('800ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(800, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(800, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class RecursoMoodleComponent implements OnDestroy {
  recurso: ModulosOrganizacion;
  vistaAlumno: any;

  idMateria: string;
  idTema: string;
  idRecurso: string;

  noData: Observable<any>;
  isScrolling = false;
  moreDataHeader = false;
  moreChips = false;
  openedScorm = false;
  moreDocs = false;

  breadcrumb = [];
  actividad: any = {};
  startedActivity = false;

  private _adjuntos: BehaviorSubject<Adjunto[]> = new BehaviorSubject<Adjunto[]>([]);
  adjuntos$ = this._adjuntos.asObservable();

  private _metadatos: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  metadatos$ = this._metadatos.asObservable();

  nivelesDificultad = ['Aprendiz', 'Avanzado', 'Experto', 'Novel'];
  urlSafe: SafeResourceUrl;
  showSpinner = false;
  pageId: string;
  isYoutube = false;
  isExternalResource = false;

  /**
   * Use to destroy and prevent memory leaks
   */
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('iframe', { read: ElementRef }) iframe: ElementRef;

  @HostListener('window:message', ['$event']) onPostMessage(value) {
    this.scormService.actionFromPostMessage(value.data?.action, value.data?.value);
  }

  constructor(
    public authManagementService: AuthManagementService,
    public breadcrumbService: BreadcrumbService,
    public colorHelper: ColorHelper,
    public dialog: MatDialog,
    public editionModeService: EditionModeService,
    public localStorageService: LocalStorageService,
    public ltiManagementService: LtiManagementService,
    public materiaManagementService: MateriaManagementService,
    public render2: Renderer2,
    public router: Router,
    public scormService: ScormService,
    public smartlinkManagementService: SmartlinkManagementService,
    public tareaManagementService: TareaManagementService,
    public temasManagementService: TemasManagementService,
    public translateService: TranslateService,
    public vistaAlumnoService: VistaAlumnoService,
    private activatedRoute: ActivatedRoute,
    private dateHelper: DateHelper,
    private envService: EnvService,
    private gtagService: GtagService,
    private sanitizer: DomSanitizer,
    private scormManagementService: ScormManagementService,
    private titleService: TitleService,
    private scrollService: ScrollService
  ) {

    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        this.idMateria = params['id'];
        this.idTema = params['idTema'];
        this.idRecurso = params['idRecurso'];
        this.materiaManagementService.getMateria(this.idMateria);
        this.temasManagementService.getTema(this.idTema);
        this.temasManagementService.getRecurso(this.idTema, this.idRecurso, this.ltiManagementService.contextId);
      });

    this.temasManagementService.tema$
      .pipe(takeUntil(this.destroy$))
      .subscribe(tema => {
        if (tema) {
          this.temasManagementService.getRecurso(this.idTema, this.idRecurso, this.ltiManagementService.contextId);
        }
      });

    this.temasManagementService.recurso$
      .pipe(takeUntil(this.destroy$))
      .subscribe(recurso => {
        if (recurso) {
          this.recurso = recurso;
          this.isYoutube = this.recurso.propiedades.actividad.match(/(?:youtu|youtube)(?:\.com|\.be)\/([\w\W]+)/i) ? true : false;
          this.isExternalResource = this.recurso.propiedades.actividad.match(/(http(s?)):\/\//i) ? true : false;
          this.prepareMetadatos(this.recurso.metadatos);
          this._adjuntos.next(this.filterAttached(recurso.propiedades.adjuntos, this.authManagementService.currentUser.usuarioRoles.includes('Alumno')));
          this.actividad.nombre = recurso.propiedades.titulo_html;

          if (recurso.propiedades.tipoActividad && recurso.propiedades.tipoActividad.valor.player) {

            // console.log('this.idMateria1: ', this.idMateria);

            const autonoma = true;
            this.scormService.API_1484_11._preInitialize(
              window,
              this.scormManagementService,
              {
                idMateria: this.idMateria,
                idTema: this.idTema,
                idRecurso: this.idRecurso,
                autonoma,
                rol: this.authManagementService.getRolPrimario(),
                typeScorm: recurso.propiedades.tipoActividad.valor.player
              },
              null);
          } else {
            // this.showSpinner = true;
          }
          this.startedActivity = !(this.authManagementService.currentUser.usuarioRoles.includes('Alumno') && !(recurso.propiedades.tipoActividad && this.recurso.propiedades.tipoActividad.valor.player));

          this.urlSafe = this.formarUrl(recurso.propiedades.actividad);

          this.titleService.setTitle(
            this.router.routerState.snapshot.root,
            this.translateService,
            recurso.propiedades.titulo_plano
          );
        }
      });


    this.materiaManagementService.materia$
      .pipe(takeUntil(this.destroy$))
      .subscribe((materia) => {
        if (materia) {
          this.actividad.color = materia.color;
          console.log(this.temasManagementService.tema);

          this.breadcrumb = [
            {
              value: `${materia.curso.ordinal}º ${materia.curso.etapa.acronimo === 'EP' ? materia.curso.etapa.nombre : materia.curso.etapa.acronimo}`, color: 'grey-text', fixed: true, visible: true
            },
            {
              value: materia.nombre, color: 'grey-text', fixed: true, visible: true, link: `/inicio/mis-materias/materia/${materia.id}`
            },
            {
              value: materia.temas.filter(tema => tema.id === this.idTema).map(tema => tema.tituloHtml), color: materia.color,
              fixed: true, visible: true, link: `/inicio/mis-materias/materia/${materia.id}/tema/${this.idTema}`
            },
          ];
          this.loadBreadcrumbData(materia);
        }
      });

    this.vistaAlumnoService.vistaAlumno$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isVistaAlumnos => {
        if (this.recurso) {
          this._adjuntos.next(isVistaAlumnos ? this.filterAttached(this.recurso.propiedades.adjuntos, isVistaAlumnos) : this.recurso.propiedades.adjuntos);
        }
      });

    const infoPage = {
      event: 'page_view',
      pageView: {
        imPage: 'recurso',
        pageId: 'recurso',
        imPagetitle: 'Recurso',
        pageTitle: 'Recurso'
      }
    };
    this.gtagService.pageview(infoPage);
  }


  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this.scormService.openLinkBanco(event);
  }

  formarUrl(urlActividad: string) {
    if (!urlActividad) {
      urlActividad = '/assets/images/not-found-ap.png';
    } else {
      if (!this.isYoutube && !this.isExternalResource) {
        if (this.recurso.propiedades.tipoActividad?.valor?.player === 'mauthor') {
          urlActividad = this.envService.rootContent + `/Contenido/Wrapper/wrapper.html?ruta=${this.envService.rootContent}${urlActividad}&tipo=mauthor`;
        } else {
          urlActividad = (this.recurso?.propiedades?.tipoRecursoPropio?.idValor === 3) ? urlActividad : this.envService.rootContentNoCDN + urlActividad;
        }
      }
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlActividad);
    }

    if (this.ltiManagementService.moodle) {
      if (urlActividad.includes('?')) {
        urlActividad += '&mode=browse';
      } else {
        urlActividad += '?mode=browse';
      }
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(urlActividad);
  }
  private loadBreadcrumbData(item) {
    if (item) {
      this.breadcrumbService.offSetBreadcrumb = 0;
      this.breadcrumbService.breadcrumbs = this.breadcrumb;
    };
  }

  ngOnDestroy() {
    this.openedScorm = false;
    this.temasManagementService.fullscreen = this.openedScorm;
    const scormElement = (document.querySelector('.wrapper .content') as HTMLElement);
    scormElement.removeAttribute('style');
    this.temasManagementService.recurso = null;
    this.breadcrumbService.breadcrumbs = [];
    this.destroy$.next();
    this.destroy$.complete();
  }

  // ************** Funciones de componente  ************
  link(enlace, event: PointerEvent) {
    const formatZip = enlace.split('zip://');
    if (formatZip && formatZip.length > 1) {
      formatZip.forEach((element, index) => {
        if (index === 0) {
          enlace = `${formatZip[0]}${this.envService.rootContent}/Contenido/Modulos/${this.idRecurso}/${formatZip[1]}`;
        }
        if (index > 1) {
          enlace = `${enlace}${this.envService.rootContent}/Contenido/Modulos/${this.idRecurso}/${element}`;
        }
      });
    }

    window.open(`${enlace}`, '_blank');

    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: 'launch',
        pageId: event.view.location.hash,
      }
    };
    this.gtagService.event(info);
  }
  // ************** Refrescar datos tabla manualemente ************
  enableEditionMode($event) {
    $event.stopPropagation();
    this.editionModeService.enable = true;
    this.editionModeService.headerData = { titulo: this.actividad.nombre, color: this.actividad.color };
  }

  loadIframe() {
    this.showSpinner = true;
    if (this.iframe) {
      this.showSpinner = false;
      this.eventListeners();
    }

    if (this.iframe?.nativeElement) {
      const iframeUrl: string = this.sanitizer.sanitize(SecurityContext.URL, this.urlSafe) || '';

      if (!iframeUrl.includes('wrapper')) {
        const doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;

        if (typeof doc.addEventListener !== undefined) {
          doc.addEventListener('click', (event: any) => {
            this.scormService.openLinkBanco(event);
          }, false);
        } else if (typeof doc.attachEvent !== undefined) {
          doc.attachEvent('onclick', (event: any) => {
            this.scormService.openLinkBanco(event);
          });
        }
      }

      this.iframe.nativeElement.style.background = null;

      const domIframe = document.getElementById('material') as any;

      const imgvalue = this.recurso && this.recurso.propiedades && this.recurso.propiedades.tipoRecursoPropio && this.recurso.propiedades.tipoRecursoPropio.idValor === 4;

      if (imgvalue) {

        const iframeElement = this.render2.selectRootElement('#material');
        const iframeDocument = iframeElement.contentDocument || iframeElement.contentWindow.document;
        const imgElement = iframeDocument.querySelector('img');

        if (imgElement) {
          this.render2.setStyle(imgElement, 'max-width', '550px');
          this.render2.setStyle(imgElement, 'margin', 'auto');
        }
      }

      // if ( TODO: el back me dirá si el recurso en NAS está disponible) {
      if (false) {
        const div = this.render2.createElement('div');
        this.render2.setStyle(div, 'background-color', '#F5F5F5');
        this.render2.setStyle(div, 'width', '100%');
        this.render2.setStyle(div, 'height', '100% ');
        this.render2.setStyle(div, 'min-height', '400px');
        this.render2.setStyle(div, 'border-radius', '10px');
        this.render2.setStyle(div, 'display', 'flex');
        this.render2.setStyle(div, 'justify-content', 'center');
        this.render2.setStyle(div, 'align-items', 'center');
        this.render2.addClass(div, 'p-5');
        this.render2.addClass(div, 'default-text');
        this.render2.addClass(div, 'font-weight-bold');

        const p = this.render2.createElement('p');
        this.render2.setStyle(p, 'text-justify', 'center');
        this.render2.setProperty(p, 'innerHTML', `<a href=https://www.aulaplaneta.com/contacto>${this.translateService.instant('seguimiento-alumno.actividad.error.no-encontrado-recurso')}</a>`);
        this.render2.appendChild(div, p);


        this.render2.appendChild(document.getElementById('ifrcontainer'), div);
        this.render2.removeChild(document.getElementById('ifrcontainer'), domIframe);
      }
    }
  }


  eventListeners() {

    window.API_1484_11.cmi = {
      ...window.API_1484_11.cmi,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // 'cmi.suspend_data': 'void',
    };

    const iframe = document.getElementById('material') as any;
    const _a = window.API_1484_11.cmi as any;

    iframe.contentWindow.postMessage(_a ?? {}, '*');
  }


  openScorm(event: PointerEvent) {
    this.openedScorm = !this.openedScorm;
    this.temasManagementService.fullscreen = this.openedScorm;

    const scormElement = (document.querySelector('.wrapper .content') as HTMLElement);
    scormElement.style.marginTop = this.openedScorm ? '0' : '5em';

    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: this.openedScorm ? 'expand_scorm' : 'shrink_scorm',
        pageId: event.view.location.hash,
      }
    };
    this.gtagService.event(info);
  }

  realizarActividad(isAutonoma, modulo) {
    this.startedActivity = true;
    // this.tareaManagementService.realizarActividad({ isAutonoma, idActividad, idTarea });
  }

  editar(modulo, event: PointerEvent) {

    if (modulo.comunes.tipoModulo.valor === 'recurso' && !modulo.autor) {
      this.temasManagementService.duplicarModulo(modulo.id).pipe(
        take(1),
        takeUntil(this.destroy$))
        .subscribe(nuevoModulo => {
          if (nuevoModulo) {
            nuevoModulo.comunes.tipoModulo.idValor = 7;
            nuevoModulo.modulosOrganizacion = [];
            nuevoModulo.propiedades.visible = 'Alumnos';
            nuevoModulo.temporal = true;
            nuevoModulo.autor = {
              id: this.authManagementService.currentUser.persona.id,
              nombre: `${this.authManagementService.currentUser.persona.nombre} ${this.authManagementService.currentUser.persona.apellidoUno} ${this.authManagementService.currentUser.persona.apellidoDos}`
            };
            this.temasManagementService.tema.modulosOrganizacion =
              this.editionModeService.modificarModulo(this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo.propiedades, modulo.id, this.editionModeService.moduloSeleccionado.id, true);
            this.editando(nuevoModulo);
          }

        });
    } else {
      this.editando(modulo);
    }

    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: (event.target as any).id,
        pageId: event.view.location.hash
      }
    };
    this.gtagService.event(info);
  }

  editando(modulo) {
    this.scrollService.bloqueateScroll();
    const dialogRef = this.dialog.open(EditarRecursoComponent, {
      data: modulo,
      restoreFocus: true,
      disableClose: true
    });
    dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(propiedades => {
      this.scrollService.desbloqueateScroll();
      if (propiedades) {
        this.temasManagementService.tema.modulosOrganizacion =
          this.editionModeService.modificarModulo(
            this.temasManagementService.tema.modulosOrganizacion, null, propiedades, modulo.id, null, true);
        this.editionModeService.recalculoIndicesOrden(this.temasManagementService.tema);
        this.temasManagementService.tema = { ...this.temasManagementService.tema } as any;
        this.temasManagementService.updateTema(this.temasManagementService.tema, parseInt(this.idMateria, 10));
      }

    });
  }

  filterAttached(adjuntos, vistaAlumno) {
    if (!adjuntos) {
      return [];
    }
    return adjuntos.filter(doc => !vistaAlumno || (vistaAlumno && doc.visible_alumno));
  }

  prepareMetadatos(meta) {
    if (meta) {
      const metadatos = [];
      if (meta.duracionNum?.valor && meta.duracionTexto?.valor) {
        metadatos.push(`Tiempo: ${meta.duracionTexto.valor}`);
      }
      if (meta.tipoMedia?.length > 0) {
        meta.tipoMedia.forEach(tipoMedia => {
          if (tipoMedia.valor) {
            metadatos.push(`${tipoMedia.valor}`);
          }
        });
      }

      this._metadatos.next((metadatos.length > 0) ? metadatos : null);
    }
  }

  gtagViewInfo(event: PointerEvent) {
    this.pageId = event.view.location.hash;
    const info = {
      event: 'open_menu',
      openMenu: {
        menuId: 'view-menu',
        pageId: this.pageId
      }
    };
    this.gtagService.event(info);
  }

  changeVistaAlumno(event) {
    const infoMenu = {
      event: 'select_menu_item',
      selectMenuItem: {
        menuItemName: event,
        menuId: 'view-menu',
        pageId: this.pageId
      }
    };
    this.gtagService.event(infoMenu);
  }

  gtagInfo(event: PointerEvent, expanded: boolean) {
    if (expanded) {
      const info = {
        event: 'open_menu',
        openMenu: {
          menuId: 'description',
          pageId: event.view.location.hash
        }
      };
      this.gtagService.event(info);
    }
  }

  getNivelDificultad(i) {
    if (this.recurso.metadatos?.nivelDificultad?.valor) {
      const valor = this.nivelesDificultad.indexOf(this.recurso.metadatos?.nivelDificultad?.valor);
      return i <= valor && valor <= 2;
    }
    return false;
  }

  isModuloPersonalizado() {
    const regex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/g;
    const isPersonalizado = this.recurso?.id?.match(regex);
  }

  asignarTareaRapidaMoodle(event?: PointerEvent) {
    const fechaActual = new Date(Date.now());
    const fechaFin = new Date(new Date(Date.now()).setDate(fechaActual.getDate() + 7));

    const data = {
      fechaInicioVigencia: this.dateHelper.setToUTC(this.dateHelper.setFechaInicio(fechaActual)),
      fechaFinVigencia: this.dateHelper.setToUTC(this.dateHelper.setFechaInicio(fechaFin)),
      moduloId: this.recurso.id,
      titulo: this.recurso.propiedades.titulo_plano,
      comentario: '',
      temaId: this.temasManagementService.tema.id,
      materiaId: this.materiaManagementService.materia.id,
      contextId: this.ltiManagementService.contextId
    };
    this.tareaManagementService.createTareaMoodle(data, this.ltiManagementService.contextId);

    const info = {
      event: 'popup_steps',
      popupSteps: {
        action: 'añadir',
        pageId: event?.view?.location.hash,
        popupId: 'dialog-asignar-tarea-moodle',
        popupName: 'popup_asignar_tarea'
      }
    };
    this.gtagService.event(info);
  }

  addRecursoMoodle(event, tema?: Tema) {

    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: 'add_recurso_moodle',
        pageId: event?.view?.location.hash,
        attributes: {
          unidadId: this.temasManagementService.tema.id,
          moodleUserId: this.authManagementService.currentUser.moodleUserId,
          moduloId: this.recurso.id
        }
      }
    };
    this.gtagService.event(info);

    console.log(info);

    const urlMoodleEnvioRecurso = this.authManagementService.currentUser.colegio.consumer.urlEnvioRecurso.split('?')[0];
    // eslint-disable-next-line max-len
    window.open(`${urlMoodleEnvioRecurso}?resourceid=${this.recurso.id}&resourcetype=${(this.recurso.autor ? 'M' : 'A')}&studyid=${this.temasManagementService.tema.id}&subjectid=${this.materiaManagementService.materia.id}`, '_blank');
  }

  gtagMenuInfo(event: PointerEvent) {

    const info = {
      event: 'open_menu',
      openMenu: {
        menuId: (event.currentTarget as HTMLElement).id,
        pageId: event.view.location.hash
      }
    };
    this.gtagService.event(info);
  }

  gtagItem(event: PointerEvent, menuId: string) {
    const infoMenu = {
      event: 'select_menu_item',
      selectMenuItem: {
        menuItemName: (event.currentTarget as HTMLElement).getAttribute('aria-label'),
        menuId: menuId,
        pageId: event.view.location.hash
      }
    };
    this.gtagService.event(infoMenu);
  }
}
