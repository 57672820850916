import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'aula-planeta-editar-metadatos',
  templateUrl: './editar-metadatos.component.html',
  styleUrls: ['./editar-metadatos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditarMetadatosComponent implements OnInit {

  dificultades: any[] = [
    { value: 'nivel 1', viewValue: 'nivel 1' },
    { value: 'nivel 2', viewValue: 'nivel 2' }
  ];

  acciones: any[] = [
    { value: 'accion 1', viewValue: 'accion 2' },
    { value: 'accion 2', viewValue: 'accion 2' }

  ];

  tiempos: any[] = [
    { value: 'tiempo 1', viewValue: 'tiempo 1' },
    { value: 'tiempo 2', viewValue: 'tiempo 2' }
  ];

  niveles1: any[] = [
    { value: 'nivel 1', viewValue: 'nivel 1' },
    { value: 'nivel 2', viewValue: 'nivel 2' }
  ];

  niveles2: any[] = [
    { value: 'nivel 1', viewValue: 'nivel 1' },
    { value: 'nivel 2', viewValue: 'nivel 2' }
  ];

  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditarMetadatosComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data) { }


  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required]],
      dificultades: ['', [Validators.required]],
      acciones: ['', [Validators.required]],
      tiempos: ['', [Validators.required]],
      niveles1: ['', [Validators.required]],
      niveles2: ['', [Validators.required]]

    });
  }

  close(result) {
    this.dialogRef.close(result);
  }
}
