<div *ngIf="!(ltiManagementService.preview$ | async) && !(temasManagementService.contextView$ | async)" id="annotations" class="position-absolute d-flex flex-column align-items-center" [ngClass]="show ? 'show' : ''">
    <div *ngIf="(anotaciones$ | async) as anotacion">
        <button attr.aria-label="{{'dialogs.add-annotations.add' | translate }}" *ngIf="anotacion.length === 0" mat-icon-button class="custom-icon" (mouseover)="show=true" (mouseleave)="showAnnotations ? show=true : show=false" (click)="openDialog($event)">
            <mat-icon class="custom-icon">note_add</mat-icon>
        </button>
        <button attr.aria-label="{{'dialogs.add-annotations.edit' | translate }}" *ngIf="anotacion.length === 1" mat-icon-button class="custom-icon default-badge" (mouseover)="show=true" (mouseleave)="showAnnotations ? show=true : show=false" (click)="toggleShowList($event, id)">
            <mat-icon class="custom-icon">insert_drive_file</mat-icon>
        </button>

        <div *ngIf="anotacion.length === 1" class="position-absolute annotations-list mat-elevation-z3" [ngClass]="[showAnnotations ? 'open' : '', last ? 'top': '']">
            <div class="annotations-list__header custom-bg__regular p-3 d-flex flex-column">
                <h5 class="mb-0">{{'dialogs.add-annotations.title-list' | translate}}</h5>
            </div>
            <mat-list id="annotations-list" class="overflow-auto py-0 annotations-list__list">
                <mat-list-item *ngFor="let item of anotacion;">
                    <div class="d-flex justify-content-between w-100 align-items-center">
                        <p (click)="openDialog($event, item)" class="description grey-text m-0" matLine>{{item.texto}}</p>
                        <button mat-icon-button class="custom-icon" (click)="delete(item.moduloId, $event)">
                            <mat-icon class="custom-icon">delete</mat-icon>
                        </button>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>

</div>