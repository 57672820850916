/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { AddModulesBaseComponent } from '../../add-modules-base.component';

@Component({
  selector: 'aula-planeta-add-module-seccion',
  templateUrl: './seccion.component.html',
  styleUrls: ['./seccion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeccionComponent extends AddModulesBaseComponent {

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SeccionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    super(fb, dialogRef, data);
  }

  ngOnInit() {
    this.formModule = this.fb.group({
      titulo_html: [this.data && this.data.propiedades.titulo_plano ?
        this.data.propiedades.titulo_plano : '', Validators.required],
      descripcion_html: [this.data && this.data.propiedades.descripcion_html ?
        this.data.propiedades.descripcion_html : ''],
      visible: [this.data && this.data.propiedades.visible === 'Profesores' ?
        true : false, Validators.required],
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'attach_module_section',
        popupId: 'dialog-add-module-seccion',
        popupName: 'popup_añadir_modulo_seccion' 
      }
    };
    this.gtagService.event(info);
  }

}

