<div id="dialog-repetir-tarea" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.repetir-tarea.titulo' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <h5 class="custom-text mb-4 subtitle">{{ data.subtitulo | translate }}</h5>
        <div class="overflow-auto">
            <form role="form" [formGroup]="form">
                <fieldset>
                    <div class="w-100 d-flex">
                        <div class="form-group default-input position-relative w-50 me-2">
                            <label for="fechaInicio">{{'dialogs.repetir-tarea.fecha-inico' | translate}}</label>
                            <input aria-labelledby="errorFechaInicio" class="form-control" formControlName="fechaInicio" id="fechaInicio" readonly type="text"
                              [min]="minDate" [max]="maxDate" [matDatepicker]="pickerInicio" [placeholder]="('dialogs.repetir-tarea.fecha-inico' | translate)"
                              (click)="pickerInicio.open()" />
                            <mat-error id="errorFechaInicio" *ngIf="form.get('fechaInicio').hasError('required') && form.controls['fechaInicio'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="pickerInicio" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                            <mat-datepicker #pickerInicio startView="month" [startAt]="minDate"></mat-datepicker>

                        </div>
                        <div class="form-group default-input position-relative w-50">
                            <label for="fechaFin">{{'dialogs.repetir-tarea.fecha-fin' | translate}}</label>
                            <input aria-labelledby="errorFechaFin" class="form-control" formControlName="fechaFin" id="fechaFin" readonly type="text"
                              [min]="form.get('fechaInicio').value" [max]="maxDate" [matDatepicker]="pickerFin" [placeholder]="('dialogs.repetir-tarea.fecha-fin' | translate)"
                              (click)="pickerFin.open()" />
                            <mat-error id="errorFechaFin" *ngIf="form.get('fechaFin').hasError('required') && form.controls['fechaFin'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="pickerFin" class="datepicker-icon default-text position-absolute" readonly></mat-datepicker-toggle>
                            <mat-datepicker #pickerFin startView="month" [startAt]="form.get('fechaInicio').value"></mat-datepicker>
                        </div>
                    </div>
                    <div class="w-100 d-flex flex-column">
                        <div class="d-flex">
                            <div class="w-100 form-group default-textarea mb-4">
                                <label for="comentario">{{'dialogs.asignar-tarea.step2.comentario' | translate}}</label>
                                <textarea id="comentario" aria-labelledby="errorComentario" type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.comentario' | translate)" formControlName="comentario" tabindex="4" maxlength="255"></textarea>
                                <span class="text-secondary"><small>{{'('+ form.controls['comentario'].value.length +'/255)'}}</small></span>
                                <mat-error id="errorComentario" *ngIf="form.get('comentario').hasError('required') && form.controls['comentario'].dirty">
                                    {{'generico.required' | translate}}
                                </mat-error>
                            </div>
                        </div>
                    </div>

                </fieldset>
            </form>
        </div>

    </div>

    <div mat-dialog-actions class="justify-content-start mt-2 mb-0 pb-0">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" (click)="onSubmit()" [disabled]="form.invalid" type="submit">{{ 'generico.guardar' | translate }}</button>
    </div>
</div>
