import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { LtiDataService } from './lti-data.service';
import { take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LtiManagementService implements OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  moodle$: Observable<boolean>;
  private _moodle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  preview$: Observable<boolean>;
  private _preview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  contextId$: Observable<boolean>;
  private _contextId: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  moodleTeacherId$: Observable<boolean>;
  private _moodleTeacherId: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  accionesdidacticas$: Observable<any>;
  private _accionesdidacticas: BehaviorSubject<any> = new BehaviorSubject<any>([])

  bloquescontenidos$: Observable<any>;
  private _bloquescontenidos: BehaviorSubject<any> = new BehaviorSubject<any>([])

  competenciasclaves$: Observable<any>;
  private _competenciasclaves: BehaviorSubject<any> = new BehaviorSubject<any>([])

  criteriosevaluaciones$: Observable<any>;
  private _criteriosevaluaciones: BehaviorSubject<any> = new BehaviorSubject<any>([])

  cursos$: Observable<any>;
  private _cursos: BehaviorSubject<any> = new BehaviorSubject<any>([])

  cursosEdixgal$: Observable<any>;
  private _cursosEdixgal: BehaviorSubject<any> = new BehaviorSubject<any>([])

  estandaresaprendizajes$: Observable<any>;
  private _estandaresaprendizajes: BehaviorSubject<any> = new BehaviorSubject<any>([])

  etapas$: Observable<any>;
  private _etapas: BehaviorSubject<any> = new BehaviorSubject<any>([])

  idiomas$: Observable<any>;
  private _idiomas: BehaviorSubject<any> = new BehaviorSubject<any>([])

  materias$: Observable<any>;
  private _materias: BehaviorSubject<any> = new BehaviorSubject<any>([])

  nivelesdificultades$: Observable<any>;
  private _nivelesdificultades: BehaviorSubject<any> = new BehaviorSubject<any>([])

  taxonomiasblooms$: Observable<any>;
  private _taxonomiasblooms: BehaviorSubject<any> = new BehaviorSubject<any>([])

  tiposcontenidos$: Observable<any>;
  private _tiposcontenidos: BehaviorSubject<any> = new BehaviorSubject<any>([])

  tiposmedia$: Observable<any>;
  private _tiposmedia: BehaviorSubject<any> = new BehaviorSubject<any>([])

  tiposmediarecursos$: Observable<any>;
  private _tiposmediarecursos: BehaviorSubject<any> = new BehaviorSubject<any>([])

  tematica$: Observable<any>;
  private _tematica: BehaviorSubject<any> = new BehaviorSubject<any>([])

  public token = '';

  constructor(private ltiDataService: LtiDataService) {
    this.moodle$ = this._moodle.asObservable();
    this.preview$ = this._preview.asObservable();
    this.contextId$ = this._contextId.asObservable();
    this.moodleTeacherId$ = this._moodleTeacherId.asObservable();
    this.accionesdidacticas$ = this._accionesdidacticas.asObservable();
    this.bloquescontenidos$ = this._bloquescontenidos.asObservable();
    this.competenciasclaves$ = this._competenciasclaves.asObservable();
    this.criteriosevaluaciones$ = this._criteriosevaluaciones.asObservable();
    this.cursos$ = this._cursos.asObservable();
    this.cursosEdixgal$ = this._cursosEdixgal.asObservable();
    this.estandaresaprendizajes$ = this._estandaresaprendizajes.asObservable();
    this.etapas$ = this._etapas.asObservable();
    this.idiomas$ = this._idiomas.asObservable();
    this.materias$ = this._materias.asObservable();
    this.nivelesdificultades$ = this._nivelesdificultades.asObservable();
    this.taxonomiasblooms$ = this._taxonomiasblooms.asObservable();
    this.tiposcontenidos$ = this._tiposcontenidos.asObservable();
    this.tiposmediarecursos$ = this._tiposmediarecursos.asObservable();
    this.tiposmedia$ = this._tiposmedia.asObservable();
    this.tematica$ = this._tematica.asObservable();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get moodle() {
    return this._moodle.getValue();
  }

  set moodle(moodle: boolean) {
    this._moodle.next(moodle);
  }

  get preview() {
    return this._preview.getValue();
  }

  set preview(preview: boolean) {
    this._preview.next(preview);
  }

  get contextId() {
    return this._contextId.getValue();
  }

  set contextId(contextId: boolean) {
    this._contextId.next(contextId);
  }

  get moodleTeacherId() {
    return this._moodleTeacherId.getValue();
  }

  set moodleTeacherId(moodleTeacherId: boolean) {
    this._moodleTeacherId.next(moodleTeacherId);
  }


  get accionesdidacticas() {
    return this._accionesdidacticas.getValue();
  }

  get bloquescontenidos() {
    return this._bloquescontenidos.getValue();
  }

  get competenciasclaves() {
    return this._competenciasclaves.getValue();
  }

  get criteriosevaluaciones() {
    return this._criteriosevaluaciones.getValue();
  }

  get cursos() {
    return this._cursos.getValue();
  }

  get cursosEdixgal() {
    return this._cursosEdixgal.getValue();
  }

  get estandaresaprendizajes() {
    return this._estandaresaprendizajes.getValue();
  }

  get etapas() {
    return this._etapas.getValue();
  }

  get idiomas() {
    return this._idiomas.getValue();
  }

  get materias() {
    return this._materias.getValue();
  }

  get nivelesdificultades() {
    return this._nivelesdificultades.getValue();
  }

  get taxonomiasblooms() {
    return this._taxonomiasblooms.getValue();
  }

  get tiposcontenidos() {
    return this._tiposcontenidos.getValue();
  }

  get tiposmediarecursos() {
    return this._tiposmediarecursos.getValue();
  }

  get tiposmedia() {
    return this._tiposmedia.getValue();
  }

  get tematica() {
    return this._tematica.getValue();
  }

  getFiltrosLtiAndalucia() {
    const ANDALUCIA = true;
    this.getAccionesdidacticas();
    this.getBloquescontenidos();
    this.getCompetenciasclaves(ANDALUCIA);
    this.getCriteriosevaluaciones();
    this.getCursos(ANDALUCIA);
    this.getEstandaresaprendizajes();
    this.getEtapas(ANDALUCIA);
    this.getIdiomas();
    this.getMaterias(ANDALUCIA);
    this.getNivelesdificultades();
    this.getTaxonomiasblooms();
    this.getTiposcontenidos();
    this.getTiposmedia();
    this.getTiposmediarecursos();
    this.getTematica();
  }

  getFiltrosLtiGenerico() {
    const ANDALUCIA = false;
    this.getEtapas(ANDALUCIA);
    this.getCursos(ANDALUCIA);
    this.getMaterias(ANDALUCIA);
    this.getCompetenciasclaves(ANDALUCIA);
    this.getTiposmediarecursos();
    this.getAccionesdidacticas();
  }

  set accionesdidacticas(accionesdidacticas: any) {
    this._accionesdidacticas.next(accionesdidacticas);
  }

  set bloquescontenidos(bloquescontenidos: any) {
    this._bloquescontenidos.next(bloquescontenidos);
  }

  set competenciasclaves(competenciasclaves: any) {
    this._competenciasclaves.next(competenciasclaves);
  }

  set criteriosevaluaciones(criteriosevaluaciones: any) {
    this._criteriosevaluaciones.next(criteriosevaluaciones);
  }

  set cursos(cursos: any) {
    this._cursos.next(cursos);
  }
  set cursosEdixgal(cursos: any) {
    this._cursosEdixgal.next(cursos);
  }

  set estandaresaprendizajes(estandaresaprendizajes: any) {
    this._estandaresaprendizajes.next(estandaresaprendizajes);
  }

  set etapas(etapas: any) {
    this._etapas.next(etapas);
  }

  set idiomas(idiomas: any) {
    this._idiomas.next(idiomas);
  }

  set materias(materias: any) {
    this._materias.next(materias);
  }

  set nivelesdificultades(nivelesdificultades: any) {
    this._nivelesdificultades.next(nivelesdificultades);
  }

  set taxonomiasblooms(taxonomiasblooms: any) {
    this._taxonomiasblooms.next(taxonomiasblooms);
  }

  set tiposcontenidos(tiposcontenidos: any) {
    this._tiposcontenidos.next(tiposcontenidos);
  }

  set tiposmediarecursos(tiposmediarecursos: any) {
    this._tiposmediarecursos.next(tiposmediarecursos);
  }

  set tiposmedia(tiposmedia: any) {
    this._tiposmedia.next(tiposmedia);
  }

  set tematica(tematica: any) {
    this._tematica.next(tematica);
  }

  getAccionesdidacticas() {
    this.ltiDataService.getAccionesdidacticas().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.accionesdidacticas = res;
        }
      );
  }

  getBloquescontenidos() {
    this.ltiDataService.getBloquescontenidos().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.bloquescontenidos = res;
        }
      );
  }

  getCompetenciasclaves(andalucia: boolean) {
    this.ltiDataService.getCompetenciasclaves(andalucia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.competenciasclaves = res;
        }
      );

  }

  getCriteriosevaluaciones() {
    this.ltiDataService.getCriteriosevaluaciones().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.criteriosevaluaciones = res;
        }
      );
  }

  getCursos(andalucia: boolean) {
    this.ltiDataService.getCursos(andalucia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.cursos = res;
        }
      );
  }



  getCursosEdixgal() {
    this.ltiDataService.getCursosEdixgal().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.cursosEdixgal = res;
        }
      );
  }

  getEstandaresaprendizajes() {
    this.ltiDataService.getEstandaresaprendizajes().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.estandaresaprendizajes = res;
        }
      );
  }

  getEtapas(andalucia: boolean) {
    this.ltiDataService.getEtapas(andalucia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.etapas = res;
        }
      );
  }

  getIdiomas() {
    this.ltiDataService.getIdiomas().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.idiomas = res;
        }
      );
  }

  getMaterias(andalucia: boolean) {
    this.ltiDataService.getMaterias(andalucia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.materias = res;
        }
      );
  }

  getNivelesdificultades() {
    this.ltiDataService.getNivelesdificultades().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.nivelesdificultades = res;
        }
      );
  }

  getTaxonomiasblooms() {
    this.ltiDataService.getTaxonomiasblooms().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.taxonomiasblooms = res;
        }
      );
  }

  getTiposcontenidos() {
    this.ltiDataService.getTiposcontenidos().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.tiposcontenidos = res;
        }
      );
  }

  getTiposmediarecursos() {
    this.ltiDataService.getTiposmediarecursos().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.tiposmediarecursos = res;
        }
      );
  }

  getTiposmedia() {
    this.ltiDataService.getTiposmedia().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.tiposmedia = res;
        }
      );
  }

  getTematica() {
    this.ltiDataService.getTematica().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.tematica = res;
        }
      );
  }


}
