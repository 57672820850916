<div id="edition-bar" class="edition-bar animated position-absolute d-flex flex-column justify-content-between align-items-center" [ngClass]="(editionModeService.position$ | async)">
    <ng-container *ngFor="let option of editionModeService.getMenu()" [ngSwitch]="option.group">
        <ng-template [ngSwitchCase]="'visibility'">
            <button (click)="$event.stopPropagation(); switchingActions(option.id, $event);" [matMenuTriggerFor]="visibilityMenu" [matTooltip]="option.name" [matTooltipPosition]="(editionModeService.position$ | async)" [attr.aria-label]="option.name" [ngClass]="option.divider ? 'edition-divider' : ''">
                <mat-icon>{{option.icon}}</mat-icon>
            </button>
            <mat-menu #visibilityMenu="matMenu" class="default-dropdown default-dropdown__edit-bar" xPosition="before" yPosition="below">
                <button (click)="switchingActions('Alumnos', $event)" role="button" mat-menu-item>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <mat-icon class="default-icon">people</mat-icon>
                            <span>{{'mode-edition.edition-bar.button.visible-for-alumns' | translate}}</span>
                        </div>
                        <mat-icon class="right-icon me-0" [hidden]="'Alumnos' !== editionModeService.moduloSeleccionado?.propiedades.visible">check</mat-icon>
                    </div>
                </button>
                <button (click)="switchingActions('Profesores', $event)" role="button" mat-menu-item>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <mat-icon class="default-icon">person_off</mat-icon>
                            <span>{{'mode-edition.edition-bar.button.no-visible-for-alumns' | translate}}</span>
                        </div>
                        <mat-icon class="right-icon me-0" [hidden]="'Profesores' !== editionModeService.moduloSeleccionado?.propiedades.visible">check</mat-icon>
                    </div>
                </button>
                <button (click)="switchingActions('Oculto', $event)" role="button" mat-menu-item>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <mat-icon class="default-icon">visibility_off</mat-icon>
                            <span>{{'mode-edition.edition-bar.button.visibility-off' | translate}}</span>
                        </div>
                        <mat-icon class="right-icon me-0" [hidden]="'Oculto' !== editionModeService.moduloSeleccionado?.propiedades.visible">check</mat-icon>
                    </div>
                </button>
            </mat-menu>
        </ng-template>
        <ng-template [ngSwitchCase]="'menu'">
            <!-- <ng-container *ngIf="!editionModeService.type.includes('seccion1')"> -->
            <button *ngIf="editionModeService.moduloSeleccionado?.propiedades.visible !== 'Oculto'" (click)="$event.stopPropagation(); switchingActions(option.id, $event)" [matMenuTriggerFor]="menu" [matTooltip]="option.name" [matTooltipPosition]="(editionModeService.position$ | async)"
                [attr.aria-label]="option.name" [ngClass]="option.divider ? 'edition-divider' : ''">
                <mat-icon>{{option.icon}}</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="default-dropdown default-dropdown__edit-bar" xPosition="before" yPosition="below">
                <button (click)="switchingActions('delete', $event)" role="button" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>{{ 'mode-edition.edition-bar.button.delete' | translate }}</span>
                </button>
                <button *ngIf="!editionModeService.moduloSeleccionado?.temporal && !(ltiManagementService.moodle$ | async)" (click)="switchingActions('mover', $event)" role="button" mat-menu-item>
                    <mat-icon>drive_file_move</mat-icon>
                    <span>{{ 'mode-edition.edition-bar.button.move-to' | translate }}</span>
                </button>
                <button *ngIf="canDuplicar()" (click)="switchingActions('duplicate', $event)" role="button" mat-menu-item>
                    <mat-icon>content_copy</mat-icon>
                    <span >{{ 'mode-edition.edition-bar.button.duplicate' | translate }}</span>
                </button>
                <!-- <button *ngIf="canDuplicarEditar()" (click)="switchingActions('duplicate', $event)" role="button" mat-menu-item>
                    <mat-icon>content_copy</mat-icon>
                    <span >{{ 'mode-edition.edition-bar.button.duplicate-and-edit' | translate }}</span>
                </button> -->
            </mat-menu>
            <!-- </ng-container> -->
        </ng-template>
        <ng-template [ngSwitchCase]="'button'">
            <button *ngIf="editionModeService.moduloSeleccionado?.propiedades?.visible !== 'Oculto'" (click)="switchingActions(option.id, $event)" [matTooltip]="option.name" [matTooltipPosition]="(editionModeService.position$ | async)" [attr.aria-label]="option.name"
                [ngClass]="option.divider ? 'edition-divider' : ''">
                <mat-icon>{{option.icon}}</mat-icon>
            </button>
        </ng-template>

    </ng-container>
</div>