import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-eliminar',
  templateUrl: 'eliminar.component.html',
  styleUrls: ['./eliminar.component.scss'],
})
export class EliminarComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EliminarComponent>, 
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {}

  ngOnInit () {
    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-eliminar',
        popupName: 'popup_eliminar',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }
}