import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-cambiar-vista',
  templateUrl: './cambiar-vista.component.html',
  styleUrls: ['./cambiar-vista.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CambiarVistaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CambiarVistaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
    ) { }


  ngOnInit() {
    const info = {
      event: 'popup',
      popup: {
        action: 'change_view',
        popupId: 'dialog-cambiar-vista',
        popupName: 'popup_change_view'
      }
    };
    this.gtagService.event(info);
  }

  close(result) {
    this.dialogRef.close(result);
  }

  getTypeButton(type) {
    return (type === 'primary') ? 'default-button__primary' : 'default-button__secondary';
  }
}
