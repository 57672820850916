/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { AddModulesBaseComponent } from '../../add-modules-base.component';
import { BehaviorSubject } from 'rxjs';
import * as  DecoupledDocumentEditor from '../../../../../../../assets/js/ck-editor-math-type/ckeditor';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'aula-planeta-add-module-imagen',
  templateUrl: './imagen.component.html',
  styleUrls: ['./imagen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagenComponent extends AddModulesBaseComponent implements AfterViewInit {


  @ViewChild('editorTexto') editorTexto: any;
  editor = DecoupledDocumentEditor;
  isDisabled = true;
  valueData = '';

  config = {
    language: 'es',
    fontSize: { options: [9, 11, 12, 13, 'default', 17, 19, 21] },
    toolbar: ['heading', '|', 'bold',
      'italic', 'fontSize', 'fontFamily', 'fontColor', 'underline', 'alignment',
      'highlight', 'MathType', 'numberedList', 'link', 'insertTable',
      'bulletedList', '|', 'fontBackgroundColor',
      'strikethrough', 'indent', 'outdent', 'undo', 'redo', 'exportPdf', 'exportWord'],
    link: {
      addTargetToExternalLinks: true
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
      ]
    }
  };

  private _imagen: BehaviorSubject<string> = new BehaviorSubject<string>('');
  imagen$ = this._imagen.asObservable();


  error = {
    imagen: false
  };

  validTypes = {
    imagen: ['image/gif', 'image/jpg', 'image/jpeg', 'image/pipeg', 'image/png']
  };

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ImagenComponent>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    super(fb, dialogRef, data);
  }

  ngOnInit() {
    this.formModule = this.fb.group({
      titulo_html: [this.data && this.data.propiedades.titulo_html ? this.data.propiedades.titulo_html : ''],
      visible: [this.data && this.data.propiedades.visible === 'Profesores' ? true : false],
      imagenName: [''],
      imagen: ['', Validators.required],
      // pie_de_imagen: [this.data && this.data.propiedades.pie_de_imagen ? this.data.propiedades.pie_de_imagen : ''],
      // texto_alternativo: [this.data && this.data.propiedades.texto_alternativo ? this.data.propiedades.texto_alternativo : ''],
      // creditos_html: [this.data && this.data.propiedades.creditos_html ? this.data.propiedades.creditos_html : '']
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'attach_image',
        popupId: 'dialog-add-module-imagen',
        popupName: 'popup_añadir_imagen'
      }
    };
    this.gtagService.event(info);
  }

  onDropImg(file, field) {

    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this._imagen.next(reader.result as string);
        this.formModule.get(field).setValue(reader.result as string);
        this.formModule.get(field + 'Name').setValue(file.name);
        this.formModule.markAsDirty();
        this.formModule.updateValueAndValidity();
        this.cd.markForCheck();
      };
    }
  }

  deleteImg(field) {
    this.formModule.get(field).setValue(null);
    this.formModule.get(field).updateValueAndValidity();
    this._imagen.next(null);
  }

  isValidFile(file, field) {
    return this.validTypes[field].includes(file.type);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.editorTexto?.elementRef?.nativeElement && this.editorTexto?.elementRef?.nativeElement?.getElementsByClassName('ck').lenght > 0) {
        this.editorTexto.elementRef.nativeElement.getElementsByClassName('ck')[0].hidden = false;
      }
    }, 100);
  }

  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.formModule.get('pie_de_imagen').setValue(data);
    this.formModule.updateValueAndValidity();
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    this.editorTexto.elementRef.nativeElement.getElementsByClassName('ck')[0].hidden = true;
  }

}

