<div id="dialog-tarea">
    <h1 mat-dialog-title class="d-flex align-items-center">{{ 'dialogs.tarea.title' | translate }}</h1>
    <div mat-dialog-content class="tarea-container">
        <mat-list role="list">
            <div class="d-flex flex-row header-list">
                <div mat-subheader class="d-flex flex-row title">{{ data.title }}</div>
                <div mat-subheader class="d-flex flex-row date">{{ 'dialogs.tarea.fecha-limite' | translate }}: {{ data.limitDate }}</div>
            </div>

            <mat-list-item *ngFor="let work of data.works; let i = index" role="listitem">
                <img [src]="work.img.src" [alt]="work.img.alt" role="img" />
                <span>{{ i + 1 }}</span>
                <div mat-line>{{ work.title }}</div>
                <div mat-line> {{ work.description }} </div>
            </mat-list-item>
        </mat-list>

    </div>
    <div mat-dialog-actions class="justify-content-end">
        <button role="button" mat-raised-button color="accent" mat-dialog-close>{{ 'dialogs.close' | translate }}</button>
    </div>
</div>
