import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SvgService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
  ) {

  }

  init() {
    this.matIconRegistry.addSvgIcon(
      'moodle',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/I130.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'asignar_recurso_moodle',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/I133.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'anadir_a_moodle',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/I134.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'tarea_asignada',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/I135A.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'tarea_asignada_clock',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/I135B.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'tarea_realizada',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/I136.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'classroom',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/classroom.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'video-player',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/video-player.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'drag_handle',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/drag_handle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ap_pro',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/ap_pro.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'novedades',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/novedades.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icono_discovery',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/icono_discovery.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rocket_beta',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/rocket_beta.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'logo_cosmos',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/logo_cosmos.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'logo_planetarium',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/logo_planetarium.svg')
    );
    
  }
}
