import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-copiar-enlace',
  templateUrl: './copiar-enlace.component.html',
  styleUrls: ['./copiar-enlace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopiarEnlaceComponent {

  constructor(
    public dialogRef: MatDialogRef<CopiarEnlaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private gtagService: GtagService
  ) { }

  ngOnInit() {
    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-crear_enlace',
        popupName: 'popup_crear_enlace',
        pageId: this.data?.event?.view.location.hash
      }
    };
    this.gtagService.event(info);
  }

  copyClipboard() {
    this.clipboard.copy(this.data.link);
    this.snackBar.open(this.translateService.instant('dialogs.copiar-enlace.copiado'), 'OK', {
      duration: 2000
    });
  }

}
