import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColorHelper } from '../../../helper/color.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-tarea-vencida',
  templateUrl: './tarea-vencida.component.html',
  styleUrls: ['./tarea-vencida.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TareaVencidaComponent implements OnInit {
  checkboxValue = false;

  constructor(
    public dialogRef: MatDialogRef<TareaVencidaComponent>,
    public colorHelper: ColorHelper,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService,

  ) { }

  ngOnInit() {
    const info = {
      event: 'popup',
      popup: {
        pageId: this.data?.event?.view?.location.hash,
        popupId: 'dialog-tarea-vencida',
        popupName: 'popup_tarea-vencida_dialog'
      }
    };
    this.gtagService.event(info);
  }

  close(result) {
    this.dialogRef.close(result);
  }

  getTypeButton(type) {
    return (type === 'primary') ? 'default-button__primary' : 'default-button__secondary';
  }

}
