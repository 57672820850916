<div id="dialog-filtrar-resultados" class="standard">
    <h1 mat-dialog-title class="custom-text heading-6">{{ 'dialogs.actualizar-contrasenya.title' | translate }}</h1>
    <div mat-dialog-content class="mat-dialog-content">
        <div class="overflow-auto">
            <form role="form" [formGroup]="form">
                <fieldset class="w-100 d-flex flex-column">

                    <div class="form-group w-100 default-input position-relative text-start">
                        <label for="oldPassword" class="default-text body-3">{{ 'dialogs.actualizar-contrasenya.oldcontrasenya' | translate}}</label>
                        <input aria-labelledby="error_oldPassword" type="password" class="form-control" id="oldPassword" formControlName="oldPassword">
                        <mat-error id="error_oldPassword" *ngIf="form.get('oldPassword').hasError('pattern') && form.controls['oldPassword'].dirty">
                            {{'mi-centro.gestion-contrasenas.validator.required' | translate}}
                        </mat-error>
                    </div>

                    <div class="form-group w-100 default-input position-relative text-start">
                        <label for="newPassword" class="default-text body-3">{{ 'dialogs.actualizar-contrasenya.contrasenya' | translate}}</label>
                        <input aria-labelledby="error_newPassword_1 error_newPassword_2 error_newPassword_3" id="newPassword" type="password" class="form-control" formControlName="newPassword">
                        <mat-error id="error_newPassword_1" *ngIf="form.get('newPassword').hasError('pattern') && form.controls['newPassword'].dirty">
                            {{'generico.formulario.segura' |translate}}
                        </mat-error>
                        <mat-error id="error_newPassword_2" *ngIf="form.get('newPassword').hasError('minlength') && form.controls['newPassword'].dirty">
                            {{'mi-centro.gestion-contrasenas.validator.minlength' | translate:{minlength: form.get('newPassword').errors.minlength.requiredLength} }}
                        </mat-error>
                        <mat-error id="error_newPassword_3" *ngIf="form.get('newPassword').hasError('required') && form.controls['newPassword'].dirty">
                            {{'mi-centro.gestion-contrasenas.validator.required' | translate}}
                        </mat-error>
                    </div>

                    <div class="form-group w-100 default-input position-relative text-start">
                        <label for="confirmNewPassword" class="default-text body-3">{{ 'dialogs.actualizar-contrasenya.repeat-password' | translate}}</label>
                        <input id="confirmNewPassword" aria-labelledby="error_confirmNewPassword" type="password" class="form-control" formControlName="confirmNewPassword">
                        <mat-error id="error_confirmNewPassword" *ngIf="form.errors?.mismatch && (form.controls['confirmNewPassword'].dirty || form.controls['confirmNewPassword'].touched)">
                            {{'perfil.contrasena.error_confirm' | translate}}
                        </mat-error>
                    </div>

                </fieldset>
            </form>
        </div>
    </div>
    <div mat-dialog-actions class="justify-content-start mt-2 mb-0 pb-0">
        <button role="button" (click)="onSubmit($event)" class="default-button default-button__secondary" style="height: 40px" type="submit">{{
            'dialogs.actualizar-contrasenya.button' | translate }}</button>
    </div>
</div>
