/* eslint-disable max-len */
import { Directive, OnInit, OnDestroy, ElementRef, Input, Renderer2, ViewContainerRef, ComponentFactoryResolver, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { EditionModeService } from '../../../services/edition-mode.service';
import { HighlightsModeService } from '../../../services/highlights-mode.service';
import { HighlightsComponent } from '../../../shared/components/highlights/highlights.component';

@Directive({
  selector: '[highlights]'
})
export class HighlightsDirective implements OnInit, OnDestroy {

  public static _elementRefSelected: ElementRef<any> = null;
  private static _positionBar = '';
  private static _options = '';
  private static _addBar = false;

  private destroy$: Subject<void> = new Subject<void>();

  @Input() positionBar = 'left';
  @Input() options = '';
  @Input() type = '';
  @Input() addBar = false;
  @Input() canEdit = true;
  @Input() modulo = {} as any;
  @Input() parent = '';

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _highlightsModeService: HighlightsModeService,
    private _editionModeService: EditionModeService,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  @HostListener('document:click', ['$event.target'])
  public onOutsideClick(targetElement) {
    const _win = window as any;
    if (this._highlightsModeService.selected) {
      const clickedInside = HighlightsDirective._elementRefSelected?.nativeElement?.contains(targetElement);
      if (!clickedInside) {
        const highlights = document.getElementById('highlights') as HTMLElement;
        if (highlights) {
          this._renderer.removeChild(this._elementRef.nativeElement, highlights);
          HighlightsDirective._elementRefSelected = null;
        }

      }
    }
  }

  getSelectedText() {
    const docu = document as any;
    let text = '';
    if (typeof window.getSelection != 'undefined') {
      text = window.getSelection().toString();
    } else if (typeof docu.selection != 'undefined' && docu.selection.type === 'Text') {
      text = docu.selection.createRange().text;
    }
    return text;
  }

  doSomethingWithSelectedText(event) {
    if (this.canEdit) {
      this._highlightsModeService.removeMode = false;
      this.viewContainerRef.clear();
      const highlights = document.getElementById('highlights') as HTMLElement;
      if (highlights) {
        this._renderer.removeChild(this._elementRef.nativeElement, highlights);
      }
      const selectedText = this.getSelectedText();

      if (selectedText) {
        this.loadComponent(event);
      } else if (event.target.tagName === 'SPAN' && event.target.style.backgroundColor) {
        this.loadComponent(event);
        this._highlightsModeService.removeMode = true;
      } else if (event.target !== 'SPAN' && event.target.parentNode.tagName === 'SPAN') {
        this.loadComponent(event);
        this._highlightsModeService.removeMode = true;
      }
    }
  }


  loadComponent(event) {
    const componentFactory = this.resolver
      .resolveComponentFactory(HighlightsComponent);

    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.modulo = this.modulo;
    const element = componentRef.location.nativeElement;
    const { x, y, right } = this._elementRef.nativeElement.getBoundingClientRect();

    let top = (event.layerY - 20);
    let left = (event.offsetX + 40);
    if (event.pageX + 165 > right && !this._highlightsModeService.removeMode) {
      top = (event.layerY + 20);
      left = (event.offsetX - 125);
    }

    this._renderer.setStyle(element, 'position', 'absolute');
    this._renderer.setStyle(element, 'top', top + 'px');
    this._renderer.setStyle(element, 'left', left + 'px');
    this._renderer.setStyle(element, 'z-index', '20');
    const host = this._elementRef.nativeElement;
    host.insertBefore(element, host.firstChild);
    HighlightsDirective._elementRefSelected = this._elementRef;
    this._highlightsModeService.innerHTMLSubrayado = this._elementRef;
  }

  @HostListener('click', ['$event'])
  onClick(event) {

    if (!this._editionModeService.enable) {
      if (event.target.nodeName === 'A' && event.target.href) {
        const url = event.target.href;
        event.stopPropagation();
        event.preventDefault();
        window.open(`${url}`, '_blank');
        return null;
      }

      if (event.target.style.backgroundColor === 'rgb(49, 49, 51)') {
        return null;
      }

      this._highlightsModeService.target = event.target;
      this._highlightsModeService.innerHTMLSubrayado = this._elementRef.nativeElement.childNodes[1].firstChild.innerHTML;
      this._highlightsModeService.contenidoHtml = this.modulo.propiedades.contenido_html;
      this._highlightsModeService.s();

      this.doSomethingWithSelectedText(event);
      this._highlightsModeService.selected = true;

      const spans = document.querySelectorAll('span');
      for (let i = 0; i < spans.length; i++) {
        // eslint-disable-next-line prefer-const
        let span = spans[i], ih, p;
        if (span.hasAttributes() === false) {
          ih = span.innerHTML;
          p = span.parentNode;
          p.removeChild(span);
          p.innerHTML += ih;
        }
      }
    }
  }

  @HostListener('touchend', ['$event'])
  ontouchend(event) {
    if (!this._editionModeService.enable) {
      if (event.target.nodeName === 'A' && event.target.href) {
        const url = event.target.href;
        event.stopPropagation();
        event.preventDefault();
        window.open(`${url}`, '_blank');
        return null;
      }
      this._highlightsModeService.target = event.target;
      this._highlightsModeService.innerHTMLSubrayado = this._elementRef.nativeElement.childNodes[1].firstChild.innerHTML;
      this._highlightsModeService.contenidoHtml = this.modulo.propiedades.contenido_html;
      this._highlightsModeService.s();

      this.doSomethingWithSelectedText(event);
      this._highlightsModeService.selected = true;

      const spans = document.querySelectorAll('span');
      for (let i = 0; i < spans.length; i++) {
        // eslint-disable-next-line prefer-const
        let span = spans[i], ih, p;
        if (span.hasAttributes() === false) {
          ih = span.innerHTML;
          p = span.parentNode;
          p.removeChild(span);
          p.innerHTML += ih;
        }
      }
    }
  }



  /**
   * Activa la animacion de entrada de la barra de edición del elemento seleccionado.
   */
  private enterAnimationEditionBar() {
    const addClassName = HighlightsDirective._positionBar === 'right' ? 'fadeInRight' : 'fadeInLeft';
    const editionBar = document.getElementById('hightlights');

    this._renderer.setStyle(editionBar, 'top', `${this.getPosition()}px`);
    this._renderer.removeClass(editionBar, 'fadeOutRight');
    this._renderer.removeClass(editionBar, 'fadeOutLeft');
    setTimeout(() => this._renderer.addClass(editionBar, addClassName), 300);
  }

  /**
   * Activa la animacion de salida de la barra de edición del elemento seleccionado.
   */
  private leaveAnimationEditionBar() {
    const addClassName = HighlightsDirective._positionBar === 'right' ? 'fadeOutRight' : 'fadeOutLeft';
    const editionBar = document.getElementById('hightlights');

    this._renderer.removeClass(editionBar, 'fadeInRight');
    this._renderer.removeClass(editionBar, 'fadeInLeft');
    this._renderer.addClass(editionBar, addClassName);
    if (this._highlightsModeService.type === 'texto') {
      this._highlightsModeService.parentInjector._lView[8].isDisabled = false;
    }
  }

  /**
   * Activa la animacion de entrada del EditionAdd si el elemento seleccionado tiene AddBar.
   */
  // private enterAnimationEditionAdd() {
  //   if (this.addBar) {
  //     HighlightsDirective._addBar = this.addBar;
  //     const factory = this.resolver.resolveComponentFactory(EditionAddComponent);
  //     const editionAddBefore = this.viewContainerRef.createComponent(factory);
  //     const editionAddAfter = this.viewContainerRef.createComponent(factory);

  //     editionAddBefore.instance.posicion = 'up';
  //     editionAddAfter.instance.posicion = 'down';
  //     if (this.type.includes('seccion1')) {

  //       const accordeon = document.querySelector('.tema-list');

  //       editionAddBefore.instance.setOptions('seccion1');
  //       this.setOptions(this.type, editionAddAfter.instance);

  //       this.enableSectionAdd(editionAddBefore, accordeon, this.getPosition('default-drawer') - 39);
  //       this.enableSectionAdd(editionAddAfter, accordeon, this.getPosition('default-drawer') + 32);
  //     } else {
  //       this.setOptions(this.type, editionAddBefore.instance);
  //       this._elementRef.nativeElement
  //         .insertBefore(editionAddBefore.location.nativeElement, this._elementRef.nativeElement.firstChild);

  //       this.setOptions(this.type, editionAddAfter.instance);
  //       this._elementRef.nativeElement.appendChild(editionAddAfter.location.nativeElement);
  //     }
  //   }
  // }

  /**
   * Activa la animacion de salida del EditionAdd si el elemento seleccionado tiene AddBar.
   */
  private leaveAnimationEditionAdd() {
    if (HighlightsDirective._addBar) {
      HighlightsDirective._addBar = false;
      this.viewContainerRef.clear();
      document.querySelectorAll('aula-planeta-edition-add').forEach(e => e.remove());
    }
  }

  /**
   * Obtiene la posicion del elemento seleccionado en funcion del parameto.
   * Si no hay parametro, obtendrá la posicion en relación al body.
   * 
   * @param stop: String Clase del elemento hasta el que se quiere obtener la posicion en relacion al elemento seleccionado.
   * @returns yPosition: Number Posicion del elemento seleccionado en relacion al parametro pasado o al body.
   */
  getPosition(stop: any = null) {
    let yPosition = 0;
    let element = HighlightsDirective._elementRefSelected.nativeElement;

    while (element) {
      yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
      element = element.offsetParent;
      if (stop && element.classList.contains(stop)) {
        break;
      }
    }

    return yPosition;
  }



  /**
   * Añade el component Edition Bar en el next o en el before del elemento seleccionado.
   * Esta funcion solo se usa con las secciones de primer nivel, ya que el EditionBar requiere en este punto que sea absoluto.
   * 
   * @param element: ComponentRef Referencia del componente Edition Bar
   * @param parentElement: Element Elemento contenedor de donde se quiere insertar el Edition Bar y que dentro esta el elemento seleccionado
   * @param position: Number Posicion en pixeles del elemento seleccionado en pantalla.
   */
  enableSectionAdd(element, parentElement, position) {
    this._renderer.addClass(element.location.nativeElement, 'position-absolute');
    this._renderer.addClass(element.location.nativeElement, 'w-100');
    this._renderer.setStyle(element.location.nativeElement, 'z-index', '11');
    if (parentElement) {

      parentElement.parentNode.insertBefore(element.location.nativeElement, parentElement.nextSibling);

      this._renderer.setStyle(element.location.nativeElement, 'top', `${position}px`);
    }
  }

  /**
   * Setea en las barras de añadir modulos las distintas opciones segun el tipo de elemento selecionado.
   * @param typeElement: String Tipo de elemento seleccionado
   * @param addBarInstance: ComponentRef Referencia del componente EditionBar, puede ser el before o el after del elemento seleccionado.
   */
  setOptions(typeElement, addBarInstance) {
    switch (typeElement) {
      case 'seccion1':
        addBarInstance.setOptions('seccion1, seccion2, divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
        break;
      case 'seccion1-recurso':
        addBarInstance.setOptions('seccion1, seccion2, divider, recurso');
        break;
      case 'seccion2':
        addBarInstance.setOptions('seccion2, seccion3, divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
        break;
      case 'seccion3':
        addBarInstance.setOptions('seccion3, divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
        break;
      case 'seccion4':
        addBarInstance.setOptions('divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
        break;
      case 'recurso-col':
        addBarInstance.setOptions('recurso');
        break;
      case 'recurso':
        addBarInstance.setOptions('seccion2, divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
        break;
      default:
        addBarInstance.setOptions('seccion2, divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
    }
  }
}
