/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import {
  Component, OnInit, ChangeDetectionStrategy, OnDestroy, HostListener, ViewChild, AfterViewInit
} from '@angular/core';
import { AuthManagementService } from '../../../core/auth/auth-management.service';
import { Observable, Subject } from 'rxjs';
import { SettingsService } from '../../../core/settings/settings.service';
import { environment as env } from '../../../../environments/environment';
import { Settings } from '../../../shared/models/settings.model';
import { routeAnimations } from '../../../core/animations/route.animations';
import { Router } from '@angular/router';
import { User } from '../../../shared/models/user.model';
import { take, takeUntil } from 'rxjs/operators';
import { TareaManagementService } from '../../../services/data/tarea/tarea-management.service';
import { formatDate, Location } from '@angular/common';
import { TaskListService } from '../../../services/tasklist.service';
import { DateHelper } from '../../../shared/helper/date.helper';
import { EditionModeService } from '../../../services/edition-mode.service';
import { ColorHelper } from '../../../shared/helper/color.helper';
import { MiCentroManagementService } from '../../mi-centro/services/mi-centro-management.service';
import { GtagService } from '../../../services/gtag/gtag.service';
import { LtiManagementService } from '../../../services/data/lti/lti-management.service';
import { MatSidenav } from '@angular/material/sidenav';
import { TemasManagementService } from '../../../services/data/temas/temas-management.service';
import { EnvService } from '../../../services/env.service';
import { LocalStorageService } from '../../../core/core.module';
import { TranslateService } from '@ngx-translate/core';
import { MicrosoftTeamsService } from '../../../services/data/microsoft-teams/microsoft-teams.service';
import { SmartlinkManagementService } from '../../../services/data/smartlink/smartlink-management-service.service';
import { MateriaManagementService } from '../../../services/data/materia/materia-management.service';
import { CookiesManagementService } from '../../../core/cookies/cookies-management.service';
import { ConfiguracionCookiesComponent } from '../../../shared/components/dialogs/configuracion-cookies/configuracion-cookies.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'aula-planeta-main-void',
  templateUrl: './main-void.component.html',
  styleUrls: ['./main-void.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routeAnimations]
})
export class MainVoidComponent implements OnInit, OnDestroy, AfterViewInit {
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  // logo = require('../../../../assets/logo_PLANETA72x72.png').default;
  languages = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn', 'he'];
  navigation = [{ link: 'inicio/mis-materias', label: 'menu.mis-materias' }];
  navigationSideMenu = null;
  otherSideMenu = null;
  user: User;
  minDate = new Date(new Date().getFullYear() - 2, 1, 1);
  maxDate = new Date(new Date().getFullYear() + 2, 12, 31);

  teacherMenu = [
    {
      icon: 'book',
      link: '/inicio/mis-materias',
      label: 'menu.subjects',
      reverse: true,
      funcionalidad: 'MateriasLibros'
    },
    {
      icon: 'assignment',
      link: '/inicio/tareas',
      label: 'menu.homework',
      funcionalidad: 'Tareas'
    },
    {
      icon: 'assignment_ind',
      link: '/inicio/trabajo-del-alumno',
      label: 'menu.student-work',
      funcionalidad: 'Seguimiento'
    },
    {
      icon: 'search',
      link: '/inicio/buscador',
      label: 'other-menu.search',
      funcionalidad: 'Buscador'
    },
    {
      icon: 'search',
      link: '/inicio/buscador',
      label: 'other-menu.search',
      funcionalidad: 'BuscadorColombia'
    },
    // {
    //   icon: 'widgets',
    //   link: '/teams/canales',
    //   label: 'Teams',
    //   funcionalidad: 'Buscador'
    // }
  ]

  studentMenu = [
    {
      icon: 'book',
      link: '/inicio/mis-materias',
      label: 'menu.subjects-alumno',
      reverse: true,
      funcionalidad: 'MateriasLibros'
    },
    {
      icon: 'assignment',
      link: '/inicio/tareas',
      label: 'menu.homework-alumno',
      funcionalidad: 'Tareas'
    },
    {
      icon: 'assignment_ind',
      link: '/inicio/trabajo-del-alumno',
      label: 'menu.student-work-alumno',
      funcionalidad: 'Seguimiento'
    }
  ]

  teacherSecondaryMenu = [
    {
      icon: 'icono_discovery',
      link: '',
      label: 'other-menu.inquiry-environment',
      newTab: true,
      funcionalidad: 'BancoContenidos'
    },
    {
      icon: 'logo_planetarium',
      link: 'https://formacion.aulaplaneta.com/login/index.php',
      label: 'other-menu.inquiry-environment.planetarium',
      newTab: true,
      funcionalidad: 'Planetarium'
    },
    // {
    //   icon: 'work',
    //   link: 'http://docsp.aulaplaneta.com/labor-docente-es/material-docente?UsuarioRol=Profesor',
    //   label: 'other-menu.teaching-material',
    //   newTab: true,
    //   funcionalidad: 'MaterialDocente'
    // },
    // {
    //   icon: 'help_center',
    //   link: 'https://www.youtube.com/playlist?list=PLQigX-k8viuraG08PCW1XXqjM3A7VTmr6',
    //   label: 'other-menu.help',
    //   newTab: true,
    //   funcionalidad: 'Ayuda'
    // }
    // {
    //   icon: 'view_module',
    //   link: '#/inicio/dialogos',
    //   label: 'other-menu.dialogs',
    //   newTab: false
    // },
    // {
    //   icon: 'gavel',
    //   link: '#/textos-legales',
    //   label: 'other-menu.textos-legales',
    //   newTab: false
    // }
  ]

  studentSecondaryMenu = [
    {
      icon: 'icono_discovery',
      link: '',
      label: 'other-menu.inquiry-environment',
      newTab: true,
      funcionalidad: 'BancoContenidos'
    },
    // {
    //   icon: 'help_center',
    //   link: 'https://www.youtube.com/watch?v=pjmdF6GlLN8',
    //   label: 'other-menu.help',
    //   newTab: true,
    //   funcionalidad: 'Ayuda'
    // }
    // {
    //   icon: 'view_module',
    //   link: '#/inicio/dialogos',
    //   label: 'other-menu.dialogs',
    //   newTab: false
    // },
    // {
    //   icon: 'gavel',
    //   link: '#/textos-legales',
    //   label: 'other-menu.textos-legales',
    //   newTab: false
    // }
  ];

  contextMenu = [
    {
      icon: 'article',
      title: 'Principales funcionalidades',
      subtitle: 'Profesorado',
      description: 'Descubre todo lo que puedes hacer con Aulaplaneta.',
      url: 'https://www.youtube.com/playlist?list=PLQigX-k8viuraG08PCW1XXqjM3A7VTmr6'
    },
    {
      icon: 'article',
      title: 'Conoce el entorno de aprendizaje',
      subtitle: 'Alumnado',
      description: 'Descubre qué ven los estudiantes y cómo pueden trabajar con Aulaplaneta. ',
      url: 'https://youtube.com/playlist?list=PLQigX-k8viur2XpUp8Q1Bp1hDXcZjXZO2'
    },
    // {
    //   icon: 'article',
    //   title: 'Crea tu propio temario en aulaPlaneta',
    //   subtitle: 'Programación de aula',
    //   description: '',
    //   url: '#'
    // },
    // {
    //   icon: 'article',
    //   title: 'Genera tu programación a medida',
    //   subtitle: 'Personalizar temario',
    //   description: '',
    //   url: '#'
    // },
    // {
    //   icon: 'article',
    //   title: 'Trabajo con las unidades de aulaPlaneta',
    //   subtitle: 'Las unidades de aulaPlaneta',
    //   description: 'Las materias en estas paginas están organizadas segñum temario',
    //   url: '#'
    // },
    // {
    //   icon: 'article',
    //   title: 'Crea tu propio temario en aulaPlaneta',
    //   subtitle: 'Programación de aula',
    //   description: '',
    //   url: '#'
    // }
  ]


  teacherAvatarMenu = [
    {
      label: 'other-menu.my-profile',
      funcionalidad: 'Mi Perfil'
    },

  ]

  @ViewChild('sidenav')
  sidenav: MatSidenav

  notificacionesTareas = [];
  // printingTareas = null;
  // printingTareas2 = [];

  isScrolling = false;
  isOpenContext = false;
  sideconf = {
    fixed: false,
    open: false
  };

  userMenu = false;
  selectedDate: Date = new Date();
  settings$: Observable<Settings>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dateHelper: DateHelper,
    public colorHelper: ColorHelper,
    public breadcrumbService: BreadcrumbService,
    public taskListService: TaskListService,
    public editionModeService: EditionModeService,
    public authManagementService: AuthManagementService,
    public tareaManagementService: TareaManagementService,
    public temasManagementService: TemasManagementService,
    public miCentroManagementService: MiCentroManagementService,
    public materiaManagementService: MateriaManagementService,
    public smartlinkManagementService: SmartlinkManagementService,
    public settingsService: SettingsService,
    public translateService: TranslateService,
    public router: Router,
    public ltiManagementService: LtiManagementService,
    public microsoftTeamsService: MicrosoftTeamsService,
    public envService: EnvService,
    public localStorageService: LocalStorageService,
    private gtagService: GtagService,
    private cookiesManagementService: CookiesManagementService,
    private dialog: MatDialog,
  ) {
    if (!this.ltiManagementService.moodle && !this.authManagementService.isOnlyMaticUser()) {
      this.materiaManagementService.getMisMaterias();
      this.materiaManagementService.getAsignaturas();
    }

    /*
    if (this.cookiesManagementService.cookies !== null && this.cookiesManagementService.cookies.length === 0) {
      document.body.classList.add('cdk-global-scrollblock');
      const dialogRef = this.dialog.open(ConfiguracionCookiesComponent, {
        data: {
          cookies: [...this.cookiesManagementService.cookies],
          cookiesData: this.cookiesManagementService.cookiesData
        }
      });
      dialogRef.afterClosed().pipe().subscribe(result => {
        document.body.classList.remove('cdk-global-scrollblock');
        this.cookiesManagementService.setCookies(result);
      });
    }
    */

  }

  @HostListener('window:scroll', ['$event'])
  eventoScroll($event) {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.isScrolling = true;
    }
    else if (this.isScrolling && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.isScrolling = false;
    }
    if (window.pageYOffset >= this.breadcrumbService.offSetBreadcrumb ||
      document.documentElement.scrollTop >= this.breadcrumbService.offSetBreadcrumb ||
      document.body.scrollTop >= this.breadcrumbService.offSetBreadcrumb) {
      this.breadcrumbService.breadcrumbs.map(item => { item.visible = true; });
    } else {
      this.breadcrumbService.breadcrumbs.map(item => { item.visible = false; });
    }
  }

  ngOnInit(): void {


    this.authManagementService.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          this.user = user;

          this.miCentroManagementService.getLogo();
          this.miCentroManagementService.getIdioma();
          this.miCentroManagementService.idioma$
            .pipe(takeUntil(this.destroy$))
            .subscribe(val => {
              if (val) {
                let codeLang = val.idiomaId.includes('CO') ? val.idiomaId.split('-')[1].toLowerCase() : val.idiomaId.split('-')[0];
                codeLang = codeLang === 'va' ? 'vc' : codeLang;
                this.translateService.setDefaultLang(codeLang);
                this.translateService.use(codeLang);
              } else {

                this.translateService.setDefaultLang('es');
                this.translateService.use('es');
              }
            });

          const isAlumno = this.user.usuarioRoles.includes('Alumno');
          this.navigationSideMenu = isAlumno ? this.studentMenu : this.teacherMenu;
          this.otherSideMenu = isAlumno ? this.studentSecondaryMenu : this.teacherSecondaryMenu;
          if (isAlumno) {
            this.notificaciones();
          }
        }



        // this.microsoftTeamsService.context$.subscribe(val => {
        //   console.log('main context subscribe', val)
        // })
      });

    this.ltiManagementService.moodle$.pipe(
      takeUntil(this.destroy$))
      .subscribe((moodle) => {
        if (moodle && this.authManagementService.userCanPermission(['BuscadorLtiAndalucia'])) {
          this.contextMenu = [
            {
              icon: 'article',
              title: 'Qué recursos tienes disponibles en tu Aula Virtual',
              subtitle: '',
              // eslint-disable-next-line max-len
              description: 'En este espacio encontrarás toda la información sobre los recursos de Aulaplaneta que podrás incluir en tu Aula Virtual para enriquecer tus clases y poder hacer el seguimiento de tus alumnos y alumnas',
              url: 'https://sites.google.com/gsuite.aulaplaneta.net/odeandalucia/inicio'
            }];
        } else if (moodle) {
          this.contextMenu = [
            {
              icon: 'article',
              title: 'Qué recursos tienes disponibles en tu Aula Virtual',
              subtitle: '',
              // eslint-disable-next-line max-len
              description: 'En este espacio encontrarás toda la información sobre los recursos de Aulaplaneta que podrás incluir en tu Aula Virtual para enriquecer tus clases y poder hacer el seguimiento de tus alumnos y alumnas',
              url: 'https://sites.google.com/view/recursosaulaplaneta/inicio'
            }];
        }

      });

    this.localStorageService.getItem('JWT')
      .pipe(takeUntil(this.destroy$))
      .subscribe(jwt => {
        // El enlace a Banco solo funciona si el BEARER no es un usuario de INT.
        const bancoUri = `${this.envService.bancoUri}?DATA=Bearer ${jwt}`;
        this.teacherSecondaryMenu[0].link = bancoUri;
        this.studentSecondaryMenu[0].link = bancoUri;
        if (!this.user?.colegio?.bancoContenidosHabilitado) {
          this.studentSecondaryMenu.shift();
        }
      });


    this.reloadOTBanner()



  }

  ngAfterViewInit(): void {
    this.taskListService.open$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) {
        setTimeout(() => {
          const previousCalendarBtn = document.querySelectorAll('.mat-calendar-controls .mat-calendar-previous-button');
          const nextCalendarBtn = document.querySelectorAll('.mat-calendar-controls .mat-calendar-next-button');

          if (previousCalendarBtn.length) {
            previousCalendarBtn[0].setAttribute('aria-label', this.translateService.instant('generico.anterior'));
          }

          if (nextCalendarBtn.length) {
            nextCalendarBtn[0].setAttribute('aria-label', this.translateService.instant('generico.siguiente'));
          }
        }, 2000);
      }
    });
  }


  reloadOTBanner() {
    const otConsentSdk = document.getElementById('onetrust-consent-sdk');
    if (otConsentSdk) {
      otConsentSdk.remove();
    }

    if ((window as any).OneTrust != null) {
      (window as any).OneTrust.Init();

      setTimeout(function () {
        (window as any).OneTrust.LoadBanner();

        const toggleDisplay = document.getElementsByClassName(
          'ot-sdk-show-settings'
        );

        for (let i = 0; i < toggleDisplay.length; i++) {
          (toggleDisplay[i] as HTMLElement).onclick = function (event) {
            event.stopImmediatePropagation();
            (window as any).OneTrust.ToggleInfoDisplay();
          };
        }
      }, 1000);
    }
  }

  onLogoutClick() {
    this.authManagementService.redirectUrl = null;
    this.authManagementService.authLogout();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidenav(show, ev) {
    ev.stopPropagation();
    if (show) {
      if (!this.sideconf.open) {
        this.sidenav.open();
        this.sideconf.open = true;
      }

      const info = {
        event: 'open_menu',
        openMenu: {
          menuId: 'burguer_menu',
          pageId: ev.view.location.hash,
        }
      };
      this.gtagService.event(info);
    } else {
      this.sidenav.close();
      this.sideconf.open = false;
    }
  }

  toggleMenu(val) {
    this.userMenu = val;
  }

  // scrollToTop(event: PointerEvent) {
  //   (function smoothscroll() {
  //     const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

  //     if (currentScroll > 0) {
  //       window.requestAnimationFrame(smoothscroll);
  //       window.scrollTo(0, currentScroll - (currentScroll / 8));
  //     }
  //   })();

  //   const info = {
  //     event: 'select_icon',
  //     selectIcon: {
  //       optionSelected: 'scroll_to_top',
  //       pageId: event.view.location.hash,
  //     }
  //   };
  //   this.gtagService.event(info); // location.href == http://localhost:4200/inicio/tareas | location.hash == /inicio/tareas
  // }

  scrollToTop(event: PointerEvent) {

    const currentScroll = document.documentElement.scrollTop;
    if (currentScroll > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: 'scroll_to_top',
        pageId: event.view.location.hash,
      }
    };
    this.gtagService.event(info); // location.href == http://localhost:4200/inicio/tareas | location.hash == /inicio/tareas
  }



  changeBg(url) {
    if (url.includes('mi-perfil') || url.includes('mi-centro')) {
      return 'light-blue-bg';
    } else if (url.includes('textos-legales')) {
      return 'light-grey-bg';
    } else {
      return '';
    }
  }

  isActive(base: string) {
    return this.router.url.includes(`/${base}`) ? 'active' : '';
  }

  route(url, openInNewTab = false) {
    if (openInNewTab) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }

  closeSidenav(sidenav) {
    if (sidenav._opened) {
      sidenav.close();
      this.sideconf.open = false;
    }
  }

  openContext(event: PointerEvent) {
    this.isOpenContext = !this.isOpenContext;
    if (this.isOpenContext) {
      const info = {
        event: 'select_icon',
        selectIcon: {
          optionSelected: 'help',
          pageId: event?.view?.location.hash,
        }
      };
      this.gtagService.event(info);
    }
  }

  openNotifications(event: PointerEvent) {
    this.taskListService.open = !this.taskListService.open;
    this.getCalendarBadges();
    if (this.taskListService.open) {
      const info = {
        event: 'select_icon',
        selectIcon: {
          optionSelected: 'calendar',
          pageId: event?.view?.location.hash,
        }
      };
      this.gtagService.event(info);
    }
  }

  countTareas(tareas) {
    let numberItems = 0;
    tareas.forEach(element => {
      numberItems += element.tareas.length;
    });

    return numberItems > 99 ? numberItems + '+' : numberItems;
  }

  getCalendarBadges() {
    if (this.notificacionesTareas.length) {
      this.notificacionesTareas.forEach(tarea => {
        const dayFormatted = new Date(this.dateHelper.getOffsetLocal(tarea.fecha))
          .toLocaleDateString('es-es', { year: 'numeric', month: 'long', day: 'numeric' });
        this.drawBadges(dayFormatted, tarea.tareas.length);
      });
    }
  }

  drawBadges(search, number) {
    const divNode = document.createElement('div');
    const textNode = document.createTextNode(number);

    divNode.className = 'calendar-badge';
    divNode.appendChild(textNode);

    setTimeout(() => {
      const element = document.querySelectorAll(`[aria-label="${search}"]`);

      if (element.length) {
        element[0].appendChild(divNode);
      }
    });
  }

  navigateToTaskList(element: Date): void {
    const elementId = formatDate(element, 'dd MMM y', 'es-es');
    const domElement = document.getElementById(elementId);
    if (domElement) {
      document.getElementById(elementId).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }





  @HostListener('click', ['$event'])
  detectChangeMonthCalendar(event: any) {
    if (event.target.ariaLabel && event.target.ariaLabel.includes('Previous month')
      || event.target.ariaLabel && event.target.ariaLabel.includes('Next month')) {
      this.getCalendarBadges();
    }
  }

  notificaciones() {

    this.tareaManagementService.getNotificacionesTareasDelAlumno();

    this.tareaManagementService.notificaciones$
      .pipe(takeUntil(this.destroy$))
      .subscribe((tareas) => {
        let date;
        let count = -1;
        this.notificacionesTareas = [];
        if (tareas) {
          // this.printingTareas = tareas;
          tareas = tareas.sort((a, b) => (a.vigencia.fechaFin > b.vigencia.fechaFin) ? 1
            : ((b.vigencia.fechaFin > a.vigencia.fechaFin) ? -1 : 0));

          tareas.forEach((item) => {
            if (date !== item.vigencia.fechaFin) {
              count += 1;
              date = item.vigencia.fechaFin;
              this.notificacionesTareas.push({ fecha: date, tareas: [] });
            }
            this.notificacionesTareas[count].tareas.push(item);
          });


          // LIMPIEZA DE DUPLICADOS Y FECHAS VACIAS PARA NAVEGADORES TABLETS QUE NO GESTIONAN BIEN LA ASINCRONÍA
          this.notificacionesTareas = this.notificacionesTareas.filter(obj => obj.tareas.length > 0);
          this.notificacionesTareas.forEach((item, index) => {
            if (item.tareas.length > 0) {
              item.tareas = item.tareas.filter((value, index, self) =>
                index === self.findIndex((t) => (
                  t.id === value.id && t.titulo === value.titulo
                ))
              );
            }
          });

        }
      });
  }


  captureFocus(element) {
    console.log(element.relatedTarget);
  }


  routeTarea(id) {
    const ruta = `/inicio/tareas/${id}/actividad/0/alumno/0/evaluacion`;
    this.router.navigate([ruta]);
    // const location = this.location as any;
    // window.open(`${location._platformStrategy._platformLocation.location.origin}/#${ruta}`, '_blank').focus();
  }

  isBuscador() {
    return this.router.url.includes('buscador');
  }

  isEvaluacionMicrosoft() {
    return this.router.url.includes('evaluacion/microsoft');
  }

  gtagInfo(item, event) {
    const info = {
      event: 'select_menu_item',
      selectMenuItem: {
        menuItemName: item.funcionalidad,
        menuId: 'burguer_menu',
        pageId: event.view.location.hash
      }
    };
    this.gtagService.event(info);
  }

  gtagUserMenu(selection, event) {
    const info = {
      event: 'open_menu',
      openMenu: {
        menuId: selection ? selection : 'user_menu',
        pageId: event.view.location.hash,
      }
    };

    this.gtagService.event(info);

  }
}
