import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MiCentroManagementService } from '../../../../features/mi-centro/services/mi-centro-management.service';
import { GestionAlumnoManagementService } from '../../../../services/data/gestion-alumno/gestion-alumno-management.service';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-nuevo-grupo-alumnos',
  templateUrl: './nuevo-grupo-alumnos.component.html',
  styleUrls: ['./nuevo-grupo-alumnos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NuevoGrupoAlumnosComponent implements OnInit {

  form: UntypedFormGroup;
  alumnos: any[] = [];
  alumnosTemp: any[] = [];
  alumnosAsignados: any[] = [];
  selectionDisponibles: SelectionModel<null>;
  selectionAsignados: SelectionModel<null>;
  editMode = false;
  pageId: string;


  constructor(
    public dialogRef: MatDialogRef<NuevoGrupoAlumnosComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: UntypedFormBuilder,
    public gestionAlumnoManagementService: GestionAlumnoManagementService,
    public miCentroManagementService: MiCentroManagementService,
    private gtagService: GtagService
  ) {
    this.gestionAlumnoManagementService.getMisClases();
    this.gestionAlumnoManagementService.getMisAlumnos();
    this.selectionDisponibles = new SelectionModel<null>(true, []);
    this.selectionAsignados = new SelectionModel<null>(true, []);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      nombreGrupo: ['', [Validators.required]],
      claseRef: [''],
      alumnosAsignados: ['', [Validators.required]]
    });

    this.pageId =  this.data.pageId;
    if (this.data && this.data.type === 'edit_group') {
      this.editMode = true;
      this.alumnosAsignados = this.data.alumnosAsignados;
      this.form.patchValue({
        ...this.data
      });
    }

    const info = {
      event: 'popup',
      popup: {
        action: this.data.type,
        pageId: this.pageId,
        popupId: 'dialog-nuevo-grupo-alumnos',
        popupName: 'popup_add_group'
      }
    };
    this.gtagService.event(info);
  }

  drop(event: CdkDragDrop<string[]>) {
    this.form.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }


  setAlumnosList(grupo) {
    let alumnos;
    // si no hay clase es que es un grupo personalizado y trae el array de alumnos
    if (!grupo.clase) {
      alumnos = grupo.alumnos;
    } else {
      alumnos = this.gestionAlumnoManagementService.getListaAlumnosEnUnaClase(grupo.clase, this.gestionAlumnoManagementService.misAlumnos);
    }
    this.alumnosTemp = (alumnos && alumnos.length > 0) ? alumnos : [];
    this.alumnos = this.alumnosTemp.filter(alumno => !this.alumnosAsignados.some(aluAsi => aluAsi.id === alumno.id));
  }

  asignarAlumnos() {
    this.alumnosAsignados = this.alumnosAsignados.concat(this.selectionDisponibles.selected);

    this.form.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.alumnos = this.alumnos.filter(alumno => !this.selectionDisponibles.selected.includes(alumno));
    this.selectionDisponibles.clear();
  }

  asignarTodos() {
    this.alumnos.forEach((row) => this.selectionDisponibles.select(row));
    this.asignarAlumnos();
  }

  desasignarAlumnos() {
    this.alumnos = this.alumnos.concat(this.alumnosTemp.filter(alumno => this.selectionAsignados.selected.includes(alumno)));

    this.alumnosAsignados = this.alumnosAsignados.filter(alumno => !this.selectionAsignados.selected.includes(alumno));
    this.form.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.selectionAsignados.clear();
  }

  desasignarTodos() {
    this.alumnosAsignados.forEach((row) => this.selectionAsignados.select(row));
    this.desasignarAlumnos();
  }

  close(result) {
    if (result) {
      const formData = this.form.value;
      this.dialogRef.close(formData);
    } else {
      this.dialogRef.close();
    }
  }
}
