import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ColorHelper } from '../../../helper/color.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';
import * as _ from 'lodash';

@Component({
  selector: 'aula-planeta-add-seccion',
  templateUrl: 'add-seccion.component.html',
  styleUrls: ['./add-seccion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddSeccionComponent implements OnInit {
  @ViewChild('stepper') private stepperSection: MatStepper;

  firstStep: UntypedFormGroup;
  secondStep: UntypedFormGroup;
  currentStep: Number = 0;
  textLimit: Number = 40;
  selection: SelectionModel<null>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('selectorProgreso') selectorProgreso: MatSelect;
  filtroRelevancia = 'mayor-relevancia';
  tipoTemas = 'ver-todo';
  filterTemas = '';
  filter = '';

  catalog = {
    temas: [
      {
        id: '1',
        titulo: 'Eiusmod aliquip aliquip Lorem sint.',
        origen: 'custom',
        imagen: 'https://media.floresfrescasonline.com/product/trebol-de-la-suerte-trebol-de-4-hojas-800x800.jpeg',
        code: 'science',
        curso: {
          ordinal: 5,
          nombre: '5º',
          etapa: {
            id: 1,
            nombre: 'Primaria',
            acronimo: 'Primaria'
          },
        },
        indiceSecciones: [
          {
            id: '1',
            tipo: 'seccion',
            indice: {
              seccion: 1,
              subseccion: 0
            },
            titulo: 'Introducción'
          },
          {
            id: '2',
            tipo: 'seccion',
            indice: {
              seccion: 2,
              subseccion: 0
            },
            titulo: 'Dolore ad velit nisi commodo veniam aliqua labore consectetur.'
          },
          {
            id: '3',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 1
            },
            titulo: 'Qui qui nulla anim ex nisi quis dolore qui nisi aute id.'
          },
          {
            id: '4',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 2
            },
            titulo: 'Est exercitation sint ullamco et exercitation incididunt ut irure quis qui non irure nisi excepteur.'
          },
          {
            id: '5',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 3
            },
            titulo: 'Officia eu et nisi labore.'
          },
          {
            id: '6',
            tipo: 'seccion',
            indice: {
              seccion: 3,
              subseccion: 0
            },
            titulo: 'Culpa qui esse ea veniam irure commodo.'
          },
          {
            id: '6',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 1
            },
            titulo: 'Eu consectetur mollit ullamco sint magna aliquip nostrud officia ad sit et dolor non.'
          },
          {
            id: '7',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 2
            },
            titulo: 'Do mollit cillum sunt sint voluptate consectetur dolore dolor id adipisicing ex proident labore veniam.'
          },
          {
            id: '8',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '9',
            tipo: 'seccion',
            indice: {
              seccion: 4,
              subseccion: 0
            },
            titulo: 'Dolor aliqua officia esse labore culpa voluptate aliqua.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '17',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 2
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '18',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '26',
            tipo: 'seccion',
            indice: {
              seccion: 5,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '27',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '28',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '29',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '36',
            tipo: 'seccion',
            indice: {
              seccion: 6,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '37',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '38',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '39',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '50',
            tipo: 'seccion',
            indice: {
              seccion: 7,
              subseccion: 7
            },
            titulo: 'Ejercitación, proyectos y competencias'
          }
        ]
      },
      {
        id: '1',
        titulo: 'Eiusmod aliquip aliquip Lorem sint.',
        origen: 'aulaplaneta',
        imagen: 'https://media.floresfrescasonline.com/product/trebol-de-la-suerte-trebol-de-4-hojas-800x800.jpeg',
        code: 'science',
        curso: {
          ordinal: 5,
          nombre: '5º',
          etapa: {
            id: 1,
            nombre: 'Primaria',
            acronimo: 'Primaria'
          },
        },
        indiceSecciones: [
          {
            id: '1',
            tipo: 'seccion',
            indice: {
              seccion: 1,
              subseccion: 0
            },
            titulo: 'Introducción'
          },
          {
            id: '2',
            tipo: 'seccion',
            indice: {
              seccion: 2,
              subseccion: 0
            },
            titulo: 'Dolore ad velit nisi commodo veniam aliqua labore consectetur.'
          },
          {
            id: '3',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 1
            },
            titulo: 'Qui qui nulla anim ex nisi quis dolore qui nisi aute id.'
          },
          {
            id: '4',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 2
            },
            titulo: 'Est exercitation sint ullamco et exercitation incididunt ut irure quis qui non irure nisi excepteur.'
          },
          {
            id: '5',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 3
            },
            titulo: 'Officia eu et nisi labore.'
          },
          {
            id: '6',
            tipo: 'seccion',
            indice: {
              seccion: 3,
              subseccion: 0
            },
            titulo: 'Culpa qui esse ea veniam irure commodo.'
          },
          {
            id: '6',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 1
            },
            titulo: 'Eu consectetur mollit ullamco sint magna aliquip nostrud officia ad sit et dolor non.'
          },
          {
            id: '7',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 2
            },
            titulo: 'Do mollit cillum sunt sint voluptate consectetur dolore dolor id adipisicing ex proident labore veniam.'
          },
          {
            id: '8',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '9',
            tipo: 'seccion',
            indice: {
              seccion: 4,
              subseccion: 0
            },
            titulo: 'Dolor aliqua officia esse labore culpa voluptate aliqua.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '17',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 2
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '18',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '26',
            tipo: 'seccion',
            indice: {
              seccion: 5,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '27',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '28',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '29',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '36',
            tipo: 'seccion',
            indice: {
              seccion: 6,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '37',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '38',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '39',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '50',
            tipo: 'seccion',
            indice: {
              seccion: 7,
              subseccion: 7
            },
            titulo: 'Ejercitación, proyectos y competencias'
          }
        ]
      },
      {
        id: '1',
        titulo: 'Eiusmod aliquip aliquip Lorem sint.',
        origen: 'custom',
        imagen: 'https://media.floresfrescasonline.com/product/trebol-de-la-suerte-trebol-de-4-hojas-800x800.jpeg',
        code: 'science',
        curso: {
          ordinal: 5,
          nombre: '5º',
          etapa: {
            id: 1,
            nombre: 'Primaria',
            acronimo: 'Primaria'
          },
        },
        indiceSecciones: [
          {
            id: '1',
            tipo: 'seccion',
            indice: {
              seccion: 1,
              subseccion: 0
            },
            titulo: 'Introducción'
          },
          {
            id: '2',
            tipo: 'seccion',
            indice: {
              seccion: 2,
              subseccion: 0
            },
            titulo: 'Dolore ad velit nisi commodo veniam aliqua labore consectetur.'
          },
          {
            id: '3',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 1
            },
            titulo: 'Qui qui nulla anim ex nisi quis dolore qui nisi aute id.'
          },
          {
            id: '4',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 2
            },
            titulo: 'Est exercitation sint ullamco et exercitation incididunt ut irure quis qui non irure nisi excepteur.'
          },
          {
            id: '5',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 3
            },
            titulo: 'Officia eu et nisi labore.'
          },
          {
            id: '6',
            tipo: 'seccion',
            indice: {
              seccion: 3,
              subseccion: 0
            },
            titulo: 'Culpa qui esse ea veniam irure commodo.'
          },
          {
            id: '6',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 1
            },
            titulo: 'Eu consectetur mollit ullamco sint magna aliquip nostrud officia ad sit et dolor non.'
          },
          {
            id: '7',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 2
            },
            titulo: 'Do mollit cillum sunt sint voluptate consectetur dolore dolor id adipisicing ex proident labore veniam.'
          },
          {
            id: '8',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '9',
            tipo: 'seccion',
            indice: {
              seccion: 4,
              subseccion: 0
            },
            titulo: 'Dolor aliqua officia esse labore culpa voluptate aliqua.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '17',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 2
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '18',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '26',
            tipo: 'seccion',
            indice: {
              seccion: 5,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '27',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '28',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '29',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '36',
            tipo: 'seccion',
            indice: {
              seccion: 6,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '37',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '38',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '39',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '50',
            tipo: 'seccion',
            indice: {
              seccion: 7,
              subseccion: 7
            },
            titulo: 'Ejercitación, proyectos y competencias'
          }
        ]
      },
      {
        id: '1',
        titulo: 'Eiusmod aliquip aliquip Lorem sint.',
        origen: 'custom',
        imagen: 'https://media.floresfrescasonline.com/product/trebol-de-la-suerte-trebol-de-4-hojas-800x800.jpeg',
        code: 'science',
        curso: {
          ordinal: 5,
          nombre: '5º',
          etapa: {
            id: 1,
            nombre: 'Primaria',
            acronimo: 'Primaria'
          },
        },
        indiceSecciones: [
          {
            id: '1',
            tipo: 'seccion',
            indice: {
              seccion: 1,
              subseccion: 0
            },
            titulo: 'Introducción'
          },
          {
            id: '2',
            tipo: 'seccion',
            indice: {
              seccion: 2,
              subseccion: 0
            },
            titulo: 'Dolore ad velit nisi commodo veniam aliqua labore consectetur.'
          },
          {
            id: '3',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 1
            },
            titulo: 'Qui qui nulla anim ex nisi quis dolore qui nisi aute id.'
          },
          {
            id: '4',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 2
            },
            titulo: 'Est exercitation sint ullamco et exercitation incididunt ut irure quis qui non irure nisi excepteur.'
          },
          {
            id: '5',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 3
            },
            titulo: 'Officia eu et nisi labore.'
          },
          {
            id: '6',
            tipo: 'seccion',
            indice: {
              seccion: 3,
              subseccion: 0
            },
            titulo: 'Culpa qui esse ea veniam irure commodo.'
          },
          {
            id: '6',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 1
            },
            titulo: 'Eu consectetur mollit ullamco sint magna aliquip nostrud officia ad sit et dolor non.'
          },
          {
            id: '7',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 2
            },
            titulo: 'Do mollit cillum sunt sint voluptate consectetur dolore dolor id adipisicing ex proident labore veniam.'
          },
          {
            id: '8',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '9',
            tipo: 'seccion',
            indice: {
              seccion: 4,
              subseccion: 0
            },
            titulo: 'Dolor aliqua officia esse labore culpa voluptate aliqua.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '17',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 2
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '18',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '26',
            tipo: 'seccion',
            indice: {
              seccion: 5,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '27',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '28',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '29',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '36',
            tipo: 'seccion',
            indice: {
              seccion: 6,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '37',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '38',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '39',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '50',
            tipo: 'seccion',
            indice: {
              seccion: 7,
              subseccion: 7
            },
            titulo: 'Ejercitación, proyectos y competencias'
          }
        ]
      },
      {
        id: '1',
        titulo: 'Eiusmod aliquip aliquip Lorem sint.',
        origen: 'custom',
        imagen: 'https://media.floresfrescasonline.com/product/trebol-de-la-suerte-trebol-de-4-hojas-800x800.jpeg',
        code: 'science',
        curso: {
          ordinal: 5,
          nombre: '5º',
          etapa: {
            id: 1,
            nombre: 'Primaria',
            acronimo: 'Primaria'
          },
        },
        indiceSecciones: [
          {
            id: '1',
            tipo: 'seccion',
            indice: {
              seccion: 1,
              subseccion: 0
            },
            titulo: 'Introducción'
          },
          {
            id: '2',
            tipo: 'seccion',
            indice: {
              seccion: 2,
              subseccion: 0
            },
            titulo: 'Dolore ad velit nisi commodo veniam aliqua labore consectetur.'
          },
          {
            id: '3',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 1
            },
            titulo: 'Qui qui nulla anim ex nisi quis dolore qui nisi aute id.'
          },
          {
            id: '4',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 2
            },
            titulo: 'Est exercitation sint ullamco et exercitation incididunt ut irure quis qui non irure nisi excepteur.'
          },
          {
            id: '5',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 3
            },
            titulo: 'Officia eu et nisi labore.'
          },
          {
            id: '6',
            tipo: 'seccion',
            indice: {
              seccion: 3,
              subseccion: 0
            },
            titulo: 'Culpa qui esse ea veniam irure commodo.'
          },
          {
            id: '6',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 1
            },
            titulo: 'Eu consectetur mollit ullamco sint magna aliquip nostrud officia ad sit et dolor non.'
          },
          {
            id: '7',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 2
            },
            titulo: 'Do mollit cillum sunt sint voluptate consectetur dolore dolor id adipisicing ex proident labore veniam.'
          },
          {
            id: '8',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '9',
            tipo: 'seccion',
            indice: {
              seccion: 4,
              subseccion: 0
            },
            titulo: 'Dolor aliqua officia esse labore culpa voluptate aliqua.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '17',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 2
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '18',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '26',
            tipo: 'seccion',
            indice: {
              seccion: 5,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '27',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '28',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '29',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '36',
            tipo: 'seccion',
            indice: {
              seccion: 6,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '37',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '38',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '39',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '50',
            tipo: 'seccion',
            indice: {
              seccion: 7,
              subseccion: 7
            },
            titulo: 'Ejercitación, proyectos y competencias'
          }
        ]
      },
      {
        id: '1',
        titulo: 'Eiusmod aliquip aliquip Lorem sint.',
        origen: 'custom',
        imagen: 'https://media.floresfrescasonline.com/product/trebol-de-la-suerte-trebol-de-4-hojas-800x800.jpeg',
        code: 'science',
        curso: {
          ordinal: 5,
          nombre: '5º',
          etapa: {
            id: 1,
            nombre: 'Primaria',
            acronimo: 'Primaria'
          },
        },
        indiceSecciones: [
          {
            id: '1',
            tipo: 'seccion',
            indice: {
              seccion: 1,
              subseccion: 0
            },
            titulo: 'Introducción'
          },
          {
            id: '2',
            tipo: 'seccion',
            indice: {
              seccion: 2,
              subseccion: 0
            },
            titulo: 'Dolore ad velit nisi commodo veniam aliqua labore consectetur.'
          },
          {
            id: '3',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 1
            },
            titulo: 'Qui qui nulla anim ex nisi quis dolore qui nisi aute id.'
          },
          {
            id: '4',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 2
            },
            titulo: 'Est exercitation sint ullamco et exercitation incididunt ut irure quis qui non irure nisi excepteur.'
          },
          {
            id: '5',
            tipo: 'subseccion',
            indice: {
              seccion: 2,
              subseccion: 3
            },
            titulo: 'Officia eu et nisi labore.'
          },
          {
            id: '6',
            tipo: 'seccion',
            indice: {
              seccion: 3,
              subseccion: 0
            },
            titulo: 'Culpa qui esse ea veniam irure commodo.'
          },
          {
            id: '6',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 1
            },
            titulo: 'Eu consectetur mollit ullamco sint magna aliquip nostrud officia ad sit et dolor non.'
          },
          {
            id: '7',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 2
            },
            titulo: 'Do mollit cillum sunt sint voluptate consectetur dolore dolor id adipisicing ex proident labore veniam.'
          },
          {
            id: '8',
            tipo: 'subseccion',
            indice: {
              seccion: 3,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '9',
            tipo: 'seccion',
            indice: {
              seccion: 4,
              subseccion: 0
            },
            titulo: 'Dolor aliqua officia esse labore culpa voluptate aliqua.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '16',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '17',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 2
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '18',
            tipo: 'subseccion',
            indice: {
              seccion: 4,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '26',
            tipo: 'seccion',
            indice: {
              seccion: 5,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '27',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '28',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '29',
            tipo: 'subseccion',
            indice: {
              seccion: 5,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '36',
            tipo: 'seccion',
            indice: {
              seccion: 6,
              subseccion: 0
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '37',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 1
            },
            titulo: 'Irure elit nisi id laboris velit sint minim adipisicing quis.'
          },
          {
            id: '38',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 2
            },
            titulo: 'Esse in officia consectetur anim id mollit.'
          },
          {
            id: '39',
            tipo: 'subseccion',
            indice: {
              seccion: 6,
              subseccion: 3
            },
            titulo: 'Consolidación'
          },
          {
            id: '50',
            tipo: 'seccion',
            indice: {
              seccion: 7,
              subseccion: 7
            },
            titulo: 'Ejercitación, proyectos y competencias'
          }
        ]
      }
    ]
  }
  dataCatalog = this.catalog.temas;

  personal = [
    {
      id: 4,
      name: 'Introducción'
    },
    {
      id: 5,
      name: 'Las características del método científico'
    },
    {
      id: 6,
      name: 'Los objetivos del método científico'
    },
    {
      id: 7,
      name: 'El proceso de investigación'
    },
    {
      id: 8,
      name: 'Los modelos de investigación'
    },
    {
      id: 9,
      name: 'El laboratorio científico'
    },
    {
      id: 10,
      name: 'Ejercitación, proyectos y competencias'
    },
    {
      id: 11,
      name: 'Lorem Ipsum 1'
    },
    {
      id: 12,
      name: 'Lorem Ipsum 2'
    },
    {
      id: 13,
      name: 'Lorem Ipsum 3'
    },
    {
      id: 14,
      name: 'Lorem Ipsum 4'
    },
    {
      id: 15,
      name: 'Lorem Ipsum 5'
    },
    {
      id: 16,
      name: 'Lorem Ipsum 6'
    },
    {
      id: 17,
      name: 'Lorem Ipsum 7'
    }
  ];

  constructor(
    private fb: UntypedFormBuilder,
    public colorHelper: ColorHelper,
    public dialogRef: MatDialogRef<AddSeccionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    this.selection = new SelectionModel(true, []);
  }

  ngOnInit() {
    this.firstStep = this.fb.group({
      sectionType: [1, Validators.required]
    });

    this.secondStep = this.fb.group({
      title: ['', Validators.required]
    });

    const info = {
      event: 'popup_steps',
      popupSteps: {
        action: 'add_section',
        popupId: 'dialog-add-seccion',
        popupName: 'popup_add_section',
        popupStep: this.currentStep.toString()
      }
    };
    this.gtagService.event(info);
  }

  onStepperChange(ev) {
    this.currentStep = ev.selectedIndex;
    if (this.currentStep === 0) {
      this.secondStep.controls['title'].setValue('');
      this.selection.clear();
    };

    const info = {
      event: 'popup_steps',
      popupSteps: {
        action: 'add_section',
        popupId: 'dialog-add-seccion',
        popupName: 'popup_add_section',
        popupStep: this.currentStep.toString()
      }
    };
    this.gtagService.event(info);
  }

  goBack() {
    this.stepperSection.previous();
    this.currentStep = 0;
  }

  goNext() {
    this.stepperSection.next();
    this.currentStep = 1;
  }

  onClose() {
    const result = this.firstStep.controls['sectionType'].value === 3 ?
      { titulo: this.secondStep.controls['title'].value } : { temas: this.selection.selected };
    this.dialogRef.close({ ...this.firstStep.value, ...result });
  }

  filtrarRelevancia(opcion: MatSelectChange) {
    if (opcion.value === 'mayor-relevancia') {
      this.sortData('ASC');
    } else if (opcion.value === 'menor-relevancia') {
      this.sortData('DESC');
    } else {
      this.sortData('ASC');
    }
  }

  filtrarTipoTemas(opcion: MatSelectChange) {
    this.selection.clear();

    if (opcion.value === 'otros-temas') {
      this.dataCatalog = this.catalog.temas.filter(elem => elem.origen === 'custom');
    } else if (opcion.value === 'temas-aula') {
      this.dataCatalog = this.catalog.temas.filter(elem => elem.origen === 'aulaplaneta');
    } else {
      this.dataCatalog = this.catalog.temas;
    }
  }

  sortData(sortDirection) {
    const isAsc = sortDirection === 'ASC';
    this.personal = this.personal.sort((a, b) => this.compare(a.id, b.id, isAsc));
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (typeof a === 'string') {
      a = _.deburr(a).toLowerCase().trim();
    }
    if (typeof b === 'string') {
      b = _.deburr(b).toLowerCase().trim();
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}