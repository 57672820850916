// WIREFRAMES - D13
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Profesor, TipoProfesor } from '../../../models/profesor.model';
import { MiCentroManagementService } from '../../../../features/mi-centro/services/mi-centro-management.service';
import { Persona } from '../../../models/persona.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { LtiManagementService } from '../../../../services/data/lti/lti-management.service';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { GestionAlumnoManagementService } from '../../../../services/data/gestion-alumno/gestion-alumno-management.service';
import { AuthManagementService } from '../../../../core/auth/auth-management.service';

@Component({
  selector: 'aula-planeta-start-compartir',
  templateUrl: './start-compartir.component.html',
  styleUrls: ['./start-compartir.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartCompartirComponent implements OnInit {

  profesoresControl = new UntypedFormControl();
  cursoControl = new UntypedFormControl();
  profesores: Profesor[] = [];
  profesoresFiltro: Observable<Profesor[]>;
  profesoresCompartir: any[] = [];
  tipoprofesor = [];
  displayedColumns: string[] = ['profesor', 'centro', 'estatus', 'acciones'];

  canSave = false;

  selectedFilters = '';
  filtersTypesArr = [
    // [
    //   // { value: 'Agrupacion', label: 'Todos los profesores de la agrupación' },
    //   { value: 'Centro', label: 'Todos los profesores del centro' },
    //   { value: 'Profesores', label: 'Selección de profesores' }
    // ],
    // [
    //   { value: 'NoCompartido', label: 'No compartir' },
    // ]
  ];
  filtersTypesMoodleArr = [
    [
      { value: 'Centro', label: 'Todos los profesores del centro' }
    ],
    [
      { value: 'NoCompartido', label: 'Dejar de compartir' }
    ]
  ];

  roles: any[] = [
    {
      name: '',
      items: [
        { value: 'Lector', viewValue: 'Lector' },
        { value: 'Editor', viewValue: 'Editor' }
      ]
    }
  ];


  constructor(
    private _change: ChangeDetectorRef,
    private miCentroManagementService: MiCentroManagementService,
    public dialogRef: MatDialogRef<StartCompartirComponent>,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    public ltiManagementService: LtiManagementService,
    private translateService: TranslateService,
    public gestionAlumnoManagementService: GestionAlumnoManagementService,
    public authManagementService: AuthManagementService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {

    this.authManagementService.isUsuarioVinculadoGoogle();
    this.authManagementService.isUsuarioVinculadoMicrosoft();

    this.filtersTypesArr = this.ltiManagementService.moodle ? this.filtersTypesMoodleArr : this.filtersTypesArr;

    this.selectedFilters = this.data.profesores && this.data.profesores.length > 0 ? 'Profesores' : this.data.compartir;

    const tmp$: Subject<void> = new Subject<void>();
    this.miCentroManagementService.getProfesores().pipe(
      takeUntil(tmp$))
      .subscribe(
        profesores => {
          tmp$.next();
          tmp$.complete();
          this.profesores = profesores;
          if (this.data.profesores) {
            const profesFiltered = this.profesores.filter(profesor => this.data.profesores.some(elem => elem.personaId === profesor.id));
            profesFiltered.map(profe =>
              this.data.profesores.forEach(elem => {
                if (profe.id === elem.personaId) {
                  profe.tipo = elem.rol;
                }
              })
            );

            this.profesoresCompartir = profesFiltered;
            this._change.markForCheck();
          }
        }
      );

    this.profesoresFiltro = this.profesoresControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    for (const value in TipoProfesor) {
      if (typeof TipoProfesor[value] === 'number') {
        this.tipoprofesor.push(value);
      }

    }

  }

  ngOnInit() {

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-start-compartir',
        popupName: 'popup_compartir',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
    if (!this.ltiManagementService.moodle) {

      if (this.data.compartir) {
        this.filtersTypesArr.push(
          [
            { value: 'Centro', label: 'Todos los profesores del centro' },
            { value: 'Profesores', label: 'Selección de profesores' }
          ]
        );
        this.filtersTypesArr.push(
          [
            { value: 'NoCompartido', label: 'No compartir' },
          ]
        );
      }


      if (this.authManagementService.currentUser.integraciones.google) {
        this.gestionAlumnoManagementService.getMisClasesClassroom();
        this.filtersTypesArr.push(
          [
            { value: 'Classroom', label: 'Google Classroom' }
          ]
        );
      }
      // TODO
      // if (this.authManagementService.currentUser.integraciones.microsoft) {
      //   this.filtersTypesArr.push(
      //     [
      //       { value: 'Teams', label: 'Microsoft Teams' }
      //     ]
      //   );
      // }
    }
  }

  displayFn(profe): string {
    return profe && profe.firstName ? `${profe.firstName} ${profe.lastName}` : '';
  }

  onClose() {
    this.clipboard.copy(this.data.linkAulaplaneta);
    let response = { teachers: this.profesoresCompartir, type: this.selectedFilters, link: this.data.linkAulaplaneta };
    if (this.selectedFilters === 'Classroom') {
      response = { ...response, course: this.cursoControl.value } as any;
    }
    this.dialogRef.close(response);
  }

  verClase(curso) {
    window.open(curso.value.alternateLink, '_blank');
  }

  anadirProfesorCompatir(profesor: Profesor) {
    if (profesor) {
      if (!profesor.tipo) {
        profesor.tipo = this.roles[0].items[0].value;
      }

      this.profesoresCompartir = [...this.profesoresCompartir, profesor];
      this.profesores.splice(this.profesores.findIndex(function (i) {
        return i.id === profesor.id;
      }), 1);

      this.profesoresControl.setValue('');
    }
  }

  quitarProfesorCompartir(profesor) {
    const position = this.profesoresCompartir.indexOf(profesor);
    if (position > -1) {
      this.profesoresCompartir = this.profesoresCompartir.filter((item, index) => index !== position);
      this.profesores = [...this.profesores, profesor];
      this.canSave = true;
      if (this.profesoresCompartir.length < 1) {
        this.selectedFilters = 'NoCompartido';
      }
    }
  }

  copyClipboard() {
    this.clipboard.copy(this.data.linkAulaplaneta);
    this.snackBar.open(this.translateService.instant('dialogs.copiar-enlace.copiado'), 'OK', {
      duration: 2000
    });
  }

  private _filter(value: string | Persona): Persona[] {
    const filterValue = (typeof value === 'string') ? value.toLowerCase() : `${value.nombre}${value.apellidoUno}${value.apellidoDos}`;
    return this.profesores.filter(profe => {
      const nombreApellido = `${profe.nombre}${profe.apellidoUno}${profe.apellidoDos}`;
      return nombreApellido.toLowerCase().includes(filterValue);
    });
  }

}
