import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthManagementService } from '../../../../core/core.module';
import { Subject } from 'rxjs';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-cambiar-contrasena',
  templateUrl: 'cambiar-contrasena.component.html',
  styleUrls: ['./cambiar-contrasena.component.scss'],
})
export class CambiarContrasenaComponent implements OnInit {

  form: UntypedFormGroup;
  private destroy$: Subject<void> = new Subject<void>();
  revealOld = false;
  revealNew = false;
  revealRepeat = false;

  constructor(
    public authManagementService: AuthManagementService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CambiarContrasenaComponent>, 
    @Inject(MAT_DIALOG_DATA) public data2,
    private gtagService: GtagService
  ) {}

  ngOnInit () {
    this.form = this.formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      repeatNewPassword: ['', [Validators.required, Validators.minLength(6)]],
    }, { validator: this.passwordMatchValidator });

    const info = {
      event: 'popup',
      popup: {
        action: 'change_password',
        popupId: 'dialog-camibar-contrasena',
        popupName: 'popup_change_password'
      }
    };
    this.gtagService.event(info);
  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    const error = frm.controls['newPassword'].value === frm.controls['repeatNewPassword'].value ? null : { mismatch: true };
    frm.controls['repeatNewPassword'].setErrors(error);
    return error;
  }

  onSubmit() {
    if (this.form.valid) {
      const passwords = this.form.getRawValue();
      // this.authManagementService.changePassword(this.authManagementService.currentUser, passwords)
      //   .pipe(
      //     take(1),
      //     takeUntil(this.destroy$)
      //   ).
      //   subscribe(() => {
      //     this.form.reset();
      //   });
      this.dialogRef.close({ passwords });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}