/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { EnvService } from '../../env.service';

@Injectable({
  providedIn: 'root'
})
export class LtiDataService {

  constructor(private http: HttpClient, public cache: LocalCacheService, private envService: EnvService) { }

  public getAccionesdidacticas(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltiaccionesdidacticas/Moodle`);
    return this.cache.observable('LTIDATASERVICE-ACCIONESDIDACTICAS', requestObservable);
  }

  public getBloquescontenidos(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltibloquescontenidos`);
    return this.cache.observable('LTIDATASERVICE-BLOQUESCONTENIDOS', requestObservable);
  }

  public getCompetenciasclaves(andalucia?: boolean): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}${(andalucia) ? '/cepda/lticompetenciasclaves' : '/cepda/lticompetenciasclaves'}/Moodle`);
    return this.cache.observable('LTIDATASERVICE-COMPETENCIASCLAVES', requestObservable);
  }

  public getCriteriosevaluaciones(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/lticriteriosevaluaciones`);
    return this.cache.observable('LTIDATASERVICE-CRITERIOSEVALUACIONES', requestObservable);
  }

  public getCursos(andalucia?: boolean): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}${(andalucia) ? '/cepda/lticursos' : '/lti/cursos'}`);
    return this.cache.observable('LTIDATASERVICE-CURSOS', requestObservable);
  }


  public getCursosEdixgal(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/lti/cursos/edixgal`);
    return this.cache.observable('LTIDATASERVICE-CURSOS', requestObservable);
  }

  public getEstandaresaprendizajes(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltiestandaresaprendizajes`);
    return this.cache.observable('LTIDATASERVICE-ESTANDARESAPRENDIZAJES', requestObservable);
  }

  public getEtapas(andalucia?: boolean): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}${(andalucia) ? '/cepda/ltietapas' : '/lti/etapas'}`);
    return this.cache.observable('LTIDATASERVICE-ETAPAS', requestObservable);
  }

  public getIdiomas(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltiidiomas`);
    return this.cache.observable('LTIDATASERVICE-IDIOMAS', requestObservable);
  }

  public getMaterias(andalucia?: boolean): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}${(andalucia) ? '/cepda/ltimaterias' : '/lti/materias'}`);
    return this.cache.observable('LTIDATASERVICE-MATERIAS', requestObservable);
  }

  public getNivelesdificultades(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltinivelesdificultades`);
    return this.cache.observable('LTIDATASERVICE-NIVELESDIFICULTADES', requestObservable);
  }

  public getTaxonomiasblooms(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltitaxonomiasblooms`);
    return this.cache.observable('LTIDATASERVICE-TAXONOMIASBLOOMS', requestObservable);
  }

  public getTiposcontenidos(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltitiposcontenidos/Moodle`);
    return this.cache.observable('LTIDATASERVICE-TIPOSCONTENIDOS', requestObservable);
  }

  public getTiposmediarecursos(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltitiposmediasrecursos/Moodle`);
    return this.cache.observable('LTIDATASERVICE-TIPOSMEDIARECURSOS', requestObservable);
  }

  public getTiposmedia(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltitipoMedia`);
    return this.cache.observable('LTIDATASERVICE-TIPOSMEDIA', requestObservable);
  }

  public getTematica(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltitematica`);
    return this.cache.observable('LTIDATASERVICE-TEMATICA', requestObservable);
  }

}
