<form id="dialog-mi-clase" role="form" [formGroup]="form">
    <fieldset>
        <h1 mat-dialog-title>{{'dialogs.mis-clase.titulo' | translate}}</h1>
        <div mat-subheader>{{'dialogs.mis-clase.subtitulo' | translate}}: </div>
         <div mat-dialog-content class="mat-dialog-content">
            <div formArrayName="cursos">
                <div *ngFor="let curso of form.get('cursos')['controls']; let i = index">
                    <mat-card class="mb-4" color="primary">
                        <div>
                            <div class="row">
                                <div class="col-md-4">
                                    <span class="ms-3">{{curso.get('name').value}} </span>
                                </div>
                                <div formArrayName="{{i}}" class="col-md-8">
                                    <mat-radio-group>
                                        <mat-radio-button *ngFor="let grupo of this.data.cursos[i].grupos; let z = index" [value]="form.get('cursos')['controls'][i].controls.grupos.value">
                                            <span class="me-3">{{grupo.name}}</span>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>

        <div mat-dialog-actions class="flex-row-reverse justify-content-start">
            <button role="button" mat-raised-button color="accent" [mat-dialog-close]="form.value.cursos" cdkFocusInitial type="submit" class="ms-2">{{'dialogs.mis-clase.accion' | translate}}</button>
            <button role="button" mat-button mat-dialog-close>{{'generico.cancelar' | translate}}</button>
        </div>
    </fieldset>
</form>
