import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { EnvService } from '../../env.service';

@Injectable({
  providedIn: 'root'
})
export class BuscadorDataService {

  constructor(private http: HttpClient, public cache: LocalCacheService, private envService: EnvService) { }

  public getTemas(filtro: string): Observable<any> {
    // const headers = { noLoader: 'noLoader' };
    const headers = {};
    return this.http.post<any>(`${this.envService.apiUrl}/buscador/temas`, filtro, { headers });
  }

  public getSecciones(filtro: string): Observable<any> {
    // const headers = { noLoader: 'noLoader' };
    const headers = {};
    return this.http.post<any>(`${this.envService.apiUrl}/buscador/secciones`, filtro, { headers });
  }

  public getRecursos(filtro: string): Observable<any> {
    // const headers = { noLoader: 'noLoader' };
    const headers = {};
    return this.http.post<any>(`${this.envService.apiUrl}/buscador/recursos`, filtro, { headers });
  }

  public getRecursosAnadir(filtro: string): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/buscador/recursos/${filtro}`);
  }

  public getRecursosBanco(filtro: string): Observable<any> {
    const params = {} as any;
    params.texto = filtro;
    params.modoAplicacion = 'Plataforma';
    // const headers = { noLoader: 'noLoader' };
    const headers = {};
    return this.http.post<any>(`${this.envService.apiUrl}/buscador/bancosearch`, params, { headers });
  }

  public getRecursosLtiAndalucia(filtro: any): Observable<any> {
    // const headers = { noLoader: 'noLoader' };
    const headers = {};
    return this.http.post<any>(`${this.envService.apiUrl}/lti/search/andalucia`, filtro, { headers });
  }

  public getRecursosLtiMoodleGenerico(filtro: any): Observable<any> {
    // const headers = { noLoader: 'noLoader' };
    const headers = {};
    return this.http.post<any>(`${this.envService.apiUrl}/lti/search/recursos`, filtro, { headers });
  }


  public getRecursosLtiMoodleEdixgal(filtro: any): Observable<any> {
    // const headers = { noLoader: 'noLoader' };
    const headers = {};
    return this.http.post<any>(`${this.envService.apiUrl}/lti/search/edixgal`, filtro, { headers });
  }


  public getRecursosBancoLtiAndalucia(filtro: any): Observable<any> {
    const params = {} as any;
    if (filtro.tematica) {
      params.tematica = filtro.tematica;
    }
    if (filtro.tiposmedia) {
      params.tipoMedia = filtro.tiposmedia;
    }
    params.texto = filtro.texto;
    params.modoAplicacion = 'Moodle';
    // const headers = { noLoader: 'noLoader' };
    const headers = {};
    return this.http.post<any>(`${this.envService.apiUrl}/buscador/bancosearch`, params, { headers });
  }

  public getAccionesdidacticas(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltiaccionesdidacticas/Plataforma`);
    return this.cache.observable('BUSCADORDATASERVICE-ACCIONESDIDACTICAS', requestObservable);
  }

  public getCompetenciasclaves(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/lticompetenciasclaves/Plataforma`);
    return this.cache.observable('BUSCADORDATASERVICE-COMPETENCIASCLAVES', requestObservable);
  }

  public getCursos(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/colegio/cursos`);
    return this.cache.observable('BUSCADORDATASERVICE-CURSOS', requestObservable);
  }

  public getIdiomas(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltiidiomas`);
    return this.cache.observable('BUSCADORDATASERVICE-IDIOMAS', requestObservable);
  }

  public getMaterias(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/buscador/Areas`);
    return this.cache.observable('BUSCADORDATASERVICE-MATERIAS', requestObservable);
  }

  public getNivelesdificultades(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltinivelesdificultades`);
    return this.cache.observable('BUSCADORDATASERVICE-NIVELESDIFICULTADES', requestObservable);
  }

  public getTaxonomiasblooms(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltitaxonomiasblooms`);
    return this.cache.observable('BUSCADORDATASERVICE-TAXONOMIASBLOOMS', requestObservable);
  }

  public getTiposcontenidos(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltitiposcontenidos/Plataforma`);
    return this.cache.observable('BUSCADORDATASERVICE-TIPOSCONTENIDOS', requestObservable);
  }

  public getTiposmedia(): Observable<any> {
    const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/cepda/ltitiposmediasrecursos/Plataforma`);
    return this.cache.observable('BUSCADORDATASERVICE-TIPOSMEDIA', requestObservable);
  }

}
