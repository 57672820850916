/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { AddModulesBaseComponent } from '../../add-modules-base.component';

@Component({
  selector: 'aula-planeta-add-module-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentoComponent extends AddModulesBaseComponent {

  error = {
    documento: false,
    image: false,
  };

  validTypes = {
    imagen_previa: ['image/gif', 'image/jpeg', 'image/jpg', 'image/pipeg', 'image/png'],
    documento: [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'text/plain'
    ]
  };

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public cd: ChangeDetectorRef,
    private gtagService: GtagService
  ) {
    super(fb, dialogRef, data);
  }

  ngOnInit() {
    this.formModule = this.fb.group({
      titulo_html: [this.data && this.data.propiedades.titulo_html ? this.data.propiedades.titulo_html : '', Validators.required],
      descripcion_html: [this.data && this.data.propiedades.descripcion_html ? this.data.propiedades.descripcion_html : ''],
      visible: [this.data && this.data.propiedades.visible === 'Profesores' ? true : false],
      documento: [this.data && this.data.propiedades.documento ? this.data.propiedades.documento : ''],
      documentoName: [this.data && this.data.propiedades.documento ? this.data.propiedades.documento : '', Validators.required],
      imagen_previa: [this.data && this.data.propiedades.imagen_previa ? this.data.propiedades.imagen_previa : ''],
      imagen_previaName: [this.data && this.data.propiedades.imagen_previa ? this.data.propiedades.imagen_previa : ''],
      creditos_html: [this.data && this.data.propiedades.creditos_html ? this.data.propiedades.creditos_html : ''],
    });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-add-module-documento',
        popupName: 'popup_añadir_documento'
      }
    };
    this.gtagService.event(info);
  }

  onDropFile(e, field) {
    this.error[field] = false;
    const file = (e as HTMLInputElement);

    if ((field !== 'documento' && this.isValidFile(file, field)) || field === 'documento') {
      
      this.onDrop(file, field);

    } else {
      this.error[field] = true;
    }
    setTimeout(() => {
      this.cd.detectChanges();
    }, 500);
  }

  onDrop(file, field) {
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file as any);
      reader.onloadend = () => {
        // this._photo.next(reader.result as string);
        this.formModule.get(field).setValue(reader.result);
        this.formModule.get(field + 'Name').setValue(file.name);
        this.formModule.markAsDirty();
        this.formModule.updateValueAndValidity();
        this.cd.markForCheck();
      };
    }
  }

  deleteFile(field) {
    this.formModule.get(field).setValue(null);
    this.formModule.get(field).updateValueAndValidity();
  }

  isValidFile(file, field) {
    return this.validTypes[field].includes(file.type);
  }

}

