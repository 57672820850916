import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'aula-planeta-reasignar-grupo',
  templateUrl: 'reasignar-grupo.component.html',
  styleUrls: ['./reasignar-grupo.component.scss'],
})
export class ReasignarGrupoComponent implements OnInit {

  displayedColumns: string[] = ['clases', 'acciones'];
  selection?: SelectionModel<any>;
  clasesColegios: any = [];

  constructor(
    public dialogRef: MatDialogRef<ReasignarGrupoComponent>,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    this.selection = new SelectionModel(true, []);
  }

  ngOnInit () {
    console.log(this.data.misClases);
    this.data.misClases.forEach(c => {

      console.log(c);
        const clase = {
          tipoAsignacion: 'Clase',
          cursoId: c.curso.id,
          grupoId: c.grupo.id,
          grupoPersonalizadoId: null,
          claseName: `${c.curso.ordinal} ${c.curso.etapa.acronimo} ${c.grupo.id}`
        };

        this.clasesColegios.push(clase);
        this.setAsignados(clase);
    });

    this.data.misGrupos.forEach(grupo => {
      const clase = {
        tipoAsignacion: 'GrupoPersonalizado',
        cursoId: null,
        grupoId: null,
        grupoPersonalizadoId: grupo.id,
        claseName: grupo.nombre
      };

      this.clasesColegios.push(clase);
      this.setAsignados(clase);
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'reassign-groups-materia',
        popupId: 'dialog-asignar-grupo',
        popupName: 'popup_assign_groups_materia'
      }
    };
    this.gtagService.event(info);
  }

  selectionToggle(element): void {
    this.selection.toggle(element);
  }

  isEstaUnaDeMisClases(clase) {
    if (this.data.asignados && this.data.asignados.length > 0) {
      return this.data.asignados.some(asignado => {
        if (asignado.tipoAsignacion === 'Clase' && clase.tipoAsignacion === 'Clase') {
          return asignado.clase.curso.id === clase.cursoId && asignado.clase.grupo.id === clase.grupoId;
        } else if(asignado.tipoAsignacion === 'GrupoPersonalizado' && clase.tipoAsignacion === 'GrupoPersonalizado') {
          return asignado.grupoPersonalizado.id === clase.grupoPersonalizadoId;
        }
      });
    }
    return false;
  }

  setAsignados(clase) {
    if (this.data.asignados && this.data.asignados.length > 0) {
      this.data.asignados.some(asignado => {
        if (asignado.tipoAsignacion === 'Clase' &&
        clase.tipoAsignacion === 'Clase' &&
        asignado.clase.curso.id === clase.cursoId &&
        asignado.clase.grupo.id === clase.grupoId) {
          this.selection.select(clase);
        } else if(asignado.tipoAsignacion === 'GrupoPersonalizado' &&
        clase.tipoAsignacion === 'GrupoPersonalizado' &&
        asignado.grupoPersonalizado.id === clase.grupoPersonalizadoId) {
          this.selection.select(clase);
        }
      });
    }
  }

  checkboxLabel(row): string {
    return `${this.selection.isSelected(row.id) ?
      this.translateService.instant('generico.deseleccionar') :
      this.translateService.instant('generico.seleccionar')} ${row.titulo} ${this.translateService.instant('generico.fila')}`;
  }

  close() {
    const info = {
      event: 'popup',
      popup: {
        action: 'guardar',
        popupId: 'dialog-asignar-grupo',
        popupName: 'popup_assign_groups_materia'
      }
    };
    this.gtagService.event(info);
    this.dialogRef.close(this.selection.selected);
  }
}
