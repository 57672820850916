<div id="dialog-crear-otra-materia" class="standard">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.crear-otra-materia.titulo' | translate }}</h1>

     <div mat-dialog-content class="mat-dialog-content">
        <div class="overflow-auto">
            <form role="form" [formGroup]="form">
                <fieldset class="w-100 d-flex flex-column">

                    <div class="form-group default-input position-relative w-100">
                        <label for="titulo">{{'dialogs.crear-otra-materia.form.titulo' | translate}}</label>
                        <input id="titulo" aria-labelledby="error_titulo" type="text" class="form-control" [placeholder]="('dialogs.crear-otra-materia.form.titulo' | translate)" formControlName="titulo">
                        <mat-error id="titulo" *ngIf="form.get('titulo').hasError('required') && form.controls['titulo'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                    <div class="form-group default-textarea position-relative w-100">
                        <label for="descripcion">{{'dialogs.crear-otra-materia.form.descripcion' | translate}}</label>
                        <textarea id="descripcion" aria-describedby="error_descripcion" type="text" class="form-control" [placeholder]="('dialogs.crear-otra-materia.form.descripcion' | translate)" formControlName="descripcion"></textarea>
                        <mat-error id="error_descripcion" *ngIf="form.get('descripcion').hasError('required') && form.controls['descripcion'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                    <div class="form-group default-input w-100">
                        <label for="materia" class="default-text">{{ 'dialogs.crear-otra-materia.form.materia-maestra' | translate }}</label>
                        <mat-select id="materia" class="default-select default-select__blue" [placeholder]="'dialogs.crear-otra-materia.form.materia-maestra' | translate" formControlName="materia_maestra" panelClass="cdkSelect">
                            <ng-container *ngFor="let materia of materias">
                                <mat-option class="default-option" [value]="materia.id">
                                    {{ materia.name }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-start mt-2 mb-0 pb-0">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__primary" (click)="onSubmit()" [disabled]="form.invalid" type="submit">{{ 'generico.guardar' | translate }}</button>
    </div>
</div>
