/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Input, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, filter, take } from 'rxjs/operators';
import { TemasManagementService } from '../../../services/data/temas/temas-management.service';
import { AddAnnotationComponent } from '../dialogs/add-annotation/add-annotation.component';
import { MateriaManagementService } from '../../../services/data/materia/materia-management.service';
import { AuthManagementService } from '../../../core/auth/auth-management.service';
import { GtagService } from '../../../services/gtag/gtag.service';
import { LtiManagementService } from '../../../services/data/lti/lti-management.service';

@Component({
  selector: 'aula-planeta-annotations',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AnnotationsComponent implements OnDestroy, OnChanges {
  @Input() show = false;
  @Input() showAnnotations = false;
  @Input() moduloId: string;
  @Input() last = false;

  id;
  lastClicked = null;
  private destroy$: Subject<void> = new Subject<void>();
  private _anotaciones: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  anotaciones$ = this._anotaciones.asObservable();

  constructor(
    public ltiManagementService: LtiManagementService,
    public temasManagementService: TemasManagementService,
    public materiaManagementService: MateriaManagementService,
    public authManagementService: AuthManagementService,
    public dialog: MatDialog,
    private gtagService: GtagService,
    public cd: ChangeDetectorRef
  ) {


    this.temasManagementService.anotaciones$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.filterAnnotationes();
      // this.showAnnotations = false;
    });


    this.temasManagementService.anotacionesShow$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      this.showAnnotations = false;
      this.show =false;
      this.cd.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (!changes.show?.currentValue) {
    //   this.showAnnotations = false;
    // } else {
       this.filterAnnotationes();
    // }
  }

  filterAnnotationes() {
    const misAnotaciones = [];
    this.temasManagementService.anotaciones
      .filter(anotacion =>
        anotacion.anotacionesTemas
          .filter(tema =>
            tema.temaId === this.temasManagementService.tema.id
          )
          .filter(tema =>
            tema.anotacionesModulos.forEach(modulo => {
              if (modulo.moduloId === this.moduloId) {
                misAnotaciones.push(modulo);
              }
            })
          )
      );

    this._anotaciones.next(misAnotaciones);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleShowList(event: PointerEvent, id) {
    event.preventDefault();
    event.stopPropagation();

    if (this.lastClicked === id && this.showAnnotations) {
        this.showAnnotations = false;
        this.show = false;
    } else {
        this.temasManagementService.anotacionesShow = false;
        setTimeout(() => {
            this.showAnnotations = true;
            this.lastClicked = id;
            this.cd.markForCheck();
        }, 100);
    }

    if (this.showAnnotations) {
      const info = {
        event: 'open_menu',
        openMenu: {
          menuId: 'annotations-list',
          pageId: event?.view.location.hash,
        }
      };
      this.gtagService.event(info);
    }
  }

  delete(id, event: PointerEvent) {
    event.preventDefault(); event.stopPropagation();
    const payload = {
      materiaId: this.materiaManagementService.materia.id,
      temaId: this.temasManagementService.tema.id,
      moduloId: id,
    };
    this.temasManagementService.deleteAnotaciones(payload);
    this.showAnnotations = false;
    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: 'delete_annotation',
        pageId: event.view.location.hash,
      }
    };
    this.gtagService.event(info);

  }

  openDialog(event: PointerEvent, item?) {
    event.preventDefault(); event.stopPropagation();
    const dialogRef = this.dialog.open(AddAnnotationComponent, {
      data: {
        titulo: item ? 'dialogs.edit-annotations.title' : 'dialogs.add-annotations.title',
        moduloId: this.moduloId,
        texto: item ? item.texto : ''
      },
      restoreFocus: true
    });

    dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(result => {
      if (result) {
        const payload = {
          materiaId: this.materiaManagementService.materia.id,
          temaId: this.temasManagementService.tema.id,
          moduloId: this.moduloId,
          texto: result.texto
        };
        this.temasManagementService.addAnotaciones(payload);
      }
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'add_annotation',
        pageId: event.view.location.hash,
        popupId: 'annotations',
        popupName: 'popup_annotations'
      }
    };
    this.gtagService.event(info);
  }

}

