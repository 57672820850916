import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'misTemas'
})
export class MisTemasPipe implements PipeTransform {

  transform(temas, idProfesor: string): unknown {
    return temas.filter(tema => tema.autor && tema.autor.id === idProfesor);
  }
}


