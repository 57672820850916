// WIREFRAME - D33

import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'aula-planeta-mi-clase',
  templateUrl: './mi-clase.component.html',
  styleUrls: ['./mi-clase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiClaseComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<MiClaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      cursos: new UntypedFormArray([])
    });
    this.patch();
  }

  patch() {
    const cursos = <UntypedFormArray>this.form.get('cursos');
    this.data.cursos.forEach(curso => {
      cursos.push(this.patchValuesCursos(curso.id, curso.name, null));
    });

    // this.data.cursos.forEach((curso, index) => {
    //   const grupos = <FormArray>this.form.get('cursos')['controls'][index].get('grupos');
    //   curso.grupos.forEach(grupo => {
    //     grupos.push(this.patchValuesGrupos(grupo.id, grupo.name, grupo.checked))
    //   });
    // });
  }

  patchValuesCursos(id, name, grupos) {
    return this.fb.group({
      id: [id],
      name: [name],
      grupos: ['']
    });
  }
  patchValuesGrupos(id, name, checked) {
    return this.fb.group({
      id: []
    });
  }


}
