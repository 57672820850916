import { Directive, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[overflow]'
})
export class OverflowDirective implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {
    const element = document.getElementById('wrapper');
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      // see also
      if (val instanceof NavigationEnd) {
        switch (val.url) {
          case '/inicio/buscador':
            element.classList.add('hide-scroll');
            break;

          default:
            element.classList.remove('hide-scroll');
            break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
