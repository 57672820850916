import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DateHelper } from '../../../helper/date.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-duplicar-tarea',
  templateUrl: 'duplicar-tarea.component.html',
  styleUrls: ['./duplicar-tarea.component.scss'],
})
export class DuplicarTareaComponent implements OnInit {
  @ViewChild('stepper') private stepperSection: MatStepper;

  secondStep: UntypedFormGroup;
  thirdStep1: UntypedFormGroup;
  thirdStep2: UntypedFormGroup;
  currentStep = 1;

  minDate = new Date(2000, 0, 1);
  maxDate = new Date(2045, 0, 1);
  selection?: SelectionModel<any>;
  indexTab = 0;

  selectionDisponibles: SelectionModel<null>;
  selectionAsignados: SelectionModel<null>;
  alumnos: any[] = [
    { nombreCompleto: 'Luz Cuesta Mogollón', nombre: 'Luz' },
    { nombreCompleto: 'Rosario Cura Sacristán', nombre: 'Rosario' },
    { nombreCompleto: 'Domingo Diaz Festivo', nombre: 'Domingo' },
    { nombreCompleto: 'Susana Oria Rica', nombre: 'Susana' },
    { nombreCompleto: 'Perico Delos Palotes', nombre: 'Perico' },
    { nombreCompleto: 'Martinez Sánchez', nombre: 'Carlos' },
    { nombreCompleto: 'Plaza Nofuentes', nombre: 'Daniel' },
  ];

  alumnosAsignados: any[] = [];
  enableCustomGroup = false;

  grupos: any[] = [
    {
      name: '',
      items: [
        { value: '1', viewValue: '5º Primaria' },
        { value: '2', viewValue: '6º Primaria' },
        { value: '3', viewValue: '1º Primaria' },
        { value: '4', viewValue: '2º Primaria' },
        { value: '5', viewValue: '3º Primaria' }
      ]
    },
    {
      name: '',
      items: [
        { value: '6', viewValue: 'Grupo personalizado 1' },
        { value: '7', viewValue: 'Grupo personalizado 2' },
        { value: '8', viewValue: 'Grupo personalizado 3' }
      ]
    }
  ];

  constructor(
    private fb: UntypedFormBuilder,
    public dateHelper: DateHelper,
    public dialogRef: MatDialogRef<DuplicarTareaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    this.selection = new SelectionModel(true, []);
    this.selectionDisponibles = new SelectionModel<null>(true, []);
    this.selectionAsignados = new SelectionModel<null>(true, []);
  }

  ngOnInit() {
    this.secondStep = this.fb.group({
      name: [this.data.titulo, Validators.required],
      inicio: [this.data.periodo.fechaInicio, Validators.required],
      fin: [this.data.periodo.fechaFin, Validators.required],
      comentario: [this.data.description, [Validators.required, Validators.maxLength(255)]],
      shared: [this.data.shared]
    });

    this.thirdStep1 = this.fb.group({
      grupo: ['3', Validators.required]
    });

    this.thirdStep2 = this.fb.group({
      alumnosAsignados: new UntypedFormControl([], Validators.required),
      curso: ['4', Validators.required],
      nombreGrupo: new UntypedFormControl('')
    });

    const info = {
      event: 'popup_steps',
      popupSteps: {
        action: 'duplicar',
        popupId: 'dialog-duplicar-tarea',
        popupName: 'popup_duplicar_tarea',
        popupStep: this.currentStep.toString()
      }
    };
    this.gtagService.event(info);
  }

  onStepperChange(ev) {
    this.currentStep = ev.selectedIndex;

    if (this.currentStep === 1) {
      this.secondStep.markAsPristine();
      this.secondStep.markAsUntouched();
      this.secondStep.reset(this.secondStep.value);
    }

    const info = {
      event: 'popup_steps',
      popupSteps: {
        action: 'duplicar',
        popupId: 'dialog-duplicar-tarea',
        popupName: 'popup_duplicar_tarea',
        popupStep: this.currentStep.toString()
      }
    };
    this.gtagService.event(info);
  }

  goBack() {
    this.stepperSection.previous();
  }

  goNext() {
    this.stepperSection.next();
  }

  onClose() {
    console.log('STEP 2 ::', this.secondStep.value);
    console.log('STEP 3.1 ::', this.thirdStep1.value);
    console.log('STEP 3.2 ::', this.thirdStep2.value);
    const result = this.indexTab === 0 ? this.thirdStep1.value : this.thirdStep2.value;
    // console.log('RESULT', {...this.firstStep.value, ...this.secondStep.value, result});
    this.dialogRef.close({ ...this.secondStep.value, ...result });
  }

  tabChange(event: MatTabChangeEvent) {
    this.indexTab = event.index;
    if (this.indexTab < 1) {
      this.thirdStep2.reset(this.thirdStep1.value);
    } else {
      this.thirdStep1.reset(this.thirdStep2.value);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    this.thirdStep2.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  asignarAlumnos() {
    this.alumnosAsignados = this.alumnosAsignados.concat(this.selectionDisponibles.selected);

    this.thirdStep2.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.alumnos = this.alumnos.filter(alumno => !this.selectionDisponibles.selected.includes(alumno));
    this.selectionDisponibles.clear();
  }

  asignarTodos() {
    this.alumnos.forEach((row) => this.selectionDisponibles.select(row));
    this.asignarAlumnos();
  }

  desasignarAlumnos() {
    this.alumnos = this.alumnos.concat(this.selectionAsignados.selected);

    this.alumnosAsignados = this.alumnosAsignados.filter(alumno => !this.selectionAsignados.selected.includes(alumno));
    this.thirdStep2.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.selectionAsignados.clear();
  }

  desasignarTodos() {
    this.alumnosAsignados.forEach((row) => this.selectionAsignados.select(row));
    this.desasignarAlumnos();
  }
}
