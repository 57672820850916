<div id="dialog-reasignar-grupo" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.reasignar-grupo.titulo' | translate }}</h1>

    <div>
        <p>{{ 'dialogs.reasignar-grupo.descripcion' | translate }}<b> {{data.materia}}</b></p>
    </div>

    <div mat-dialog-content class="mat-dialog-content">
        <table *ngIf="clasesColegios && clasesColegios.length > 0" #table mat-table class="w-100 mb-5 default-regular-table" [dataSource]="clasesColegios">

            <ng-container matColumnDef="clases">
                <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold w-7"> Clases </th>
                <td mat-cell class="font-weight-bold" *matCellDef="let element; index as numrow" id="Row_{{numrow}}">{{element.claseName}}</td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold w-7"> Asignación </th>
                <td mat-cell *matCellDef="let element; index as numrow">
                    <mat-checkbox aria-labelledby="Row_{{numrow}}" (click)="$event.stopPropagation()" (change)="$event ? selectionToggle(element) : null " [checked]="isEstaUnaDeMisClases(element)"></mat-checkbox>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row class="base" *matRowDef="let element; columns: displayedColumns;"></tr>
        </table>
        <div class="no-results-base" *ngIf="data.clases && data.clases.length < 1">
            <mat-icon class="material-icons-round">how_to_reg</mat-icon>
            <div>
                <span>{{ 'dialogs.mis-clases.sin-clases-asignadas' | translate }}</span>
            </div>
        </div>
    </div>


    <div mat-dialog-actions class=" mat-dialog-actions justify-content-start mt-2 mb-0 pb-0">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" (click)="close()" type="button">{{ 'generico.guardar' | translate }}</button>
    </div>
</div>
