<div *ngIf="!(ltiManagementService.preview$ | async)" id="dialog-add-annotation" class="standard mw-500px">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ data.titulo | translate }}</h1>
    <div mat-dialog-content class="mat-dialog-content">
        <form role="form" [formGroup]="form" class="empty-section d-flex flex-row">
            <div class="w-100 d-flex flex-column">

                <div class="form-group default-textarea mb-0">
                    <label for="annotation">{{'dialogs.add-annotations.form.annotation' | translate}}</label>
                    <textarea id="annotation" aria-labelledby="charsRemaining errorAnnotation" type="text" class="form-control" [placeholder]="('dialogs.add-annotations.form.annotation' | translate)" [maxlength]="textLimit" formControlName="texto"></textarea>
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error id="errorAnnotation" class="mt-0" [ngClass]="form.get('texto').hasError('required') && form.controls['texto'].dirty ? 'visible mb-3' : 'invisible'">
                            {{'generico.required' | translate}}
                        </mat-error>
                        <span aria-live="polite" id="charsRemaining" class="grey-text__light text__small">{{form.controls['texto'].value.length}}/{{textLimit}}</span>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__primary" (click)="onClose()" [disabled]="!form.valid">{{ 'generico.guardar' | translate }}</button>
    </div>

</div>
