import { Component, ChangeDetectionStrategy, Inject, ViewChild, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as  DecoupledDocumentEditor from '../../../../../assets/js/ck-editor-math-type/ckeditor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aula-planeta-guia-didactica',
  templateUrl: './guia-didactica.component.html',
  styleUrls: ['./guia-didactica.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuiaDidacticaComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('editorQ') editorQ: any;
  editor = DecoupledDocumentEditor;
  isDisabled = true;
  valueData = '';

  toolbar = ['heading', '|', 'bold',
    'italic', 'fontSize', 'fontFamily', 'underline', 'strikethrough', 'alignment',
    'highlight', 'MathType', 'numberedList', 'insertTable',
    'bulletedList', '|', 'indent',
    'outdent', 'undo', 'redo', 'exportPdf', 'exportWord', 'fontBackgroundColor',
    'fontColor'];

  config = {
    language: 'es',
    fontSize: { options: [9, 11, 12, 13, 'default', 17, 19, 21] },
    toolbar: ['heading', '|', 'bold',
      'italic', 'fontSize', 'fontFamily', 'underline', 'strikethrough', 'alignment',
      'highlight', 'MathType', 'numberedList', 'insertTable',
      'bulletedList', '|', 'indent',
      'outdent', 'undo', 'redo', 'exportPdf', 'exportWord', 'fontBackgroundColor',
      'fontColor'],
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
      ]
    }
  };

  constructor(
    public dialogRef: MatDialogRef<GuiaDidacticaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {

  }

  @HostListener('click', ['$event'])
  onClick(event) {
    console.log(event);

    if (event.target.nodeName === 'A') {
      const url = event.target.href;
      event.stopPropagation();
      event.preventDefault();
      window.open(url, '_blank');
    }
  }

  ngOnInit() {
    // this.isDisabled = this.data.editable;
    this.isDisabled = true;
    this.dialogRef.keydownEvents()
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(event => {
        if (event.key === 'Escape') {
          this.onCancel();
        }
      });

    this.dialogRef.backdropClick()
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(event => {
        this.onCancel();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  onCancel(): void {
    this.dialogRef.close(this.valueData);
  }

  toggleDisabled() {
    // this.isDisabled = !this.isDisabled;
    this.isDisabled = true;

    if (this.isDisabled) {
      this.editorQ.elementRef.nativeElement.getElementsByClassName('ck')[0].hidden = true;
    } else {
      this.editorQ.elementRef.nativeElement.getElementsByClassName('ck')[0].hidden = false;
    }
  }

  public onChange({ editor }: ChangeEvent) {
    const data = editor.data as any;
    this.valueData = data;
    console.log(this.valueData);
  }

  public onReady(editor) {
    console.log(this.data.content);

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    this.editorQ.elementRef.nativeElement.getElementsByClassName('ck')[0].hidden = true;
  }
}
