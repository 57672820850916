<div id="dialog-duplicar" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text">{{ data.titulo | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <div class="accordeon-list">
            <form role="form" [formGroup]="dpForm">
                <fieldset class="w-100 d-flex flex-column flex-md-row">
                    <div class="form-group default-input w-100 me-4">
                        <label for="duplicateName" class="default-text">{{ 'dialogs.duplicate.name' | translate }}</label>
                        <input id="duplicateName" aria-labelledby="errorDuplicateName" type="text" class="form-control" [placeholder]="('dialogs.duplicate.name' | translate)" formControlName="name" >
                        <mat-error id="errorDuplicateName" *ngIf="dpForm.get('name').hasError('required') && dpForm.controls['name'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                    <div class="form-group default-input w-100" *ngIf="!(ltiManagementService.moodle$ | async)">
                        <label for="duplicateLocation" class="default-text">{{ 'dialogs.duplicate.location' | translate }}</label>
                        <mat-select id="duplicateLocation" formControlName="type" (selectionChange)="changeLocation($event)" class="default-select default-select__blue" [placeholder]="'dialogs.duplicate.location' | translate" panelClass="cdkSelect">
                            <ng-container *ngFor="let location of locationsType">
                                <mat-option class="default-option" [value]="location.value">
                                    {{ location.label }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>
                </fieldset>
                <div class="w-100 d-flex">
                    <div class="form-group">
                        <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="shared">{{ 'dialogs.asignar-tarea.step2.shared' | translate }}</mat-slide-toggle>
                    </div>
                </div>
                <div class="my-4" *ngIf="isCurrentLocation === false && data.cursos.length">
                    <div class="location-title w-100 mb-3 pb-3">
                        <span class="default-text">{{ 'dialogs.copiar.location-label' | translate }}</span>
                    </div>
                    <div class="overflow-auto">
                        <mat-accordion class="d-flex flex-column" displayMode="flat" role="list">
                            <mat-expansion-panel *ngFor="let curso of data.cursos;" class="mat-elevation-z0" role="listitem">
                                <mat-expansion-panel-header class="flex-row-reverse">
                                    <mat-panel-title class="grey-text__light">{{ curso.name }}</mat-panel-title>
                                </mat-expansion-panel-header>

                                <ng-template *ngIf="curso.materias" matExpansionPanelContent>
                                    <mat-radio-group formControlName="location">
                                        <mat-accordion class="d-flex flex-column" role="list">

                                            <ng-container *ngIf="!data.isTemas; else temas">
                                                <mat-list role="list">
                                                    <mat-radio-group formControlName="location">
                                                        <mat-list-item *ngFor="let materia of curso.materias;" role="listitem" [ngClass]="colorHelper.getColor(materia.color)">
                                                            <mat-radio-button [value]="materia.id" class="custom-text" [checked]="materia.id===dpForm.get('location').value"> {{ materia.nombre }}</mat-radio-button>
                                                        </mat-list-item>
                                                    </mat-radio-group>
                                                </mat-list>
                                            </ng-container>

                                            <ng-template #temas>
                                                <mat-expansion-panel *ngFor="let materia of curso.materias;" class="mat-elevation-z0" role="listitem">
                                                    <mat-expansion-panel-header class="flex-row-reverse">
                                                        <mat-panel-title [ngClass]="colorHelper.getColor(materia.color)">
                                                            <mat-radio-button (click)="$event.stopPropagation()" [value]="materia.id" class="custom-text mb-0" [checked]="materia.id===dpForm.get('location').value">{{ materia.nombre }}</mat-radio-button>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>

                                                    <ng-template *ngIf="materia.indiceTemas" matExpansionPanelContent>

                                                        <mat-accordion class="d-flex flex-column" role="list">

                                                            <ng-container *ngIf="!data.isSecciones; else secciones">
                                                                <mat-list role="list">
                                                                    <mat-radio-group formControlName="location">
                                                                        <mat-list-item *ngFor="let tema of materia.indiceTemas;" role="listitem" [ngClass]="colorHelper.getColor(materia.color)">
                                                                            <mat-radio-button [value]="tema.id" class="custom-text" [checked]="tema.id===dpForm.get('location').value"> {{ tema.tituloPlano }}</mat-radio-button>
                                                                        </mat-list-item>
                                                                    </mat-radio-group>
                                                                </mat-list>
                                                            </ng-container>

                                                            <ng-template #secciones>

                                                                <mat-expansion-panel *ngFor="let tema of materia.indiceTemas;" class="mat-elevation-z0" role="listitem">
                                                                    <mat-expansion-panel-header class="flex-row-reverse">
                                                                        <mat-panel-title [ngClass]="colorHelper.getColor(materia.color)">
                                                                            <mat-radio-button (click)="$event.stopPropagation()" [value]="tema.id" class="custom-text mb-0" [checked]="tema.id===dpForm.get('location').value">{{ tema.tituloPlano }}</mat-radio-button>
                                                                        </mat-panel-title>
                                                                    </mat-expansion-panel-header>

                                                                    <ng-template *ngIf="materia.isSecciones" matExpansionPanelContent>

                                                                        <mat-accordion class="d-flex flex-column" role="list">

                                                                            <mat-list role="list">
                                                                                <mat-radio-group formControlName="location">
                                                                                    <mat-list-item *ngFor="let seccion of materia.indiceSeccion;" role="listitem" [ngClass]="colorHelper.getColor(materia.color)">
                                                                                        <mat-radio-button [value]="seccion.id" class="custom-text" [checked]="seccion.id===dpForm.get('location').value"> {{ seccion.titulo }}</mat-radio-button>
                                                                                    </mat-list-item>
                                                                                </mat-radio-group>
                                                                            </mat-list>


                                                                        </mat-accordion>
                                                                    </ng-template>

                                                                </mat-expansion-panel>
                                                            </ng-template>
                                                        </mat-accordion>
                                                    </ng-template>

                                                </mat-expansion-panel>
                                            </ng-template>




                                        </mat-accordion>

                                    </mat-radio-group>
                                </ng-template>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                </div>

            </form>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-start mt-3">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" [mat-dialog-close]="dpForm.value" type="submit" [disabled]="dpForm.invalid">{{ 'dialogs.duplicate.button' | translate }}</button>
    </div>
</div>
