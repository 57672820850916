<div id="dialog-ocultar-unidad-alumnado" class="standard">
    <h1 mat-dialog-title class="custom-text font-weight-bold">{{ 'dialogs.ocultar-unidad-alumnado.title' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <h2 class="custom-text mt-2 mb-2">{{ 'dialogs.ocultar-unidad-alumnado.subtitle' | translate }}</h2>
        <div class="form-group mb-3">
            <div class="d-flex flex-column w-25">
                <div class="form-group default-input">
                    <label class="custom-text">{{ 'dialogs.ocultar-unidad-alumnado.select' | translate }}</label>
                    <mat-select cdkFocusInitial class="default-select default-select__blue" [placeholder]="'dialogs.ocultar-unidad-alumnado.select' | translate" (selectionChange)="changed = true" panelClass="cdkSelect" [(ngModel)]="selectedOption">
                        <mat-option class="default-option" *ngFor="let option of options" [value]="option.value">
                            {{ option.viewValue | translate }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
        <div class="overflow-y-auto overflow-x-hidden" *ngIf="selectedOption === 'manual'">
            <form [formGroup]="alumnosForm">
                <fieldset class="w-100 d-flex flex-column flex-md-row justify-content-between my-4">
                    <div class="d-flex flex-column w-25">
                        <div class="form-group default-input">
                            <label class="custom-text">{{ 'dialogs.ocultar-unidad-alumnado.clase' | translate }}</label>
                            <mat-select cdkFocusInitial class="default-select default-select__blue" [placeholder]="'dialogs.ocultar-unidad-alumnado.clase' | translate" formControlName="curso" panelClass="cdkSelect">
                                <mat-optgroup *ngFor="let grupo of grupos" class="default-group-options">
                                    <mat-option class="default-option" *ngFor="let _grupo of grupo" [value]="_grupo.nombre" (click)="setAlumnosList(_grupo)">
                                        {{_grupo.nombre}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </div>
                    </div>

                    <div class="alumnos-container w-70 d-flex flex-column flex-md-row mb-4">
                        <div class="w-100 alumnos-container__list">
                            <label class="w-100 pb-2 mb-0 default-text">{{ 'mi-centro.gestion-contrasenas.alumnos-disponibles' | translate }}</label>
                            <div>
                                <div *ngFor="let item of alumnos">
                                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionDisponibles.toggle(item) : null " [checked]="selectionDisponibles.isSelected(item)" [aria-label]="item.nombreCompleto">
                                        {{item.nombre}} {{item.apellidoUno}} {{item.apellidoDos}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="alumnos-container__actions d-flex flex-row flex-md-column justify-content-center align-content-center px-2">
                            <button mat-icon-button class="custom-text" (click)="asignarAlumnos()" attr.aria-label="{{'mi-centro.gestion-curso.asignar.alumnos' | translate}}">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                            <button mat-icon-button class="custom-text" (click)="asignarTodos()" attr.aria-label="{{'mi-centro.gestion-curso.asignar.todos' | translate}}">
                            <mat-icon>last_page</mat-icon>
                        </button>
                            <button mat-icon-button class="custom-text" (click)="desasignarTodos()" attr.aria-label="{{'mi-centro.gestion-curso.desasignar.todos' | translate}}">
                            <mat-icon>first_page</mat-icon>
                        </button>
                            <button mat-icon-button class="custom-text" (click)="desasignarAlumnos()" attr.aria-label="{{'mi-centro.gestion-curso.desasignar.alumnos' | translate}}">
                            <mat-icon>chevron_left</mat-icon>
                        </button>
                        </div>
                        <div class="w-100 alumnos-container__list">
                            <label class="w-100 pb-2 mb-0 default-text">{{ 'mi-centro.gestion-contrasenas.alumnos-seleccionados' | translate }}</label>
                            <div>
                                <div *ngFor="let item of alumnosAsignados">
                                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionAsignados.toggle(item) : null " [checked]="selectionAsignados.isSelected(item)" [aria-label]="item.nombreCompleto">
                                        {{item.nombre}} {{item.apellidoUno}} {{item.apellidoDos}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>

                </fieldset>
            </form>
        </div>
    </div>
    <div mat-dialog-actions class="justify-content-start mb-0 mt-4">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'dialogs.create-theme.cancel' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" (click)="onClose()" [disabled]="selectedOption === 'manual' && alumnosAsignados.length < 1">{{ 'generico.asignar' | translate }}</button>
    </div>
</div>
