import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'aula-planeta-mover-copiar-elemento',
  templateUrl: 'mover-copiar-elemento.component.html',
  styleUrls: ['./mover-copiar-elemento.component.scss'],
})
export class MoverCopiarElementoComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  panelOpenState = false;
  isCurrentLocation = true;
  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<MoverCopiarElementoComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      action: ['move', Validators.required],
      location: [this.data.currentLocationId, Validators.required]
    });
  }

  changeLocation($event) {
    this.isCurrentLocation = $event.value;
    const newValue = (this.isCurrentLocation) ? this.data.currentLocationId : null;
    this.form.controls['location'].setValue(newValue);
  }
}
