<div id="dialog-crear-materia" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ isEditTitle() | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <p>{{'dialogs.crear-materia.subtitulo' | translate}}</p>
        <form role="form" [formGroup]="form">
            <fieldset>
                <div class="d-flex">
                    <div class="w-100 d-flex flex-column me-3">

                        <div class="form-group default-input position-relative w-100">
                            <label for="nombre">{{'dialogs.crear-otra-materia.form.nombre' | translate}}</label>
                            <input id="nombre" aria-describedby="error-nombre" type="text" class="form-control" [placeholder]="('dialogs.crear-otra-materia.form.nombre' | translate)" formControlName="nombre">
                            <mat-error id="error-nombre" *ngIf="form.get('nombre').hasError('required') && form.controls['nombre'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="form-group default-textarea position-relative w-100">
                            <label for="descripcion">{{'dialogs.crear-otra-materia.form.descripcion' | translate}}</label>
                            <textarea id="descripcion" aria-describedby="error-descripcion" type="text" class="form-control" [placeholder]="('dialogs.crear-otra-materia.form.descripcion' | translate)" formControlName="descripcion"></textarea>
                            <mat-error id="error-descripcion" *ngIf="form.get('descripcion').hasError('required') && form.controls['descripcion'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="d-flex">
                            <!-- ETAPA -->
                            <div class="form-group default-input w-100 me-3">
                                <label for="etapas" class="default-text">{{ 'dialogs.crear-otra-materia.form.etapa-educativa' | translate }}</label>
                                <mat-select id="etapas" class="default-select default-select__blue" [(value)]="selectedEtapa" [placeholder]="'dialogs.crear-otra-materia.form.etapa-educativa' | translate" (selectionChange)="filterByEtapa(selectedEtapa)" panelClass="cdkSelect">
                                    <ng-container *ngFor="let etapa of etapas">
                                        <mat-option class="default-option" [value]="etapa">
                                            {{ etapa }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </div>

                            <!-- CURSO -->
                            <div class="form-group default-input w-100">
                                <label for="curso" class="default-text">{{ 'dialogs.crear-otra-materia.form.curso' | translate }}</label>
                                <mat-select id="curso" class="default-select default-select__blue" [(value)]="selectedtCurso" [placeholder]="'dialogs.crear-otra-materia.form.curso' | translate" (selectionChange)="filterByCurso(selectedtCurso)" panelClass="cdkSelect" [disabled]="!selectedEtapa || selectedEtapa == 'Todas'">
                                    <ng-container *ngIf="selectedEtapa && cursos">
                                        <mat-option *ngFor="let curso of cursos?.get(selectedEtapa)" class="default-option" [value]="curso.ordinal">
                                            {{ curso.ordinal }}º
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </div>
                        </div>

                        <!-- MATERIA MAESTRA -->
                        <div class="form-group default-input w-100">
                            <label for="materia" class="default-text">{{ 'dialogs.crear-otra-materia.form.materia-maestra' | translate }}</label>
                            <mat-select id="materia" class="default-select default-select__blue" [placeholder]="'dialogs.crear-otra-materia.form.materia-maestra' | translate" formControlName="materiaMaestraId" panelClass="cdkSelect">
                                <ng-container *ngFor="let materia of materiasCentroFiltro">
                                    <mat-option class="default-option" [value]="materia.id">
                                        {{ materia.nombre }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </div>
                    </div>
                    <div class="w-100 d-flex flex-column">
                        <div class="form-group default-input">
                            <label for="autores" class="default-text">{{ 'dialogs.crear-otra-materia.form.autores' | translate }}</label>
                            <div class="form-group default-input autocomplete-input w-100 d-flex align-items-center position-relative">
                                <mat-icon class="ms-2 position-absolute custom-icon" matPrefix>search</mat-icon>
                                <input id="autores" class="form-control ps-5" type="text" [placeholder]="('dialogs.start-compartir.buscar' | translate)" [formControl]="profesoresControl" [matAutocomplete]="auto" aria-label="Buscar profesores" />
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" class="font-weight-bold" panelClass="cdkSelect">
                                    <mat-option class="default-option avatar-option" *ngFor="let profesor of profesoresFiltro | async" [value]="profesor" (click)="anadirProfesorCompatir(profesor)">
                                        <img *ngIf="profesor.img" class="photo me-2" [src]="profesor.img" [alt]="profesor.firstName">
                                        <mat-icon *ngIf="!profesor.img" class="icon me-2">person</mat-icon>
                                        {{profesor.nombre}} {{profesor.apellidoUno}} {{profesor.apellidoDos}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>

                        <div class="overflow-auto">
                            <table #table mat-table multiTemplateDataRows class="w-100 mb-5 default-table standard" [dataSource]="profesoresCompartir">
                                <ng-container matColumnDef="profesor">
                                    <th mat-header-cell *matHeaderCellDef>Nombre</th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="d-flex align-items-center">
                                            <img *ngIf="element.img" [src]="element.img | safe:'url'" alt="" height="40" width="40" class="cover me-2">
                                            <mat-icon *ngIf="!element.img" class="icon me-2">person</mat-icon>
                                            <span class="font-weight-bold">{{element.nombre}} {{element.apellidoUno}} {{element.apellidoDos}}</span>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="acciones">
                                    <th class="text-end pe-0 acciones w-10" mat-header-cell *matHeaderCellDef>Acciones</th>
                                    <td class="pe-0 text-end" mat-cell *matCellDef="let element; let i = index">
                                        <button class="custom-icon" mat-icon-button aria-label="Eliminar" (click)="quitarProfesorCompartir(element)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row class="base" *matRowDef="let element; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </div>
                </div>




            </fieldset>
        </form>
    </div>

    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
      <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
      <button role="button" class="default-button default-button__primary" [disabled]="form.invalid || profesoresCompartir.length < 1" (click)="onSubmit()" type="submit">{{ 'generico.guardar' | translate }}</button>
  </div>


</div>
