/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { AddModulesBaseComponent } from '../../add-modules-base.component';
import * as  DecoupledDocumentEditor from '../../../../../../../assets/js/ck-editor-math-type/ckeditor';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'aula-planeta-add-module-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioComponent extends AddModulesBaseComponent {

  @ViewChild('editorTexto') editorTexto: any;
  editor = DecoupledDocumentEditor;
  isDisabled = true;
  valueData = '';

  error = {
    audio: false,
    image: false,
    subtitulos: false
  };

  validTypes = {
    audio: ['audio/mpeg', 'audio/mp4', 'audio/vnd.wav', 'audio/ogg'],
    image: ['image/gif', 'image/jpg', 'image/jpeg', 'image/pipeg', 'image/png'],
    subtitulos: ['application/x-subrip',
      'application/octet-stream',
      'application/x-subtitle',
      'application/x-subtitle-sami',
      'application/x-subtitle-tmplayer',
      'application/x-subtitle-mpl2',
      'application/x-subtitle-dks',
      'application/x-subtitle-qttext',
      'application/x-subtitle-lrc',
      'text/plain'
    ]
  };

  private _audio: BehaviorSubject<string> = new BehaviorSubject<string>('');
  audio$ = this._audio.asObservable();
  private _imagen_previa: BehaviorSubject<string> = new BehaviorSubject<string>('');
  imagen_previa$ = this._imagen_previa.asObservable();

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AudioComponent>,
    public cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    super(fb, dialogRef, data);
  }

  ngOnInit() {
    this.formModule = this.fb.group({
      titulo_html: [this.data && this.data.propiedades.titulo_html ? this.data.propiedades.titulo_html : '', Validators.required],
      descripcion_html: [this.data && this.data.propiedades.descripcion_html ? this.data.propiedades.descripcion_html : ''],
      visible: [this.data && this.data.propiedades.visible === 'Profesores' ? true : false],
      audio: [this.data && this.data.propiedades.audio ? this.data.propiedades.audio : null, Validators.required],
      audioName: [''],
      imagen_previaName: [''],
      imagen_previa: [this.data && this.data.propiedades.imagen_previa ? this.data.propiedades.imagen_previa : ''],
      transcripcion: [this.data && this.data.propiedades.transcripcion ? this.data.propiedades.transcripcion : ''],
      subtitulos: [this.data && this.data.propiedades.subtitulos ? this.data.propiedades.subtitulos : ''],
      subtitulosName: [null],
      creditos_html: [this.data && this.data.propiedades.creditos_html ? this.data.propiedades.creditos_html : ''],
    });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-add-module-audio',
        popupName: 'popup_añadir_audio'
      }
    };
    this.gtagService.event(info);
  }

  deleteFile(field) {
    this.formModule.patchValue({
      audio: null
    });

    this.formModule.get(field).updateValueAndValidity();
  }


  onDropFile(e, field) {

    this.error[field] = false;
    const file = (e as HTMLInputElement);
    if (this.isValidFile(file, field)) {
      this.uploadFile(file, field);
    } else {
      this.error[field] = true;
    }

  }

  uploadFile(file, field) {

    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.formModule.get(field).setValue(reader.result as string);
        this.formModule.get(field + 'Name').setValue(file.name);
        this.formModule.markAsDirty();
        this.formModule.updateValueAndValidity();
        this.cd.markForCheck();
      };
    }
  }

  isValidFile(file, field) {
    return file.type.length < 1 ? true : this.validTypes[field].includes(file.type);
  }

  onDropImg(file, field) {

    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this._imagen_previa.next(reader.result as string);
        this.formModule.get(field).setValue(reader.result as string);
        this.formModule.get(field + 'Name').setValue(file.name);
        this.formModule.markAsDirty();
        this.formModule.updateValueAndValidity();
        this.cd.markForCheck();
      };
    }
  }

  deleteImg(field) {
    this.formModule.get(field).setValue(null);
    this.formModule.get(field).updateValueAndValidity();
    this._imagen_previa.next(null);
  }



  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.formModule.get('pie_de_imagen_previa').setValue(data);
    this.formModule.updateValueAndValidity();
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    this.editorTexto.elementRef.nativeElement.getElementsByClassName('ck')[0].hidden = true;
  }

}

