import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ColorHelper } from '../../../helper/color.helper';
import { MatAccordion } from '@angular/material/expansion';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-crear-enlace',
  templateUrl: 'crear-enlace.component.html',
  styleUrls: ['./crear-enlace.component.scss'],
})
export class CrearEnlaceComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  panelOpenState = false;
  isCurrentLocation = true;
  cpForm: UntypedFormGroup;
  temaSeleccionado: null;
  indiceSeleccionado: null;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CrearEnlaceComponent>,
    public colorHelper: ColorHelper,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngOnInit() {
    this.cpForm = this.formBuilder.group({
      location: [this.data.currentLocationId],
      seccion: [this.data.seccion]
    });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-crear-enlace',
        popupName: 'popup_crear_enlace',
        pageId: this.data?.event?.view.location.hash
      }
    };
    this.gtagService.event(info);
  }
  close() {
    this.dialogRef.close({ ...this.cpForm.value });
  }
}