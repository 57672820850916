import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'aula-planeta-configuracion-cookies',
  templateUrl: 'configuracion-cookies.component.html',
  styleUrls: ['./configuracion-cookies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfiguracionCookiesComponent implements OnInit {

  form: FormGroup;

  constructor(
    protected fb: FormBuilder,
    public dialogRef: MatDialogRef<ConfiguracionCookiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    dialogRef.disableClose = true;
  }




  ngOnInit() {


    let convertedObject = null;
    if (this.data.cookies) {
      // Convertir el array en un objeto
      convertedObject = this.data.cookies.reduce((acc, item) => {
        acc[item.site] = item.accept === 'true' ? true : (item.accept === 'false' ? false : item.accept);
        return acc;
      }, {});
    }

    this.form = this.fb.group({
      privacidad: [convertedObject?.privacidad === 'undefined' ? true : convertedObject?.privacidad],
      tecnicas: [convertedObject?.tecnicas === 'undefined' ? true : convertedObject?.tecnicas],
      analisis: [convertedObject?.analisis === 'undefined' ? true : convertedObject?.analisis],
      publicitarias: [convertedObject?.publicitarias === 'undefined' ? true : convertedObject?.publicitarias],
      preferencias: [convertedObject?.preferencias === 'undefined' ? true : convertedObject?.preferencias]
    });

  }

  close(all, none) {

    if (all) {
      this.form.setValue({
        privacidad: true,
        tecnicas: true,
        analisis: true,
        publicitarias: true,
        preferencias: true,
      });
    } else if (none) {
      this.form.setValue({
        privacidad: false,
        tecnicas: true,
        analisis: false,
        publicitarias: false,
        preferencias: false,
      });
    }

    const response = [
      {
        site: 'privacidad',
        accept: this.form.get('privacidad').value
      },
      {
        site: 'tecnicas',
        accept: this.form.get('tecnicas').value
      },
      {
        site: 'analisis',
        accept: this.form.get('analisis').value
      },
      {
        site: 'publicitarias',
        accept: this.form.get('publicitarias').value
      },
      {
        site: 'preferencias',
        accept: this.form.get('preferencias').value
      }
    ];

    this.dialogRef.close(response);
  }

}
