<div id="dialog-imagen-destacada" [class]="colorHelper.getColor(color)">
    <div mat-dialog-content
      [ngClass]="{
        'horizontal' : proporcion === 'Horizontal',
        'muy-horizontal' : proporcion === 'MuyHorizontal',
        'standard-abajo' : proporcion === 'Standard' && posicionPie === 'Centrado',
        'standard-derecha' : proporcion === 'Standard' && posicionPie === 'Derecha',
        'vertical' : proporcion === 'Vertical',
        'muy-vertical' : proporcion === 'MuyVertical'
      }">
        <!-- w-80 for vertical height -->
        <div
          [ngClass]="{
            'dimensions-horizontal' : proporcion === 'Horizontal',
            'dimensions-muy-horizontal' : proporcion === 'MuyHorizontal',
            'dimensions-standard' : proporcion === 'Standard',
            'dimensions-vertical' : proporcion === 'Vertical',
            'dimensions-muy-vertical' : proporcion === 'MuyVertical'
          }">
            <img sanitize-img type="default" role="img" [src]="imgToRender" [alt]="data.img.alt" />
        </div>

        <div *ngIf="pieDeImagen"
          class="texto"
          [ngClass]="{
            'texto-horizontal' : proporcion === 'Horizontal',
            'texto-muy-horizontal' : proporcion === 'MuyHorizontal',
            'texto-standard-centrado' : proporcion === 'Standard' && posicionPie === 'Centrado',
            'texto-standard-derecha' : proporcion === 'Standard' && posicionPie === 'Derecha',
            'mt-4' : proporcion === 'Standard' && posicionPie === 'Abajo',
            'texto-vertical' : proporcion === 'MuyVertical' || proporcion === 'Vertical'
          }">
            <p [innerHTML]="pieDeImagen | safe : 'html'" class="body-2"></p>
        </div>
    </div>

    <div class="buttons-vertical"
      [ngClass]="{'sin-pie-de-imagen' : !pieDeImagen}">
        <div class="close d-flex justify-content-end">
            <button mat-dialog-close mat-fab color="transparent" class="custom-bg__regular default-button default-button--xs default-button--circle default-button__primary--transparent d-flex align-items-center justify-content-center" >
                <mat-icon class="white-text">clear</mat-icon>
            </button>
        </div>
    </div>
</div>
