import { Persona } from './persona.model';
export enum TipoProfesor {
  lector,
  comentador,
  editor
}

export interface Profesor extends Persona {
  email?: string,
  tipo?: TipoProfesor,
}
