import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

@Component({
  selector: 'aula-planeta-enviar-comentario',
  templateUrl: './enviar-comentario.component.html',
  styleUrls: ['./enviar-comentario.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnviarComentarioComponent {

  comentarioControl: UntypedFormControl = new UntypedFormControl();

  constructor(
    public dialogRef: MatDialogRef<EnviarComentarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

}
