import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer, of } from 'rxjs';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { Tarea } from '../../../shared/models/tarea.model';
import { EnvService } from '../../env.service';
import { CuadernoEvaluacionData } from '../../../shared/models/cuaderno.model';




@Injectable({
  providedIn: 'root'
})
export class TareaDataService {



  constructor(private http: HttpClient, public cache: LocalCacheService, private envService: EnvService) { }

  public getTareas(isProfesor): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/tarea-asignada/${isProfesor ? 'profesor' : 'alumno'}`);
    // const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/tarea-asignada/${isProfesor ? 'profesor' : 'alumno'}`);
    // return this.cache.observable('TAREADATASERVICE-GETTAREAS', requestObservable);
  }

  public getTareasMoodle(isProfesor): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/tarea-asignada/moodle/${isProfesor ? 'profesor' : 'alumno'}`);
  }

  public getTarea(isAutonoma: boolean, isProfesor: boolean, id: string): Observable<any> {
    let urlApi = '';
    if (isAutonoma) {
      urlApi = `${this.envService.apiUrl}/tarea-autonoma/alumno/tarea/${id}`;
    } else {
      urlApi = `${this.envService.apiUrl}/tarea-asignada/${isProfesor ? 'alumnos' : 'alumno'}/tarea/${id}`;
    }
    return this.http.get<any>(urlApi);
  }

  public getTareaMoodle(isAutonoma: boolean, isProfesor: boolean, id: string): Observable<any> {
    let urlApi = '';
    if (isAutonoma) {
      urlApi = `${this.envService.apiUrl}/tarea-autonoma/alumno/tarea/${id}`;
    } else {
      urlApi = `${this.envService.apiUrl}/tarea-asignada/${isProfesor ? 'alumnos' : 'alumno'}/tarea/${id}`;
    }
    //   urlApi = `${this.envService.apiUrl}/tarea-asignada/moodle/${isProfesor ? 'alumnos' : 'alumno'}/tarea/${id}`;
    return this.http.get<any>(urlApi);

  }

  public getTareasDelAlumno(idAlumno: string, isProfesor: boolean): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/trabajo-alumno/alumno${isProfesor ? '/' + idAlumno : ''}`);
  }

  public getTareasDelAlumnoMoodle(idAlumno: string, isProfesor: boolean): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/trabajo-alumno/moodle/alumno${isProfesor ? '/' + idAlumno : ''}`);
  }

  public getNotificacionesTareasDelAlumno(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/trabajo-alumno/notificaciones`);
  }

  public getTrabajoDelAlumno(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/trabajo-alumno/alumnos`);
  }

  public getTrabajoDelAlumnoMoodle(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/trabajo-alumno/moodle/alumnos`);
  }

  public createTarea(tarea: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/tarea-asignada/Asignar`, tarea);
  }

  public createTareaMoodle(tarea: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/tarea-asignada/moodle/Asignar`, tarea);
  }

  public createTareaClassroom(tarea: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/Google/CrearTarea`, tarea);
  }

  public enviarTablonClassroom(tablon: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/Google/Tablon`, tablon);
  }

  public createTareaTeams(tarea: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/Teams/AsignarTarea`, tarea);
  }

  public editarTarea(tarea: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada/Actualizar`, tarea);
    // return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada/Modificar`, tarea);
  }

  public editarTareaMoodle(tarea: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada/moodle/Modificar`, tarea);
    // return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada-moodle/Modificar`, tarea);
  }

  public reasignarTarea(tarea: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada/Reasignar`, tarea);
  }

  public validarReasignarTarea(body: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada/ValidarReasignar`, body);
  }

  public validarActualizarTarea(body: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada/ValidarActualizar`, body);
  }


  public reasignarAlumnos(body: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada/ReasignarAlumnos`, body);
  }

  public repetirTarea(tarea: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada/Repetir`, tarea);
  }

  public repetirTareaAlumnosSeleccionados(tarea: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada/RepetirAlumnos`, tarea);
  }

  public evaluarActividad(isAutonoma: boolean, idEvidencia: any, data): Observable<any> {
    return this.http.put<any>
      (`${this.envService.apiUrl}/actividad-${isAutonoma ? 'autonoma' : 'asignada'}/Evaluar/${idEvidencia}`, { ...data });
  }

  public repetirActividad(data: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/actividad-asignada/Repetir`, {
      actividadAsignadaId: data.idActividad,
      alumnoId: data.idAlumno,
      fechaInicioVigencia: data.fechaInicioVigencia,
      fechaFinVigencia: data.fechaFinVigencia,
      comentarioRepetir: data.comentario
    });
  }

  public realizarActividad(data: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/actividad-asignada/Realizar/${data.idActividad}`, {});
  }

  public ocultarTareaVencida(data: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/actividad-asignada/OcultarTareaVencida/${data.idActividad}`, {});
  }

  public duplicarTarea(tarea: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/tarea-asignada/duplicar/${tarea.id}`, null);
  }

  public deleteTarea(tarea: Tarea): Observable<any> {
    return this.http.delete<any>(`${this.envService.apiUrl}/tarea-asignada/${tarea.id}`);
  }

  public deleteTareaByIds(ids: number[]): Observable<any> {
    return this.http.delete<any>(`${this.envService.apiUrl}/tarea-asignada/DeleteByIds`, { body: ids });
  }

  public deleteTareaMoodle(tarea: Tarea): Observable<any> {
    return this.http.delete<any>(`${this.envService.apiUrl}/tarea-asignada/moodle/${tarea.id}`);
    // return this.http.delete<any>(`${this.envService.apiUrl}/tarea-asignada-moodle/${tarea.id}`);
  }

  public desasignarContenido(tareaId): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/tarea-asignada/Desasignar/${tareaId}`, {});
  }

  public descargarCsv(tarea: Tarea): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/tareas/${tarea.id}/descargar`, tarea);
  }

  public descargarListadoAlumnosCSV(alumnoId, ids): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(`${this.envService.apiUrl}/trabajo-alumno/Alumno/${alumnoId}/tareas/exportar`, ids, httpOptions);

  }

  public descargarListadoActividadesCSV(tareaId, ids): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(`${this.envService.apiUrl}/tarea-asignada/Alumnos/Tarea/${tareaId}/exportar`, ids, httpOptions);
  }

  public descargarListadoNotasCSV(ids): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(`${this.envService.apiUrl}/trabajo-alumno/alumnos/tareas/exportar`, ids, httpOptions);
  }

  public adjuntarFichero(adjunto: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/tareas/actividad/adjuntar`, adjunto);
  }

  public descargarAlumnosSeleccionadosCSV(alumnosId): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(`${this.envService.apiUrl}/aula/exportarAlumnos`, alumnosId, httpOptions);
  }


  // plataforma
  public getModuloAsignadosComoTareaEnPlataforma(temaId): Observable<any> {
    const headers = { noLoader: 'noLoader' };
    const url = `${this.envService.apiUrl}/contenido/tareas/profesor/tema/${temaId}`;

    return this.http.get<any>(url, { headers });
  }

  public getModuloAsignadosComoTareaAlumnoEnPlataforma(temaId): Observable<any> {
    const headers = { noLoader: 'noLoader' };
    const url = `${this.envService.apiUrl}/contenido/tareas/alumno/tema/${temaId}`;
    return this.http.get<any>(url, { headers });
  }

  // moodle
  public getModuloAsignadosComoTareaEnMoodle(temaId, contextId): Observable<any> {
    const headers = { noLoader: 'noLoader' };
    let url = `${this.envService.apiUrl}/contenido/modulos-asignados-tareas/tema/${temaId}`;
    if (contextId) {
      url = url + `?contextId=${contextId}`;
    }
    return this.http.get<any>(url, { headers });
  }

  public getModuloAsignadosComoTareaAlumnoEnMoodle(temaId, contextId): Observable<any> {
    const headers = { noLoader: 'noLoader' };
    let url = `${this.envService.apiUrl}/contenido/modulos-asignados-tareas/alumno/tema/${temaId}`;
    if (contextId) {
      url = url + `?contextId=${contextId}`;
    }
    return this.http.get<any>(url, { headers });
  }

  public getModuloAsignadosComoTareaMoodle(temaId, contextid): Observable<any> {
    const headers = { noLoader: 'noLoader' };
    return this.http.get<any>
      (`${this.envService.apiUrl}/contenido/modulos-asignados-moodle/contextid/${contextid}/tema/${temaId}`, { headers });
  }

  public getCuadernoEvaluacion(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/trabajo-alumno/alumnos/cuadernoevaluacion`, data);
  }

  public getCursosCuadernoEvaluacion(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/aula/Clases`);
  }

  public getAsignaturasCuadernoEvaluacion(): Observable<any> {
    // const asignaturas = [
    //   { id: 6, nombre: 'Ciencias de la naturaleza' },
    //   { id: 1, nombre: 'Matemáticas' },
    //   { id: 16, nombre: 'Geografía e historia' }];

    return this.http.get<any>(`${this.envService.apiUrl}/aula/Asignaturas`);
    // return of(asignaturas);

  }

}


export { CuadernoEvaluacionData };

