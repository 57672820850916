import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroy, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

export enum ConnectionStatusEnum {
  online,
  offline
}

@Injectable({
  providedIn: 'root'
})
export class NetworkConnection implements OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  public status: ConnectionStatusEnum = ConnectionStatusEnum.offline;
  public isConnected = false;
  public online$: Observable<Event>;
  public offline$: Observable<Event>;

  public init() {
    this.online$ = fromEvent(window, 'online');
    this.offline$ = fromEvent(window, 'offline');

    this.status = navigator.onLine ? 0 : 1;
    this.isConnected = navigator.onLine;

    this.online$.pipe(
      takeUntil(this.destroy$)).subscribe(e => {
        console.log('Online');
        this.status = ConnectionStatusEnum.online;
        this.isConnected = true;
      });

    this.offline$.pipe(
      takeUntil(this.destroy$)).subscribe(e => {
        console.log('Offline');
        this.status = ConnectionStatusEnum.offline;
        this.isConnected = false;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  constructor() {
    this.init();
  }

}