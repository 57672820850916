import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Incidencia } from '../../../models/incidencia.model';
import { FormularioIncidenciasManagementService } from '../../../../services/formulario-incidencias-management.service';
import { FormularioIncidenciasService } from '../../../../services/formulario-incidencias.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'aula-planeta-enviar-incidencias',
  templateUrl: './enviar-incidencias.component.html',
  styleUrls: ['./enviar-incidencias.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EnviarIncidenciasComponent {
  form: FormGroup;
  fileName = '';
  fileType = '';

  private destroy$: Subject<void> = new Subject<void>();
  private _photo: BehaviorSubject<string> = new BehaviorSubject<string>('');
  photo$ = this._photo.asObservable();
  private _video: BehaviorSubject<string> = new BehaviorSubject<string>('');
  video$ = this._video.asObservable();

  constructor(
    public dialogRef: MatDialogRef<EnviarIncidenciasComponent>,
    private router: Router,
    private formularioIncidenciaManagementService: FormularioIncidenciasManagementService,
   private translateService: TranslateService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = new FormGroup({
      asunto: new FormControl('', [
        Validators.required,
        Validators.maxLength(150)
      ]),
      descripcion: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      imagen: new FormControl('')
    });
  }

  private detectDevice(): string {
    const ua = navigator.userAgent;
    if (/mobile/i.test(ua)) {
      return 'Móvil';
    } else if (/iPad|Android|Touch/i.test(ua)) {
      return 'Tableta';
    } else {
      return 'Desktop';
    }
  }

  onDrop(file) {
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      this.fileName = file.name;
      this.fileType = file.type.includes('video') ? 'video' : 'imagen';
      reader.onloadend = () => {
        if (this.fileType === 'video') {
          this._video.next(reader.result as string);
        } else {
          this._photo.next(reader.result as string);
        }
        this.form.patchValue({ imagen: reader.result });
        this.form.markAsDirty();
        this.form.updateValueAndValidity();
      };
    }
  }

  deleteFile(event: MouseEvent): void {
    event.stopPropagation();
    this.fileName = '';
    this.fileType = '';
    this.form.patchValue({ imagen: null });
    this._photo.next('');
    this._video.next('');
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      let data: Incidencia;
      const url = window.location.origin + this.router.url;
      const asunto = this.form.value.asunto.trim() || '';
      const comentario = this.form.value.descripcion.trim() || '';
      const email = this.form.value.email.trim() || '';
      const dispositivo = this.detectDevice();
      const userAgent = navigator.userAgent;

      if (this.fileType === 'video') {
        data = {
          url,
          asunto,
          comentario,
          email,
          dispositivo,
          userAgent,
          video: {
            contenidoFichero: this._video.value.trim().split('base64,')[1] || '',
            encodingType: 0,
            nombreFichero: this.fileName
          }
        };
      } else {
        data = {
          url,
          asunto,
          comentario,
          email,
          dispositivo,
          userAgent,
          imagen: {
            contenidoFichero: this._photo.value.trim().split('base64,')[1] || '',
            encodingType: 0,
            nombreFichero: this.fileName
          }
        };
      }

      this.formularioIncidenciaManagementService.postIncidencia(data)
        .pipe(
          take(1),
          takeUntil(this.destroy$)
        ).subscribe(() => {
          const message= this.translateService.instant('dialogs.incidencias.gracias-snackbar');

          setTimeout(() => {
            this.snackBar.open(message, 'OK', {
              duration: 3000
            });
            this.dialogRef.close(this.form.value);
          }, 2);
         
        });
    }
  }
}