import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { EnvService } from '../../env.service';

@Injectable({
  providedIn: 'root'
})
export class SmartlinkDataService {

  constructor(private http: HttpClient, public cache: LocalCacheService, private envService: EnvService) { }

  public getPermalink(id): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/retrocompatibilidad/permaid/${id}`);
  }

  public getSmartlinkData(id, page): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/smartlink/${page}/${id}`);
  }

  public getRetroSmartlinkData(id): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/aula/materia/tema/${id}`);
  }

  public getRetroSmartlinkBanco(texto: string): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/BancoContenidos/RedirectBancoContenidos?recurso=${texto}`,
      { responseType: 'text' as 'json' });
  }
}
