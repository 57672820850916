import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthManagementService } from '../../../../core/core.module';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GenericComponent } from '../generic/generic.component';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-actualizar-contrasenya',
  templateUrl: './actualizar-contrasenya.component.html',
  styleUrls: ['./actualizar-contrasenya.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActualizarContrasenyaComponent implements OnInit {
  /**
   * Use to destroy and prevent memory leaks
   */
  private destroy$: Subject<void> = new Subject<void>();

  form: UntypedFormGroup;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private gtagService: GtagService,
    private authManagementService: AuthManagementService,
    public dialogRef: MatDialogRef<ActualizarContrasenyaComponent>,

  ) {

  }
  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      oldPassword: new UntypedFormControl('', [Validators.required]),
      newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      confirmNewPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6)])
    });
    this.form.setValidators(this.passwordMatchValidator);
  }

  onSubmit(event: PointerEvent) {

    console.log(this.form.value);


    // stop here if form is invalid
    if (this.form.invalid) {
      this.form.controls['oldPassword'].markAsDirty();
      this.form.controls['newPassword'].markAsDirty();
      this.form.controls['confirmNewPassword'].markAsDirty();
      return;
    }

    const passwords = this.form.getRawValue();
    this.authManagementService
      .changePassword(passwords)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        const info = {
          event: 'configuration_selected',
          configurationSelected: {
            action: 'cambiar_contraseña',
            pageId: event?.view?.location.hash,
            section: 'contraseña'
          }
        };
        this.gtagService.event(info);

        this.form.reset();
        const dialogGeneric = this.dialog.open(GenericComponent, {
          data: {
            titulo: 'Contraseña',
            icon: 'password',
            contenido: 'La contraseña ha sido cambiada con éxito',
            cerrar: 'cerrar',
            color: 'standard',
            accion: 'Denunciar',
            close: true, // muestra el aspa de cerrado,
            actions: [],
            event: event,
            action: 'cambiar_contraseña_ok'
          },
          restoreFocus: true
        });
        dialogGeneric.afterClosed()
          .pipe(
            takeUntil(this.destroy$))
          .subscribe(evento => {
            this.dialogRef.close();
            this.router.navigate(['/']);
          });
      },
        error => {
          const info = {
            event: 'configuration_selected_error',
            configurationSelectedError: {
              errorId: error?.status,
              errorMessage: error?.statusText,
              section: 'contraseña',
              pageId: event?.view?.location.hash
            }
          };
          this.gtagService.event(info);
        });

    // this.router.navigate(['/']);

  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    const error = frm.controls['newPassword'].value === frm.controls['confirmNewPassword'].value ? null : { mismatch: true };
    frm.controls['confirmNewPassword'].setErrors(error);
    return error;
  }

}
