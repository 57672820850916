import { Directive, Input, Output, EventEmitter, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  @Input() excludes: [];  
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target) || target.classList.contains(this.excludes);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}