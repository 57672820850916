<div id="dialog-descargar-offline" class="standard">
    <h1 mat-dialog-title class="custom-text mb-4">{{ 'dialogs.descargar-offline.titulo' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <div class="mb-4">
            <h2 class="custom-text mb-3">{{ 'dialogs.descargar-offline.subtitulo' | translate }}</h2>
            <p>{{ 'dialogs.descargar-offline.cuerpo1' | translate }}</p>
        </div>
        <div class="mb-5">
            <h2 class="custom-text mb-3">{{ 'dialogs.descargar-offline.instrucciones' | translate }}</h2>
            <p>{{ 'dialogs.descargar-offline.instruccion1' | translate }}</p>
            <p class="mb-4">{{ 'dialogs.descargar-offline.instruccion2' | translate }}</p>

            <div class="form-group w-50">
                <label class="default-text">{{ 'dialogs.descargar-offline.so' | translate }}</label>
                <mat-select cdkFocusInitial class="default-select default-select__blue" [placeholder]="'dialogs.descargar-offline.so' | translate" panelClass="cdkSelect">
                    <ng-container *ngFor="let dispositivo of dispositivos">
                        <mat-option class="default-option" [value]="dispositivo.value">
                            {{ dispositivo.viewValue }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="justify-content-start mt-2">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close >{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__primary" [mat-dialog-close]="true">{{ 'dialogs.descargar-offline.aceptar' | translate }}</button>
    </div>
</div>
