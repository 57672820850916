import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { AuthManagementService, LocalStorageService } from '../../core/core.module';
import { SmartlinkManagementService } from '../data/smartlink/smartlink-management-service.service';
import { TemasManagementService } from '../data/temas/temas-management.service';

@Injectable({
  providedIn: 'root'
})
export class PermalinkService implements CanActivate{

  public smartlink = false;
 
  constructor(
    private router: Router,
    private smartlinkManagementService: SmartlinkManagementService,
    private authManagementService: AuthManagementService,
    private temasManagementService: TemasManagementService,
    private localStorageService: LocalStorageService

  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const permaId = route.queryParams.permaId;
    const bancoContenidosUrl = route.queryParams.bancoContenidosUrl;

    if (permaId) {
      const tmp$: Subject<void> = new Subject<void>();
      const token = await this.getToken();
      this.authManagementService.redirectUrl = state.url as any;
      localStorage.setItem('redirectUrl', state.url);
      this.smartlinkManagementService.getPermalink(permaId).pipe(
        take(1),
        takeUntil(tmp$))
        .subscribe(
          res => {
            
            this.authManagementService.redirectUrl = res.urlRedireccion as any;
            localStorage.setItem('redirectUrl', res.urlRedireccion);
            if (this.authManagementService.currentUser.moodle) {
              res.urlRedireccion = `${res.urlRedireccion}?moodle=${token}`;
            }
            this.router.navigateByUrl(res.urlRedireccion, { replaceUrl: true });
            this.smartlinkManagementService.smartlink = true;
          },
          _err => {
            localStorage.removeItem('redirectUrl');
            this.router.navigate(['/']);
          }
        );
      return false;
    }

    if (bancoContenidosUrl) {
      const tmp$: Subject<void> = new Subject<void>();
      this.authManagementService.redirectUrl = state.url as any;
      localStorage.setItem('redirectUrl', state.url);
      this.smartlinkManagementService.getRetroSmartlinkBanco(bancoContenidosUrl).pipe(
        take(1),
        takeUntil(tmp$))
        .subscribe(
          res => {
            tmp$.next();
            tmp$.complete();
            this.authManagementService.redirectUrl = res as any;
            localStorage.setItem('redirectUrl', res);
            window.open(res, '_self');
          },
          _err => {
            localStorage.removeItem('redirectUrl');
            this.router.navigate(['/']);
          }
        );
      return false;
    }

    return true;
  }

  private formarUrl(idMateria, idTema, idRecurso) {
    if (idRecurso) {
      return `/inicio/mis-materias/materia/${idMateria}/tema/${idTema}/recurso/${idRecurso}`;
    } else {
      return `/inicio/mis-materias/materia/${idMateria}/tema/${idTema}`;
    }
  }

  private async getToken() {
    return await this.localStorageService.getItem('JWT').pipe(first()).toPromise();
  }
}