/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ApplicationRef, NgZone, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditionModeService } from '../../../../services/edition-mode.service';
import { AudioComponent } from '../../dialogs/add-modules-base/modulos/audio/audio.component';
import { DestacadoComponent } from '../../dialogs/add-modules-base/modulos/destacado/destacado.component';
import { DocumentoComponent } from '../../dialogs/add-modules-base/modulos/documento/documento.component';
import { EnlaceComponent } from '../../dialogs/add-modules-base/modulos/enlace/enlace.component';
import { ImagenComponent } from '../../dialogs/add-modules-base/modulos/imagen/imagen.component';
import { SeccionComponent } from '../../dialogs/add-modules-base/modulos/seccion/seccion.component';
import { TablaComponent } from '../../dialogs/add-modules-base/modulos/tabla/tabla.component';
import { TextoComponent } from '../../dialogs/add-modules-base/modulos/texto/texto.component';
import { VideoComponent } from '../../dialogs/add-modules-base/modulos/video/video.component';
import { AddRecursoComponent } from '../../dialogs/add-recurso/add-recurso.component';
import { TemasManagementService } from '../../../../services/data/temas/temas-management.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EditRecursoPlantillaComponent } from '../../dialogs/edit-recurso-plantilla/edit-recurso-plantilla.component';
import { environment } from '../../../../../environments/environment';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { ScrollHorizontalService } from '../../../../services/scroll-horizontal.service';
import { EditionDirective } from '../../../../core/directives/edition/edition.directive';
import { ScrollService } from '../../../../core/scroll/scroll.service';

@Component({
  selector: 'aula-planeta-edition-add',
  templateUrl: './edition-add.component.html',
  styleUrls: ['./edition-add.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('out, void', style({ opacity: 0 })),
      state('in', style({ opacity: 1 })),
      transition('in <=> out', animate('800ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('in <=> void', animate('800ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditionAddComponent implements OnInit, OnDestroy {


  private destroy$: Subject<void> = new Subject<void>();

  voidConst: string;
  posicion: string;
  options: any = [];
  optionsDefault: any = [
    {
      id: 'seccion1',
      icon: 'format_indent_increase',
      label: 'Añadir sección 1º nivel'
    },
    {
      id: 'seccion2',
      icon: 'format_indent_increase',
      label: 'Añadir sección 2º nivel'
    },
    {
      id: 'seccion3',
      icon: 'format_indent_increase',
      label: 'Añadir sección 3º nivel'
    },
    {
      id: 'seccion4',
      icon: 'format_indent_increase',
      label: 'Añadir sección 4º nivel'
    },
    {
      id: 'divider',
      icon: '',
      label: ''
    },
    {
      id: 'texto',
      icon: 'article',
      label: 'Añadir texto'
    },
    {
      id: 'imagen',
      icon: 'image',
      label: 'Añadir imagen'
    },
    {
      id: 'recurso',
      icon: 'smart_display',
      label: 'Añadir recurso'
    },
    {
      id: 'destacado',
      icon: 'highlight',
      label: 'Añadir destacado'
    },
    {
      id: 'documento',
      icon: 'insert_drive_file',
      label: 'Añadir documento'
    },
    {
      id: 'audio',
      icon: 'audiotrack',
      label: 'Añadir sonido'
    },
    {
      id: 'video',
      icon: 'movie',
      label: 'Añadir video'
    },
    {
      id: 'enlace',
      icon: 'link',
      label: 'Añadir enlace'
    }
  ];

  dialogComponents = {
    seccion1: SeccionComponent,
    seccion2: SeccionComponent,
    seccion3: SeccionComponent,
    seccion4: SeccionComponent,
    audio: AudioComponent,
    enlace: EnlaceComponent,
    destacado: DestacadoComponent,
    documento: DocumentoComponent,
    imagen: ImagenComponent,
    tabla: TablaComponent,
    texto: TextoComponent,
    video: VideoComponent,
    recurso: AddRecursoComponent
  }

  constructor(
    public applicationRef: ApplicationRef,
    public zone: NgZone,
    public dialog: MatDialog,
    public editionModeService: EditionModeService,
    public temasManagementService: TemasManagementService,
    private scrollHorizontalService: ScrollHorizontalService,
    private gtagService: GtagService,
    private scrollService: ScrollService
  ) { }

  ngOnInit() {
    // empty
    this.scrollHorizontalService.scrollHorizontal = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  setOptions(listId) {
    this.options = listId ? this.optionsDefault.filter(option => listId.indexOf(option.id) > -1) : this.optionsDefault;
  }

  openDialog(idTipoComponenteModulo, event?: PointerEvent) {
    let popupId: string;
    let popupName: string;
    let action: string;
    switch (idTipoComponenteModulo) {
      case 'seccion1':
      case 'seccion2':
        action = 'añadir',
          popupId = 'popup_añadir_modulo_seccion',
          popupName = 'popup_añadir_modulo_seccion';
        break;
      case 'texto':
        action = 'guardar',
          popupId = 'dialog-add-module-texto',
          popupName = 'popup_añadir_modulo_texto';
        break;
      case 'imagen':
        popupId = 'dialog-add-module-imagen';
        popupName = 'popup_añadir_imagen',
          action = 'guardar';
        break;
      case 'recurso':
        popupId = 'dialog-add-recurso',
          popupName = 'popup_añadir_recurso',
          action = 'añadir';
        break;
      case 'recurso':
        popupId = 'dialog-añadir-destacado',
          popupName = 'popup_añadir_destacado',
          action = 'guardar';
        break;
      case 'documento':
        popupId = 'dialog-add-module-documento',
          popupName = 'popup_añadir_documento',
          action = 'guardar';
        break;
      case 'audio':
        popupId = 'dialog-add-module-audio',
          popupName = 'popup_añadir_audio',
          action = 'guardar';
        break;
      case 'tabla':
        popupId = 'dialog-add-module-tabla',
          popupName = 'popup_añadir_tabla',
          action = 'guardar';
        break;
      case 'video':
        popupId = 'dialog-add-module-video',
          popupName = 'popup_añadir_video',
          action = 'guardar';
        break;
      case 'enlace':
        popupId = 'dialog-add-module-enlace',
          popupName = 'popup_añadir_enlace',
          action = 'guardar';
        break;
    }
    this.editionModeService.dialogOpen = true;
    this.scrollService.bloqueateScroll();
    const dialogRef = this.dialog.open(this.dialogComponents[idTipoComponenteModulo], {
      restoreFocus: true,
      disableClose: true
    });
    dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(moduloCreado => {
      // this.temasManagementService.restoreScrollPosition();
      this.scrollService.desbloqueateScroll();
      this.editionModeService.dialogOpen = false;

      if (moduloCreado) {
        const info = {
          event: 'popup',
          popup: {
            action: action,
            popupId: popupId,
            popupName: popupName,
            pageId: event?.view?.location.hash
          }
        };
        this.gtagService.event(info);

        const moduleSchema = this.editionModeService.generateModuleSchema();
        moduleSchema.temporal = true;
        let hija = false;
        switch (idTipoComponenteModulo) {
          case 'seccion1':
            idTipoComponenteModulo = 'seccion';
            moduleSchema.comunes.tipoModulo.valor = idTipoComponenteModulo;
            moduleSchema.comunes.tipoModulo.idValor = 1;
            moduleSchema.clave.smartlinkId = this.editionModeService.generateUUID();
            moduleSchema.propiedades = { ...moduleSchema.propiedades, ...moduloCreado };
            this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewSeccion1(
              this.temasManagementService.tema.modulosOrganizacion, moduleSchema, this.posicion);
            this.refresh();
            break;

          case 'seccion2':
            hija = false;
            idTipoComponenteModulo = 'seccion';
            moduleSchema.comunes.tipoModulo.idValor = 1;
            moduleSchema.comunes.tipoModulo.valor = idTipoComponenteModulo;
            moduleSchema.clave.smartlinkId = this.editionModeService.generateUUID();
            moduleSchema.propiedades = { ...moduleSchema.propiedades, ...moduloCreado };
            this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
              this.temasManagementService.tema.modulosOrganizacion, null, moduleSchema, this.editionModeService.parent.id, this.posicion, hija);
            this.refresh();
            break;

          case 'seccion3':
            hija = this.editionModeService.type === 'seccion2';
            idTipoComponenteModulo = 'seccion';
            moduleSchema.comunes.tipoModulo.idValor = 1;
            moduleSchema.comunes.tipoModulo.valor = idTipoComponenteModulo;
            moduleSchema.clave.smartlinkId = this.editionModeService.generateUUID();
            moduleSchema.propiedades = { ...moduleSchema.propiedades, ...moduloCreado };
            this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
              this.temasManagementService.tema.modulosOrganizacion, null, moduleSchema, this.editionModeService.parent.id, this.posicion, hija);
            this.refresh();
            break;

          case 'seccion4':
            hija = this.editionModeService.type === 'seccion3';
            idTipoComponenteModulo = 'seccion';
            moduleSchema.comunes.tipoModulo.idValor = 1;
            moduleSchema.comunes.tipoModulo.valor = idTipoComponenteModulo;
            moduleSchema.clave.smartlinkId = this.editionModeService.generateUUID();
            moduleSchema.propiedades = { ...moduleSchema.propiedades, ...moduloCreado };
            this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
              this.temasManagementService.tema.modulosOrganizacion, null, moduleSchema, this.editionModeService.parent.id, this.posicion, hija);
            this.refresh();
            break;

          case 'recurso':

            if (moduloCreado.sectionType === 1) {
              hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
              const nuevoModulo = moduloCreado.recursoBuscador;
              nuevoModulo.comunes.tipoModulo.idValor = 7;
              nuevoModulo.modulosOrganizacion = [];
              nuevoModulo.propiedades.visible = 'Alumnos';
              this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
              this.refresh();

            } else if (moduloCreado.sectionType === 2) {

              this.temasManagementService.crearModuloDesdePlantilla({
                plantilla: moduloCreado.plantilla
              }).pipe(
                take(1),
                takeUntil(this.destroy$))
                .subscribe(respuesta => {

                  let actividad: string = respuesta.actividad;
                  actividad = actividad.replace('Principal', 'teditor');
                  const url = `${actividad}?out_url=${environment.api}/plantilla/actualizar/${respuesta.id}/crear`;

                  const dialogRef = this.dialog.open(EditRecursoPlantillaComponent, {
                    data: {
                      url,
                      idModulo: respuesta.id,
                      color: 'standard'
                    },
                    restoreFocus: true
                  });
                  dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(nuevoModulo => {
                    console.log('nuevoModulo:', nuevoModulo);

                    if (nuevoModulo) {
                      // idTipoComponenteModulo = idTipoComponenteModulo.slice(0, -1) === 'seccion' ? 'seccion' : idTipoComponenteModulo;
                      nuevoModulo.comunes.tipoModulo.idValor = 7;
                      // hija = this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3';
                      // nuevoModulo.comunes.tipoModulo.valor = 'recurso';
                      // nuevoModulo.id = respuesta.id;
                      hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';

                      nuevoModulo.modulosOrganizacion = [];
                      nuevoModulo.propiedades.visible = 'Alumnos';
                      this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                        this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
                      this.refresh();
                    } else {
                      this.refresh();
                    }
                  });
                });

            } else if (moduloCreado.sectionType === 3) {

              switch (moduloCreado.recurso.type) {
                case 1:
                  this.temasManagementService.crearModuloRecursoEnlace(
                    {
                      moduloRecursoPropioTipo: 0,
                      titulo: moduloCreado.recurso.title,
                      descripcion: moduloCreado.recurso.description,
                      url: moduloCreado.recurso.url,
                      plantilla: '',
                      codigoEmbeded: '',
                      visible: 'Alumnos'
                    }
                  ).pipe(
                    take(1),
                    takeUntil(this.destroy$))
                    .subscribe(respuesta => {
                      hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
                      const nuevoModulo = respuesta;
                      nuevoModulo.comunes.tipoModulo.idValor = 7;
                      nuevoModulo.modulosOrganizacion = [];
                      nuevoModulo.propiedades.visible = 'Alumnos';
                      this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                        this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
                      this.refresh();

                    });

                  break;
                case 2:
                  this.temasManagementService.crearModuloRecursoDocumento(
                    {
                      moduloRecursoPropioTipo: 0,
                      titulo: moduloCreado.recurso.title,
                      descripcion: moduloCreado.recurso.description,
                      url: '',
                      plantilla: '',
                      codigoEmbeded: moduloCreado.recurso.embeded,
                      visible: 'Alumnos',
                      documento: {
                        nombreFichero: moduloCreado.recurso.fileName ? moduloCreado.recurso.fileName : null,
                        contenidoFichero: moduloCreado.recurso.file ? moduloCreado.recurso.file.split('base64,')[1] : null,
                        encodingType: 0
                      },
                    }
                  ).pipe(
                    take(1),
                    takeUntil(this.destroy$))
                    .subscribe(respuesta => {

                      const nuevoModulo = respuesta;
                      hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
                      nuevoModulo.comunes.tipoModulo.idValor = 7;
                      nuevoModulo.modulosOrganizacion = [];
                      nuevoModulo.propiedades.visible = 'Alumnos';
                      this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                        this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
                      this.refresh();

                    });
                  break;
                case 3:
                  this.temasManagementService.crearModuloRecursoVideo(
                    {
                      moduloRecursoPropioTipo: 0,
                      titulo: moduloCreado.recurso.title,
                      descripcion: moduloCreado.recurso.description,
                      url: '',
                      plantilla: '',
                      codigoEmbeded: moduloCreado.recurso.embeded,
                      visible: 'Alumnos'
                    }
                  ).pipe(
                    take(1),
                    takeUntil(this.destroy$))
                    .subscribe(respuesta => {

                      const nuevoModulo = respuesta;
                      hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
                      nuevoModulo.comunes.tipoModulo.idValor = 7;
                      nuevoModulo.modulosOrganizacion = [];
                      nuevoModulo.propiedades.visible = 'Alumnos';
                      this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                        this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
                      this.refresh();

                    });

                  break;
                case 4:
                  this.temasManagementService.crearModuloRecursoImagen(
                    {
                      moduloTipo: 1,
                      titulo: moduloCreado.recurso.title,
                      descripcion: moduloCreado.recurso.description,
                      url: '',
                      plantilla: '',
                      codigoEmbeded: '',
                      visible: 'Alumnos',
                      abrirEnVentanaNueva: true,
                      creditos: moduloCreado.recurso.creditos_html,
                      textoAlternativo: moduloCreado.recurso.texto_alternativo,
                      pieDeImagen: moduloCreado.recurso.pie_de_imagen,
                      transcripcion: '',
                      subtitulosFichero: null,
                      imagen: {
                        nombreFichero: moduloCreado.recurso.imagenName ? moduloCreado.recurso.imagenName : null,
                        contenidoFichero: moduloCreado.recurso.imagen ? moduloCreado.recurso.imagen.split('base64,')[1] : null,
                        encodingType: 0
                      },
                      imagenPreviaFichero: {
                        nombreFichero: moduloCreado.recurso.imagen_previaName ? moduloCreado.recurso.imagen_previaName : null,
                        contenidoFichero: moduloCreado.recurso.imagen_previa ? moduloCreado.recurso.imagen_previa.split('base64,')[1] : null,
                        encodingType: 0
                      },
                    }


                  ).pipe(
                    take(1),
                    takeUntil(this.destroy$))
                    .subscribe(respuesta => {

                      const nuevoModulo = respuesta;
                      hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
                      nuevoModulo.comunes.tipoModulo.idValor = 7;
                      nuevoModulo.modulosOrganizacion = [];
                      nuevoModulo.propiedades.visible = 'Alumnos';
                      this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                        this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
                      this.refresh();

                    });
                  break;

                case 5:
                  this.temasManagementService.crearModuloRecursoAudio(
                    {
                      moduloRecursoPropioTipo: 0,
                      titulo: moduloCreado.recurso.title,
                      descripcion: moduloCreado.recurso.description,
                      url: '',
                      plantilla: '',
                      codigoEmbeded: moduloCreado.recurso.embeded,
                      visible: 'Alumnos',
                      audio: {
                        nombreFichero: moduloCreado.recurso.fileName ? moduloCreado.recurso.fileName : null,
                        contenidoFichero: moduloCreado.recurso.file ? moduloCreado.recurso.file.split('base64,')[1] : null,
                        encodingType: 0
                      },
                    }
                  ).pipe(
                    take(1),
                    takeUntil(this.destroy$))
                    .subscribe(respuesta => {

                      const nuevoModulo = respuesta;
                      hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
                      nuevoModulo.comunes.tipoModulo.idValor = 7;
                      nuevoModulo.modulosOrganizacion = [];
                      nuevoModulo.propiedades.visible = 'Alumnos';
                      this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                        this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
                      this.refresh();

                    });
                  break;
              }
            }

            break;

          case 'documento':
            const payload = {
              titulo: moduloCreado.titulo_html,
              descripcion: moduloCreado.descripcion_html,
              visible: moduloCreado.visible,
              documento: {
                nombreFichero: moduloCreado.documentoName ? moduloCreado.documentoName : null,
                contenidoFichero: moduloCreado.documento ? moduloCreado.documento.split('base64,')[1] : null
              },
              imagenPreviaFichero: moduloCreado.imagen_previa ? {
                nombreFichero: moduloCreado.imagen_previaName,
                contenidoFichero: moduloCreado.imagen_previa.split('base64,')[1]
              } : null,
              creditos: moduloCreado.creditos_html
            };
            this.temasManagementService.crearModuloDocumento(payload).pipe(
              take(1),
              takeUntil(this.destroy$))
              .subscribe(nuevoModulo => {
                nuevoModulo.modulosOrganizacion = [];
                hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
                // nuevoModulo.propiedades.visible = 'Alumnos';
                this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                  this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
                this.refresh();
              });

            break;

          case 'audio':
            const audio = {
              moduloTipo: 1,
              titulo: moduloCreado.titulo_html,
              descripcion: moduloCreado.descripcion_html,
              visible: moduloCreado.visible,
              audio: {
                nombreFichero: moduloCreado.audioName ? moduloCreado.audioName.toLowerCase() : null,
                contenidoFichero: moduloCreado.audio ? moduloCreado.audio.split('base64,')[1] : null,
                encodingType: 0
              },
              imagenPreviaFichero: moduloCreado.imagen_previa ? {
                nombreFichero: moduloCreado.imagen_previaName ? moduloCreado.imagen_previaName.toLowerCase() : null,
                contenidoFichero: moduloCreado.imagen_previa.split('base64,')[1],
                encodingType: 0
              } : null,
              subtitulosFichero: moduloCreado.subtitulos ? {
                nombreFichero: moduloCreado.subtitulosName,
                contenidoFichero: moduloCreado.subtitulos.split('base64,')[1],
                encodingType: 0
              } : null,
              creditos: moduloCreado.creditos_html,
              textoAlternativo: '',
              pieDeImagen: '',
              transcripcion: moduloCreado.transcripcion,
              abrirEnVentanaNueva: true
            };
            this.temasManagementService.crearModuloAudio(audio).pipe(
              take(1),
              takeUntil(this.destroy$))
              .subscribe(nuevoModulo => {
                nuevoModulo.modulosOrganizacion = [];
                hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
                // nuevoModulo.propiedades.visible = 'Alumnos';
                this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                  this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
                this.refresh();
              });

            break;

          case 'imagen':
            const imagen = {
              moduloTipo: 1,
              titulo: moduloCreado.titulo_html,
              descripcion: moduloCreado.descripcion_html,
              visible: moduloCreado.visible,
              imagen: {
                nombreFichero: moduloCreado.imagenName ? moduloCreado.imagenName.toLowerCase() : null,
                contenidoFichero: moduloCreado.imagen ? moduloCreado.imagen.split('base64,')[1] : null,
                encodingType: 0
              },
              imagenPreviaFichero: moduloCreado.imagen_previa ? {
                nombreFichero: moduloCreado.imagen_previaName ? moduloCreado.imagen_previaName.toLowerCase() : null,
                contenidoFichero: moduloCreado.imagen_previa.split('base64,')[1],
                encodingType: 0
              } : null,
              subtitulosFichero: moduloCreado.fichero_subtitulos ? {
                nombreFichero: moduloCreado.fichero_subtitulosName,
                contenidoFichero: moduloCreado.fichero_subtitulos.split('base64,')[1],
                encodingType: 0
              } : null,
              creditos: moduloCreado.creditos_html,
              textoAlternativo: moduloCreado.texto_alternativo,
              pieDeImagen: moduloCreado.pie_de_imagen,
              transcripcion: moduloCreado.transcripcion,
              abrirEnVentanaNueva: true
            };

            this.temasManagementService.crearModuloImagen(imagen).pipe(
              take(1),
              takeUntil(this.destroy$))
              .subscribe(nuevoModulo => {
                nuevoModulo.modulosOrganizacion = [];
                hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
                // nuevoModulo.propiedades.visible = 'Alumnos';
                this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                  this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
                this.refresh();
              });

            break;


          case 'video':
            const video = {
              moduloTipo: 1,
              titulo: moduloCreado.titulo_html,
              descripcion: moduloCreado.descripcion_html,
              visible: moduloCreado.visible,
              video: {
                nombreFichero: moduloCreado.videoName ? moduloCreado.videoName : null,
                contenidoFichero: moduloCreado.video ? moduloCreado.video.split('base64,')[1] : null,
                encodingType: 0
              },
              imagenPreviaFichero: moduloCreado.imagen_previa ? {
                nombreFichero: moduloCreado.imagen_previaName,
                contenidoFichero: moduloCreado.imagen_previa.split('base64,')[1],
                encodingType: 0
              } : null,
              subtitulosFichero: moduloCreado.subtitulos ? {
                nombreFichero: moduloCreado.subtitulosName,
                contenidoFichero: moduloCreado.subtitulos.split('base64,')[1],
                encodingType: 0
              } : null,
              creditos: moduloCreado.creditos_html,
              textoAlternativo: moduloCreado.texto_alternativo,
              pieDeImagen: moduloCreado.pie_de_imagen,
              transcripcion: moduloCreado.transcripcion,
              abrirEnVentanaNueva: true
            };

            this.temasManagementService.crearModuloVideo(video).pipe(
              take(1),
              takeUntil(this.destroy$))
              .subscribe(nuevoModulo => {
                nuevoModulo.modulosOrganizacion = [];
                hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
                // nuevoModulo.propiedades.visible = 'Alumnos';
                this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                  this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, this.posicion, hija);
                this.refresh();
              });

            break;

          default:
            idTipoComponenteModulo = idTipoComponenteModulo.slice(0, -1) === 'seccion' ? 'seccion' : idTipoComponenteModulo;
            moduleSchema.comunes.tipoModulo.idValor = idTipoComponenteModulo === 'seccion' ? 1 : 0;
            hija = (this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3' || this.editionModeService.type === 'seccion4') && this.posicion === 'down';
            moduleSchema.comunes.tipoModulo.valor = idTipoComponenteModulo;
            moduleSchema.propiedades = { ...moduleSchema.propiedades, ...moduloCreado };
            this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
              this.temasManagementService.tema.modulosOrganizacion, null, moduleSchema, this.editionModeService.parent.id, this.posicion, hija);
            this.refresh();
            break;
        }

      } else {
        this.refresh();
        const info = {
          event: 'popup',
          popup: {
            action: 'cancelar',
            popupId: popupId,
            popupName: popupName,
            pageId: event?.view?.location.hash
          }
        };
        this.gtagService.event(info);
      }
    });
  }

  refresh() {
    this.editionModeService.recalculoIndicesOrden(this.temasManagementService.tema);
    this.temasManagementService.tema = { ...this.temasManagementService.tema } as any;
    this.editionModeService.triggerOutsideClick();
  }

  gtagInfo(event: PointerEvent) {
    const info = {
      event: 'select_icon',
      selectIcon: {
        optionSelected: 'add_element',
        pageId: event.view.location.hash,
      }
    };
    this.gtagService.event(info);
  }

}
