<div id="dialog-modificar-tema" class="standard">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.modificar-tema.title' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <form role="form" [formGroup]="form">
            <div class="d-flex flex-wrap fielset-container">

                <fieldset class="w-100 d-flex flex-column flex-md-row">
                    <div class="w-100 me-3 d-flex flex-column">
                        <div class="form-group default-input mb-4">
                            <label for="name">{{ 'dialogs.modificar-tema.name' | translate }}</label>
                            <input id="name" aria-labelledby="error_name" type="text" class="form-control" [placeholder]="('dialogs.modificar-tema.name' | translate)" formControlName="name">
                            <mat-error id="error_name" *ngIf="form.get('name').hasError('required') && form.controls['name'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="form-group default-textarea mb-4">
                            <label for="description">{{'dialogs.modificar-tema.description' | translate}}</label>
                            <textarea id="description" aria-labelledby="error_description" type="text" class="form-control" [placeholder]="('dialogs.modificar-tema.description' | translate)" formControlName="description"></textarea>
                            <mat-error id="error_description" *ngIf="form.get('description').hasError('required') && form.controls['description'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="form-group">
                            <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="shared">{{ 'dialogs.modificar-tema.share' | translate }}</mat-slide-toggle>
                        </div>
                    </div>
                    <div class="w-100 d-flex flex-column">
                        <div class="form-group default-input mb-4">
                            <label for="author">{{ 'dialogs.modificar-tema.author' | translate }}</label>
                            <input id="author" aria-labelledby="error_author" type="text" class="form-control" [placeholder]="('dialogs.modificar-tema.author' | translate)" formControlName="author">
                            <mat-error id="error_author" *ngIf="form.get('author').hasError('required') && form.controls['author'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>

                        <div class="d-flex flex-column form-group default-dropzone">
                            <label for="fileField">{{ 'dialogs.modificar-tema.upload-image' | translate }}</label>
                            <div class="mb-2" (dropZone)="onDrop($event)" (click)="fileField.click()">
                                <div class="default-dropzone__container d-flex align-items-center justify-content-center">
                                    <img *ngIf="form.get('img').value" [src]="form.get('img').value | safe:'url'" alt="Imagen del tema">
                                    <mat-icon *ngIf="!form.get('img').value">cloud_upload</mat-icon>
                                </div>
                            </div>
                            <div class="p-0 d-flex flex-column default-dropzone__text">
                                <p class="mb-3">{{ 'dialogs.modificar-tema.drag-or' | translate }}<a [matMenuTriggerFor]="menu">{{ (form.get('img').value ? 'dialogs.modificar-tema.change' : 'dialogs.modificar-tema.add') | translate }}</a>{{ 'dialogs.modificar-tema.one-image' | translate }}</p>
                                <input id="fileField" type="file" name="img" #fileField (change)="onDrop($event.target.files[0])" hidden multiple>
                                <mat-menu #menu="matMenu" class="default-dropdown">
                                    <button mat-menu-item (click)="fileField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>{{ 'dialogs.modificar-tema.button.select-image' | translate }}</button>
                                    <button [disabled]="!form.get('img').value" mat-menu-item (click)="deleteImg()"> <mat-icon class="material-icons-filled">delete</mat-icon>{{ 'dialogs.modificar-tema.button.delete-image' | translate }}</button>
                                </mat-menu>
                                <p>{{ 'dialogs.modificar-tema.help' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </fieldset>

            </div>

            <div mat-dialog-actions class="justify-content-start mt-2">
                <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'dialogs.modificar-tema.cancel' | translate }}</button>
                <button role="button" class="default-button default-button__primary" (click)="onSubmit()" type="submit" [disabled]="!form.valid || form.pristine">{{ 'dialogs.modificar-tema.create' | translate }}</button>
            </div>
        </form>
    </div>
</div>
