import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy }
  from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColorHelper } from '../../../helper/color.helper';
import { CrearMateriaComponent } from './components/crear-materia/crear-materia.component';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { MateriaManagementService } from '../../../../services/data/materia/materia-management.service';
import { EliminarComponent } from '../eliminar/eliminar.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'aula-planeta-configurar-otras-materias',
  templateUrl: 'configurar-otras-materias.component.html',
  styleUrls: ['./configurar-otras-materias.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurarOtrasMateriasComponent implements OnInit, OnChanges, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  removable = true;
  displayedColumns: string[] = [
    'titulo',
    'acciones'
  ];

  cursos: any = [];
  tabIndexActive = 0;
  dataSource = new MatTableDataSource<[]>();

  constructor(
    public materiaManagementService: MateriaManagementService,
    public dialogRef: MatDialogRef<ConfigurarOtrasMateriasComponent>,
    public colorHelper: ColorHelper,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {

  }

  ngOnInit() {
    const info = {
      event: 'popup',
      popup: {
        action: 'configure_other_subjects',
        popupId: 'dialog-configurar-otras-materias',
        popupName: 'popup_configure_other_subjects'
      }
    };
    this.gtagService.event(info);
    this.materiaManagementService.getOtrasMaterias();
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  refresh() {
    this.cursos = [];
    this.materiaManagementService.otrasMaterias$
      .pipe(
        take(1),
        takeUntil(this.destroy$))
      .subscribe(val => {

        if (val && val.length > 0) {
          const cursos = [];
          val.forEach((current: any) => {
            const currentCurso = current.curso.ordinal + ' ' + current.curso.etapa.acronimo;
            const index = cursos.findIndex(elem => elem.title === currentCurso);
            if (index !== -1) {
              cursos[index].materias.push(current);
            } else {
              cursos.push({ title: currentCurso, materias: [current] });
            }
          });
          this.cursos = cursos;

          if (this.cursos[this.tabIndexActive]) {
            this.dataSource.data = this.cursos[this.tabIndexActive].materias;
          } else {
            this.dataSource.data = this.cursos[0].materias;
          }
        }
      });
  }

  setData(tabChangeEvent: MatTabChangeEvent): void {
    this.tabIndexActive = tabChangeEvent.index;
    console.log(this.tabIndexActive);
    this.dataSource.data = this.cursos[this.tabIndexActive].materias;
  }

  removeMateria(materia, event) {
    const dialogRef = this.dialog.open(EliminarComponent, {
      data: {
        event: event
      },
      restoreFocus: true
    });

    dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(result => {
      if (result) {
        const info = {
          event: 'popup',
          popup: {
            action: 'eliminar',
            pageId: event.view.location.hash,
            popupId: 'dialog-dialog-eliminar',
            popupName: 'popup_delete'
          }
        };
        this.gtagService.event(info);

        this.materiaManagementService.deleteMateriaCentro(materia);
        this.refresh();
      } else {
        const info = {
          event: 'popup',
          popup: {
            action: 'cancelar',
            pageId: event?.view?.location.hash,
            popupId: 'dialog-dialog-eliminar',
            popupName: 'popup_delete'
          }
        };
        this.gtagService.event(info);
      }
    });
  }

  addMateria(event) {
    const dialogRef = this.dialog.open(CrearMateriaComponent, {
      data: {
        isEdit: false
      },
      restoreFocus: true
    });

    dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(result => {
      if (result) {
        const info = {
          event: 'popup',
          popup: {
            action: 'crear',
            pageId: event.view.location.hash,
            popupId: 'dialog-dialog-crear-otra-materia-aula',
            popupName: 'popup_create_other_materia'
          }
        };
        this.gtagService.event(info);

        this.materiaManagementService.createMateriaCentro(result);
        this.refresh();
      } else {
        const info = {
          event: 'popup',
          popup: {
            action: 'cancelar',
            pageId: event?.view?.location.hash,
            popupId: 'dialog-dialog-crear-otra-materia-aula',
            popupName: 'popup_create_other_materia'
          }
        };
        this.gtagService.event(info);
      }
    });
  }

  editMateria(materia, event) {
    this.materiaManagementService.getAutoreMateriaCentro(materia.id).pipe(takeUntil(this.destroy$)).subscribe(val => {
      const dialogRef = this.dialog.open(CrearMateriaComponent, {
        data: {
          nombre: materia.nombre,
          descripcion: materia.descripcion,
          profesores: val,
          materiaMaestraId: materia.materiaMaestra.id,
          etapa: materia.curso.etapa.nombre,
          curso: materia.curso.ordinal,
          isEdit: true
        },
        restoreFocus: true
      });

      dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(result => {
        if (result) {
          const info = {
            event: 'popup',
            popup: {
              action: 'editar',
              pageId: event.view.location.hash,
              popupId: 'dialog-dialog-crear-otra-materia-aula',
              popupName: 'popup_create_other_materia'
            }
          };
          this.gtagService.event(info);

          this.materiaManagementService.updateMateriaCentro(materia.id, result);
          this.refresh();

        } else {
          const info = {
            event: 'popup',
            popup: {
              action: 'cancelar',
              pageId: event?.view?.location.hash,
              popupId: 'dialog-dialog-crear-otra-materia-aula',
              popupName: 'popup_create_other_materia'
            }
          };
          this.gtagService.event(info);
        }
      });
    });
  }
}
