<div id="dialog-mover" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text">{{ data.titulo | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <h5 class="custom-text mb-4">{{ data.subtitulo | translate }}</h5>

        <div class="accordeon-list">
            <form role="form" [formGroup]="mvForm">

                <div class="my-4">
                    <div class="location-title w-100 mb-3 pb-3">
                        <span class="default-text">{{ 'dialogs.move.location-label' | translate }}</span>
                    </div>
                    <div class="overflow-auto">
                        <mat-accordion class="d-flex flex-column" displayMode="flat" role="list">
                            <mat-expansion-panel *ngFor="let curso of data.cursos;" class="mat-elevation-z0" role="listitem">
                                <mat-expansion-panel-header class="flex-row-reverse">
                                    <mat-panel-title class="grey-text__light">{{ curso.name }}</mat-panel-title>
                                </mat-expansion-panel-header>

                                <ng-template *ngIf="curso.materias" matExpansionPanelContent>
                                    <mat-radio-group formControlName="location">
                                        <mat-accordion class="d-flex flex-column" role="list">

                                            <ng-container *ngIf="!data.isTemas; else temas">
                                                <mat-list role="list">
                                                    <mat-radio-group formControlName="location">
                                                        <mat-list-item *ngFor="let materia of curso.materias;" role="listitem" [ngClass]="colorHelper.getColor(materia.color)">
                                                            <mat-radio-button [value]="materia.id" class="custom-text" [checked]="materia.id===mvForm.get('location').value"> {{ materia.nombre }}</mat-radio-button>
                                                        </mat-list-item>
                                                    </mat-radio-group>
                                                </mat-list>
                                            </ng-container>

                                            <ng-template #temas>
                                                <mat-expansion-panel *ngFor="let materia of curso.materias;" class="mat-elevation-z0" role="listitem">
                                                    <mat-expansion-panel-header class="flex-row-reverse">
                                                        <mat-panel-title [ngClass]="colorHelper.getColor(materia.color)">
                                                            <mat-radio-button *ngIf="!data.isTemas" (click)="$event.stopPropagation()" [value]="materia.id" class="custom-text mb-0" [checked]="materia.id===mvForm.get('location').value">{{ materia.nombre }}</mat-radio-button>
                                                            <span class="custom-text mb-0" [ngClass]="colorHelper.getColor(materia.color)" *ngIf="data.isTemas">{{ materia.nombre }}</span>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>

                                                    <ng-template *ngIf="materia.indiceTemas" matExpansionPanelContent>

                                                        <mat-accordion class="d-flex flex-column" role="list">

                                                            <ng-container *ngIf="!data.isSecciones; else secciones">
                                                                <mat-list role="list">
                                                                    <mat-radio-group formControlName="location">
                                                                        <mat-list-item *ngFor="let tema of materia.indiceTemas" role="listitem" [ngClass]="colorHelper.getColor(materia.color)">
                                                                            <mat-radio-button [value]="tema.id" class="custom-text" [checked]="tema.id===mvForm.get('location').value"> {{ tema.tituloPlano }}</mat-radio-button>
                                                                        </mat-list-item>
                                                                    </mat-radio-group>
                                                                </mat-list>
                                                            </ng-container>

                                                            <ng-template #secciones>

                                                                <mat-expansion-panel *ngFor="let tema of materia.indiceTemas" class="mat-elevation-z0" role="listitem">
                                                                    <mat-expansion-panel-header class="flex-row-reverse">
                                                                        <mat-panel-title [ngClass]="colorHelper.getColor(materia.color)">
                                                                            <mat-radio-button *ngIf="data.isTemasSeleccionable" (click)="$event.stopPropagation();temaSeleccionado = tema.id" [value]="tema.id" class="custom-text mb-0" (change)="mvForm.get('seccion').setValue(null)" [checked]="tema.id===mvForm.get('location').value">{{ tema.tituloPlano }}</mat-radio-button>
                                                                            <span class="custom-text mb-0" [ngClass]="colorHelper.getColor(materia.color)" *ngIf="!data.isTemasSeleccionable">{{ tema.tituloPlano }}</span>
                                                                        </mat-panel-title>
                                                                    </mat-expansion-panel-header>

                                                                    <ng-template *ngIf="tema.indiceSecciones" matExpansionPanelContent>

                                                                        <mat-accordion class="d-flex flex-column" role="list">

                                                                            <mat-list role="list">
                                                                                <mat-radio-group formControlName="seccion">
                                                                                    <mat-list-item *ngFor="let seccion of tema.indiceSecciones;" role="listitem" [ngClass]="colorHelper.getColor(materia.color)">
                                                                                        <mat-radio-button [disabled]="meCabeUnaGorda(seccion)" [value]="seccion.id" class="custom-text" (change)="temaSeleccionado = tema.id; _tema = tema; indiceSeleccionadoVirtual = seccion; mvForm.get('location').setValue(null)" [checked]="seccion.id===mvForm.get('seccion').value && temaSeleccionado ===tema.id">
                                                                                            <span *ngIf="seccion.visible === 'Alumnos'">
                                                                                                {{seccion.indice.seccion +'.'+ (seccion.indice.subseccion === 0 ? ' ': seccion.indice.subseccion +'.')+ (seccion.indice.subsubseccion === 0 ? ' ': seccion.indice.subsubseccion +'.')+ (seccion.indice.subsubsubseccion === 0 ? ' ': seccion.indice.subsubsubseccion +'.') +' '+seccion.titulo }}
                                                                                            </span>
                                                                                            <span *ngIf="seccion.visible !== 'Alumnos'">
                                                                                                {{'#.'+ (seccion.indice.subseccion === 0 ? ' ': '#.')+ (seccion.indice.subsubseccion === 0 ? ' ': '#.')+ (seccion.indice.subsubsubseccion === 0 ? ' ': seccion.indice.subsubaubseccion +'.') +' '+seccion.titulo }}
                                                                                            </span>
                                                                                        </mat-radio-button>
                                                                                    </mat-list-item>
                                                                                </mat-radio-group>
                                                                            </mat-list>


                                                                        </mat-accordion>
                                                                    </ng-template>

                                                                </mat-expansion-panel>
                                                            </ng-template>
                                                        </mat-accordion>
                                                    </ng-template>

                                                </mat-expansion-panel>
                                            </ng-template>




                                        </mat-accordion>

                                    </mat-radio-group>
                                </ng-template>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                </div>
            </form>
        </div>

    </div>

    <div mat-dialog-actions class="justify-content-start mt-3">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" (click)="close()" mat-dialog-close type="submit" [disabled]="mvForm.invalid" cdkFocusRegionEnd>{{ 'dialogs.move.button' | translate }}</button>
    </div>
</div>
