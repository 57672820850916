import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { GtagService } from '../../../services/gtag/gtag.service';
import { MiCentroDataService } from './mi-centro-data.service';

@Injectable({
  providedIn: 'root'
})
export class MiCentroManagementService implements OnDestroy {


  private _logo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  logo$ = this._logo.asObservable();

  private _cursos: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  cursos$ = this._cursos.asObservable();

  private _grupos: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  grupos$ = this._grupos.asObservable();

  private _materiasNormasOcultas: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  materiasNormasOcultas$ = this._materiasNormasOcultas.asObservable();

  private _alumnos: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  alumnos$ = this._alumnos.asObservable();

  private _alumnosLicencia: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  alumnosLicencia$ = this._alumnosLicencia.asObservable();

  private _idioma: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  idioma$ = this._idioma.asObservable();

  private _idiomas: BehaviorSubject<any> = new BehaviorSubject<any[]>([]);
  idiomas$ = this._idiomas.asObservable();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private miCentroDataService: MiCentroDataService,
    private gtagService: GtagService) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get cursos() {
    return this._cursos.getValue();
  }

  get logo() {
    return this._logo.getValue();
  }

  set alumnos(alumnos: any[]) {
    this._alumnos.next(alumnos);
  }

  get alumnos() {
    return this._alumnos.getValue();
  }

  set materiasNormasOcultas(materiasNormasOcultas: any[]) {
    this._materiasNormasOcultas.next(materiasNormasOcultas);
  }

  get materiasNormasOcultas() {
    return this._materiasNormasOcultas.getValue();
  }

  set alumnosLicencia(alumnosLicencia: any[]) {
    this._alumnosLicencia.next(alumnosLicencia);
  }

  get alumnosLicencia() {
    return this._alumnosLicencia.getValue();
  }

  set idioma(idioma: any) {
    this._idioma.next(idioma);
  }

  get idioma() {
    return this._idioma.getValue();
  }

  set idiomas(idiomas: any[]) {
    this._idiomas.next(idiomas);
  }

  get idiomas() {
    return this._idiomas.getValue();
  }

  get grupos() {
    return this._grupos.getValue();
  }


  exportCsvListadoAlumnos() {
    return this.miCentroDataService.exportCsvListadoAlumnos().pipe(
      take(1),
      takeUntil(this.destroy$)
    );
  }

  exportCsvListadoProfes() {
    return this.miCentroDataService.exportCsvListadoProfes().pipe(
      take(1),
      takeUntil(this.destroy$)
    );
  }

  asignarContraseñas(newPassword: string, confirmPassword: string, usuariosId: string[], event: PointerEvent) {
    this.miCentroDataService.asignarContraseñas(newPassword, confirmPassword, usuariosId).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        const info = {
          event: 'configuration_selected',
          configurationSelected: {
            action: 'guardar',
            pageId: event?.view?.location.hash,
            section: 'gestion de contraseñas'
          }
        };
        this.gtagService.event(info);
      }, error => {
        const info = {
          event: 'configuration_selected_error',
          configurationSelectedError: {
            errorId: error?.status,
            errorMessage: error?.statusText,
            section: 'gestion de contraseñas',
            pageId: event?.view?.location.hash
          }
        };
        this.gtagService.event(info);
      });
  }

  asignarAlumnosClase(grupoId: string, cursoId: string, usuariosId: string[], event: PointerEvent) {
    this.miCentroDataService.asignarAlumnosClase(grupoId, cursoId, usuariosId).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        const info = {
          event: 'configuration_selected',
          configurationSelected: {
            action: 'guardar',
            pageId: event?.view?.location.hash,
            section: 'gestion de clases'
          }
        };
        this.gtagService.event(info);
      }, error => {
        const info = {
          event: 'configuration_selected_error',
          configurationSelectedError: {
            errorId: error?.status,
            errorMessage: error?.statusText,
            section: 'gestion de clases',
            pageId: event?.view?.location.hash
          }
        };
        this.gtagService.event(info);
      });
  }

  accesoBancoContenidos(acceso: boolean): Observable<any> {
    return this.miCentroDataService.accesoBancoContenidos(acceso);
  }

  getMateriasNormasOcultas() {
    this.miCentroDataService.getMateriasNormasOcultas().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.materiasNormasOcultas = res;
        }
      );
  }

  ocultarMateriasNormas(values: any[]) {
    this.miCentroDataService.ocultarMateriasNormas(values).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.getMateriasNormasOcultas();
        }
      );
  }

  get(acceso: boolean): Observable<any> {
    return this.miCentroDataService.accesoBancoContenidos(acceso);
  }

  getAlumnos() {
    this.miCentroDataService.getAlumnos().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.alumnosNext(res);
        }
      );
  }

  getAlumnosLicencia() {
    this.miCentroDataService.getAlumnosLicencia().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.alumnosLicenciaNext(res);
        }
      );
  }

  getCursosGrupos() {
    this.miCentroDataService.getCursosGrupos().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.cursosNext(res.cursos);
          this.gruposNext(res.grupos);
        }
      );
  }

  getProfesores(): Observable<any> {
    return this.miCentroDataService.getProfesores();
  }

  setIdioma(idioma: any) {
    this.miCentroDataService.setIdioma(idioma).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getIdioma();
      });
  }

  getIdioma() {
    this.miCentroDataService.getIdioma().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(idioma => {
        this.idioma = idioma;
      });
  }

  getIdiomas() {
    this.miCentroDataService.getIdiomas().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(idiomas => {
        this.idiomas = idiomas.idiomas;
      });
  }

  setLogo(img: any) {
    this.miCentroDataService.setLogo({ logo: img }).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getLogo();
      });
  }

  setLogoVisible(visible: boolean, event: PointerEvent) {
    this.miCentroDataService.setLogoVisible({ visible }).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getLogo();
        const info = {
          event: 'configuration_selected',
          configurationSelected: {
            action: 'cambiar_visibilidad',
            pageId: event?.view?.location.hash,
            section: 'logotipo del centro'
          }
        };
        this.gtagService.event(info);
      }, error => {
        const info = {
          event: 'configuration_selected_error',
          configurationSelectedError: {
            errorId: error?.status,
            errorMessage: error?.title,
            section: 'logotipo del centro',
            pageId: event?.view?.location.hash
          }
        };
        this.gtagService.event(info);
      });
  }

  getLogo() {
    return this.miCentroDataService.getLogo().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.logoNext(res);
        }
      );
  }

  private logoNext(logo: any) {
    this._logo.next(logo);
  }

  private cursosNext(cursos: any[]) {
    this._cursos.next(cursos);
  }

  private gruposNext(grupos: any[]) {
    this._grupos.next(grupos);
  }

  private alumnosNext(alumnos: any[]) {
    this._alumnos.next(alumnos);
  }

  private alumnosLicenciaNext(alumnosLicencia: any[]) {
    this._alumnosLicencia.next(alumnosLicencia);
  }

}


