<div id="dialog-adjuntar-fichero" class="standard">
    <h1 mat-dialog-title class="custom-text">{{'dialogs.adjuntar-fichero.titulo' | translate}}</h1>
    <div mat-dialog-content  class="mat-dialog-content">

        <form role="form" [formGroup]="form">
            <fieldset class="w-100 d-flex flex-column">
                <div class="d-flex flex-column form-group default-dropzone mb-4">
                    <label>{{ 'dialogs.adjuntar-fichero.upload-label' | translate }}</label>
                    <div class="mb-3">
                        <div *ngIf="!form.get('file').value" (dropZone)="onDrop($event.target.files[0])" (click)="fileField.click()" class="default-dropzone__container default-dropzone__container--xl d-flex align-items-center justify-content-center">
                            <mat-icon  class="custom-icon icon">cloud_upload</mat-icon>
                        </div>
                        <div *ngIf="form.get('file').value" class="default-dropzone__container default-dropzone__container--xl d-flex align-items-center justify-content-center">
                            <div class="d-flex flex-column w-85">
                                <div class="d-flex align-items-center justify-content-between mb-4">
                                    <div class="d-flex align-items-center">
                                        <mat-icon class="custom-icon file-icon">insert_drive_file</mat-icon>
                                        <span class="grey-text ps-2">{{file.name}}</span>
                                    </div>
                                    <span class="grey-text__light">{{(file.size / 1024).toFixed(2)}}KB</span>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <mat-progress-bar
                                        class="default-progress-bar"
                                        mode="determinate"
                                        [value]="progressUpload$ | async">
                                    </mat-progress-bar>
                                    <mat-icon class="ms-3 success-outline-icon rounded-circle">done</mat-icon>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="p-0 d-flex flex-column default-dropzone__text">
                        <p class="mb-0">{{'dialogs.adjuntar-fichero.upload-info' | translate}}<a [matMenuTriggerFor]="menu">{{form.get('file').value ? 'cambia' : 'añade' }}</a>{{'dialogs.adjuntar-fichero.upload-info2' | translate}}</p>
                        <input type="file" name="file" #fileField (change)="onDrop($event.target.files[0])" hidden>
                        <mat-menu #menu="matMenu" class="default-dropdown">
                            <button mat-menu-item (click)="fileField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                            <button [disabled]="!form.get('file').value" mat-menu-item (click)="deleteFile()"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                        </mat-menu>
                        <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                        <mat-error class="mt-0 danger-text" *ngIf="isError">
                            {{'generico.formulario.fichero-novalid' | translate}}
                        </mat-error>
                    </div>
                </div>
                <div class="form-group default-input mb-4">
                    <label>{{ 'dialogs.adjuntar-fichero.upload-file-name' | translate }}</label>
                    <input type="text" class="form-control" [placeholder]="('dialogs.adjuntar-fichero.upload-file-placeholder' | translate)" formControlName="title">
                </div>
            </fieldset>
        </form>

    </div>
    <div mat-dialog-actions class="justify-content-start mt-5 mb-0">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__primary" [mat-dialog-close]="form.value" type="button" [disabled]="(!form.valid || form.pristine) && !isEndUpload">{{ 'dialogs.adjuntar-fichero.adjuntar' | translate }}</button>
    </div>
</div>

