import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as DecoupledDocumentEditor from '../../../../../../../assets/js/ck-editor-math-type/ckeditor';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { AddModulesBaseComponent } from '../../add-modules-base.component';

@Component({
  selector: 'aula-planeta-texto',
  templateUrl: './texto.component.html',
  styleUrls: ['./texto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextoComponent extends AddModulesBaseComponent implements AfterViewInit {

  @ViewChild('editorTexto', { static: false }) editorTexto: any;
  editor = DecoupledDocumentEditor;
  isDisabled = true;
  valueData = '';

  toolbar = ['heading', '|', 'bold',
    'italic', 'fontSize', 'fontFamily', 'underline', 'strikethrough', 'alignment',
    'highlight', 'MathType', 'numberedList', 'link', 'insertTable',
    'bulletedList', '|', 'indent',
    'outdent', 'undo', 'redo', 'exportPdf', 'exportWord', 'fontBackgroundColor',
    'fontColor'];

  config = {
    language: 'es',
    fontSize: { options: [9, 11, 12, 13, 'default', 17, 19, 21] },
    toolbar: this.toolbar,
    link: {
      addTargetToExternalLinks: true
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
      ]
    }
  };

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TextoComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    super(fb, dialogRef, data);
  }

  ngOnInit() {
    this.formModule = this.fb.group({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      contenido_html: [this.data && this.data.propiedades.contenido_html ? this.data.propiedades.contenido_html : '', Validators.required],
      visible: [this.data && this.data.propiedades.visible ? this.data.propiedades.visible : false],
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'attach_text',
        popupId: 'dialog-add-module-texto',
        popupName: 'popup_añadir_modulo_texto'
      }
    };
    this.gtagService.event(info);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.editorTexto && this.editorTexto.elementRef && this.editorTexto.elementRef.nativeElement) {
        this.editorTexto.elementRef.nativeElement.getElementsByClassName('ck')[0].hidden = false;
      }
    }, 100);
  }

  toggleDisabled() {
    this.isDisabled = !this.isDisabled;
    if (this.editorTexto && this.editorTexto.elementRef && this.editorTexto.elementRef.nativeElement) {
      const editorElement = this.editorTexto.elementRef.nativeElement.getElementsByClassName('ck')[0];
      if (this.isDisabled) {
        editorElement.hidden = true;
      } else {
        editorElement.hidden = false;
      }
    }
  }

  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.formModule.get('contenido_html').setValue(data);
    this.formModule.updateValueAndValidity();

  }

  public onReady(editor) {
    // No es necesario mover la barra de herramientas aquí ya que se maneja en la directiva
  }

  onClose() {

    this.dialogRef.close(this.formModule.value);

  }
}
