import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateHelper } from '../../../helper/date.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-repetir-actividad',
  templateUrl: 'repetir-actividad.component.html',
  styleUrls: ['./repetir-actividad.component.scss'],
})
export class RepetirActividadComponent implements OnInit {

  form: UntypedFormGroup;

  minDate = new Date(Date.now());
  maxDate = new Date(2045, 0, 1);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dateHelper: DateHelper,
    public dialogRef: MatDialogRef<RepetirActividadComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      fechaInicio: this.formBuilder.control(this.dateHelper.getOffsetLocal(this.data.fechaInicio), [Validators.required]),
      fechaFin: this.formBuilder.control('', [Validators.required]),
      comentario: this.formBuilder.control('', [Validators.maxLength(255)]),
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'reapeat_activity',
        popupId: 'dialog-repetir-actividad',
        popupName: 'popup_repeat_activity'
      }
    };
    this.gtagService.event(info);
  }

  onSubmit() {
    if (this.form.valid) {
      const fechas = this.form.getRawValue();
      this.dialogRef.close({ fechas });
    }
  }
}