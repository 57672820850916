<div id="dialog-filtrar-resultados" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.filtrar-resultados.title' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <div class="overflow-auto">
            <form role="form" [formGroup]="form">
                <fieldset class="w-100 d-flex flex-column">
                    <div *ngFor="let filter of data.filters" class="form-group default-input w-100">

                        <ng-container *ngIf="filter.type == 'text'">
                            <label for="filtro{{filter.code}}" class="default-text">{{ filter.label }}</label>
                            <input id="filtro{{filter.code}}" type="text" class="form-control" [formControlName]="filter.code" [placeholder]="filter.label">
                        </ng-container>

                        <ng-container *ngIf="filter.type == 'select'">
                            <label class="default-text">{{ filter.label }}</label>
                            <mat-select [formControlName]="filter.code" class="default-select default-select__blue input-style" [placeholder]="filter.label" panelClass="cdkSelect">
                                <ng-container *ngFor="let value of filter.values">
                                    <mat-option class="default-option" [value]="value.value">
                                        {{ value.viewValue }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </ng-container>

                        <ng-container *ngIf="filter.type == 'date'">
                            <div class="d-flex">
                                <div class="w-50 form-group default-input mb-0 position-relative me-3">
                                    <label for="filtro{{filter.code}}From" class="default-text">{{ filter.labelFrom }}</label>
                                    <input id="filtro{{filter.code}}From" type="text" class="form-control" [min]="minDate" [max]="maxDate" [matDatepicker]="pickerInicio" (click)="pickerInicio.open()" [placeholder]="filter.labelFrom" [formControlName]="filter.code+'From'">
                                    <mat-datepicker-toggle matSuffix [for]="pickerInicio" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerInicio startView="year" [startAt]="now"></mat-datepicker>
                                </div>

                                <div class="w-50 form-group default-input mb-0 position-relative">
                                    <label for="filtro{{filter.code}}Until" class="default-text">{{ filter.labelUntil }}</label>
                                    <input id="filtro{{filter.code}}Until" [min]="minDate" [max]="maxDate" [matDatepicker]="pickerFin" (click)="pickerFin.open()" type="text" class="form-control" [placeholder]="filter.labelUntil" [formControlName]="filter.code+'Until'">
                                    <mat-datepicker-toggle matSuffix [for]="pickerFin" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerFin startView="year" [startAt]="now"></mat-datepicker>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </fieldset>
            </form>
        </div>

    </div>

    <div mat-dialog-actions class="justify-content-start mt-2 mb-0 pb-0">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" [mat-dialog-close]="form.value" type="submit">{{ 'dialogs.filtrar-resultados.button' | translate }}</button>
    </div>
</div>
