<div id="dialog-add-subseccion" class="standard">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ 'dialogs.add-subseccion.title' | translate }}</h1>
    <div mat-dialog-content class="mat-dialog-content">
        <mat-horizontal-stepper [linear]="true" #stepper class="default-stepper" (selectionChange)="onStepperChange($event)">
            <mat-step [stepControl]="firstStep" label="{{ 'dialogs.add-subseccion.step1' | translate}}">
                <form role="form" [formGroup]="firstStep" class="mt-4">
                    <h2 class="custom-text mb-4">{{ 'dialogs.add-subseccion.step1-title' | translate }}</h2>
                    <p class="mb-5">{{ 'dialogs.add-subseccion.step1-text' | translate }}</p>

                    <mat-radio-group class="d-flex flex-column radio-list" role="list" formControlName="sectionType">
                        <mat-radio-button role="listitem" class="mb-3" [value]="1" checked [disableRipple]="true">
                            <div class="d-flex flex-column ms-2">
                                <span>{{ 'dialogs.add-subseccion.seccion-aula-planeta' | translate}}</span>
                                <small class="grey-text__light text__small">{{ 'dialogs.add-subseccion.seccion-aula-planeta-hint' | translate}}</small>
                            </div>
                        </mat-radio-button>
                        <mat-radio-button role="listitem" class="mb-3" [value]="2" [disableRipple]="true">
                            <div class="d-flex flex-column ms-2">
                                <span>{{ 'dialogs.add-subseccion.seccion-propia' | translate}}</span>
                                <small class="grey-text__light text__small">{{ 'dialogs.add-subseccion.seccion-propia-hint' | translate}}</small>
                            </div>
                        </mat-radio-button>
                        <mat-radio-button role="listitem" [value]="3" [disableRipple]="true">
                            <div class="d-flex flex-column ms-2">
                                <span>{{ 'dialogs.add-subseccion.seccion-vacia' | translate}}</span>
                                <small class="grey-text__light text__small">{{ 'dialogs.add-subseccion.seccion-vacia-hint' | translate}}</small>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>

                </form>
            </mat-step>

            <mat-step [stepControl]="secondStep" label="{{ 'dialogs.add-subseccion.step2' | translate}}">

                <ng-container *ngIf="firstStep.controls['sectionType'].value === 1">
                    <h2 class="custom-text my-4">{{ 'dialogs.add-subseccion.subtitulo-catalogo' | translate }}</h2>

                    <div class="d-flex justify-content-between align-items-center filters-container mb-0">
                        <!-- FILTRO -->
                        <div class="d-flex align-items-center selector">
                            <div class="pe-0 pt-2">
                                <mat-select class="custom-arrow" #selectorProgreso [(value)]="tipoTemas" (selectionChange)="filtrarTipoTemas($event)">
                                    <mat-option class="default-option" value="ver-todo">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>{{'generico.filtros.ver-todo' | translate}}</span>
                                            <mat-icon class="right-icon" [hidden]="'ver-todo' !== tipoTemas">check</mat-icon>
                                        </div>
                                    </mat-option>
                                    <mat-option class="default-option" value="temas-aula">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>{{'generico.filtros.temas-aula' | translate}}</span>
                                            <mat-icon class="right-icon" [hidden]="'temas-aula' !== tipoTemas">check</mat-icon>
                                        </div>
                                    </mat-option>
                                    <mat-option class="default-option" value="otros-temas">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>{{'generico.filtros.otros-temas' | translate}}</span>
                                            <mat-icon class="right-icon" [hidden]="'otros-temas' !== tipoTemas">check</mat-icon>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="pt-0 ps-3 selector-text">
                                <span>{{ 'generico.filtros.' + tipoTemas | translate }}</span>
                            </div>

                        </div>

                        <!-- BUSCADOR -->
                        <div class="search-filter-input search-filter-input__blue d-flex align-items-center px-1" [ngClass]="filter ? 'search-filter-input__typing' : ''" >
                            <mat-icon class="ms-2 search-icon" matPrefix (click)="filter = filterInput.value">search</mat-icon>
                            <input class="ms-2" matInput type="text" (keyup)="filter = filterInput.value" placeholder="{{'generico.buscador.dialogo' | translate}}" #filterInput />
                            <div class="d-flex justify-content-end align-content-center ms-1 me-1">
                                <button *ngIf="filter" class="me-1 custom-icon" [disabled]="!filter" mat-icon-button aria-label="Clear" (click)="filter='';filterInput.value='';">
                                    <mat-icon [ngClass]="filter ? 'grey-text__lightest' : ''">close</mat-icon>
                                </button>
                                <button class="me-2 custom-icon" [disabled]="!filter" mat-icon-button aria-label="Filtrar" (click)="filter = filterInput.value">
                                    <mat-icon>filter_list</mat-icon>
                                </button>
                            </div>
                        </div>

                    </div>
                    <div class="overflow-auto data-table-container">
                        <form [formGroup]="secondStep">
                            <mat-accordion class="d-flex flex-column" role="list">
                                <mat-expansion-panel *ngFor="let tema of dataCatalog;" class="mat-elevation-z0 my-0" role="listitem">

                                    <mat-expansion-panel-header class="theme-row px-0">
                                        <mat-panel-title class="d-flex align-items-center" [ngClass]="colorHelper.getColor(tema.color)">
                                            <div class="d-flex align-items-center w-75">
                                                <img [src]="tema.imagen" [alt]="tema.nombre" />
                                                <span class="custom-text font-weight-bold titulo">{{ tema.titulo }}</span>
                                            </div>
                                            <span class="text__small grey-text">{{ tema.curso.ordinal }} {{ tema.curso.etapa.acronimo }}</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <ng-template *ngIf="tema.indiceSecciones" matExpansionPanelContent>
                                        <mat-list role="list" class=" theme-list">
                                            <ng-container *ngFor="let seccion of tema.indiceSecciones;">
                                                <mat-list-item role="listitem" class="position-relative theme-list-item">
                                                    <mat-checkbox *ngIf="seccion.tipo === 'subseccion'" class="position-absolute theme-checkbox" (change)="selection.toggle(seccion)" [disableRipple]="true" [checked]="selection.isSelected(seccion)"></mat-checkbox>
                                                    <span class="ps-3 grey-text__light text__small">
                                                        {{seccion.indice.seccion}}.{{seccion.indice.subseccion === 0 ? '' : seccion.indice.subseccion +"."}} {{seccion.titulo}}
                                                    </span>
                                                </mat-list-item>
                                            </ng-container>

                                        </mat-list>
                                    </ng-template>


                                </mat-expansion-panel>
                            </mat-accordion>
                        </form>
                    </div>
                </ng-container>

                <ng-container *ngIf="firstStep.controls['sectionType'].value === 2">
                    <h2 class="custom-text my-4">{{ 'dialogs.add-subseccion.seccion-propia' | translate }}</h2>
                    <div class="d-flex justify-content-between align-items-center filters-container">
                        <!-- FILTRO -->
                        <div class="d-flex align-items-center selector">
                            <div class="pe-0 pt-2">
                                <mat-select class="custom-arrow" #selectorProgreso [(value)]="filtroRelevancia" (selectionChange)="filtrarRelevancia($event)">
                                    <mat-option class="default-option" value="mayor-relevancia">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>{{'generico.filtros.mayor-relevancia' | translate}}</span>
                                            <mat-icon class="right-icon" [hidden]="'mayor-relevancia' !== filtroRelevancia">check</mat-icon>
                                        </div>
                                    </mat-option>
                                    <mat-option class="default-option" value="menor-relevancia">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>{{'generico.filtros.menor-relevancia' | translate}}</span>
                                            <mat-icon class="right-icon" [hidden]="'menor-relevancia' !== filtroRelevancia">check</mat-icon>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="pt-0 ps-3 selector-text">
                                <span>{{ 'generico.filtros.' + filtroRelevancia | translate }}</span>
                            </div>

                        </div>

                        <!-- BUSCADOR -->
                        <div class="search-filter-input search-filter-input__blue d-flex align-items-center px-1" [ngClass]="filter ? 'search-filter-input__typing' : ''" >
                            <mat-icon class="ms-2 search-icon" matPrefix (click)="filter = filterInput.value">search</mat-icon>
                            <input class="ms-2" matInput type="text" (keyup)="filter = filterInput.value" placeholder="{{'generico.buscador.dialogo' | translate}}" #filterInput />
                            <div class="d-flex justify-content-end align-content-center ms-1 me-1">
                                <button *ngIf="filter" class="me-1 custom-icon" [disabled]="!filter" mat-icon-button aria-label="Clear" (click)="filter='';filterInput.value='';">
                                    <mat-icon [ngClass]="filter ? 'grey-text__lightest' : ''">close</mat-icon>
                                </button>
                                <button class="me-2 custom-icon" [disabled]="!filter" mat-icon-button aria-label="Filtrar" (click)="filter = filterInput.value">
                                    <mat-icon>filter_list</mat-icon>
                                </button>
                            </div>
                        </div>

                    </div>
                    <div class="overflow-auto data-table-container">

                        <form [formGroup]="secondStep" class="mt-2 mb-4">
                            <mat-list role="list">
                                <mat-list-item *ngFor="let topic of personal;" role="listitem" class="personal-itemlist">
                                    <mat-checkbox (change)="selection.toggle(topic)" [disableRipple]="true" [checked]="selection.isSelected(topic)">
                                        <span class="ps-3 grey-text__light text__small">{{ topic.name }}</span>
                                    </mat-checkbox>
                                </mat-list-item>
                            </mat-list>
                        </form>
                    </div>

                </ng-container>

                <ng-container *ngIf="firstStep.controls['sectionType'].value === 3">
                    <h2 class="custom-text my-4">{{ 'dialogs.add-subseccion.seccion-vacia' | translate }}</h2>
                    <p class="mb-4">{{ 'dialogs.add-subseccion.seccion-vacia-instrucciones' | translate }}</p>
                    <form role="form" [formGroup]="secondStep" class="empty-section">
                        <div class="form-group w-25 default-input">
                            <label>{{'dialogs.add-subseccion.campo-nueva-subseccion' | translate}}</label>
                            <input type="text" class="form-control" [placeholder]="('dialogs.add-subseccion.campo-nueva-subseccion' | translate)" [maxlength]="textLimit" formControlName="title">
                            <div class="d-flex justify-content-between align-items-start mt-2">
                                <mat-error class="mt-0" [ngClass]="secondStep.get('title').hasError('required') && secondStep.controls['title'].dirty ? 'visible' : 'invisible'">
                                    {{'generico.required' | translate}}
                                </mat-error>
                                <span class="grey-text__light text__small">{{secondStep.controls['title'].value.length}}/{{textLimit}}</span>
                            </div>
                        </div>
                    </form>
                </ng-container>

            </mat-step>

        </mat-horizontal-stepper>
    </div>

    <div mat-dialog-actions class="justify-content-between">
        <button role="button" class="default-button default-button__secondary" mat-dialog-close>{{ 'dialogs.create-theme.cancel' | translate }}</button>

        <div class="d-flex">
            <button role="button" class="default-button default-button__primary me-3" [disabled]="currentStep < 1" (click)="goBack()">{{ 'generico.anterior' | translate}}</button>
            <button role="button" class="default-button default-button__primary me-3" *ngIf="currentStep < 1" (click)="goNext()">{{ 'generico.siguiente' | translate}}</button>
            <button role="button" class="default-button default-button__primary" (click)="onClose()" *ngIf="currentStep >= 1" [disabled]="!secondStep.valid && selection.selected.length === 0">{{ 'generico.anadir' | translate }}</button>
        </div>
    </div>
</div>
