<div id="dialog-mover-copiar-element">
    <h1 mat-dialog-title class="d-flex align-items-center">{{ 'dialogs.move-copy-element.title' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <mat-card class="d-flex align-items-center mat-elevation-z0 notificacion-msg" role="alert">
            <mat-icon>info</mat-icon>
            <span>
                {{ 'dialogs.move-copy-element.card-info' | translate }}
            </span>
        </mat-card>

        <div class="accordeon-list">
            <form role="form" [formGroup]="form">
                <mat-form-field class="w-100">
                    <mat-label>{{ 'dialogs.move-copy-element.resource-name' | translate }}</mat-label>
                    <input matInput name="name" type="text" formControlName="name">
                    <mat-error>{{ 'dialogs.create-theme.required' | translate }}</mat-error>
                </mat-form-field>

                <div class="action-check">
                    <label class="w-100">{{ 'dialogs.move-copy-element.select-action' | translate }}</label>
                    <mat-radio-group formControlName="action" name="actionType">
                        <mat-radio-button name="actionType" value="move" checked>{{ 'dialogs.move-copy-element.select-action.move' | translate}}</mat-radio-button>
                        <mat-radio-button name="actionType" value="url">{{ 'dialogs.move-copy-element.select-action.url' | translate}}</mat-radio-button>
                        <mat-radio-button name="actionType" value="duplicate">{{ 'dialogs.move-copy-element.select-action.duplicate' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="location-check">
                    <label>{{ 'dialogs.move-copy-element.select-location' | translate }}</label>
                    <mat-radio-group (change)="changeLocation($event)" name="location">
                        <mat-radio-button name="location" [value]="true" checked>{{ 'dialogs.move-copy-element.current-location' | translate}}</mat-radio-button>
                        <mat-radio-button name="location" [value]="false">{{ 'dialogs.move-copy-element.other-location' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </div>

                <mat-accordion *ngIf="isCurrentLocation === false" class="d-flex flex-column" displayMode="flat" role="list">

                    <mat-expansion-panel *ngFor="let location of data.locations;" class="mat-elevation-z0" role="listitem">
                        <mat-expansion-panel-header class="flex-row-reverse">
                            <mat-panel-title>{{ location.name }}</mat-panel-title>
                        </mat-expansion-panel-header>

                        <ng-template *ngIf="location.subjects" matExpansionPanelContent>
                            <mat-accordion class="d-flex flex-column" role="list">
                                <mat-expansion-panel *ngFor="let subject of location.subjects;" class="mat-elevation-z0" role="listitem">
                                    <mat-expansion-panel-header class="flex-row-reverse">
                                        <mat-panel-title>{{ subject.name }}</mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <ng-template *ngIf="subject.topics" matExpansionPanelContent>
                                        <mat-accordion class="d-flex flex-column" role="list">
                                            <mat-expansion-panel *ngFor="let topic of subject.topics;" class="mat-elevation-z0" role="listitem">
                                                <mat-expansion-panel-header class="flex-row-reverse">
                                                    <mat-panel-title>{{ topic.name }}</mat-panel-title>
                                                </mat-expansion-panel-header>

                                                <ng-template *ngIf="topic.subtopics" matExpansionPanelContent>
                                                    <mat-list role="list">
                                                        <mat-radio-group formControlName="location">
                                                            <mat-list-item *ngFor="let subtopic of topic.subtopics;" role="listitem">
                                                                <mat-radio-button [value]="subtopic.id"> {{ subtopic.name }}</mat-radio-button>
                                                            </mat-list-item>
                                                        </mat-radio-group>
                                                    </mat-list>
                                                </ng-template>

                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </ng-template>

                                </mat-expansion-panel>
                            </mat-accordion>
                        </ng-template>

                    </mat-expansion-panel>
                </mat-accordion>
            </form>
        </div>

    </div>
    <div mat-dialog-actions class="flex-row-reverse justify-content-start">
        <button role="button" mat-raised-button color="accent" [mat-dialog-close]="form.value" type="submit" [disabled]="form.invalid" class="ms-2">{{ 'dialogs.ok' | translate }}</button>
        <button role="button" mat-button mat-dialog-close>{{ 'dialogs.close' | translate }}</button>
    </div>
</div>
