<div id="dialog-copiar-enlace" class="standard">
    <h1 mat-dialog-title class="custom-text">{{'dialogs.copiar-enlace.titulo' | translate}}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <div class="mb-4">
            <p>{{ 'dialogs.copiar-enlace.frase' | translate }}</p>
        </div>
        <div class="form-group default-input w-100 position-relative">
            <label for="copyLink" class="default-text">{{ 'dialogs.copiar-enlace.link' | translate }}</label>
            <input id="copyLink" type="text" class="form-control link-shared" [placeholder]="('dialogs.duplicate.name' | translate)" [value]="data.link" readonly>
            <button mat-icon-button class="clipboard-btn custom-text position-absolute" (click)="copyClipboard()" attr.aria-label="{{'dialogs.copiar-enlace.copiar' | translate}}">
                <mat-icon matSuffix>content_copy</mat-icon>
            </button>
        </div>
    </div>
    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__secondary" mat-dialog-close>{{'generico.aceptar' | translate}}</button>
    </div>
</div>
