<div id="dialog-add-module-audio" class="standard mw-550px">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ data ? ('dialogs.edit-module-base.audio.title'| translate) : ('dialogs.add-module-base.audio.title' | translate) }}</h1>
    <div mat-dialog-content class="mat-dialog-content">
        <form role="form" [formGroup]="formModule" class="empty-section d-flex flex-row">
            <div class="w-100 d-flex flex-column">
                <div class="form-group default-input mb-0">
                    <label>{{'dialogs.add-module-base.form.titulo' | translate}}</label>
                    <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.titulo' | translate)" formControlName="titulo_html">
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('titulo_html').hasError('required') && formModule.controls['titulo_html'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>

                <div class="form-group default-textarea mb-0">
                    <label>{{'dialogs.add-module-base.form.descripcion' | translate}}</label>
                    <textarea type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.descripcion' | translate)" [maxlength]="textLimit" formControlName="descripcion_html"></textarea>
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0" [ngClass]="formModule.get('descripcion_html').hasError('required') && formModule.controls['descripcion_html'].dirty ? 'visible mb-3' : 'invisible'">
                            {{'generico.required' | translate}}
                        </mat-error>
                        <span class="grey-text__light text__small">{{formModule.controls['descripcion_html'].value.length}}/{{textLimit}}</span>
                    </div>
                </div>

                <div *ngIf="!data" class="d-flex flex-column form-group default-dropzone mb-3">
                  <label>{{ 'dialogs.add-module-base.form.file-image' | translate }}</label>
                  <div class="d-flex flex-column flex-md-row align-items-center">
                      <div (dropZone)="onDropImg($event.target.files[0], 'imagen_previa')" (click)="imageField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center" [ngClass]="(imagen_previa$ | async) ? 'default-dropzone__container--white' : '' ">
                          <div class="avatar d-flex align-items-center justify-content-center">
                              <div *ngIf="(imagen_previa$ | async) as foto; else nube" class="avatar text-center">
                                  <img [src]="foto" alt="">
                              </div>
                              <ng-template #nube>
                                  <div class="avatar text-center">
                                      <mat-icon>cloud_upload</mat-icon>
                                  </div>
                              </ng-template>

                          </div>
                      </div>

                      <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                          <p class="mb-0"><a [matMenuTriggerFor]="menu">{{formModule.get('imagen_previa').value ? ('generico.cambiar' | translate) : ('generico.anadir' | translate) }}</a>{{'dialogs.adjuntar-fichero.upload-info2' | translate}}</p>
                          <input type="file" name="file" #imageField (change)="onDropImg($event.target.files[0], 'imagen_previa')" hidden>
                          <mat-menu #menu="matMenu" class="default-dropdown">
                              <button mat-menu-item (click)="imageField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                              <button [disabled]="!formModule.get('imagen_previa').value" mat-menu-item (click)="deleteImg('imagen_previa')"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                          </mat-menu>
                          <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                      </div>
                  </div>
                  <mat-error class="danger-text text__small mt-2" *ngIf="error.image">
                      {{'generico.formulario.fichero-novalid' | translate}}
                  </mat-error>
              </div>

                <div class="form-group">
                    <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="visible">{{ 'dialogs.add-module-base.form.visible-profesor' | translate }}</mat-slide-toggle>
                </div>

                <div *ngIf="!data" class="d-flex flex-column form-group default-dropzone mb-3">
                    <label>{{ 'dialogs.add-module-base.form.file-audio' | translate }}</label>
                    <div class="d-flex flex-column flex-md-row align-items-center">
                        <div (dropZone)="onDropFile($event.target.files[0], 'audio')" (click)="audioField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center">
                            <mat-icon *ngIf="!formModule.get('audio').value" class="custom-icon icon">cloud_upload</mat-icon>
                            <mat-icon *ngIf="formModule.get('audio').value" class="custom-icon icon">cloud_done</mat-icon>
                        </div>

                        <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                            <p class="mb-0"><a [matMenuTriggerFor]="menu">{{formModule.get('audio').value ? ('generico.cambiar' | translate) : ('generico.anadir' | translate) }}</a>{{'dialogs.adjuntar-fichero.upload-info2' | translate}}</p>
                            <input type="file" name="file" #audioField (change)="onDropFile($event.target.files[0], 'audio')" hidden>
                            <mat-menu #menu="matMenu" class="default-dropdown">
                                <button mat-menu-item (click)="audioField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                                <button [disabled]="!formModule.get('audio').value" mat-menu-item (click)="deleteFile('audio')"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                            </mat-menu>
                            <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                        </div>
                    </div>
                    <mat-error class="danger-text text__small mt-2" *ngIf="error.audio">
                        {{'generico.formulario.fichero-novalid-audio' | translate}}
                    </mat-error>
                </div>

                <div class="form-group default-textarea mb-0">
                    <label>{{'dialogs.add-module-base.form.transcripcion' | translate}}</label>
                    <textarea type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.transcripcion' | translate)" formControlName="transcripcion"></textarea>
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('transcripcion').hasError('required') && formModule.controls['transcripcion'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>

                <div *ngIf="!data" class="d-flex flex-column form-group default-dropzone mb-3">
                  <label>{{ 'dialogs.add-module-base.form.file-subtitulos' | translate }}</label>
                  <div class="d-flex flex-column flex-md-row align-items-center">
                      <div (dropZone)="onDropFile($event.target.files[0], 'subtitulos')" (click)="subtitleField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center">
                          <mat-icon *ngIf="!formModule.get('subtitulos').value" class="custom-icon icon">cloud_upload</mat-icon>
                          <mat-icon *ngIf="formModule.get('subtitulos').value" class="custom-icon icon">cloud_done</mat-icon>
                      </div>

                      <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                          <p class="mb-0"><a [matMenuTriggerFor]="menu">{{formModule.get('subtitulos').value ? ('generico.cambiar' | translate) : ('generico.anadir' | translate) }}</a>{{'dialogs.adjuntar-fichero.upload-info2' | translate}}</p>
                          <input type="file" name="file" #subtitleField (change)="onDropFile($event.target.files[0], 'subtitulos')" hidden>
                          <mat-menu #menu="matMenu" class="default-dropdown">
                              <button mat-menu-item (click)="subtitleField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                              <button [disabled]="!formModule.get('subtitulos').value" mat-menu-item (click)="deleteFile('subtitulos')"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                          </mat-menu>
                          <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                      </div>
                  </div>
                  <mat-error class="danger-text text__small mt-2" *ngIf="error.subtitulos">
                      {{'generico.formulario.fichero-novalid' | translate}}
                  </mat-error>
              </div>


                <div class="form-group default-input mb-0">
                    <label>{{'dialogs.add-module-base.form.creditos' | translate}}</label>
                    <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.creditos' | translate)" formControlName="creditos_html">
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('creditos_html').hasError('required') && formModule.controls['creditos_html'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" (click)="onClose()" [disabled]="!formModule.valid">{{ 'generico.guardar' | translate }}</button>
    </div>

</div>
