<div id="dialog-modificar-materia-personalizada" class="standard">
    <h1 mat-dialog-title class="custom-text">{{ 'dialogs.modificar-materia-personalizada.titulo' | translate }}</h1>

     <div mat-dialog-content class="mat-dialog-content">
        <div class="overflow-auto fluid-content">
            <form role="form" [formGroup]="form">
                <fieldset class="w-100 d-flex flex-column">
                    <div class="form-group default-input position-relative w-100">
                        <label for="nuevoTituloMateria">{{'dialogs.modificar-materia-personalizada.form.titulo' | translate}}</label>
                        <input id="nuevoTituloMateria" aria-labelledby="nuevoTituloMateriaError" type="text" class="form-control" [placeholder]="('dialogs.modificar-materia-personalizada.form.placeholder' | translate)" formControlName="titulo">
                        <mat-error id="nuevoTituloMateriaError" *ngIf="form.get('titulo').hasError('required') && form.controls['titulo'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-start mt-2 mb-0 pb-0">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" (click)="onSubmit()" [disabled]="form.invalid" type="submit">{{ 'generico.guardar' | translate }}</button>
    </div>
</div>
