import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollHorizontalService {

  scrollHorizontal$: Observable<boolean>;
  private _scrollHorizontal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.scrollHorizontal$ = this._scrollHorizontal.asObservable();
  }

  get scrollHorizontal() {
    return this._scrollHorizontal.getValue();
  }

  set scrollHorizontal(scrollHorizontal: boolean) {
    this._scrollHorizontal.next(scrollHorizontal);
  }
}
