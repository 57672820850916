import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ColorHelper } from '../../../helper/color.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { AuthManagementService } from '../../../../core/core.module';
import * as _ from 'lodash';

@Component({
  selector: 'aula-planeta-copiar',
  templateUrl: 'copiar.component.html',
  styleUrls: ['./copiar.component.scss'],
})
export class CopiarComponent implements OnInit, AfterViewChecked {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  panelOpenState = false;
  isCurrentLocation = true;
  cpForm: UntypedFormGroup;
  locationsType = [
    { value: true, label: 'Ubicación actual' },
    { value: false, label: 'Otra ubicación' }
  ];
  temaSeleccionado: null;
  indiceSeleccionado: null;

  _tema;
  indiceSeleccionadoVirtual;

  constructor(
    public authManagementService: AuthManagementService,
    public colorHelper: ColorHelper,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CopiarComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService,
    private cdr: ChangeDetectorRef  // Inyectar ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.cpForm = this.formBuilder.group({
      name: [this.data.nombre],
      type: [true],
      location: [this.data.currentLocationId],
      seccion: [this.data.seccion]
    });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-copiar',
        popupName: 'popup_copiar',
        pageId: this.data?.event?.view.location.hash
      }
    };
    this.gtagService.event(info);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  selectMateria(id: any) {
    this.temaSeleccionado = id;
    this.cpForm.get('location').setValue(id);
    this.updateChanges();
  }

  selectTema(id: any) {
    this.temaSeleccionado = id;
    this.cpForm.get('location').setValue(id);
    this.cpForm.get('seccion').setValue(null);
    this.updateChanges();
  }

  selectSeccion(tema: any, seccion: any) {
    this.temaSeleccionado = tema.id;
    this._tema = tema;
    this.indiceSeleccionadoVirtual = seccion;
    this.cpForm.get('location').setValue(null);
    this.cpForm.get('seccion').setValue(seccion.id);
    this.updateChanges();
  }

  updateChanges() {
    setTimeout(() => {
      this.cdr.detectChanges();
    });
  }

  changeLocation($event) {
    this.isCurrentLocation = $event.value;
    const newValue = (this.isCurrentLocation) ? this.data.currentLocationId : null;
    this.cpForm.controls['location'].setValue(newValue);
    this.cdr.markForCheck();
  }

  close() {
    if (this.indiceSeleccionadoVirtual) {
      this.indiceSeleccionado = this._tema.indiceSecciones.find(seccion =>
        seccion.id === this.indiceSeleccionadoVirtual.id &&
        _.isEqual(seccion.indice, this.indiceSeleccionadoVirtual.indiceOriginal)
      );
    }
    this.dialogRef.close({ ...this.cpForm.value, location: this.temaSeleccionado, seccion: this.indiceSeleccionado });
  }

  meCabeUnaGorda(seccion) {
    if (this.data.isCopiarSeccionIntoSeccion) {
      const nivelesDeLaSeccion = this.calcularNivelMaximo(seccion.indice);
      if ((this.data.profundidad + nivelesDeLaSeccion) <= 4) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private calcularNivelMaximo(indice) {
    let nivelMaximo = 0;

    for (const key in indice) {
      if (indice[key] !== 0) {
        nivelMaximo = key === 'seccion' ? 1 : key === 'subseccion' ? 2 : key === 'subsubseccion' ? 3 : 4;
      }
    }

    return nivelMaximo;
  }
}
