<div id="dialog-add-module-documento" class="standard mw-550px">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ data ? ('dialogs.edit-module-base.documento.title'| translate) : ('dialogs.add-module-base.documento.title' | translate) }}</h1>
    <div mat-dialog-content  class="mat-dialog-content">
        <form role="form" [formGroup]="formModule" class="empty-section d-flex flex-row">
            <div class="w-100 d-flex flex-column">
                <div class="form-group default-input mb-0">
                    <label>{{'dialogs.add-module-base.form.titulo' | translate}}</label>
                    <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.titulo' | translate)" formControlName="titulo_html">
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('titulo_html').hasError('required') && formModule.controls['titulo_html'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>

                <div class="form-group default-textarea mb-0">
                    <label>{{'dialogs.add-module-base.form.descripcion' | translate}}</label>
                    <textarea type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.descripcion' | translate)" [maxlength]="textLimit" formControlName="descripcion_html"></textarea>
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0" [ngClass]="formModule.get('descripcion_html').hasError('required') && formModule.controls['descripcion_html'].dirty ? 'visible mb-3' : 'invisible'">
                            {{'generico.required' | translate}}
                        </mat-error>
                        <span class="grey-text__light text__small">{{formModule.controls['descripcion_html'].value.length}}/{{textLimit}}</span>
                    </div>
                </div>

                <div class="form-group">
                    <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="visible">{{ 'dialogs.add-module-base.form.visible-profesor' | translate }}</mat-slide-toggle>
                </div>

                <div *ngIf="!data" class="d-flex flex-column form-group default-dropzone mb-3">
                    <label>{{ 'dialogs.add-module-base.form.file-documento' | translate }}</label>
                    <div class="d-flex flex-column flex-md-row align-items-center">
                        <div (dropZone)="onDropFile($event.target.files[0], 'documento')" (click)="documentoField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center">
                            <div class="d-flex flex-column flex-md-col align-items-center">
                                <mat-icon *ngIf="!formModule.get('documento').value" class="custom-icon icon">cloud_upload</mat-icon>
                                <mat-icon *ngIf="formModule.get('documento').value" class="custom-icon icon p-2">cloud_done</mat-icon>
                                <p class="p-2" *ngIf="formModule.get('documento').value">{{formModule.get('documentoName').value}}</p>
                            </div>
                        </div>

                        <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                            <p class="mb-0"><a [matMenuTriggerFor]="menu">{{formModule.get('documento').value ? ('generico.cambiar' | translate) : ('generico.anadir' | translate) }}</a>{{'dialogs.adjuntar-fichero.upload-info2' | translate}}</p>
                            <input type="file" name="file" #documentoField (change)="onDropFile($event.target.files[0], 'documento')" hidden>
                            <mat-menu #menu="matMenu" class="default-dropdown">
                                <button mat-menu-item (click)="documentoField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                                <button [disabled]="!formModule.get('documento').value" mat-menu-item (click)="deleteFile('documento')"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                            </mat-menu>
                            <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                        </div>
                    </div>
                    <mat-error class="danger-text text__small mt-2" *ngIf="error.documento">
                        {{'generico.formulario.fichero-novalid' | translate}}
                    </mat-error>
                </div>

                <div class="d-flex flex-column form-group default-dropzone mb-3">
                    <label>{{ 'dialogs.add-module-base.form.file-image-prev' | translate }}</label>
                    <div class="d-flex flex-column flex-md-row align-items-center">
                        <div (dropZone)="onDropFile($event.target.files[0], 'image')" (click)="imageField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center">
                            <img *ngIf="formModule.get('imagen_previa').value" [src]="formModule.get('imagen_previa').value | safe:'resourceUrl'" alt="Imagen del audio">
                            <mat-icon *ngIf="!formModule.get('imagen_previa').value">cloud_upload</mat-icon>
                        </div>

                        <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                            <p class="mb-0"><a [matMenuTriggerFor]="menuImg">{{formModule.get('imagen_previa').value ? ('generico.cambiar' | translate) : ('generico.anadir' | translate) }}</a>{{'dialogs.adjuntar-fichero.upload-info2' | translate}}</p>
                            <input type="file" name="file" #imageField (change)="onDropFile($event.target.files[0], 'imagen_previa')" hidden>
                            <mat-menu #menuImg="matMenu" class="default-dropdown">
                                <button mat-menu-item (click)="imageField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                                <button [disabled]="!formModule.get('imagen_previa').value" mat-menu-item (click)="deleteFile('imagen_previa')"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                            </mat-menu>
                            <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                        </div>
                    </div>
                    <mat-error class="danger-text text__small mt-2" *ngIf="error.image">
                        {{'generico.formulario.fichero-novalid' | translate}}
                    </mat-error>
                </div>

                <div class="form-group default-input mb-0">
                    <label>{{'dialogs.add-module-base.form.creditos' | translate}}</label>
                    <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.creditos' | translate)" formControlName="creditos_html">
                    <div class="d-flex justify-content-between align-items-start mt-2">
                        <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('creditos_html').hasError('required') && formModule.controls['creditos_html'].dirty">
                            {{'generico.required' | translate}}
                        </mat-error>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" (click)="onClose()" [disabled]="!formModule.valid">{{ 'generico.guardar' | translate }}</button>
    </div>

</div>
