/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  template: ''
})

export class AddModulesBaseComponent implements OnInit {

  formModule: UntypedFormGroup;
  textLimit: Number = 200;

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.formModule = this.fb.group({
      titulo_html: ['', Validators.required],
      descripcion_html: ['', Validators.required],
      visible: ['', Validators.required]
    });
  }

  onClose() {
    this.dialogRef.close(
      {
        ...this.formModule.value,
        titulo_plano: this.formModule.get('titulo_html') ? this.formModule.get('titulo_html').value : '',
        visible: this.formModule.get('visible').value ? 'Profesores' : 'Alumnos',
      }
    );
  }
}
