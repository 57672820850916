import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatStepper } from '@angular/material/stepper';
import { GestionAlumnoManagementService } from '../../../../services/data/gestion-alumno/gestion-alumno-management.service';
import { DateHelper } from '../../../helper/date.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aula-planeta-modificar-tarea-moodle',
  templateUrl: './modificar-tarea-moodle.component.html',
  styleUrls: ['./modificar-tarea-moodle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModificarTareaMoodleComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('stepper') private stepperSection: MatStepper;

  secondStep: FormGroup;

  minDate = new Date(2000, 0, 1);
  maxDate = new Date(2045, 0, 1);
  selection?: SelectionModel<any>;
  todayDate = new Date().toISOString().slice(0, 10);
  indexTab = 0;

  selectionDisponibles: SelectionModel<null>;
  selectionAsignados: SelectionModel<null>;

  alumnos: any[] = [];
  alumnosTemp: any[] = [];
  alumnosAsignados: any[] = [];
  enableCustomGroup = false;

  grupos: any[] = [];


  constructor(
    private fb: FormBuilder,
    public dateHelper: DateHelper,
    public dialogRef: MatDialogRef<ModificarTareaMoodleComponent>,
    private gestionAlumnoManagementService: GestionAlumnoManagementService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    this.selection = new SelectionModel(true, []);
    this.selectionDisponibles = new SelectionModel<null>(true, []);
    this.selectionAsignados = new SelectionModel<null>(true, []);
  }

  ngOnInit() {
    this.secondStep = this.fb.group({
      name: this.fb.control(this.data.titulo, [Validators.required]),
      inicio: this.fb.control(this.dateHelper.getOffsetLocal(this.data.periodo.fechaInicio), [Validators.required]),
      fin: this.fb.control(this.dateHelper.getOffsetLocal(this.data.periodo.fechaFin), [Validators.required]),
      comentario: this.fb.control(this.data.description, [Validators.maxLength(255)]),
      shared: [this.data.shared]
    });

    this.gestionAlumnoManagementService.getMisClases();
    this.gestionAlumnoManagementService.getMisAlumnos();
    this.gestionAlumnoManagementService.misClases$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(clases => {
        this.grupos[0] = clases.map(el => ({
          nombre: `${el.curso.ordinal} ${el.curso.etapa.acronimo} ${el.grupo.id}`,
          clase: { curso: el.curso, grupo: el.grupo },
          alumnos: el.alumnos
        }));
      });

    this.gestionAlumnoManagementService.getMisGruposPersonalizados();
    this.gestionAlumnoManagementService.misGruposPersonalizados$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(gruposPersonalizados => {
        this.grupos[1] = gruposPersonalizados.map(el => ({
          nombre: el.nombre,
          alumnos: el.alumnos
        }));
      });

    const info = {
      event: 'popup',
      popup: {
        action: 'modify_task',
        popupId: 'dialog-modificar-tarea',
        popupName: 'popup_modify_task',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  compareDates() {
    return new Date(this.secondStep.get('inicio').value) > new Date(this.secondStep.get('fin').value);
  }

  goBack() {
    this.stepperSection.previous();
  }

  goNext() {
    this.stepperSection.next();
  }

  // log() {
  //   console.log(this.secondStep)
  // }

  onClose() {
    this.dialogRef.close({
      name: this.secondStep.value.name,
      inicio: this.dateHelper.setToUTC(this.dateHelper.setFechaInicio(this.secondStep.value.inicio)),
      fin: this.dateHelper.setToUTC(this.dateHelper.setFechaFin(this.secondStep.value.fin)),
      comentario: this.secondStep.value.comentario,
      shared: this.secondStep.value.shared
    });
  }
}
