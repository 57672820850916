import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CookiesDataService } from './cookies-data.service';
import { take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CookiesManagementService implements OnDestroy {

  cookiesData = [
    {
      cookie: '_ga_H6N0YZERY0',
      proveedor: '.aulaplaneta.com',
      categoria: 'Técnicas',
      catValue: 'tecnicas',
      entidad: 'Propias'
    },
    {
      cookie: 'ABTasty',
      proveedor: '.aulaplaneta.com',
      categoria: 'Técnicas',
      catValue: 'tecnicas',
      entidad: 'Propias'
    },
    {
      cookie: '_ga',
      proveedor: '.aulaplaneta.com',
      categoria: 'Técnicas',
      catValue: 'analisis',
      entidad: 'Propias'
    },
    {
      cookie: 'ABTastySession',
      proveedor: '.aulaplaneta.com',
      categoria: 'Técnicas',
      catValue: 'tecnicas',
      entidad: 'Propias'
    },
    {
      cookie: '_gid',
      proveedor: '.aulaplaneta.com',
      categoria: 'Técnicas',
      catValue: 'tecnicas',
      entidad: 'Propias'
    }
  ];
  /*
  cookiesData = [
    {
      cookie: 'aula-planeta#ng_forage',
      proveedor: 'web.aulaplaneta.com',
      categoria: 'Técnicas',
      catValue: 'tecnicas',
      entidad: 'Propias'
    },
    {
      cookie: 'aula-planeta#local-forage-detect-blob-support',
      proveedor: 'web.aulaplaneta.com',
      categoria: 'Técnicas',
      catValue: 'tecnicas',
      entidad: 'Propias'
    },
    {
      cookie: '_clck',
      proveedor: 'aulaplaneta.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Propias'
    },
    {
      cookie: '_clsk',
      proveedor: 'aulaplaneta.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Propias'
    },
    {
      cookie: 'ABTastyUA',
      proveedor: 'try.abtasty.com',
      categoria: 'Publicitarias',
      catValue: 'publicitarias',
      entidad: 'Terceros'
    },
    {
      cookie: 'JSESSIONID',
      proveedor: '.wiris.net',
      categoria: 'Técnicas',
      catValue: 'tecnicas',
      entidad: 'Terceros'
    },
    {
      cookie: 'CLID',
      proveedor: 'www.clarity.ms',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'MUID',
      proveedor: 'bing.com',
      categoria: 'Publicitarias',
      catValue: 'publicitarias',
      entidad: 'Terceros'
    },
    {
      cookie: 'MR',
      proveedor: 'bing.com',
      categoria: 'Publicitarias',
      catValue: 'publicitarias',
      entidad: 'Terceros'
    },
    {
      cookie: 'SRM_B',
      proveedor: 'bing.com',
      categoria: 'Publicitarias',
      catValue: 'publicitarias',
      entidad: 'Terceros'
    },
    {
      cookie: 'SM',
      proveedor: 'c.clarity.ms',
      categoria: 'Publicitarias',
      catValue: 'publicitarias',
      entidad: 'Terceros'
    },
    {
      cookie: 'MUID',
      proveedor: 'clarity.ms',
      categoria: 'Publicitarias',
      catValue: 'publicitarias',
      entidad: 'Terceros'
    },
    {
      cookie: 'MR',
      proveedor: 'c.clarity.ms',
      categoria: 'Publicitarias',
      catValue: 'publicitarias',
      entidad: 'Terceros'
    },
    {
      cookie: 'ANONCHK',
      proveedor: 'c.clarity.ms',
      categoria: 'Publicitarias',
      catValue: 'publicitarias',
      entidad: 'Terceros'
    },
    {
      cookie: '_cltk',
      proveedor: 'www.clarity.ms',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'c.gif',
      proveedor: 'c.clarity.ms',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '_ga_N4KVZLMKV5',
      proveedor: 'google analitcs',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '_ga	',
      proveedor: 'google analitcs',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '_ga_KHPS48WCJR	',
      proveedor: 'google analitcs',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '_gid	',
      proveedor: 'google analitcs',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '_ga_YKC71V0Y4W	',
      proveedor: 'google analitcs',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-1PSIDTS',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-1PSIDCC',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'SID',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'SIDCC',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '1P_JAR',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'APISID',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'NID',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'CONSENT',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'AEC',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-1PSID',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-3PSIDTS',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-ENID',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-3PAPISID',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-1PAPISID',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-3PSIDCC',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'SAPISID',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-3PSID',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'HSID',
      proveedor: '.googkle.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'SEARCH_SAMESITE',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'SSID',
      proveedor: '.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '_ga_6VGGZHMLM2',
      proveedor: 'play.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '_ga',
      proveedor: 'play.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-1PSIDCC',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'CONSENT',
      proveedor: '.youtube',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'SID',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-3PSIDCC',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'SAPISID',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    }, {
      cookie: '__Secure-YEC',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'VISITOR_INFO1_LIVE',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-1PAPISID',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'SSID',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-1PSIDTS',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-3PSIDTS',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-3PSID',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'SIDCC',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'PREF',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'YSC',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-1PSID',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'VISITOR_PRIVACY_METADATA',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '__Secure-3PAPISID',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'APISID',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },

    {
      cookie: 'HSID',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: 'LOGIN_INFO',
      proveedor: '.youtube.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },

    {
      cookie: '__Secure-OSID',
      proveedor: 'play.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },

    {
      cookie: 'OSID',
      proveedor: 'play.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },

    {
      cookie: 'OTZ',
      proveedor: 'www.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },

    {
      cookie: 'UULE',
      proveedor: 'www.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
    {
      cookie: '_ga',
      proveedor: 'www.google.com',
      categoria: 'Análisis',
      catValue: 'analisis',
      entidad: 'Terceros'
    },
  ];
  */
  /**
   * Contains the cookies
  */
  private _cookies: BehaviorSubject<any> = new BehaviorSubject(null);
  public cookies$ = this._cookies.asObservable();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private cookiesDataService: CookiesDataService
  ) { }


  /**
  * set cookies
  */
  setCookies(cookies: any) {
    this.cookiesDataService.setCookies(cookies).pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.getCookies();
    });
  }

  /**
  * get cookies
  */
  getCookies() {
    return this.cookiesDataService.getCookies().pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(resp => {
      this.cookies = resp;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get cookies(): any {
    return this._cookies.getValue();
  }
  public set cookies(value) {
    this._cookies.next(value);
  }


}
