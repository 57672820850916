import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { MiCentroManagementService } from '../../../../features/mi-centro/services/mi-centro-management.service';
import { GestionAlumnoManagementService } from '../../../../services/data/gestion-alumno/gestion-alumno-management.service';
import { MateriaManagementService } from '../../../../services/data/materia/materia-management.service';
import { Subject } from 'rxjs';
import { ColorHelper } from '../../../helper/color.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-asignar-unidad',
  templateUrl: './asignar-unidad.component.html',
  styleUrls: ['./asignar-unidad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsignarUnidadComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  form: UntypedFormGroup;
  alumnos: any[] = [];
  alumnosTemp: any[] = [];
  materias = [];
  alumnosAsignados: any[] = [];
  selectionDisponibles: SelectionModel<null>;
  selectionAsignados: SelectionModel<null>;
  editMode = false;

  constructor(
    public dialogRef: MatDialogRef<AsignarUnidadComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: UntypedFormBuilder,
    public gestionAlumnoManagementService: GestionAlumnoManagementService,
    public materiaManagementService: MateriaManagementService,
    public miCentroManagementService: MiCentroManagementService,
    public colorHelper: ColorHelper,
    private gtagService: GtagService
  ) {
    this.gestionAlumnoManagementService.getMisClases();
    this.gestionAlumnoManagementService.getMisAlumnos();
    this.selectionDisponibles = new SelectionModel<null>(true, []);
    this.selectionAsignados = new SelectionModel<null>(true, []);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      nombreGrupo: [''],
      claseRef: [''],
      materia: [''],
      nuevoGrupo: [''],
      alumnosAsignados: ['', [Validators.required]]
    });

    if (this.data) {
      this.editMode = true;
      this.alumnosAsignados = this.data.alumnosAsignados ? this.data.alumnosAsignados : [];
      this.form.patchValue({
        ...this.data
      });
    }

    this.form.get('nuevoGrupo').valueChanges
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          this.form.get('nombreGrupo').setValidators([Validators.required]);
        } else {
          this.form.get('nombreGrupo').setValidators([]);
        }
        this.form.get('nombreGrupo').updateValueAndValidity();
        this.form.updateValueAndValidity();
      });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-asignar-unidad',
        popupName: 'popup_asignar_unidad',
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  getMateriasByCurso(cursoId) {
    const tmp$: Subject<void> = new Subject<void>();
    this.materiaManagementService.getMateriasByCurso(cursoId).pipe(
      takeUntil(tmp$)
    ).subscribe(materias => {
      tmp$.next();
      tmp$.complete();
      this.materias = materias;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    this.form.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  setAlumnosList(grupo) {
    let alumnos;
    // si no hay clase es que es un grupo personalizado y trae el array de alumnos
    if (!grupo.clase) {
      alumnos = grupo.alumnos;
    } else {
      alumnos = this.gestionAlumnoManagementService.getListaAlumnosEnUnaClase(grupo.clase, this.gestionAlumnoManagementService.misAlumnos);
    }
    this.alumnosTemp = (alumnos && alumnos.length > 0) ? alumnos : [];
    this.alumnos = this.alumnosTemp;
    this.desasignarTodos();
  }

  asignarAlumnos() {
    this.alumnosAsignados = this.alumnosAsignados.concat(this.selectionDisponibles.selected);

    this.form.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.alumnos = this.alumnos.filter(alumno => !this.selectionDisponibles.selected.includes(alumno));
    this.selectionDisponibles.clear();
  }

  asignarTodos() {
    this.alumnos.forEach((row) => this.selectionDisponibles.select(row));
    this.asignarAlumnos();
  }

  desasignarAlumnos() {
    this.alumnos = this.alumnos.concat(this.alumnosTemp.filter(alumno => this.selectionAsignados.selected.includes(alumno)));

    this.alumnosAsignados = this.alumnosAsignados.filter(alumno => !this.selectionAsignados.selected.includes(alumno));
    this.form.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.selectionAsignados.clear();
  }

  desasignarTodos() {
    this.alumnosAsignados.forEach((row) => this.selectionAsignados.select(row));
    this.desasignarAlumnos();
  }

  close(result) {
    if (result) {
      const formData = this.form.value;
      this.dialogRef.close(formData);
    } else {
      this.dialogRef.close();
    }
  }
}
