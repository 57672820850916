
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '../../../services/env.service';

@Injectable({
  providedIn: 'root'
})
export class MiCentroDataService {

  constructor(private http: HttpClient, private envService: EnvService) { }

  public getAlumnos(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/colegio/Alumnos`);
  }

  public getAlumnosLicencia(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/colegio/AlumnosLicencia`);
  }

  public getCursosGrupos(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/colegio/CursosGrupos`);
  }

  public getMateriasNormasOcultas(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/colegio/normas-materiasCentro-ocultas-alumnos`);
  }

  public ocultarMateriasNormas(values: string[]): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/colegio/ocultar-normas-materiasCentro-alumnos`, values);
  }

  public asignarContraseñas(newPassword: string, confirmNewPassword: string, usuariosId: string[]): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/admin/Alumnos/Passwords`, { newPassword, confirmNewPassword, usuariosId });
  }

  public asignarAlumnosClase(grupoId: string, cursoId: string, usuariosId: string[]): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/admin/Alumnos/Clase`, { grupoId, cursoId, usuariosId });
  }

  public accesoBancoContenidos(acceso: boolean): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/admin/Alumnos/BancoContenidosHabilitado`, { habilitado: acceso });
  }

  public getLogo(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/colegio`);
  }

  public setLogo(data: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/colegio/logo`, data);
  }

  public setLogoVisible(data: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/colegio/logo-visible`, data);
  }

  public exportCsvListadoAlumnos(): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${this.envService.apiUrl}/admin/alumnos/Listado`, httpOptions);
  }

  public exportCsvListadoProfes(): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${this.envService.apiUrl}/admin/profesores/Listado`, httpOptions);
  }

  public getProfesores(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/colegio/Profesores`);
  }

  public getIdioma(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/colegio/Idioma`);
  }

  public getIdiomas(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/colegio/idiomas`);
  }

  public setIdioma(idioma: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/colegio/idioma`, idioma);
  }
}
