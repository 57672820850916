/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { AddModulesBaseComponent } from '../../add-modules-base.component';

@Component({
  selector: 'aula-planeta-add-module-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TablaComponent extends AddModulesBaseComponent {

  error = {
    imagen_previa: false
  };

  validTypes = {
    imagen_previa: ['image/gif', 'image/jpeg', 'image/jpg', 'image/pipeg', 'image/png']
  };

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TablaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    super(fb, dialogRef, data);
  }

  ngOnInit() {
    this.formModule = this.fb.group({
      titulo_html: [this.data && this.data.propiedades.titulo_html ? this.data.propiedades.titulo_html : '', Validators.required],
      contenido_html: [this.data && this.data.propiedades.contenido_html ? this.data.propiedades.contenido_html : '', Validators.required],
      visible: [this.data && this.data.propiedades.visible === 'Profesores' ? true : false],
      imagen_previa: [null]
    });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-add-module-tabla',
        popupName: 'popup_añadir_tabla'
      }
    };
    this.gtagService.event(info);
  }

  onDropFile(e, field) {
    this.error[field] = false;
    const file = (e as HTMLInputElement);

    if (this.isValidFile(file, field)) {
      this.onDrop(file, field);
    } else {
      this.error[field] = true;
    }
  }

  onDrop(file, field) {
    const url = URL.createObjectURL(file);
    this.formModule.get(field).setValue(url);
  }

  deleteFile(field) {
    this.formModule.get(field).setValue(null);
    this.formModule.get(field).updateValueAndValidity();
  }

  isValidFile(file, field) {
    return this.validTypes[field].includes(file.type);
  }

}

