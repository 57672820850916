
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateHelper {

  browserLanguage = navigator.language;

  getOffsetLocal(date) {
    if (date) {
      return moment.utc(date).local().format();
    }
    return '';
  }

  setToUTC(date) {
    if (date) {
      return moment.utc(date).format();
    }
    return '';
  }

  setFechaInicio(date) {
    if (date) {
      return moment(date).startOf('day').local().format();
    }
    return '';
  }

  setFechaFin(date) {
    if (date) {
      return moment(date).endOf('day').local().format();
    }
    return '';
  }

  disableWeekendsFilter(d: Date) {
    return (d.getDay() !== 0 && d.getDay() !== 6);
  }
}
