import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'aula-planeta-tarea',
  templateUrl: 'tarea.component.html',
  styleUrls: ['./tarea.component.scss'],
})
export class TareaComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    public dialogRef: MatDialogRef<TareaComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {}
}
