import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { AuthManagementService } from '../../../../core/core.module';

@Component({
  selector: 'aula-planeta-crear-tema-nuevo',
  templateUrl: 'crear-tema-nuevo.component.html',
  styleUrls: ['./crear-tema-nuevo.component.scss'],
})
export class CrearTemaNuevoComponent implements OnInit {
  form: UntypedFormGroup;
  file = null;
  currentUser;

  private _photo: BehaviorSubject<string> = new BehaviorSubject<string>('');
  photo$ = this._photo.asObservable();

  constructor(
    private fb: UntypedFormBuilder,
    private gtagService: GtagService,
    public authManagementService: AuthManagementService,
    public dialogRef: MatDialogRef<CrearTemaNuevoComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

    this.currentUser = this.authManagementService.currentUser;
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      author: [`${this.currentUser.persona.nombre} ${this.currentUser.persona.apellidoUno}`],
      shared: [false],
      img: ['',],
      imgName: ['']
    });

    const info = {
      event: 'popup',
      popup: {
        pageId: this.data.event?.view?.location.hash,
        popupId: 'dialog-crear-tema',
        popupName: 'popup_crear_tema'
      }
    };
    this.gtagService.event(info);
  }

  /**
   * Submit create new theme
   */
  onSubmit() {

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.form.value);
  }

  onDrop(file) {
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this._photo.next(reader.result as string);
        this.form.patchValue({
          img: reader.result,
          imgName: file.name
        });
        this.form.get('img').updateValueAndValidity();
        this.form.get('imgName').updateValueAndValidity();
      };
    }
  }

  deleteImg() {
    this._photo.next(null);
    this.form.patchValue({
      img: null,
      imgName: null
    });
    this.form.get('img').updateValueAndValidity();
    this.form.get('imgName').updateValueAndValidity();
  }
}