/* eslint-disable max-len */
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { SeccionComponent } from '../dialogs/add-modules-base/modulos/seccion/seccion.component';
import { AudioComponent } from '../dialogs/add-modules-base/modulos/audio/audio.component';
import { EnlaceComponent } from '../dialogs/add-modules-base/modulos/enlace/enlace.component';
import { DestacadoComponent } from '../dialogs/add-modules-base/modulos/destacado/destacado.component';
import { DocumentoComponent } from '../dialogs/add-modules-base/modulos/documento/documento.component';
import { ImagenComponent } from '../dialogs/add-modules-base/modulos/imagen/imagen.component';
import { TablaComponent } from '../dialogs/add-modules-base/modulos/tabla/tabla.component';
import { TextoComponent } from '../dialogs/add-modules-base/modulos/texto/texto.component';
import { VideoComponent } from '../dialogs/add-modules-base/modulos/video/video.component';
import { EditarRecursoComponent } from '../dialogs/editar-recurso/editar-recurso.component';
import { JumboTemaComponent } from '../dialogs/add-modules-base/modulos/jumbo-tema/jumbo-tema.component';
import { Subject, BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { HighlightsModeService } from '../../../services/highlights-mode.service';
import { AuthManagementService } from '../../../core/auth/auth-management.service';
import { TemasManagementService } from '../../../services/data/temas/temas-management.service';
import { LtiManagementService } from '../../../services/data/lti/lti-management.service';
import { MateriaManagementService } from '../../../services/data/materia/materia-management.service';
import { GtagService } from '../../../services/gtag/gtag.service';
import { take, takeUntil } from 'rxjs/operators';
import { HighlightsDirective } from '../../../core/directives/highlights/highlights.directive';

@Component({
  selector: 'aula-planeta-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighlightsComponent implements OnDestroy {

  dialogComponents = {
    seccion1: SeccionComponent,
    seccion2: SeccionComponent,
    seccion3: SeccionComponent,
    seccion4: SeccionComponent,
    audio: AudioComponent,
    enlace: EnlaceComponent,
    destacado: DestacadoComponent,
    documento: DocumentoComponent,
    imagen: ImagenComponent,
    tabla: TablaComponent,
    texto: TextoComponent,
    video: VideoComponent,
    recurso: EditarRecursoComponent,
    'jumbo-tema': JumboTemaComponent,
    'recurso-col': EditarRecursoComponent
  }

  @Input() show = true;
  @Input() showPallete = true;
  @Input() modulo: any;
  @Input() last = false;

  lastSelectedColor = 'blue';
  showColors: boolean;

  private _highlights: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  highlights$ = this._highlights.asObservable();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public highlightsModeService: HighlightsModeService,
    public authManagementService: AuthManagementService,
    public temasManagementService: TemasManagementService,
    public ltiManagementService: LtiManagementService,
    private materiaManagementService: MateriaManagementService,
    private host: ElementRef<HTMLElement>
  ) { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  colorSelector(color, evento?) {
    this.highlightsModeService.r();

    this.lastSelectedColor = color;
    switch (color) {
      case 'green':
        this.highlightsModeService.color = '#7dc46a';
        break;
      case 'blue':
        this.highlightsModeService.color = '#6eadde';
        break;
      case 'red':
        this.highlightsModeService.color = '#e95b8a';
        break;
      case 'orange':
        this.highlightsModeService.color = '#facc5c';
        break;
      case 'purple':
        this.highlightsModeService.color = '#bb87bb';
        break;
    }

    document.execCommand('HiliteColor', true, this.highlightsModeService.color);
    window.getSelection().removeAllRanges();
    this.highlightsModeService.removeRNG();
    this.host.nativeElement.remove();

    this.update();
  }

  remove() {
    if (this.highlightsModeService.target.tagName === 'SPAN' && this.highlightsModeService.target.style.backgroundColor) {
      this.highlightsModeService.target.style.removeProperty('background-color');
    } else if (this.highlightsModeService.target !== 'SPAN' && this.highlightsModeService.target.parentNode.tagName === 'SPAN') {
      this.highlightsModeService.target.parentNode.style.removeProperty('background-color');
    }
    this.highlightsModeService.removeMode = false;
    this.host.nativeElement.remove();
    this.update();
  }

  update() {
    this.temasManagementService.addHighlights(this.temasManagementService.tema.id, this.modulo.id, {
      subrayadoHtml: this.highlightsModeService.innerHTMLSubrayado.nativeElement.childNodes[1].firstChild.innerHTML,
      contenidoHtml: this.highlightsModeService.contenidoHtml,
      autor: this.modulo.autor
    });
  }

}