import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { take, takeUntil, timestamp } from 'rxjs/operators';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { Subject } from 'rxjs';
declare var gtag: any;

export const CURRENT_USER = 'CURRENT_USER';

@Injectable({
  providedIn: 'root'
})

export class GtagService implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  constructor(private router: Router, private localStorageService: LocalStorageService) { }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  public pageview(info: any) {
    this.localStorageService.getItem(CURRENT_USER).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(currentUser => {
        gtag({
          event: info.event || undefined,
          page_view: {
            im_page: info.pageView.imPage || undefined,
            page_id: info.pageView.pageId || undefined,
            im_pagetitle: info.pageView.imPagetitle || undefined,
            area_name: info.pageView.areaName || undefined,
            area_id: info.pageView.areaId || undefined,
            task_name: info.pageView.taskName || undefined,
            task_id: info.pageView.taskId || undefined,
            unit_name: info.pageView.unitName || undefined,
            unit_id: info.pageView.unitId || undefined,
            resource_name: info.pageView.resourceName || undefined,
            resource_id: info.pageView.resourceId || undefined,
            // default parameters
            user_id: currentUser?.persona.id || undefined,
            user_type: currentUser?.usuarioRoles.join(',') || undefined,
            school_id: currentUser?.colegio.id || undefined,
            school_name: currentUser?.colegio.nombre || undefined,
            school_group: undefined,
            access_chanel: document.referrer || undefined,
            page_location: this.router.url || undefined,
            language: window.navigator.language || undefined,
            page_referer: document.referrer || undefined,
            page_title: info.pageView.imPagetitle || undefined,
            screen_resolution: `${window.screen.availWidth} x ${window.screen.availHeight}` || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
      });
  }

  public login(info: any) {
    gtag({
      event: info.event || undefined,
      login: {
        method: info.login.method,

      } || undefined,
      context: {
        timestamp: Date.now()
      } || undefined
    });
  }

  public error(info: any) {
    gtag({
      event: info.event || undefined,
      error: {
        method: info.error.method || undefined,
        error_id: info.error.errorId || undefined,
        error_message: info.error.errorMessage || undefined,
        page_id: info.error.pageId || undefined,
      },
      context: {
        timestamp: Date.now()
      } || undefined
    });
  }

  public event(info: any) {
    switch (info.event) {
      case 'select_content':
        gtag({
          event: info.event || undefined,
          select_content: {
            content_type: info.selectContent.contentType || undefined,     // materia | proyecto | reto_mensual
            page_id: info.selectContent.pageId || undefined,
            content_name: info.selectContent.contentName || undefined,     // Nombre del contenido clicado
            section: info.selectContent.section || undefined,              // Nombre de la seccion clicada:  licencia | contraseña
            content_id: info.selectContent.contentId || undefined,         // identificador del contenido: 65436
            attributes: info.selectContent.attributes || undefined,
          },       // Información de la materia,
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'select_icon':
        gtag({
          event: info.event || undefined,
          select_icon: {
            option_selected: info.selectIcon.optionSelected || undefined,
            page_id: info.selectIcon.pageId || undefined,
            attributes: info.selectIcon.attributes || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'search':
        gtag({
          event: info.event || undefined,
          search: {
            search_term: info.search.searchTerm || undefined,
            page_id: info.search.pageId || undefined,
            results_number: info.search.resultsNumber || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'search_filter':
        gtag({
          event: info.event || undefined,
          search_filter: {
            filterName: info.searchFilter.filterName || undefined,
            search_term: info.searchFilter.searchTerm || undefined,
            page_id: info.searchFilter.pageId || undefined,
            results_number: info.searchFilter.resultsNumber || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'result_search_filter':
        gtag({
          event: info.event || undefined,
          result_search_filter: {
            content_name: info.resultSearchFilter.contentName || undefined,
            section: info.resultSearchFilter.section || undefined,
            page_id: info.resultSearchFilter.pageId || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'open_menu':
        gtag({
          event: info.event || undefined,
          open_menu: {
            menu_id: info.openMenu.menuId || undefined,
            page_id: info.openMenu.pageId || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'select_menu_item':
        gtag({
          event: info.event || undefined,
          select_menu_item: {
            menu_item_name: info.selectMenuItem.menuItemName || undefined,
            menu_id: info.selectMenuItem.menuId || undefined,
            page_id: info.selectMenuItem.pageId || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'filter':
        gtag({
          event: info.event || undefined,
          filter: {
            filter_name: info.filter.filterName || undefined,
            filter_id: info.filter.filterId || undefined,
            page_id: info.filter.pageId || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'download':
        gtag({
          event: info.event || undefined,
          download: {
            menu_id: info.download.menuId || undefined,
            page_id: info.download.pageId || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;

        

      case 'open_filter':
        gtag({
          event: info.event || undefined,
          open_filter: {
            filter_id: info.openFilter.filterId || undefined,
            page_id: info.openFilter.pageId || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'popup':
        gtag({
          event: info.event || undefined,
          popup: {
            action: info.popup.action || undefined,
            page_id: info.popup.pageId || undefined,
            popup_id: info.popup.popupId || undefined,
            popup_name: info.popup.popupName || undefined,
            popup_tap_name: info.popup.popupTapName || undefined,
            ...(info.popup.shareTeachers && { share_teachers: info.popup.shareTeachers || undefined })
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'popup_error':
        gtag({
          event: info.event || undefined,
          popup_error: {
            error_id: info.popupError.errorId || undefined,
            error_message: info.popupError.errorMessage || undefined,
            popup_id: info.popupError.popupId || undefined,
            popup_name: info.popupError.popupName || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'popup_steps':
        gtag({
          event: info.event || undefined,
          popup_steps: {
            action: info.popupSteps.action || undefined,
            page_id: info.popupSteps.pageId || undefined,
            popup_id: info.popupSteps.popupId || undefined,
            popup_name: info.popupSteps.popupName || undefined,
            popup_step: info.popupSteps.popupStep || undefined,
            ...(info.popupSteps.shareTeachers && { share_teachers: info.popupSteps.shareTeachers || undefined }),
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'popup_steps_error':
        gtag({
          event: info.event || undefined,
          popup_steps_error: {
            error_id: info.popupStepsError.errorId || undefined,
            error_message: info.popupStepsError.errorMessage || undefined,
            popup_id: info.popupStepsError.popupId || undefined,
            popup_name: info.popupStepsError.popupName || undefined,
            popup_step: info.popupStepsError.popupStep || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'sign_up':
        gtag({
          event: info.event || undefined,
          sign_up: {
            page_id: info.signUp.pageId || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'select_tab':
        gtag({
          event: info.event || undefined,
          select_tab: {
            tab_name: info.selectTab.tabName || undefined,
            page_id: info.selectTab.pageId || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'qualification':
        gtag({
          event: info.event || undefined,
          qualification: {
            action: info.qualification.action || undefined,
            page_id: info.qualification.pageId || undefined,
            field: info.qualification.field || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'configuration_selected':
        gtag({
          event: info.event || undefined,
          configuration_selected: {
            action: info.configurationSelected.action || undefined,
            page_id: info.configurationSelected.pageId || undefined,
            section: info.configurationSelected.section || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      case 'configuration_selected_error':
        gtag({
          event: info.event || undefined,
          configuration_selected_error: {
            error_id: info.configurationSelectedError.errorId || undefined,
            error_message: info.configurationSelectedError.errorMessage || undefined,
            page_id: info.configurationSelectedError.pageId || undefined,
            section: info.configurationSelectedError.section || undefined,
          },
          context: {
            timestamp: Date.now()
          } || undefined
        });
        break;
      default:
        gtag({ event: 'unknown_event' });
    }
  }

}
