import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../../shared/models/user.model';
import { EnvService } from '../../services/env.service';

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {
  constructor(private http: HttpClient, private envService: EnvService) { }

  public authenticate(userName: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/Account/Login`, { userName, password });
  }

  public perfil(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Account/perfil`);
  }

  public authenticateExternal(type: string, data: any): Observable<any> {
    console.log(data)
    if (type === 'backoffice' || (type === 'google' && data.token)) {
      return of(data);
    } else if (type === 'Teams') {
      return this.http.post<any>(`${this.envService.apiUrl}/${type}/Login?idToken=${data}`, {});
    }

    else {

      return this.http.post<any>(`${this.envService.apiUrl}/${type}/Login`, { ...data });
    }
  }


  // Nuevo Login de Google






  public isUsuarioVinculadoGoogle(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Google/EsUsuarioVinculado`);
  }

  public obtenerUrlLoginGoogle(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Google/ObtenerUrlLogin`);
  }

  public getGoogleUser(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Google/GetUsuarioVinculado`);
  }

  public isUsuarioVinculadoMicrosoft(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Microsoft/EsUsuarioVinculado`);
  }

  public obtenerUrlLoginMicrosoft(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Microsoft/ObtenerUrlLogin`);
  }

  public getMicrosoftUser(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Microsoft/GetUsuarioVinculado`);
  }

  public logout(): Observable<User> {
    return this.http.put<any>(`${this.envService.apiUrl}/Account/logout`, {});
  }

  public refreshToken() {
    return this.http.put<any>(`${this.envService.apiUrl}/Account/Token`, {});
  }

  public changePassword(password: any) {
    return this.http.put<any>(`${this.envService.apiUrl}/Account/Password`, password);
  }

  public validaLicencia(licencia: string) {
    return this.http.get<any>(`${this.envService.apiUrl}/Account/Licencia/Validar/${licencia}`);
  }

  public anadirLicencia(licencia: string) {
    return this.http.post<any>(`${this.envService.apiUrl}/Account/Licencia`, { licencia });
  }

  public setLicenciaValida(payload: any) {
    return this.http.put<any>(`${this.envService.apiUrl}/Account/Licencia/Activar`, payload);
  }

  public loginIp() {
    return this.http.post<any>(`${this.envService.apiUrl}/account/login/ip`, {});
  }

  public loginReferrer(urlReferrer: string) {
    return this.http.post<any>(`${this.envService.apiUrl}/account/login/referrer`, { urlReferrer });
  }

  public loginWorldbook(obj: any) {
    return this.http.post<any>(`${this.envService.apiUrl}/account/login/worldbook`, obj);
  }


  // TODO Pendiente de api



  public vincularUsuarioGoogleByUserId(userId: any): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Google/VincularByUserId/${userId}`);
  }


  // public obtenerUrlVinculacionUsuarioId(userId: any): Observable<any> {
  //   return this.http.get<any>(`${this.envService.apiUrl}/Google/ObtenerUrlVinculacionUsuarioId/`);
  // }

  public loginWithGoogle(googletoken) {

    return this.http.post<any>(`${this.envService.apiUrl}/Google/ProviderUserToken`, googletoken,);


  }


  public obtenerUrlVinculacionUserId(userId, loginTipo) {

    const body = {
      userId,
      loginTipo
    }

    return this.http.post<any>(`${this.envService.apiUrl}/Google/ObtenerUrlVinculacionUsuarioId`, body);
  }

  /**
   * Get user
   * @param User User to register
   */
  public register(user: any): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/Account/Registrar/${user.tipoLicencia}`, user);
  }

  /**
   * Reset password
   * @param username username to change password
   */
  public resetPassword(username: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.http.post<any>(`${this.envService.apiUrl}/Account/Password/Recuperar`, { username }, { headers });
  }

  /**
   * Reset password
   * @param username username to change password
   */
  public resetPasswordForm(username: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/Account/Password/Recuperar`, username);
  }

  /**
 * Validate password
 * @param password password to validate
 */
  public validatePassword(password: any): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/Account/Password/Validate/${password}`);
  }

  public isValidRefreshToken(socialNetwork: string): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/${socialNetwork}/IsValidRefreshToken`);
  }
}
