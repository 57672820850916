import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'aula-planeta-descargar-csv',
  templateUrl: 'descargar-csv.component.html',
  styleUrls: ['./descargar-csv.component.scss'],
})
export class DescargarCsvComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  showSpinner = false;
  constructor(
    private gtagService: GtagService,
    public dialogRef: MatDialogRef<DescargarCsvComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    const location = this.data.event?.view.location.hash || '';
    const info = {
      event: 'popup',
      popup: {
        action: 'download',
        pageId: location,
        popupId: 'dialog-descargar-csv',
        popupName: 'popup_download'
      }
    };
    this.gtagService.event(info);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  onDownload() {
    this.showSpinner = true;
    this.data.callback(this.data.params)
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(
        {
          next: elem => {
            this.downloadFile(elem);
            this.showSpinner = false;
            this.dialogRef.close();
          },
          error: error => {
            this.showSpinner = false;
            this.dialogRef.close();
            const info = {
              event: 'popup_error',
              popupError: {
                errorId: error?.status,
                errorMessage: error?.statusText,
                popupId: 'dialog-descargar-csv',
                pageId: 'popup_download'
              }
            };
            this.gtagService.event(info);
          }
        }
      );
  }

  downloadFile(blob) {
    const newBlob = new Blob([blob], { type: 'text/csv;' });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');

    link.href = data;
    link.download = this.data.titleFile + '.csv';
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);

  }
}