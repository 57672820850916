import { Subject } from 'rxjs';
import { EventEmitter, OnDestroy, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Directive, Input, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { TemasManagementService } from '../../../services/data/temas/temas-management.service';

@Directive({
  selector: '[cantidadTareasAsignadas]'
})
export class CantidadTareasAsignadasDirective implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  @Input() idSeccion = '';
  @Input() idTema = '';
  @Output() total = new EventEmitter<void>();

  constructor(
    private temasManagementService: TemasManagementService
  ) { }

  ngOnInit(): void {
    this.temasManagementService.getTareasPorSeccion(this.idTema, this.idSeccion).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.total.emit(res.numeroTareas);
        }
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}