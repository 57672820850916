/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LtiManagementService } from '../../../../services/data/lti/lti-management.service';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-add-annotation',
  templateUrl: './add-annotation.component.html',
  styleUrls: ['./add-annotation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddAnnotationComponent implements OnInit {
  form: UntypedFormGroup;
  textLimit = 200;

  constructor(
    public ltiManagementService: LtiManagementService,
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddAnnotationComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      texto: [this.data.texto, Validators.required],
    });

    const location = this.data.event?.view.location.hash || '';
    const info = {
      event: 'popup',
      popup: {
        action: 'add_annotation',
        popupId: 'dialog-add-annotation',
        popupName: 'popup_add_annotation'
      }
    };
    this.gtagService.event(info);
  }

  onClose() {
    this.dialogRef.close({ ...this.form.value }
    );
  }

}

