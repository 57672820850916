<div id="dialog-add-module-texto" style="max-width: 600px;">
    <h1 class="default-text" mat-dialog-title>{{ 'dialogs.edit-module-base.jumbo-tema.title' | translate }}</h1>

    <div mat-dialog-content class="mat-dialog-content">
        <div class="row">
            <form role="form" [formGroup]="formModule" class="empty-section d-flex flex-row w-100">
                <div class="w-100 d-flex flex-column">
                    <div class="form-group default-input mb-0 p-3">
                        <label>{{'dialogs.add-module-base.form.titulo' | translate}}</label>
                        <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.titulo' | translate)" formControlName="tituloHtml">
                        <div class="d-flex justify-content-between align-items-start mt-2">
                            <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('tituloHtml').hasError('required') && formModule.controls['tituloHtml'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>
                    </div>

                    <div class="form-group default-input mb-0 p-3">
                        <label>{{'dialogs.add-module-base.form.descripcion' | translate}}</label>
                        <div ckeditor [editor]="editor" [config]="config" [formControl]="formModule.controls['descripcion_html']"></div>
                        <div class="d-flex justify-content-between align-items-start mt-2">
                            <mat-error class="mt-0 visible mb-3" *ngIf="formModule.get('descripcion_html').hasError('required') && formModule.controls['descripcion_html'].dirty">
                                {{'generico.required' | translate}}
                            </mat-error>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-start mt-3 mb-0">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__primary" (click)="onClose()" [disabled]="!formModule.valid">{{ 'generico.guardar' | translate }}</button>
    </div>
</div>
