<div id="dialog-generico" [ngClass]="colorHelper.getColor(data.color)">
    <div mat-dialog-content class="d-flex w-100 position-relative m-0 p-0">
        <button *ngIf="data.close" aria-label="{{'dialogs.close' | translate}}" class="d-flex align-items-center justify-content-center py-1 default-button default-button__primary position-absolute" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
        <div *ngIf="data.icon" class="col-2 p-0 custom-icon">
            <mat-icon class="mt-2">{{ data.icon }}</mat-icon>
        </div>
        <div class="d-flex flex-column justify-content-between" [ngClass]="data.icon ? 'col-9': 'col-12'" [class.text-center]="data.actions.length === 1">
            <h1 mat-dialog-title class="d-flex align-items-center custom-text mb-3" [class.mx-auto]="data.actions.length === 1">{{ data.titulo }}</h1>
            <p class="mb-0 content">{{ data.contenido }}</p>
            <div *ngIf="data.actions.length > 1" mat-dialog-actions class="justify-content-start mt-3">
                <button *ngFor="let action of data.actions;" role="button" class="default-button me-3" [mat-dialog-close]="action.onClose" [ngClass]="getTypeButton(action.type)">{{ action.key | translate }}</button>
            </div>
            <div *ngIf="data.actions.length === 1" mat-dialog-actions class="justify-content-center mt-3 single-button">
                <button *ngFor="let action of data.actions;" role="button" class="default-button" [mat-dialog-close]="action.onClose" [ngClass]="getTypeButton(action.type)">{{ action.key | translate }}</button>
            </div>
        </div>
    </div>
</div>