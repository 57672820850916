import { Component, ChangeDetectionStrategy, OnDestroy, AfterViewInit } from '@angular/core';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { EditionModeService } from '../../../../services/edition-mode.service';
import { TemasManagementService } from '../../../../services/data/temas/temas-management.service';
import { MateriaManagementService } from '../../../../services/data/materia/materia-management.service';
import { LocalCacheService } from '../../../../core/load-cache/load-cache.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { HighlightsModeService } from '../../../../services/highlights-mode.service';
import { Router, NavigationStart } from '@angular/router';
import { LocalStorageService } from '../../../../core/core.module';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'aula-planeta-edition-header',
  templateUrl: './edition-header.component.html',
  styleUrls: ['./edition-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditionHeaderComponent implements AfterViewInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  private routerSubscription: Subscription;
  hasUnsavedChanges = true; // Este debe ser tu estado real
  isNavigating = false;
  private scrollPosition = 0;
  jwt;

  constructor(
    public editionModeService: EditionModeService,
    public highlightsModeService: HighlightsModeService,
    public localStorageService: LocalStorageService,
    public breadcrumbService: BreadcrumbService,
    private temasManagementService: TemasManagementService,
    private materiaManagementService: MateriaManagementService,
    private localCacheService: LocalCacheService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.localStorageService.getItem('JWT').pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(jwt => {
        this.jwt = jwt;
      });

    // Añade una entrada al historial para interceptar la navegación hacia atrás
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', this.handleBrowserBack.bind(this));
    window.addEventListener('beforeunload', this.beforeUnloadNotification.bind(this));
    window.addEventListener('unload', this.unloadHandler.bind(this));

    this.editionModeService.enable$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(enabled => {
      this.hasUnsavedChanges = enabled;
    });
  }

  ngAfterViewInit(): void {
    this.temasManagementService.restoreScrollPosition();
  }

  save() {
    this.hasUnsavedChanges = false;
    this.temasManagementService.unsetTemaEnEdicion(this.temasManagementService.tema.id).pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(
      () => {
        if (this.highlightsModeService.highlightsToDelete.length > 0) {
          this.highlightsModeService.deleteMassive();
        }
        this.temasManagementService.updateTema(this.temasManagementService.tema, parseInt(this.materiaManagementService.materia.id, 10));
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS')
          .pipe(take(1), takeUntil(this.destroy$)).subscribe(() => this.materiaManagementService.getMisMaterias());
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS')
          .pipe(take(1), takeUntil(this.destroy$)).subscribe(() => this.materiaManagementService.getMisLibros());
        this.localCacheService.expire('MATERIADATASERVICE-GETASIGNATURAS')
          .pipe(take(1), takeUntil(this.destroy$)).subscribe(() => this.materiaManagementService.getAsignaturas());
        this.editionModeService.enable = false;
        this.editionModeService.fromDuplicarUnidad = false;
        this.editionModeService.headerData = {};
        this.editionModeService.closeWiris();
      }
    );
  }

  close() {
    this.hasUnsavedChanges = false;
    if (this.highlightsModeService.highlightsToDelete.length > 0) {
      this.highlightsModeService.highlightsToDelete = [];
    }

    this.localCacheService.expire(`TEMASDATASERVICE-GETTEMA-${this.temasManagementService.tema.id}`)
      .pipe(take(1), takeUntil(this.destroy$)).subscribe();
    this.localCacheService.expire(`MATERIADATASERVICE-GETMATERIA-${this.materiaManagementService.materia.id}`)
      .pipe(take(1), takeUntil(this.destroy$)).subscribe();

    this.temasManagementService.unsetTemaEnEdicion(this.temasManagementService.tema.id).pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(
      () => {
        this.materiaManagementService.getMisMaterias();
        this.materiaManagementService.getAsignaturas();
        this.materiaManagementService.getMisLibros();
        this.temasManagementService.getTema(this.temasManagementService.tema.id);
        this.editionModeService.enable = false;
        this.editionModeService.fromDuplicarUnidad = false;
        this.editionModeService.headerData = {};
        this.editionModeService.closeWiris();
      }
    );
  }

  handleBrowserBack(event: PopStateEvent) {
    if (this.editionModeService.enable) {

      if (this.hasUnsavedChanges && !this.isNavigating) {
        event.preventDefault();
        window.history.pushState(null, '', window.location.href); // Volver a añadir el estado ficticio
        console.log('Navegación cancelada por el usuario');

        const confirmNavigation = confirm(this.translateService.instant('notificaciones.edicion.alerta'));
        if (!confirmNavigation) {
          this.restoreScrollPosition();
        } else {
          this.hasUnsavedChanges = false;
          this.editionModeService.enable = false;
          this.isNavigating = true;
          // Permitir la navegación hacia atrás manualmente
          window.history.go(-1); // Ajustar si es necesario
        }
      }
    }
  }

  private restoreScrollPosition(): void {
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 0);
  }

  // Manejar evento de cerrar o refrescar la ventana
  beforeUnloadNotification(event: any): void {
    if (this.hasUnsavedChanges) {
      event.preventDefault();
      event.returnValue = this.translateService.instant('notificaciones.edicion.alerta'); // Advertencia del navegador
      return this.translateService.instant('notificaciones.edicion.alerta');
    }
  }

  // Handler para el evento unload
  unloadHandler($event: any): void {
    if (this.hasUnsavedChanges) {
      this.close();
    }
  }

  ngOnDestroy(): void {
    if (this.temasManagementService.tema) {
      this.temasManagementService.unsetTemaEnEdicion(this.temasManagementService.tema.id).pipe(
        take(1),
        takeUntil(this.destroy$)
      ).subscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    window.removeEventListener('popstate', this.handleBrowserBack.bind(this));
    window.removeEventListener('beforeunload', this.beforeUnloadNotification.bind(this));
    window.removeEventListener('unload', this.unloadHandler.bind(this));
  }
}
